'use strict';
require('angular-messages');

module.exports =
  angular
    .module('bookdoc.contact', [
      'ui.bootstrap',
      'ui.router',
      'ngMessages',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('./services').name,
      require('./controllers').name,
      require('./directives').name
    ]);
