"use strict";

function EBPersonalInfoCtrl() {
  var ctrl = this;
  console.log(ctrl.personalInfo);
  console.log(ctrl.practiceLocation);
}

var ebPersonalInfoComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/eb-personal-info.html',
    controller: EBPersonalInfoCtrl,
    bindings: {
      personalInfo: '<',
      practiceLocation: '<',
      claimLimit: '<'
    }
  };
};

EBPersonalInfoCtrl.$inject = [];

module.exports = ebPersonalInfoComponent();
