'use strict';
module.exports =
  angular
    .module('bookdoc.calendar', [
      'ui.bootstrap',
      'ui.router',
      'restangular',
      'angularMoment',
      'ct.ui.router.extras.sticky',
      'ct.ui.router.extras.dsr',
      'duScroll',
      'timer',
      require('../../../tmp/templates').name,
      require('../common').name,
      require('../profile').name,
      require('./availability').name,
      require('./appointments').name,
      require('./controllers').name
    ])
    .constant('weekCount', '7');

require('./calendar.routes');
