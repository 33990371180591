"use strict";

function DiagnoseFormItemCtrl(ebService) {
  var ctrl = this;


  ctrl.$onInit = function () {
    if (!ctrl.validateDiagnoseObsberver)
      ctrl.validateDiagnoseObsberver = ctrl.validateDiagnoseSubject.subscribe(function () {
        validateDiagnose();
      });
    if (ctrl.diagnose.id && !ctrl.diagnose.diagnosis_id)
      ctrl.diagnose.diagnosis_id = ctrl.diagnose.id;
  };

  function validateDiagnose() {
    if (!ctrl.diagnose.diagnosis_id) {
      ctrl.diagnosisIsMissing = true;
    }
  }

  ctrl.getDiagnosis = function (query) {
    return ebService
      .getClaimAutoCompleteList(query, ctrl.claimId, "diagnosis")
      .then(function (response) {
        return response.result.filter(function (diagnosis) {
          return !ctrl.bannedDiagnoses.includes(diagnosis.name);
        });
      });
  };

  ctrl.onSelectDiagnosisCallback = function (item, model, label) {
    ctrl.diagnose.diagnosis_id = item.id;
    ctrl.diagnose.name = item.name;
    ctrl.diagnosisIsMissing = false;
  };

  ctrl.onRemoveDiagnoseClicked = function () {
    ctrl.onRemoveBtnClicked({index: ctrl.index});
  };

  ctrl.onClearDiagnose = function () {
    ctrl.diagnose.diagnosis_id = null;
    ctrl.diagnose.name = "";
    ctrl.diagnoseName = "";
    if (ctrl.diagnose.id) {
      ctrl.diagnose.hasBeenEdited = true;
    }
  };
}

var DiagnoseFormItemComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/diagnose-form-item.html',
    controller: DiagnoseFormItemCtrl,
    bindings: {
      index: '<',
      diagnose: '<',
      claimId: '<',
      bannedDiagnoses: '=',
      validateDiagnoseSubject: '<',
      onRemoveBtnClicked: '&'
    }
  };
};

DiagnoseFormItemCtrl.$inject = ['ebService'];

module.exports = DiagnoseFormItemComponent();
