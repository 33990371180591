'use strict';

var _ = require('lodash');

var ReferDoctor = function ($modalInstance, $modal, APP_CONSTANT, ValidateMessagesService, referApiService, referService, sendData, $rootScope) {
  var vm = this;
  
  vm.pattern = APP_CONSTANT.patterns;

  vm.validateMessages = ValidateMessagesService;

  vm.doctorName = '';

  vm.practiceLocationName = '';

  var referralData = sendData.referralData;

  var referFromCalendar = sendData.referFromCalendar;

  // Close referral modal
  vm.closeModal = function () {
    $modalInstance.dismiss();
  };

  // Submit suggest doctor
  vm.suggestDoctor = function () {
    vm.suggestForm.$setSubmitted();

    if (vm.suggestForm.$valid) {
      referralData.name = vm.doctorName;
      referralData.practice_location = vm.practiceLocationName;
      
      referApiService.suggestDoctor(referralData)
        .then(
          function (data) {
            referService.showConfirmModal();
            if (!referFromCalendar) {
              $rootScope.$broadcast('getReferral');
            }
          },

          function (error) {
            if (error.status === 422 && error.data) {
              referService.showReferralError(error.data);
            }
          }
        );
    }
  };
};

ReferDoctor.$inject = [
  '$modalInstance',
  '$modal',
  'APP_CONSTANT',
  'ValidateMessagesService',
  'referApiService',
  'referService',
  'sendData',
  '$rootScope'
];

module.exports = ReferDoctor;
