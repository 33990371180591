'use strict';

module.exports =
  angular
    .module('bookdoc.common.filters', [])
    .filter('timeFilter', require('./time.filter'))
    .filter('phoneFilter', require('./phone.filter'))
    .filter('languageFilter', require('./language.filter'))
    .filter('practiceLocationFilter', require('./practice-location.filter'))
    .filter('capitalize', require('./capitalize.filter'))
    .filter('specialtiesFilter', require('./specialty.filter'))
    .filter('timestampToHour', require('./time-stamp-to-hour.filter'))
    .filter('dateTimeToYear', require('./date-time-to-year.filter'))
    .filter('auditLogHourFormat', require('./audit-log-hour-format.filter'));
