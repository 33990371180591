'use strict';

var _ = require('lodash');

var ConfirmRefer = function ($modalInstance, $modal, $modalStack) {
  var vm = this;

  // Close referral modal
  vm.closeModal = function () {
    $modalInstance.dismiss();
  };

  vm.closeAllModal = function () {
    $modalStack.dismissAll();
  };


};

ConfirmRefer.$inject = [
  '$modalInstance',
  '$modal',
  '$modalStack',
];

module.exports = ConfirmRefer;
