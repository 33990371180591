/* global moment*/

'use strict';

var languageFilter = function() {
  return function (languageList) {
    var formattedLanuage = '';

    if(languageList) {
      var tmpArray = languageList.split('|');
      formattedLanuage = tmpArray.join(', ');
    }

    return formattedLanuage;
  };
};

languageFilter.$inject = [

];

module.exports = languageFilter;

