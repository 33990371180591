/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.contact.services',
    [
      'ngResource'
    ]
  );
  require('./contact.service');
