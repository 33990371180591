'use strict';

var states = function ($stateProvider) {
  // var token = Session.token;

  $stateProvider
    .state('main.terms', {
      url: 'terms?isGrouping/',
      params : {
        token: [
          'Auth.AuthSession',

          function (Session) {
            return Session.token;
          }

        ]
      },
      templateUrl: "terms/views/terms-of-use.html",
      controller: 'TermsCtrl',
      controllerAs: 'term'
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.terms')
  .config(states);
