'use strict';

var $ = require('jquery');

var whenScrolledDirective = function ($document, $window) {
  return {
    restrict: 'A',
    link: function(scope, elem, attrs){

      // we load more elements when scrolled past a limit
      $document.bind('scroll', function(){

        // 50 is height of footer
        if($window.pageYOffset + $window.innerHeight + 50 >= $document.height()) {
          scope.$apply(attrs.whenScrolled);
        }
      });
    }
  };
};

whenScrolledDirective.$inject = [
  '$document',
  '$window'
];

module.exports = whenScrolledDirective;
