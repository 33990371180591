'use strict';

var _ = require('lodash');

var InsuranceEmployersModalCtrl = function ($modalInstance, InformationAPI, $modal, profile, $q, $scope, SearchDropdownService, APP_CONSTANT, ValidationFileTypeService, ValidateMessagesService, InsuranceService, ChainRequestService, CommonModals) {
  var vm = this;

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {
    vm.profile = profile.data;
    vm.allInsurances = [];
    vm.insurances = [];
    vm.insurancesBackup = [];
    vm.isUpdating = false;

    vm.errorMessage = {
      insuranceSelect: false,
      insuranceDuplicate: false,
      fileSize: false,
      wrongType: false,
      disableAdd: false
    };

    vm.validateMessages = ValidateMessagesService;

    // Define document format
    vm.documentsFormat = APP_CONSTANT.documentFileFormat;

    vm.currentIndex = 0;

    vm.viewInsurance = profile.viewInsurance;
    vm.viewCorporate = !vm.viewInsurance;
  }

  createVariables();

  // Init practice location index
  var practiceLocationIndex = profile.practiceLocationIndex;

  // Init practice location ID
  var practiceLocationId = vm.profile.locations[practiceLocationIndex].id;

  vm.changeCurrentIndex = function(index) {
    vm.currentIndex = index;
  };

  // Handle when select file
  $scope.changeFile = function(element) {

    var documentFile = element.files[0];

    if (!documentFile) {
      return;
    }

    var fileType = documentFile.type;
    var isValidType = ValidationFileTypeService.checkFileType(fileType, vm.documentsFormat);
    vm.errorMessage.wrongType = isValidType;

    if (isValidType) {

      $scope.$apply(function() {
        vm.fileSize = documentFile.size;
        vm.inValidFileSize = ValidationFileTypeService.checkFileSize(vm.fileSize);

        if (vm.inValidFileSize) {

          vm.errorMessage.fileSize = true;
          vm.insurances[vm.currentIndex].fileName = documentFile = '';

        } else {

          $scope.$emit('updateLoadingStatus', true);

          vm.errorMessage.fileSize = false;
          vm.documentData = documentFile;

          var chain = ChainRequestService.startQueue();

          if (vm.insurances[vm.currentIndex].letter_appointment && vm.insurances[vm.currentIndex].isNewInsurance) {

            // delete old document
            chain = chain.then(deleteLetter);
            chain = chain.then(function (data) {
              handleDeleteSuccess();
            });
          }
          vm.isUpdating = true;
          // upload document
          chain = chain.then(uploadLetter);
          chain = chain.then(function (data) {
            handleUploadSuccess(data);
            vm.insurances[vm.currentIndex].fileName = documentFile.name;
            vm.errorMessage.disableAdd = !InsuranceService.isEmptyElement(vm.insurances);
          }, function (err) {
            $scope.$emit('updateLoadingStatus', false);
            vm.isUpdating = false;
          });
        }

        vm.errorMessage.wrongType = false;
      });

    } else {
      $scope.$apply(function() {
        vm.errorMessage.wrongType = true;
        vm.insurances[vm.currentIndex].fileName = documentFile = '';
      });
    }
  };

  vm.removeLetter = function(index) {
    vm.currentIndex = index;
    var chain = ChainRequestService.startQueue();
    chain = chain.then(deleteLetter);
    chain = chain.then(function (data) {
      handleDeleteSuccess();
    });
  };

   // Call service to upload letter
  function uploadLetter() {
    return InformationAPI
          .insurances
          .uploadLetter(vm.documentData);
  };

  // Call service to delete letter
  function deleteLetter() {
    var documentID = vm.insurances[vm.currentIndex].letter_appointment;
    return InformationAPI
          .insurances
          .deleteLetter(documentID);
  };

  // Delete insurance
  vm.deleteInsurance =  function(index) {
    vm.currentIndex = index;

    if (!vm.insurances[vm.currentIndex].isNewInsurance) {
      vm.removeInsuranceData();
    } else {
      var chain = ChainRequestService.startQueue();

      if (vm.insurances[vm.currentIndex].letter_appointment) {
        chain = chain.then(deleteLetter);
        chain = chain.then(function (data) {
          handleDeleteSuccess();
        });
      } 
      chain = chain.then(function () {
        vm.removeInsuranceData();
      });
    }
  };
  // Handle when upload success
  function handleUploadSuccess(data) {
    vm.insurances[vm.currentIndex].letter_appointment = data.id;
    $scope.$emit('updateLoadingStatus', false);
    vm.isUpdating = false;
  };

  // Handle when delete success
  function handleDeleteSuccess() {
    angular.element('.insurance' + vm.currentIndex + ' ' + '.upload-letter').val(null);
    vm.insurances[vm.currentIndex].fileName = '';
    vm.insurances[vm.currentIndex].letter_appointment = null;
    vm.errorMessage.disableAdd = true;
  };

  /**
   * Get all insurances
   * @return {array}
   */
  function fetchInsuranceData() {

    vm.insurances = angular.copy(vm.profile.locations[practiceLocationIndex].insurances) || [];
    vm.insurances = InsuranceService.formatData(vm.insurances, practiceLocationId);
    vm.insurancesBackup = InsuranceService.sortById(angular.copy(vm.insurances));

    InformationAPI
      .insurances
      .getInsurances()
      .then(
        function (data) {
          vm.allInsurances = data;
        },
        function (data) {
          console.log(data);
        }
      );
  }

  function fetchEmployerData() {
    
    vm.employers = _.filter(vm.profile.locations[practiceLocationIndex].employers, function(employer) {
      return employer.verified;
    });

  }

  /**
   * Validate insurance
   * @param  {Function} callback
   */
  function insuranceValidate() {
    vm.errorMessage.insuranceSelect = false;
    vm.errorMessage.insuranceDuplicate = false;

    if (InsuranceService.checkNoExitedInsuranceName(vm.insurances)) {
      vm.errorMessage.insuranceSelect = true;
      return false;
    }

    if (InsuranceService.hasDuplicates(vm.insurances)) {
      vm.errorMessage.insuranceDuplicate = true;
      return false;
    }

    return true;
  }

  fetchInsuranceData();
  // FIX ME: hide ability to add corporate from doctor portal
  fetchEmployerData();

  /**
   * Get value when choose insurance
   * @param  {number} id
   * @param  {string} name
   * @param  {number} index
   * @param  {object} item
   */
  vm.getValue = function (element, id, name, index, item) {
    element[index].id = id;
    element[index].name = name;
    vm.selectedItem = item;
    vm.selectedName = name;

  };

  vm.setInsuranceSelected = function (id, name, index, item) {

    vm.getValue(vm.insurances, id, name, index, item);
    insuranceValidate();
    vm.errorMessage.disableAdd = !InsuranceService.isEmptyElement(vm.insurances);

  };

  /* Show modal thank you update */
  vm.notificationModal = function () {

    $modal.open({
      animation: false,
      templateUrl: 'profile/views/information/location-thank.modal.html',
      controller: require('./location-thank.controller'),
      controllerAs: 'locaTks',
      windowClass: 'modal-info modal-thank-update'
    });

  };

  function saveInsurances () {
    return InformationAPI
      .insurances
      .saveInsurances(vm.insuranceResquestJson);
  }

  /**
   * Update insurances and employers
   */
  vm.updateInsurancesEmployers = function () {
    
    vm.insuranceForm.$setSubmitted();

    vm.hasError = false;

    if (!insuranceValidate()) {
      vm.hasError = true;
    }

    if (!vm.insuranceForm.$valid) {
      return;
    }

    /* Check has error */
    if (vm.hasError) {
      return;
    }

    if (!vm.isUpdating) {
      vm.isUpdating = true;
    } else {
      return;
    }

    /* Create promise */
    var deferred = $q.defer();
    deferred.resolve();
    var chain = deferred.promise;

    $scope.$emit('updateLoadingStatus', true);

    /* Check if has change insurances */
    // if (!vm.insuranceNoUpdate) {
      var insurancesAdd =  InsuranceService.compareArray(vm.insurances, vm.insurancesBackup, true);
      var insurancesRemove = InsuranceService.compareArray(vm.insurancesBackup, vm.insurances, false);

      vm.insuranceResquestJson = {
        "practice_location_id": practiceLocationId,
        "insurances": insurancesRemove.concat(insurancesAdd)
      };

      if (vm.insuranceResquestJson.insurances.length) {

        /* Send request to backend */
        chain = chain.then(saveInsurances);
        chain = chain.then(
          function (data) {
            // Update insurance
            vm.profile.locations[practiceLocationIndex].insurances = data;

            // Update status of approved icon 
            var insuranceCount = data.length;
            for (var i = 0; i < insuranceCount; i++) {
              if (!data[i].verified) {

                vm.showNotApprovedIcon = true;
                break;

              } else {

                vm.showNotApprovedIcon = false;
              }
            } 

            vm.profile.locations[practiceLocationIndex].showNotApprovedInsurance = vm.showNotApprovedIcon;
          }
        );

        /* Update data to view */
        chain = chain
          .then(function (data) {
            $modalInstance.close();
            // FIX ME: hide ability to add corporate from doctor portal
            // if (vm.insurancesHasUpdate || vm.employersHasUpdate) {
            if (insurancesAdd.length) {
              vm.notificationModal();
            }

          },
          function (err) {
            console.log(err);
          })
          .finally(function() {
            vm.isUpdating = false;
            $scope.$emit('updateLoadingStatus', false);
            vm.cancel();
          });
      } else {
        vm.isUpdating = false;
        $scope.$emit('updateLoadingStatus', false);
        vm.cancel();
      }
  };

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }
  });

  /**
   * Add new insurance
   */
  vm.addInsurance = function () {

    if (!vm.errorMessage.disableAdd) {
      var newInsurance = {
        id: 0,
        name: 'Select Insurance Name',
        fileName: '',
        isNewInsurance: true
      };

      vm.insurances.push(newInsurance);

      vm.errorMessage.insuranceSelect = InsuranceService.checkNoExitedInsuranceName(vm.insurances);

      vm.errorMessage.disableAdd = true;
    }
  };

  /**
   * Remove insurance
   * @param  {number} insurance index
   * @return {array}
   */
  vm.removeInsuranceData = function () {
    vm.insurances.splice(vm.currentIndex, 1);
    insuranceValidate();
    vm.errorMessage.disableAdd = !InsuranceService.isEmptyElement(vm.insurances);
  };

  /**
   * Cancel modal
   */
  vm.cancel = function () {
    if (!vm.isUpdating) {
      $modalInstance.close();
      vm.insuranceResquestJson = {};
      // FIX ME: hide ability to add corporate from doctor portal
      // vm.employerResquestJson = {};
    } else {
      return;
    }
  };

  /* Handle auto complete for insurance dropdown*/

  // handle when toggle dropdown
  vm.toggleDropdown = function () {
    vm.searchKey = '';
    SearchDropdownService.setAutoFocus();
  };

  // keep dropdown open when click on input tab
  vm.keepDropdownOpen = function($event) {
    $event.stopPropagation();
  };

};

InsuranceEmployersModalCtrl.$inject = [
  '$modalInstance',
  'bookdoc.information.api',
  '$modal',
  'profile',
  '$q',
  '$scope',
  'SearchDropdownService',
  'APP_CONSTANT',
  'ValidationFileTypeService',
  'ValidateMessagesService',
  'InsuranceService',
  'ChainRequestService',
  'CommonModals'
];

module.exports = InsuranceEmployersModalCtrl;
