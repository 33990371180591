'use strict';


var signatureFormDirective = function($rootScope, APP_CONSTANT, ValidationFileTypeService, RegistrationAPI, CommonModals, ValidateMessagesService, ChainRequestService) {
  return {
    restrict: 'E',
    scope: {
      signatureFormId: '=signatureFormId',
      registrationData: '=registrationData',
      signatureFormActive: '=signatureFormActive'
    },
    templateUrl: 'registration/views/signature-form.html',
    link: function(scope, elem, attrs) {

      scope.linkDownload = '';

      scope.$on('updateSignatureFormUrl', function(event, data) {
        var speciatiesList = data,
            speciatiesListLength = speciatiesList.length;

        for(var i = 0; i < speciatiesListLength; i++) {
          if (speciatiesList[i] === 'General Practitioner (GP Doctor)' || speciatiesList[i] === 'Dentist (General)' || speciatiesList[i] === 'GP Doctor (Aesthetic Medicine)') {
            scope.linkDownload = 'assets/downloads/Doctor_Registration_Form__GP_.pdf';
            return false;
          } else {
            scope.linkDownload = 'assets/downloads/Doctor_Registration_Form__Specialist_.pdf';
          }
        }
      });

      scope.documentFileFormat = APP_CONSTANT.documentFileFormat;

      scope.validateMessages = ValidateMessagesService;

      scope.invaliSignature = {
        fileSize: false,
        wrongType: false
      };

      scope.signatureFileName = '';

      scope.isUploading = false;

      scope.uploadElement = angular.element('#uploadFile');

      scope.documentData = {};

      // Handle when select file
      scope.changeFile = function(element) {
        var documentFile = element.files[0];
        if (!documentFile) {
          return;
        }
        var fileType = documentFile.type;
        var isValidType = ValidationFileTypeService.checkFileType(fileType, scope.documentFileFormat);

        scope.invaliSignature.wrongType = isValidType;

        if (isValidType) {

          scope.$apply(function(scope) {

            scope.invaliSignature.wrongType = false;

            scope.fileSize = documentFile.size;

            scope.invaliSignature.fileSize = ValidationFileTypeService.checkFileSize(scope.fileSize);

            if (!scope.invaliSignature.fileSize) {
              scope.documentData = documentFile;
              scope.isUploading = true;
              var chain = ChainRequestService.startQueue();

              if (scope.signatureFormId) {
                // delete old document
                chain = chain.then(deleteSignatureForm);
                chain = chain.then(function (data) {
                  handleDeleteSuccess();
                });
              }

              // upload document
              chain = chain.then(uploadSignatureForm);
              chain = chain.then(function (data) {
                handleUploadSuccess(data);
                scope.signatureFileName = documentFile.name;
              }, function (err) {
                CommonModals.openBaseModal("Server error!");
              });
            } else {

              scope.signatureFileName = '';
            }

          });

        } else {
          scope.$apply(function(scope) {
            scope.signatureFileName = '';
            scope.invaliSignature.wrongType = true;
          });
        }
      };

      // Call service to upload APC
      function uploadSignatureForm() {
        return RegistrationAPI.signatureForm.addSignatureForm(scope.documentData); 
      };

      // Call service to delete APC
      function deleteSignatureForm() {
        return RegistrationAPI.signatureForm.deleteSignatureForm(scope.signatureFormId);
      };

      // Handle when upload success
      function handleUploadSuccess(data) {
        scope.signatureFormId = data.id;
        scope.isUploading = false;
      };

      // Handle when delete success
      function handleDeleteSuccess() {
        scope.uploadElement.val(null);
        scope.signatureFileName = '';
        scope.signatureFormId = null;
      };

      // Delete APC document
      scope.deleteSignatureDocument = function() {
        var chain = ChainRequestService.startQueue();
        chain = chain.then(deleteSignatureForm);
        chain = chain.then(function (data) {
          handleDeleteSuccess();
        });
      };

      scope.$on('validateSignatureForm', function(event) {
        scope.signatureForm.$setSubmitted();
        if (scope.signatureForm.$valid) {
          $rootScope.$broadcast('validateLatestApcForm');
        } else {
          scope.signatureFormActive = true;
        }
      });
    }
  };
};

signatureFormDirective.$inject = [
  '$rootScope',
  'APP_CONSTANT',
  'ValidationFileTypeService',
  'RegistrationAPI',
  'CommonModals',
  'ValidateMessagesService',
  'ChainRequestService'
];

module.exports = signatureFormDirective;
