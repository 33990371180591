"use strict";

var chatService = function (Restangular, quickBloxService) {
  var service = {
    getChatSessions: getChatSessions,
    getChatSession: getChatSession,
    getChatSessionTwilio: getChatSessionTwilio,
    getChatHealthInfoHistory: getChatHealthInfoHistory,
    getChatMedication: getChatMedication,
    createChatMedication: createChatMedication,
    updateChatMedication: updateChatMedication,
    getChatMedicationHistory: getChatMedicationHistory,
    getPatientAffectedBodyParts: getPatientAffectedBodyParts,
    getPatientInitials: getPatientInitials,
    getTotalChatNotifications: getTotalChatNotifications,
    generateQRCode: generateQRCode,
    getDoctorChatReviews: getDoctorChatReviews,
    saveChatNote: saveChatNote,
    initializeCall: initializeCall,
    requestUploadFile: requestUploadFile,
    createFollowUpVideoSession: createFollowUpVideoSession,
    getTwilioToken: getTwilioToken,
    getTwilioTokens: getTwilioTokens,
    updateReadStatus: updateReadStatus,
    userFirstMessage: userFirstMessage,
    updateHasReplied: updateHasReplied,
  };

  /**
   * List all chat sessions for Ongoing/History
   * @param {*} successCallback
   * @param {*} failureCallback
   * @param {*} isOngoing
   * @param {*} page
   * @param {*} perPage
   */
  function getChatSessions(
    successCallback,
    failureCallback,
    indexType,
    page,
    perPage,
    search
  ) {
    var params = {
      page: page,
      per_page: perPage,
      query: search,
    };

    var path = "";

    switch (indexType) {
      case "isOnGoing":
        path = "chat_sessions";
        params["type"] = "ongoing";
        break;
      case "isUpcoming":
        path = "chat_sessions";
        params["type"] = "upcoming";
        break;
      case "isHistory":
        path = "chat_sessions/history";
        break;
      default:
        break;
    }

    Restangular.all(path)
      .customGET("", params)
      .then(
        function (sessions) {
          if (sessions != null) successCallback(sessions, indexType);
        },
        function (error) {
          failureCallback(error);
          console.log(error);
        }
      );
  }

  function getTwilioToken(successCallback) {
    Restangular.all("/twilio_chat_token_json")
      .customGET("")
      .then(
        function (twilioToken) {
          successCallback(twilioToken.token);
        },
        function (error) {
          console.log(error);
        }
      );
  }

  /* get multiple tokens */
  function getTwilioTokens(successCallback) {
    Restangular.all("/twilio_chat_tokens")
      .customGET("")
      .then(
        function (twilioTokens) {
          successCallback(twilioTokens.tokens);
        },
        function (error) {
          console.log(error);
        }
      );
  }

  function getChatHealthInfoHistory(
    successCallback,
    failureCallback,
    chatSessionId,
    page,
    startDate,
    endDate
  ) {
    var params = {
      page: page,
      start_date: startDate,
      end_date: endDate,
    };

    Restangular.all(
      "chat_sessions/" + chatSessionId + "/chat_health_info/history"
    )
      .customGET("", params)
      .then(
        function (healthInfoHistory) {
          if (healthInfoHistory != null) successCallback(healthInfoHistory);
        },
        function (error) {
          failureCallback(error);
          console.log(error);
        }
      );
  }

  function updateReadStatus(id, newMessage) {
    var params = {
      read_by_doctor: 1,
    };
    if (newMessage === 0) params["read_by_user"] = 0;
    Restangular.one("chat_sessions/" + id + "/update_read_status").customPUT(
      params,
      null
    );
  }

  function userFirstMessage(id) {
    Restangular.one("chat_sessions/" + id + "/user_first_message").customPUT(
      null,
      null
    );
  }

  function updateHasReplied(id) {
    Restangular.one("chat_sessions/" + id + "/has_replied").customPUT(
      null,
      null
    );
  }

  function getChatMedication(chatSessionId, successCallback, failureCallback) {
    Restangular.all("chat_sessions/" + chatSessionId + "/chat_medication")
      .customGET("", null)
      .then(
        function (chatMedication) {
          successCallback(chatMedication);
        },
        function (error) {
          failureCallback(error);
          console.log(error);
        }
      );
  }

  function createChatMedication(
    chatSessionId,
    payload,
    successCallback,
    errorCallback
  ) {
    Restangular.one("chat_sessions/" + chatSessionId + "/chat_medication")
      .customPOST(payload, null)
      .then(successCallback, errorCallback);
  }

  function updateChatMedication(
    chatSessionId,
    payload,
    successCallback,
    errorCallback
  ) {
    Restangular.one("chat_sessions/" + chatSessionId + "/chat_medication")
      .customPUT(payload, null)
      .then(successCallback, errorCallback);
  }

  function getChatMedicationHistory(
    successCallback,
    failureCallback,
    chatSessionId,
    page,
    startDate,
    endDate
  ) {
    var params = {
      page: page,
      start_date: startDate,
      end_date: endDate,
    };

    Restangular.all(
      "chat_sessions/" + chatSessionId + "/chat_medication/history"
    )
      .customGET("", params)
      .then(
        function (chatMedication) {
          if (chatMedication != null) successCallback(chatMedication);
        },
        function (error) {
          failureCallback(error);
          console.log(error);
        }
      );
  }

  /**
   * Get a particular chat session by Id
   * @param {*} qbDialogId
   * @param {*} successCallback
   * @param {*} errorCallback
   */
  function getChatSession(qbDialogId, successCallback, errorCallback) {
    return Restangular.one("chat_sessions/quickblox", qbDialogId)
      .get()
      .then(
        function (session) {
          if (session != null) successCallback(session);
        },
        function (error) {
          errorCallback(error);
          console.log(error);
        }
      ).cancel;
  }

  function getChatSessionTwilio(twilioSid, successCallback, errorCallback) {
    return Restangular.one("chat_sessions/twilio", twilioSid)
      .get()
      .then(
        function (session) {
          if (session != null) successCallback(session);
        },
        function (error) {
          errorCallback(error);
          console.log(error);
        }
      ).cancel;
  }

  /**
   * Get corresponding chat sessions for quickblox ids
   * @param {*} successCallback
   * @param {*} failureCallback
   */
  function getChatSessionsQbDialogIds(successCallback, failureCallback) {
    var params = {};
    Restangular.all("chat_sessions/qb_ids")
      .customGET("", params)
      .then(
        function (sessions) {
          if (sessions != null) successCallback(sessions);
        },
        function (error) {
          failureCallback(error);
          console.log(error);
        }
      );
  }

  function getPatientAffectedBodyParts(body_part) {
    var parts = "Multiple Parts";

    if (
      Object.keys(body_part).length == 1 &&
      body_part[Object.keys(body_part)[0]].length == 1
    ) {
      parts =
        Object.keys(body_part)[0] +
        " - " +
        body_part[Object.keys(body_part)[0]][0];
    }
    return parts;
  }

  function getPatientInitials(fullname) {
    var patienFullNameArray = fullname.split(" ");
    var patienInitials = patienFullNameArray[0].substring(0, 1);
    if (patienFullNameArray.length > 1)
      patienInitials += patienFullNameArray[1].substring(0, 1);
    return patienInitials;
  }

  /**
   * Call to get all unread chat notification messages
   * DEPRECATED
   * @param {*} successCallback
   * @param {*} errorCallback
   */
  function getTotalChatNotifications(successCallback, errorCallback) {
    var chatSessionsSuccessCallback = function (sessions) {
      if (sessions.qb_ids.length > 0)
        loadChatDialogsFromQuickBlox(
          successCallback,
          errorCallback,
          sessions.qb_ids
        );
      else successCallback(0);
    };

    var chatSessionsErrorCallback = function () {
      errorCallback("Error happens while fetching sessions");
    };

    getChatSessionsQbDialogIds(
      chatSessionsSuccessCallback,
      chatSessionsErrorCallback
    );
  }

  /**
   * Load chat dialogs from Quickblox
   * @param {*} successCallback
   * @param {*} errorCallback
   * @param {*} dialogIds
   */
  var loadChatDialogsFromQuickBlox = function (
    successCallback,
    errorCallback,
    dialogIds
  ) {
    var success = function (dialogs) {
      var totalNumberOfNotifications = 0;
      for (var i = 0; i < dialogs.items.length; i++) {
        totalNumberOfNotifications += dialogs.items[i].unread_messages_count;
      }
      quickBloxService.joinQuickBloxDialogs(dialogIds); // we have to join the quickblox dialogs so that we can receive notifications on new incoming messages.
      successCallback(totalNumberOfNotifications);
    };

    var error = function (err) {
      errorCallback("Error in getting the dialogs from quickblox");
    };

    quickBloxService.getDialogs(dialogIds, success, error);
  };

  function generateQRCode(successCallback, errorCallback) {
    Restangular.one("/promo_codes/qr ")
      .post()
      .then(
        function (response) {
          console.log(response);
          successCallback(response.chat_promo_code.qr_url);
        },
        function (error) {
          errorCallback(error);
        }
      );
  }

  function getDoctorChatReviews(doctorId, successCallback, errorCallback) {
    Restangular.one(/doctor_chat_infos/, doctorId)
      .get()
      .then(
        function (doctorInfo) {
          successCallback(doctorInfo);
        },
        function (error) {
          errorCallback(error);
        }
      );
  }

  function saveChatNote(chatId, notes, successCallback, errorCallback) {
    Restangular.one("chat_sessions", String(chatId))
      .customPUT(
        {
          doctor_note: notes,
        },
        null
      )
      .then(successCallback, errorCallback);
  }

  function initializeCall(payload, successCallback, errorCallback) {
    Restangular.one("video_sessions/notifications")
      .customPOST(payload, null)
      .then(successCallback, errorCallback);
  }

  function requestUploadFile(sessionId, successCallback, errorCallback) {
    Restangular.one("video_sessions/" + sessionId + "/get_upload_url")
      .get()
      .then(successCallback, errorCallback);
  }

  function createFollowUpVideoSession(params, successCallback, errorCallback) {
    Restangular.all("doctors/video_sessions")
      .customPOST(params)
      .then(successCallback, errorCallback);
  }

  return service;
};

chatService.$inject = ["Restangular", "quickBloxService"];

module.exports = chatService;
