/* Define controller for appointments module*/
'use strict';

var ChangePasswordFirstTimeCtrl = function ($scope, $state, $rootScope, AuthService, Session, Provider, RoleValidation) {
  var vm = this;
  vm.changePW = {};
  vm.isAcceptTerm = false;
  vm.isUpdating = false;
  vm.isAssistant = RoleValidation.isAssistant($rootScope.currentUser);
  $scope.customErrMessage = "Error";
  // Hide indicator
  $rootScope.showIndicator = false;
  // Reset title of change-password page
  window.document.title = 'BookDoc - Portal';
  
  function update() {
    if (!vm.isUpdating) {
      vm.isUpdating = true;
    } else {
      return;
    }

    if (vm.isAcceptTerm) {
      AuthService
        .updatePassword(vm.changePW.currentPassword, vm.changePW.newPassword, vm.changePW.confirmPassword, vm.isAssistant)
        .then(
          function (data) {
            Session.create('Bearer ' + data[Provider.tokenKey], data);
            $rootScope.currentUser = AuthService.currentUser();
            $rootScope.isAuthenticated = AuthService.isAuthenticated();
            if (data.clinic_id) {
              $state.go('main.grouping');
            } else {
              $state.go('main.dashboard');
            }

          },

          function (err) {
            if(Array.isArray(err.data.errors)){
              $scope.updatePasswordForm.currentPassword.$setValidity('passwordInvalid', false);
            }else{
              if(err.data.errors.password){
                $scope.updatePasswordForm.newPassword.$setValidity('newPasswordInvalid', false);
                $scope.customErrMessage = "Password " +  err.data.errors.password[0]
              }
            }
            vm.isUpdating = false;

          }

        );
    }
  }

  vm.update = update;
};

ChangePasswordFirstTimeCtrl.$inject = [
  '$scope',
  '$state',
  '$rootScope',
  'Auth.AuthService',
  'Auth.AuthSession',
  'Auth.Auth',
  'RoleValidation'
];

module.exports = ChangePasswordFirstTimeCtrl;
