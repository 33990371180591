/* Define controller for appointments module*/
'use strict';

var _ = require('lodash');

// Need to define $ to use jQuery
var $ = require('jquery');

var CalendarCtrl = function ($scope, weekCount, moment, profileData, $stateParams, $timeout, $rootScope, GroupingAPI, LockMechanismService, $state, NotificationsService, ENV, RoleValidation, TimeZoneService) {
  var vm = this;

  $scope.doctorIdParam = Number($stateParams.doctorId) || null;
  $scope.practiceLocationIdParam = Number($stateParams.practiceLocationId) || null;

  var millisecondOfFiveMinute = 5 * 60 * 1000;
  vm.showCountDown = false;

  $rootScope.profiles = profileData;
  var isAssistant = RoleValidation.isAssistant($rootScope.currentUser);

  vm.locationsInViewMode = [];

  var currentTime = TimeZoneService.currentTime();

  _.forEach($rootScope.profiles.locations, function(location) {

    var remainingTime = location.lock_expired_at * 1000 - currentTime;

    if (remainingTime > 0) {
      location.remainingTime = remainingTime; 

      // Check doctor or assistant is in view mode
      if (!isAssistant || (isAssistant &&  $rootScope.currentUser.id !== location.assistant_id)) {
        location.inViewMode = true;
      }

      // Check if assistant is in edit mode
      if (isAssistant && $rootScope.currentUser.id === location.assistant_id) {
        location.inViewMode = false;
        location.remainingTimeShowCountDown = location.remainingTime - millisecondOfFiveMinute;
        location.checkRemainingTime = true;

        // Implement show countdonw
        $timeout(function() {
          if (location.checkRemainingTime) {
            vm.showCountDown = true;
          }
        }, location.remainingTimeShowCountDown);

        // Implement forced out of Edit mode after 30 minute
        handleUnLockMechanism(location);
      }
        
    }
  });

  function handleUnLockMechanism(location) {
    $timeout(function() {
      if (location.checkRemainingTime) {
        // Unsubscribe lock mechanism chanel
        NotificationsService.unsubscribe('/lock_mechanism/');
        $rootScope.enableNotification = false;

        // Call API to unlock mechanism
        GroupingAPI.unLockMechanism(location.id)
          .then(
            function(data) {
              $state.go('main.grouping');
            },
            function(error) {
            }
          );
      }
    }, location.remainingTime);
  }

  showCurrentModeMessage();

  $scope.$on('changeMode', function (event, changeModeData) {

    var locationIndex = LockMechanismService.findLocationIndex($rootScope.profiles.locations, changeModeData.id);

    currentTime = TimeZoneService.currentTime();

    if (!isAssistant || (isAssistant && $rootScope.currentUser.id !== changeModeData.assistant_id)) {

      // Change to edit mode
      if (changeModeData.lock_expired_at === 0 && $rootScope.profiles.locations[locationIndex].inViewMode) {
        $rootScope.profiles.locations[locationIndex].inViewMode = false;
        // Broadcast get availability event to implement update availability when change view mode to edit mode
        $scope.$broadcast('getAvailability');
      }

      // Change to view mode
      if (changeModeData.lock_expired_at > 0) {
        $rootScope.profiles.locations[locationIndex].lock_expired_at = changeModeData.lock_expired_at;
        $rootScope.profiles.locations[locationIndex].remainingTime = changeModeData.lock_expired_at * 1000 - currentTime;
        $rootScope.profiles.locations[locationIndex].inViewMode = $rootScope.profiles.locations[locationIndex].remainingTime > 0;

      }
      
    }

    // Change assistant to edit mode
    if (isAssistant && $rootScope.currentUser.id === changeModeData.assistant_id && changeModeData.lock_expired_at > 0) {
      $rootScope.profiles.locations[locationIndex].lock_expired_at = changeModeData.lock_expired_at;
      $rootScope.profiles.locations[locationIndex].remainingTime = changeModeData.lock_expired_at * 1000 - currentTime;
      $rootScope.profiles.locations[locationIndex].inViewMode = false;
      $rootScope.profiles.locations[locationIndex].remainingTimeShowCountDown = $rootScope.profiles.locations[locationIndex].remainingTime - millisecondOfFiveMinute;
      $rootScope.profiles.locations[locationIndex].checkRemainingTime = true;
      
      $timeout(function() {
        if ($rootScope.profiles.locations[locationIndex].checkRemainingTime) {
          vm.showCountDown = true;
        }
      }, $rootScope.profiles.locations[locationIndex].remainingTimeShowCountDown);

      handleUnLockMechanism($rootScope.profiles.locations[locationIndex]);
    }

    if (!changeModeData.lock_expired_at && !changeModeData.assistant_id && isAssistant && !$rootScope.profiles.locations[locationIndex].inViewMode) {
      GroupingAPI.lockMechanism($scope.practiceLocationIdParam)
        .then(
          function(data) {
            console.log(data);
          },
          function(error) {

          }
        );
    }

    showCurrentModeMessage();

  });

  function showCurrentModeMessage() {
    $timeout(function() {
      vm.currentModeMessage = '';
      vm.locationsInViewMode = LockMechanismService.getViewModeLocation($rootScope.profiles.locations);
      vm.showViewModeMessage = vm.locationsInViewMode.length;
      // Show current mode message for doctor
      if (vm.showViewModeMessage && !isAssistant) {
        var listPracticeLocationName = _.map(vm.locationsInViewMode, 'name');
        vm.currentModeMessage = listPracticeLocationName.join(', ');
      }

      // Show current mode message for assistant
      if (isAssistant) {
        var currentLocationIndex = LockMechanismService.findLocationIndex(vm.locationsInViewMode, $scope.practiceLocationIdParam);
        vm.currentModeMessage = currentLocationIndex >= 0 ? vm.locationsInViewMode[currentLocationIndex].name : '';
      }
    });
  }

  // Listen close browser tab event if user is assistant
  if (isAssistant) {
    window.onbeforeunload = function() {
      if ($scope.practiceLocationIdParam && !vm.locationsInViewMode.length) {
        var serverIP = ENV.api.ip;
        $.ajax({
          type: 'DELETE',
          // Set async to false to make sure send unlock mechanism request successfully before close browser tab 
          async: false,
          url: serverIP + "/api/unlock_mechanism",
          data: {
            location_id: $scope.practiceLocationIdParam
          },
          headers: {
            'Authorization': $rootScope.currentUser.auth_token
          }
        });
      }
    };
  }
};

CalendarCtrl.$inject = [
  '$scope',
  'weekCount',
  'moment',
  'profileData',
  '$stateParams',
  '$timeout',
  '$rootScope',
  'GroupingAPI',
  'LockMechanismService',
  '$state',
  'NotificationsService',
  'ENV',
  'RoleValidation',
  'TimeZoneService'
];

module.exports = CalendarCtrl;
