function PatientSymptomsCtrl(
  $modal,
  moment,
  snackbarService,
  chatService,
  $rootScope
) {
  var ctrl = this;
  ctrl.session = {};
  ctrl.showFollowUpButton = false;
  ctrl.healthInfoEnabled = false;
  ctrl.chatMedication = {
    acei_arb_arni: false,
    beta_blocker: false,
    mra: false,
    sglt2_i: false,
    loop_diuretic: false,
  };
  ctrl.showChatMedication = false;

  var loadPatientSymptoms = function (sessionDetails) {
    if (sessionDetails.body_part === undefined) return;

    ctrl.symptoms = {};
    ctrl.symptoms.body_parts = [];

    var bodyParts = Object.keys(sessionDetails.body_part);
    for (var i = 0; i < bodyParts.length; i++) {
      ctrl.symptoms.body_parts.push(
        bodyParts[i] + " - " + sessionDetails.body_part[bodyParts[i]].join(", ")
      );
    }

    ctrl.session = sessionDetails;

    ctrl.symptoms.healthProblem = sessionDetails.description;
    ctrl.symptoms.allergy = sessionDetails.allergy;
    ctrl.symptoms.allergyNote = sessionDetails.allergy_note;
    ctrl.symptoms.medication = sessionDetails.medication;
    ctrl.symptoms.medicationNote = sessionDetails.medication_note;
    ctrl.symptoms.previousDiagnose = sessionDetails.previous_diagnose;
    ctrl.symptoms.previousDiagnoseNote = sessionDetails.previous_diagnose_note;
    ctrl.symptoms.gender = sessionDetails.gender;
    ctrl.showChatMedication = sessionDetails.start_at < moment().unix();

    ctrl.showFollowUpButton =
      sessionDetails.video_session &&
      sessionDetails.video_session.start_at < moment().unix();

    chatService.getChatMedication(
      ctrl.session.id,
      function (res) {
        if (res && res.chat_medication) {
          ctrl.chatMedication = res.chat_medication;
        } else {
          ctrl.chatMedication = {
            acei_arb_arni: false,
            beta_blocker: false,
            mra: false,
            sglt2_i: false,
            loop_diuretic: false,
          };
        }
      },
      function (res) {
        console.log("res err", res);
      }
    );
  };

  ctrl.openFollowUpModal = function () {
    $modal.open({
      animation: true,
      resolve: {
        chatSessionId: function () {
          return ctrl.session.id;
        },
        videoSessionId: function () {
          return ctrl.session.video_session.id;
        },
        userInfo: function () {
          return {
            name:
              ctrl.session.user_info.first_name +
              " " +
              ctrl.session.user_info.last_name,
            user_id: ctrl.session.user_info.id,
            phone: ctrl.session.user_info.phone,
            email: ctrl.session.user_info.email,
            healthProblem: ctrl.session.description,
            body_parts: ctrl.symptoms.body_parts,
          };
        },
      },
      templateUrl:
        "calendar/appointments/views/create-follow-up-appointment.html",
      controller: require("../../calendar/appointments/controllers/create-follow-up-appointment.controller"),
      controllerAs: "$ctrl",
      windowClass: "modal-info modal-info--tabs modal-info--appointment",
    });
  };

  ctrl.parseDate = function (date) {
    return moment(date).format("DD.MM.YYYY");
  };

  ctrl.openHealthHistory = function () {
    $modal.open({
      animation: true,
      resolve: {
        sessionDetails: function () {
          return ctrl.sessionDetails;
        },
      },
      templateUrl: "chat/views/healthInfoModal.html",
      controller: require("../controllers/healthInfo.controller"),
      controllerAs: "$ctrl",
      windowClass: "modal-info modal-info--tabs modal-size--health-info",
    });
  };

  ctrl.openMeidcationHistory = function () {
    $modal.open({
      animation: true,
      resolve: {
        sessionDetails: function () {
          return ctrl.sessionDetails;
        },
      },
      templateUrl: "chat/views/medicationModal.html",
      controller: require("../controllers/medicationHistory.controller"),
      controllerAs: "$ctrl",
      windowClass: "modal-info modal-info--tabs modal-size--health-info",
    });
  };

  ctrl.handleChatMedicationChange = function () {
    if (ctrl.chatMedication.id) {
      chatService.updateChatMedication(
        ctrl.session.id,
        { chat_medication: ctrl.chatMedication },
        function (res) {
          ctrl.chatMedication = res.chat_medication;
        },
        function () {}
      );
    } else {
      chatService.createChatMedication(
        ctrl.session.id,
        { chat_medication: ctrl.chatMedication },
        function (res) {
          ctrl.chatMedication = res.chat_medication;
        },
        function () {}
      );
    }
  };

  this.$onChanges = function (changes) {
    if (
      changes.sessionDetails != null &&
      changes.sessionDetails.currentValue != null
    ) {
      loadPatientSymptoms(changes.sessionDetails.currentValue);
    }
  };

  this.$onInit = function () {
    chatService.getDoctorChatReviews(
      $rootScope.currentUser.id,
      function (res) {
        ctrl.healthInfoEnabled = res.health_info_enabled;
      },
      function () {}
    );
  };

  ctrl.copyURL = function () {
    const el = document.createElement("textarea");
    el.value = ctrl.session.video_session?.patient_twilio_video_link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    snackbarService.showSnakeBar("URL has been copied!");
  };
}

PatientSymptomsCtrl.$inject = [
  "$modal",
  "moment",
  "SnackbarService",
  "chatService",
  "$rootScope",
];

var patientSymptomsComponent = function () {
  return {
    templateUrl: "chat/views/patientSymptoms.html",
    controller: PatientSymptomsCtrl,
    bindings: {
      sessionDetails: "<",
    },
  };
};

module.exports = patientSymptomsComponent();
