'use strict';

var provider = angular
  .module('Auth')
  .provider('Auth.Auth', function() {
    var authHeader, endpoints, tokenKey;
    authHeader = 'Authorization';
    tokenKey = 'token';
    endpoints = {
      login: 'api/login',
      logout: 'api/logout',
    };
    return {
      setEndpoints: function(val) {
        endpoints = val;
      },
      setAuthHeader: function(val) {
        authHeader = val;
      },
      setTokenKey: function(val) {
        tokenKey = val;
      },
      $get: function() {
        return {
          endpoints: endpoints,
          authHeader: authHeader,
          tokenKey: tokenKey
        };
      }
    };
  });

module.exports = provider;
