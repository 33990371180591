/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.calendar.availability.controllers',
    [
    ]
  )
  .controller('AvailabilityCtrl', require('./availability.controller'));
