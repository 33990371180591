/* global moment*/

'use strict';

(typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);

var timestampToHour = function (TimeZoneService) {
  return function (timeStamp) {
    return timeStamp ? TimeZoneService.timestampToTime(timeStamp, 'HH:mm') : '';
  };

};

timestampToHour.$inject = [
  'TimeZoneService'
];

module.exports = timestampToHour;
