"use strict";
var QB = require("quickblox");
var Rx = require("rx");

var quickBloxService = function ($rootScope, ENV) {
  var service = {
    init: init,
    login: login,
    getDialogs: getQuickBloxDialogs,
    loadDialogMsgs: loadQuickBloxDialogMsgs,
    joinQBDialog: joinQBDialog,
    subscribeToMessageListener: subscribeToMessageListener,
    sendMessage: sendMessage,
    joinQuickBloxDialogs: joinQuickBloxDialogs,
    uploadAttachment: uploadAttachment,
    getPrivateUrl: getPrivateUrl,
    isChatMessageRead: isChatMessageRead,
    getTotalNumberOfUnreadMsgs: getTotalNumberOfUnreadMsgs,
    joinQBVideo: joinQBVideo,
    hangUpVideoSession: hangUpVideoSession,
    muteAudioSession: muteAudioSession,
    unmuteAudioSession: unmuteAudioSession,
    muteVideoSession: muteVideoSession,
    unmuteVideoSession: unmuteVideoSession,
  };

  var user = {};
  var loginObservable = new Rx.Subject();
  var isLoggedIn = false;
  var messageListenerSubject;
  var MSGS_MAX_LIMIT = 50;

  function init(
    appId,
    authKey,
    authSecret,
    accountKey,
    appConfig,
    quickBloxUser
  ) {
    QB.init(appId, authKey, authSecret, accountKey, appConfig);
    user = quickBloxUser;
    messageListenerSubject = new Rx.Subject();
  }

  function login(successCallback, errorCallback) {
    QB.createSession(
      { login: user.login, password: user.pass },
      function (err, res) {
        if (res) {
          $rootScope.qbUser.id = res.user_id;
          QB.chat.connect(
            { userId: res.user_id, password: user.pass },
            function (err, roster) {
              if (err) {
                errorCallback();
                console.log(err);
              } else {
                isLoggedIn = true;
                console.log("Logged in successfully.");
                console.log(roster);

                QB.chat.onDisconnectedListener = onChatDisconnectedListener;
                QB.chat.onReconnectListener = onChatReconnectListener;
                successCallback();
                loginObservable.onNext(roster);
                QB.chat.onMessageListener = onMessageReceived;
              }
            }
          );
        } else {
          console.log(err);
        }
      }
    );
  }

  function onMessageReceived(userId, message) {
    messageListenerSubject.onNext({
      userId: userId,
      message: message,
    });
  }

  function getQuickBloxDialogs(dialogIds, successCallback, failureCallback) {
    var onLoginSuccess = function () {
      console.log("Here is the dialog id");
      var filters = {
        _id: { in: dialogIds },
        sort_desc: "last_message_date_sent",
      };
      QB.chat.dialog.list(filters, function (err, resDialogs) {
        if (err) {
          console.log(err);
          failureCallback(err);
        } else {
          console.log(resDialogs);
          successCallback(resDialogs);
        }
      });
    };
    if (!isLoggedIn) {
      loginObservable.subscribe(
        onLoginSuccess,
        function () {
          console.log("On login observable error");
        },
        function () {
          console.log("On login completed");
        }
      );
    } else {
      onLoginSuccess();
    }
  }

  function loadQuickBloxDialogMsgs(
    dialogId,
    skip,
    successCallback,
    errorCallback
  ) {
    var params = {
      chat_dialog_id: dialogId,
      sort_desc: "date_sent",
      limit: MSGS_MAX_LIMIT,
      skip: skip,
    };
    QB.chat.message.list(params, function (err, messages) {
      if (messages) {
        successCallback(messages);
      } else {
        errorCallback(err);
      }
    });
  }

  function stopAllWebRTCSession() {
    Object.keys(QB.webrtc.sessions).forEach(function (sessionId) {
      QB.webrtc.sessions[sessionId].stop({});
    });
  }

  function joinQBVideo(
    dialogId,
    ext,
    acceptedCallback,
    userNotAnswerCallback,
    rejectedCallback,
    sessionInitializedCallback,
    onStopCallCallback,
    missingMediaCallback,
    startCallCallback,
    extra,
    sendNotification
  ) {
    stopAllWebRTCSession();
    var filters = {
      _id: { in: dialogId },
      sort_desc: "last_message_date_sent",
    };
    QB.chat.dialog.list(filters, function (err, resDialogs) {
      if (err) {
        console.log(err);
      } else {
        console.log(resDialogs);
        var calleesIds = resDialogs.items[0].occupants_ids.filter(function (
          id
        ) {
          return id !== user.id;
        });
        var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
        var additionalOptions = {};

        var session = QB.webrtc.createNewSession(
          calleesIds,
          sessionType,
          null,
          additionalOptions
        );
        console.log(
          "[debug] QB-Service: session initialized session: ",
          session,
          " calleesIds: ",
          calleesIds,
          " additionalOptions: ",
          additionalOptions,
          " resDialogs: ",
          resDialogs
        );
        sessionInitializedCallback(session);
        var mediaParams = {
          audio: true,
          video: {
            aspectRatio: {
              exact: 150 / 100,
            },
          },
          options: {
            muted: true,
            mirror: true,
          },
          elemId: "main_video",
        };

        session.getUserMedia(mediaParams, function (err, stream) {
          if (err) {
            console.log(err);
            missingMediaCallback();
          } else {
            session.call(ext);
            var payload = JSON.stringify({
              ios_voip: "1",
              VOIPCall: "1",
              sessionID: "" + dialogId,
              opponentsIDs: "" + calleesIds[0],
              contactIdentifier: extra.name,
              conferenceType: "1",
              timestamp: Math.ceil(extra.current_time / 1000),
            });
            var runningEnv = "production";
            var pushParameters = {
              notification_type: "push",
              push_type: "apns_voip",
              user: { ids: "" + calleesIds[0] },
              event_type: "one_shot",
              message: QB.pushnotifications.base64Encode(payload),
              environment: runningEnv,
            };
            if (sendNotification) {
              QB.pushnotifications.events.create(
                pushParameters,
                function (error, result) {
                  if (error) {
                    console.log(error);
                  } else {
                    console.log("Push Notification is sent.");
                  }
                }
              );
            }
            startCallCallback();
          }
        });
        window.se = session;

        session.onRemoteStreamListener = function (
          remoteSession,
          userId,
          remoteStream
        ) {
          console.log(
            "[debug] QB-Service: remote stream received session: ",
            remoteSession,
            " userId: ",
            userId,
            " remoteStream: ",
            remoteStream
          );
          remoteSession.attachMediaStream("remote_main_video", remoteStream, {
            video: {
              aspectRatio: {
                exact: 380 / 1600,
              },
            },
          });
        };

        QB.webrtc.onUserNotAnswerListener = function (session, userId) {
          console.log(
            "[debug] QB-Service: user didn't answer session: ",
            session,
            " userId: ",
            userId
          );
          userNotAnswerCallback();
        };
        QB.webrtc.onRejectCallListener = function (session, userId, extension) {
          console.log(
            "[debug] QB-Service: call is being rejected session: ",
            session,
            " userId: ",
            userId,
            " extension: ",
            extension
          );
          rejectedCallback();
        };

        QB.webrtc.onAcceptCallListener = function (session, userId, extension) {
          console.log(
            "[debug] QB-Service: call accepted session: ",
            session,
            " userId: ",
            userId,
            " extension: ",
            extension
          );
          acceptedCallback();
        };
        QB.webrtc.onStopCallListener = function (session, userId, extension) {
          console.log(
            "[debug] QB-Service: call stopped session: ",
            session,
            " userId: ",
            userId,
            " extension: ",
            extension
          );
          onStopCallCallback();
        };
      }
    });
  }

  function muteAudioSession(session) {
    session.mute("audio");
  }

  function unmuteAudioSession(session) {
    session.unmute("audio");
  }

  function muteVideoSession(session) {
    session.mute("video");
  }

  function unmuteVideoSession(session) {
    session.unmute("video");
  }

  function hangUpVideoSession(session) {
    console.log("[debug] QB-Service: Portal reject session: ", session);
    session.stop({});
  }

  function joinQBDialog(dialogId, successCallback, failureCallback) {
    var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);

    QB.chat.muc.join(dialogJid, function (resultStanza) {
      var joined = true;

      for (var i = 0; i < resultStanza.childNodes.length; i++) {
        var elItem = resultStanza.childNodes.item(i);
        if (elItem.tagName === "error") {
          joined = false;
        }
      }

      if (joined) {
        successCallback();
      } else {
        failureCallback();
      }
    });
  }

  function subscribeToMessageListener(subscriber) {
    messageListenerSubject.subscribe(subscriber);
  }

  function sendMessage(dialogId, msg) {
    var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
    QB.chat.send(dialogJid, msg);
  }

  function uploadAttachment(file, successCallback, failureCallback) {
    var params = {
      name: file.name,
      file: file,
      type: file.type,
      size: file.size,
      public: false,
    };
    QB.content.createAndUpload(params, function (err, res) {
      if (err) {
        failureCallback(err);
      } else {
        successCallback(res);
      }
    });
  }

  function joinQuickBloxDialogs(qbIds) {
    qbIds.forEach(function (qbId) {
      joinQBDialog(
        qbId,
        function () {
          console.log("Joined qb dialog" + qbId);
        },
        function () {
          console.log("Error Joined qb dialog" + qbId);
        }
      );
    });
  }

  function getPrivateUrl(uid) {
    return QB.content.privateUrl(uid);
  }

  //Quickblox listeners
  function onChatDisconnectedListener() {
    console.log("Chat disconnected");
  }

  function onChatReconnectListener() {
    console.log("On Chat Reconnect");
  }

  function isChatMessageRead(session, dialogId, msgId, callback) {
    var params = { chat_dialog_ids: [dialogId] };
    QB.chat.message.unreadCount(params, function (err, res) {
      console.log(err);
      console.log(res);

      if (res != null && res[dialogId] === 0) {
        callback(true);
        session.last_msg_is_read = true;
        return;
      }
      callback(false);
      session.last_msg_is_read = false;
    });
  }

  function getTotalNumberOfUnreadMsgs(callback) {
    var params = {};
    QB.chat.message.unreadCount(params, function (err, res) {
      if (res != null) callback(res.total);
    });
  }

  return service;
};

quickBloxService.$inject = ["$rootScope", "ENV"];

module.exports = quickBloxService;
