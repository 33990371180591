/*globals moment*/

'use strict';

var bdDurationDirective = function ($filter) {
  return {
    restrict: 'E',
    templateUrl: 'calendar/availability/views/duration.html',
    replace: true,

    scope: {
      timeslot: '=timeslotData',
      locations: '=locationsData'
    },

    link: function (scope) {
      scope.timeslot.location = $filter('findByProperty')(scope.timeslot.location_id, 'id', scope.locations);
    }

  };

};

bdDurationDirective.$inject = ['$filter'];

module.exports = bdDurationDirective;
