'use strict';

var auditStates = function ($stateProvider) {
  $stateProvider
    .state('main.audits', {
      url: 'audits/?page?location_id',
      reloadOnSearch: false,
      templateUrl: 'audits/views/audits.html',
      controller: 'AuditCtrl',
      controllerAs: 'audits',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',

          function ($rootScope, ProfileAPI) {
            return $rootScope.profiles || ProfileAPI.get($rootScope.currentUser && $rootScope.currentUser.id);
          }
        ]
      }
    });
};

auditStates.$inject = ['$stateProvider'];

module.exports = auditStates;
