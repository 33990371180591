'use strict';

var _ = require('lodash');
require("moment-range");
require("moment-timezone");
require("select2")();
var $ = require('jquery');

var CreateAppointmentCtrl = function ($modalInstance, appointmentInformation, moment, AppointmentAPI, appointmentCommonService, DataTimeService, $modal, availabilitiesApiService, CommonModals, $q, APP_CONSTANT, $scope, ValidateMessagesService, appointmentService, $rootScope,LockMechanismService, TimeZoneService, $modalStack, referApiService, referService) {

  var vm = this;

  vm.PARTS = [
    {
      name: "Head",
      parts: [
        "Forehead",
        "Eyes",
        "Nose",
        "Mouth",
        "Ear",
        "Neck/ throat",
        "Others"
      ]
    },
    {
      name: "Body",
      parts: [
        "Upper back",
        "Lower back",
        "Chest",
        "Breast",
        "Rib",
        "Stomach",
        "Appendix",
        "Hip",
        "Buttocks",
        "Others"
      ]
    },
    {
      name: "Arms",
      parts: [
        "Fingers",
        "Wrist",
        "Elbow",
        "Forearm",
        "Shoulder",
        "Underarm",
        "Others"
      ]
    },
    {
      name: "Groin",
      parts: [
        "Penis",
        "Vagina",
        "Others"
      ]
    },
    {
      name: "Feet",
      parts: [
        "Thigh",
        "Knee",
        "Shin",
        "Ankle",
        "Toes",
        "Heel",
        "Others"
      ]
    }
  ]

  vm.isVirtual = appointmentInformation.isEditAppointment ? (appointmentInformation.reason === "Video session" ? true : false) : false;
  vm.shouldSelectBodyPart = false;
  vm.emptySlots = false;
  vm.healthProblemIsEmpty = false;
  vm.videoEnabled = $rootScope.profiles.video_enabled;
  vm.sendSmsAfter = false;
  vm.smsAllocation = null;
  vm.canSendSms = vm.currentLocation && vm.currentLocation.sms_enabled && vm.smsAllocation && vm.smsAllocation.balance > 0;

  vm.slots = [];

  vm.newVirtualAppt = {
    date: new Date(moment(appointmentInformation.date, 'DD/MM/YYYY').format('YYYY/MM/DD')) || new Date(),
    lastConsultationDate: new Date(),
    location: $rootScope.profiles.virtual_clinic_id,
    slot: {
      displayName: ''
    },
    description: "",
    note: ""
  };

  vm.changeSlot = function (slot) {
    vm.newVirtualAppt.slot = slot;
  }

  vm.rescheduleVirtualAppt = function () {
    AppointmentAPI.rescheduleVirtualAppt(appointmentInformation.appointmentId, appointmentInformation.appointmentId, {
      start_time: vm.newVirtualAppt.slot.start,
      end_time: vm.newVirtualAppt.slot.end
    }).then(
      function (res) {
        appointmentInformation.rescheduleCallback();
        $modalStack.dismissAll();
      },

      function (error) {
        if (error.status === 422) {
          var msg = _.values(error.data);
          msg = msg[0][0] || msg[0];

          if (error.data && error.data.error) {
            msg = error.data.error;
          }

          CommonModals.openBaseModal(msg);

        }
        console.log(error);
        vm.isCreatingAppt = false;
      }
    );
  };

  vm.createVirtualAppt = function () {
    var bodyPart = {};
    var values = $('.js-example-basic-multiple').val();
    if (!values) {
      vm.shouldSelectBodyPart = true;
      return;
    }
    if (vm.newVirtualAppt.description.trim() === "") {
      vm.healthProblemIsEmpty = true;
      return;
    } else {
      vm.healthProblemIsEmpty = false;
    }
    if (vm.emptySlots) return;
    values.forEach(function (part) {
      if (bodyPart[part.split('-')[0]]) {
        bodyPart[part.split('-')[0]].push(part.split('-')[1])
      } else {
        bodyPart[part.split('-')[0]] = [part.split('-')[1]]
      }
    })
    AppointmentAPI.createVirtualAppt({
      "practice_location_id": vm.newVirtualAppt.location,
      "start_time": vm.newVirtualAppt.slot.start,
      "end_time": vm.newVirtualAppt.slot.end,
      "body_part": bodyPart,
      "description": vm.newVirtualAppt.description,
      "note": vm.newVirtualAppt.note,
      "last_consultation_date": moment(vm.newVirtualAppt.lastConsultationDate).unix(),
    }).then(function (res) {
      $modal.open({
        animation: true,
        resolve: {
          qrCode: function () {
            return res.qr_url;
          },
          deeplinkCode: function () {
            return res.deeplink_url;
          },
          date: function () {
            return moment(vm.newVirtualAppt.date).format("DD/MM/YYYY");
          },
          time: function () {
            return vm.newVirtualAppt.slot.displayName;
          },
          duration: function () {
            return moment.unix(vm.newVirtualAppt.slot.end).diff(moment.unix(vm.newVirtualAppt.slot.start), 'minutes');
          }
        },
        templateUrl: 'calendar/appointments/views/qr-code-appt-confirm-modal.html',
        controller: require('./qr-code-appt-confirm-modal.controller'),
        controllerAs: '$ctrl',
        windowClass: 'qr-code-appt-modal'
      });
      $modalInstance.dismiss();
    })
  }

  function getAvailableSlots() {
    if (!$rootScope.profiles.video_enabled) return;
    vm.isLoading = true;
    AppointmentAPI.getAvailableSlots(vm.newVirtualAppt.location, moment(vm.newVirtualAppt.date).format("YYYYMMDD")).then(function (res) {
      vm.slots = res.map(function (slot) {
        return {
          start: slot.start,
          end: slot.end,
          offset: slot.offset,
          displayName: "" + moment.unix(slot.start - slot.offset).format("hh:mm a") + " - " + moment.unix(slot.end - slot.offset).format("hh:mm a"),
        };
      });
      if (appointmentInformation.isEditAppointment && appointmentInformation.isBookDocAppointment) {
        vm.newVirtualAppt.slot = vm.slots.filter(function (slot) {
          console.log(("" + moment.unix(slot.start - slot.offset).format("h:mm a") + " - " + moment.unix(slot.end - slot.offset).format("h:mm a")), appointmentInformation.start + " - " + appointmentInformation.end)
          return ("" + moment.unix(slot.start - slot.offset).format("h:mm a") + " - " + moment.unix(slot.end - slot.offset).format("h:mm a")) === appointmentInformation.start + " - " + appointmentInformation.end;
        })[0];
      } else {
        var startTime = moment(moment(vm.newVirtualAppt.date).format('YYYY/MM/DD ') + vm.newAppt.start, 'YYYY/MM/DD h:m a').unix()

        var defaultOpenSlot = vm.slots.find(function (slot) {
          return slot.start - slot.offset === startTime
        })

        vm.newVirtualAppt.slot = defaultOpenSlot || vm.slots[0] || { displayName: '' };
      }
      if (vm.slots.length === 0) { vm.emptySlots = true } else { vm.emptySlots = false }
      $('.js-example-basic-multiple').select2();
      vm.isLoading = false;
    });
  };

  $scope.$watch(angular.bind(vm, function () {
    return vm.newVirtualAppt.date;
  }), function () {
    getAvailableSlots();
  });

  $scope.$watch(angular.bind(vm, function () {
    return vm.isVirtual;
  }), function () {
    setTimeout(function () {
      $('.js-example-basic-multiple').select2();
    }, 500);
  });

  vm.defaultPhoneNumberPattern = vm.phoneNumberPattern = /^[+]?[0-9]{9,15}$/;

  // Init time dropdown component
  vm.dataTimeDropdown = DataTimeService.dataTime;

  // Init reason for visit list
  vm.reasonForVisitList = appointmentInformation.reasonForVisitList || [];

  // validation messages
  vm.validateMessages = ValidateMessagesService;

  var eventID = appointmentInformation.id || null;

  if (appointmentInformation.currentLocation) {
    vm.currentLocation = appointmentInformation.currentLocation[0];
  } else if (eventID) {
    vm.currentLocation = {
      id: null
    };
  } else {
    vm.currentLocation = null;
  }

  vm.newAppt = {
    date: new Date(moment(appointmentInformation.date, 'DD/MM/YYYY').format('YYYY/MM/DD')) || new Date(),
    start: (typeof appointmentInformation.start === 'string' && appointmentInformation.start) || '8:00 am',
    end: (typeof appointmentInformation.end === 'string' && appointmentInformation.end) || '8:00 am',
    location: vm.currentLocation,
    id: appointmentInformation.id || null,
    reason: appointmentInformation.reason || null,
    first_name: appointmentInformation.first_name || null,
    last_name: appointmentInformation.last_name || null,
    phone: appointmentInformation.phone || null,
    insurance :appointmentInformation.insurance || null,
    corporate: appointmentInformation.employer || null,
    note: appointmentInformation.note || null,
    email: appointmentInformation.email || null,
    skipSmsReminder: appointmentInformation.skip_sms_reminder || false
  };

  vm.apptOriginalNote = angular.copy(vm.newAppt.note);

  vm.rangeIsValid = true;

  vm.start = vm.newAppt.start;
  vm.end = vm.newAppt.end;

  vm.noLocationSelected = false;

  vm.eventSources = appointmentInformation.eventSources;

  vm.timeOffEvents = appointmentInformation.apptData;

  vm.timeOffNote = vm.timeOffEvents && vm.timeOffEvents.timeOffNote || null;

  vm.locations = appointmentInformation.locations;

  vm.hasVerifiedLocation = _.countBy(vm.locations, function(location) {
    return location.verified;
  });

  vm.isBookDocAppointment = appointmentInformation.isBookDocAppointment;
  vm.isCollapsedTimeOffNote = true;

  vm.isCollapsedApptNote = vm.isBookDocAppointment ? false : true;

  // Define characters limit of the note
  vm.charactersLimit = APP_CONSTANT.charactersLimit;

  vm.isEditAppointment = appointmentInformation.isEditAppointment;

  var timeOffId = appointmentInformation.timeOffId || null;

  vm.patterns = APP_CONSTANT.patterns;

  // vm.namePattern = APP_CONSTANT.patterns.nameWithoutNumber;
  if (eventID) {
    vm.disableUpdateTimeOff = LockMechanismService.checkLocationInViewMode($rootScope.profiles.locations, appointmentInformation.apptData.location_id);
  }

  // change value of date when select on date picker calendar
  $scope.$watch(angular.bind(vm, function () {
    return vm.newAppt.date;
  }), function (newVal) {
    checkRangeIsValid();
  });

  // Use doctorId for create appointment by assistant
  var doctorId = appointmentInformation.doctorId;

  // Use practiceLocationId for get appointments/availabilities
  vm.practiceLocationId = doctorId ? vm.currentLocation.id : null;

  if (vm.currentLocation && vm.currentLocation.id) {
    if (!vm.reasonForVisitList.length){
      getPanelShipReason(vm.currentLocation.id);
    }
    getSmsAllocation(vm.currentLocation.id, doctorId);
  }

  function checkRangeIsValid() {

    var appointmentDate = TimeZoneService.dateToTimezoneDate(vm.newAppt.date, null, 'DD/MM/YYYY');
    if (appointmentDate === appointmentInformation.date && appointmentInformation.start === vm.start && appointmentInformation.end === vm.end && (appointmentInformation.appointmentId || eventID)) {
        vm.rangeIsValid = true;
        return;
      }

    if (vm.newAppt.end && vm.newAppt.start && vm.newAppt.date) {
      vm.rangeIsValid = appointmentCommonService.rangeIsValid(vm.newAppt) && appointmentCommonService.timeIsInFuture(vm.newAppt);
    } else {
      vm.rangeIsValid = false;
    }

  }

  function getPanelShipReason(practiceLocationId) {
    // Show indicator
    vm.isLoading = true;

    var requestParams = {
      practice_location_id: practiceLocationId
    };

    AppointmentAPI.getPanelShipReason(requestParams)
    .then(function (data) {
        vm.reasonForVisitList = data;
        if (vm.newAppt.reason) {
          var reasonIndex = _.findIndex(vm.reasonForVisitList, function(reason) {
           return reason.name === vm.newAppt.reason;
          });

          if (reasonIndex < 0) {
            vm.newAppt.reason = null;
          }
        }
      }, function(error) {
        console.log(error);
      }
    )
    .finally(function() {
      vm.isLoading = false;
    });
  }

  vm.toggled = function(open, val) {
    if (val !== '' && !APP_CONSTANT.chromeOfiOSDevices) {
      DataTimeService.scrollDropdown(open);
    }
  };

  vm.closeModal = function () {
    $modalInstance.dismiss();
  };

  vm.changeLocation = function (e, location) {
    var repeatVal = e.target.attributes.data.value;
    vm.currentLocation = location;
    vm.newAppt.location = location;
    getPanelShipReason(vm.currentLocation.id);
    vm.checkSelectedLocation();
    getSmsAllocation(vm.currentLocation.id, doctorId);
  };

  vm.changeReason = function (e) {

    // Update dropdown value
    var repeatVal = e.target.attributes.data.value;

    // vm.currentReason = repeatVal;
    vm.newAppt.reason = repeatVal;

  };

  vm.changeStart = function (e) {

    // Update dropdown value
    var repeatVal = e.target.attributes.data.value;

    vm.start = repeatVal;
    vm.newAppt.start = repeatVal;

    checkRangeIsValid();

  };

  vm.changeEnd = function (e) {

    // Update dropdown value
    var repeatVal = e.target.attributes.data.value;

    vm.end = repeatVal;
    vm.newAppt.end = repeatVal;
    // vm.rangeIsValid = false;

    checkRangeIsValid();

  };

  /*************** Handle set time off ******************/

  vm.isSettingTimeOff = false;

  // Handle for when click on Rest of the day button
  vm.restOfTheDay = function () {
    // Disable button rest of the day when is view mode
    if (vm.currentLocation && vm.currentLocation.inViewMode || vm.disableUpdateTimeOff) {
      return;
    }

    // define start time of
    var currentTime = TimeZoneService.currentTime(),
        timeOffdate = moment(vm.newAppt.date).format("YYYYMMDD"),
        timeOffdateFormat = moment(vm.newAppt.date).format("YYYY/MM/DD");

    var stTime = eventID ? TimeZoneService.utcTimestamp(timeOffdateFormat, vm.end) : TimeZoneService.utcTimestamp(timeOffdateFormat, vm.start);
    stTime = moment(stTime * 1000).format();
    // check if update time off
    var startTime = moment.tz(stTime, 'Etc/UTC').utc().format(),
        startVl = moment(startTime).valueOf()/1000;

    if (currentTime > (startVl * 1000) && appointmentInformation.id) {
      appointmentCommonService.showPastTimeMsg();
    } else {

      // check location
      if (!vm.newAppt.location && !eventID) {
        appointmentCommonService.showChooseLocationMsg();
        return;
      }

      // Disabled click multi Rest of the day button
      if (!vm.isSettingTimeOff) {
        vm.isSettingTimeOff = true;
      } else {
        return;
      }

      if(eventID) {
        vm.currentLocation.id = appointmentInformation.apptData.location_id;
      }

      var timeoff = {
        date: moment.tz((moment(stTime).format('L') + ' ' + moment(stTime).format('HH:mm')), 'Etc/UTC').utc().format("YYYYMMDD"),
        typeOfEvent: 'timeoff',
        location_id: vm.currentLocation.id,
        doctor_id: doctorId
      };

      var eventData = [];

      _.forEach(vm.eventSources, function(source) {
        _.forEach(source.events, function(event) {
          if (TimeZoneService.noneHyphenDateFormat(event.start) === timeOffdate && event.typeOfEvent !== 'notavailable') {
            if (event.typeOfEvent !== "timeoff") {
              event.location_id = event.location.id;
            }
            eventData.push(event);
          }
        });
      });

      // Group all vailabilities time of day
      var availabilityData = _.filter(appointmentInformation.availDataRes, function(avail) {
        return moment(avail.date, "YYYY/MM/DD").format("YYYYMMDD") === timeOffdate && !avail.off;

      });

      // Sort availabilities
      var availsData = _.sortBy(availabilityData, function(evaiData) {
        return evaiData.start_time;
      });
      vm.creatingTimeOffs(availsData, startVl, startTime, eventData, timeoff, stTime)
        .then(function(data) {
          var isOverAvailableMonths = false;

          if (data.rules.length === 0) {
            // Close modal
            vm.closeModal();

            return;
          }

          // Can not create/update time-off over 6 months
          _.each(data.rules, function(timeoff) {
            var endTime = moment(timeoff.date + ' ' + timeoff.end_time, 'YYYYMMDD HH:mm');
            timeoff.note = vm.timeOffNote;
            isOverAvailableMonths = appointmentService.checkOverAvailableMonths(endTime);
            if (isOverAvailableMonths) {
              return;
            }
          });

          if (isOverAvailableMonths) {
            appointmentCommonService.showTimeOffOverAvailableMonthsMsg();
            vm.isSettingTimeOff = false;
            return;
          }

          // Call API to create rest of the day
          AppointmentAPI
            .createTimeOff(data)
            .then(
              function (res) {

                for(var l = 0; l < res.length; l++) {
                  var timeoffDataRes = {
                    location_id: res[l].location_id,
                    id: res[l].id,
                    start: TimeZoneService.renderCalendar(res[l].start_time),
                    end: TimeZoneService.renderCalendar(res[l].end_time),
                    typeOfEvent: "timeoff",
                    timeOffNote: res[l].note
                  };

                  // Render on calendar
                  appointmentInformation.eventSources[3].events.push(timeoffDataRes);

                  // Close modal
                  vm.closeModal();
                }
              }, function(error) {

                if (error.status === 422 && error.data && !error.data.error) {
                  var msg = _.values(error.data);
                  msg = msg[0][0] || msg[0];
                  CommonModals.openBaseModal(msg);

                } else {
                  CommonModals.showInvalidPracticeLocationModal(error, !!doctorId);
                }
                console.log(error);
                vm.isDeletingTimeOff = false;
              }
            )
            .finally(function() {
              vm.isSettingTimeOff = false;
            });
          });
    }
  };

  vm.creatingTimeOffs = function(availsData, startVl, startTime, eventData, timeoff, stTime) {
    return $q(function(resolve, reject) {
      var len = availsData.length;

      var rules = {
        rules: []
      };

      if (len > 0) {
        for (var i = 0; i < len; i++) {
          if (availsData[i].location_id !== vm.currentLocation.id && availsData[i].start_time <= startVl &&  availsData[i].end_time > startVl) {
            appointmentCommonService.showWithoutAvailabilitiesMsg();
            vm.isSettingTimeOff = false;
            return;
          } else if (availsData[i].location_id === vm.currentLocation.id) {
            // Analysis availabilities to creating time-off event for rest of the day
            rules = appointmentService.collectTimeOffFromAvailabilities(availsData, startVl, startTime, eventData, timeoff, stTime, rules, i, vm.currentLocation);
          }

          if (i === len - 1) {
            resolve(rules);
          }
        }

      } else {
        resolve(rules);
      }
    });
  };

  // Delete time off
  vm.isDeletingTimeOff = false;
  vm.deleteTimeOff = function () {

    // Disable button delete time off when is view mode
    if (vm.disableUpdateTimeOff) {
      return;
    }

    var currentTime = TimeZoneService.currentTime(),
        date = moment(appointmentInformation.date, "DD/MM/YYYY").format("YYYY/MM/DD"),
        startValue = TimeZoneService.timeToTimeStamp(date, appointmentInformation.start);

    if (currentTime > startValue) {
      appointmentCommonService.showPastTimeMsg();
    } else {
      if (!vm.isDeletingTimeOff) {
        vm.isDeletingTimeOff = true;
      } else {
        return;
      }
      var rules = {
            rules: []
          },
          id = vm.newAppt.id;

      // Remove from calendar
      AppointmentAPI
        .deleteTimeOff(id)
        .then(function (res) {
          var idx = 0;
            idx = _.findIndex(appointmentInformation.eventSources[3].events, {'id': id});

          appointmentInformation.eventSources[3].events.splice(idx, 1);
          appointmentInformation.calendar.fullCalendar('removeEvents', id);

          vm.closeModal();
        }, function(error) {

          if (error.status === 422 && error.data && !error.data.errors) {
            var msg = _.values(error.data);
            msg = msg[0][0] || msg[0];
            CommonModals.openBaseModal(msg);

          } else {
            CommonModals.showInvalidPracticeLocationModal(error, !!doctorId);
          }
          console.log(error);
          vm.isDeletingTimeOff = false;
        })
        .finally(function() {
          vm.isDeletingTimeOff = false;
        });

    }

  };


  vm.updateAvailability = function () {
    // Check start and end time, disable button update availability when is view mode
    if (!vm.rangeIsValid || vm.currentLocation.inViewMode || vm.disableUpdateTimeOff) {
      return;
    }

    var currentTime = TimeZoneService.currentTime(),
        timeOffDate = moment(appointmentInformation.date, "DD/MM/YYYY").format("YYYY/MM/DD"),
        startValue = TimeZoneService.timeToTimeStamp(timeOffDate, appointmentInformation.start);

    if (currentTime > startValue && appointmentInformation.id && (appointmentInformation.start !== vm.start || appointmentInformation.end !== vm.end)) {
      appointmentCommonService.showPastTimeMsg();
    } else {

      if (!vm.newAppt.location && !eventID) {
        appointmentCommonService.showChooseLocationMsg();
        return;
      }
      // Disabled click multi Update button
      if (!vm.isSettingTimeOff) {
        vm.isSettingTimeOff = true;
      } else {
        return;
      }
      var date = moment(vm.newAppt.date).format("YYYY/MM/DD"),
          st = moment(date + " " + vm.start).format(),
          et = moment(date + " " + vm.end).format();

      // Can not create/update time-off over available months
      var isOverAvailableMonths = appointmentService.checkOverAvailableMonths(et);
      if (isOverAvailableMonths) {
        appointmentCommonService.showTimeOffOverAvailableMonthsMsg();
        vm.isSettingTimeOff = false;
        return;
      }

      // Define rules
      var rules = {
        rules: []
      };

      // check if update time off
      if (eventID) {

        var startUpdate = TimeZoneService.utcTimestamp(date, vm.start);
        var endUpdate = TimeZoneService.utcTimestamp(date, vm.end);
        var timeoffNote = vm.timeOffNote || null;
        AppointmentAPI
          .updateTimeOff(startUpdate, endUpdate, eventID, timeoffNote)
          .then(
            function (res) {

              var idx = _.findIndex(appointmentInformation.eventSources[3].events, {'id': eventID}),
                  stUpdate = TimeZoneService.renderCalendar(res.start_time),
                  endUpdate = TimeZoneService.renderCalendar(res.end_time);

              // Remove time off before update
              appointmentInformation.eventSources[3].events.splice(idx, 1);

              appointmentInformation.calendar.fullCalendar('removeEvents', eventID);

              var timeOffUpdate = {
                id : res.id,
                typeOfEvent: "timeoff",
                location_id: res.location_id,
                appointment_length: res.appointment_length,
                start: stUpdate,
                end:  endUpdate,
                timeOffNote: res.note
              };

              // Push time off updated
              appointmentInformation.eventSources[3].events.push(timeOffUpdate);
              vm.closeModal();

            }, function(error) {

              if (error.status === 422 && error.data && !error.data.errors) {
                var msg = _.values(error.data);
                msg = msg[0][0] || msg[0];
                CommonModals.openBaseModal(msg);

              } else {
                CommonModals.showInvalidPracticeLocationModal(error, !!doctorId);
              }
              console.log(error);
              vm.isDeletingTimeOff = false;
            }
          )
          .finally(function() {
            vm.isSettingTimeOff = false;
          });
      } else {
        var newTimeOff = {
          location_id: vm.currentLocation.id,
          appointment_length: (moment(et).valueOf() - moment(st).valueOf())/(60*1000),
          start_time: TimeZoneService.utcTimestamp(date, vm.start),
          end_time:  TimeZoneService.utcTimestamp(date, vm.end),
          doctor_id: doctorId,
          note: vm.timeOffNote
        };
        rules.rules.push(newTimeOff);
        AppointmentAPI
          .createTimeOff(rules)
          .then(function (res) {
            res = res[0];
            // Updated time-off format
            var timeoff = {
              location_id: res.location_id,
              id: res.id,
              start: TimeZoneService.renderCalendar(res.start_time),
              end: TimeZoneService.renderCalendar(res.end_time),
              typeOfEvent: "timeoff",
              timeOffNote: res.note
            };

            // Render on calendar
            appointmentInformation.eventSources[3].events.push(timeoff);
            // Close modal
            vm.closeModal();
          }, function(error) {

            if (error.status === 422 && !error.data.errors) {
              var msg = _.values(error.data);
              msg = msg[0][0] || msg[0];
              CommonModals.openBaseModal(msg);

            } else {
              CommonModals.showInvalidPracticeLocationModal(error, !!doctorId);
            }
            console.log(error);
            vm.isDeletingTimeOff = false;
          })
          .finally(function() {
            vm.isSettingTimeOff = false;
          });
        }
    }
  };

  vm.validateAvailabilities = function(range1, date, callback, timeoff) {
    var isOverlapTimeOff = false,
        invalidAvailability = true,
        isContainTimeOff = false,
        isOverlapLocation = false;

    var appointmentDate = TimeZoneService.dateToTimezoneDate(appointmentInformation.date, 'DD/MM/YYYY', 'YYYYMMDD');
    // Bypass check overlap appointment/timeoff in case just edit appointment info
    if (appointmentDate === date && appointmentInformation.start === vm.start && appointmentInformation.end === vm.end && ((appointmentInformation.appointmentId && appointmentInformation.currentLocation[0].id === vm.newAppt.location.id) || eventID)) {
      callback();
      return;
    }

    availabilitiesApiService
      .getByWeek(date, date, vm.practiceLocationId, doctorId, $scope.practiceLocationIdParam)
      .then(function(res) {
        var len = res.length;
        if (len > 0) {
          for (var i = 0; i < len; i++) {
            var availSt = moment(moment.unix(res[i].start_time).format()).format(),
                availEt = moment(moment.unix(res[i].end_time).format()).format(),
                range2 = moment.range(availSt, availEt);

                if (timeoff && range2.contains(range1.start, false) && range2.contains(range1.end, false) && !isContainTimeOff) {
                  isContainTimeOff = true;
                }

                if (!res[i].off && range2.overlaps(range1)) {
                  if (eventID) {
                    if (res[i].location_id !== appointmentInformation.apptData.location_id) {
                      isOverlapLocation = true;
                    }
                  } else if (res[i].location_id !== vm.currentLocation.id) {
                    isOverlapLocation = true;
                  }

                }
          }

        }

      }, function(error) {
        vm.isCreatingAppt = false;
        vm.isSettingTimeOff = false;
        CommonModals.showInvalidPracticeLocationModal(error, !!doctorId);
      })
      .then(function() {
        if (!isContainTimeOff && timeoff) {
          appointmentCommonService.showWithoutAvailabilitiesMsg();
          vm.isCreatingAppt = false;
          vm.isSettingTimeOff = false;
          return;
        } else if (isOverlapLocation) {
          vm.isCreatingAppt = false;
          vm.isSettingTimeOff = false;

          // appointmentCommonService.showExistingApptMsg();

          var modalInstance = $modal.open({
            animation: true,
            resolve: {
              sendData: function () {
                return {
                  callback: callback
                };
              }
            },
            templateUrl: 'calendar/appointments/views/conflict-location-appt-modal.html',
            controller: require('./conflict-location-appt.controller'),
            controllerAs: 'conflictAppt',
            windowClass: 'modal-incorrect modal-medium'

          });

          return;
        } else if (isOverlapTimeOff) {
          return;
        }
        else {
          callback();
        }
      })
      .finally(function() {
      });


  };

  vm.appointmentValidate = function(start, end, date, callback, timeoff) {

    // moment.tz.add('Etc/GMT+0|GMT|0|0|');
    var _date = moment(date, 'YYYY/MM/DD').format('YYYYMMDD');
    var st = moment(date + " " + start).format(),
        startTime = moment(moment(st).format('L') + ' ' + moment(st).format('HH:mm')).format(),
        et = moment(date + " " + end).format(),
        endTime = moment(moment(et).format('L') + ' ' + moment(et).format('HH:mm')).format();

    startTime = TimeZoneService.calendarSelectedTime(startTime);
    endTime = TimeZoneService.calendarSelectedTime(endTime);

    var range1 = moment.range(startTime, endTime);
    var isOverlapAppt = false;
    vm.validateAvailabilities(range1, _date, callback, timeoff);
  };

  // Flag for disable multi click on Create appt button
  vm.isCreatingAppt = false;

  vm.createAppointment = function () {

    if (!vm.newAppt.reason || vm.newAppt.location.inViewMode) {
      return;
    }
    // Check not change appt note with bookdoc appt
    if (vm.isBookDocAppointment && angular.equals(vm.apptOriginalNote, vm.newAppt.note)) {
      $modalStack.dismissAll();
      return;
    }

    // Disabled click on Create appt button
    if (!vm.isCreatingAppt) {
      vm.isCreatingAppt = true;
    } else {
      return;
    }

    var date = moment(vm.newAppt.date).format('YYYY/MM/DD'),
        startTime = TimeZoneService.timeToTimeStamp(date, vm.newAppt.start),
        endTime = TimeZoneService.timeToTimeStamp(date, vm.newAppt.end);

    // Can not create appointment over available months
    var isOverAvailableMonths = appointmentService.checkOverAvailableMonths(moment(date + " " + vm.newAppt.end));
    if (isOverAvailableMonths) {
      appointmentCommonService.showApptOverAvailableMonthsMsg();
      vm.isCreatingAppt = false;
      return;
    }

    var newAppt = {
      start: startTime/1000,
      end: endTime/1000,
      date: TimeZoneService.appointmentDateFormat(date, vm.newAppt.start),
      location: vm.newAppt.location.id,
      reason: vm.newAppt.reason,
      first_name: vm.newAppt.first_name,
      last_name: vm.newAppt.last_name,
      phone: vm.newAppt.phone,
      insurance:vm.newAppt.insurance,
      employer: vm.newAppt.corporate,
      doctor_id: doctorId,
      note: vm.newAppt.note,
      email: vm.newAppt.email,
      skip_sms_reminder: vm.newAppt.skipSmsReminder
    };

    if (vm.isEditAppointment) {
      // Doctor just can edit note of BookDoc appt
      if (vm.isBookDocAppointment) {
        newAppt = {
          note: vm.newAppt.note
        };
      } else {
        newAppt.time = newAppt.start;
        newAppt.endtime = newAppt.end;
        newAppt.practice_location_id = vm.newAppt.location.id;
        delete newAppt.start;
        delete newAppt.end;
        delete newAppt.location;
      }
    }

    // Format phone number
    if (!vm.isBookDocAppointment) {
      newAppt.phone = newAppt.phone.replace(APP_CONSTANT.formatPhoneNumber,'');
    }

    vm.appointmentValidate(vm.newAppt.start, vm.newAppt.end, date, function() {
      AppointmentAPI
        .createAppointment(newAppt, appointmentInformation.appointmentId)
        .then(
          function (res) {
            var availDate = TimeZoneService.timeStampToNoneHyphenDate(res.time),
                timeoff = {};

            // Set patient info when edit bookdoc appt
            if (vm.isBookDocAppointment) {
              res.patient = {
                name: [res.visitor.first_name, res.visitor.last_name].join(' '),
                first_name: res.visitor.first_name,
                last_name: res.visitor.last_name,
                insurance: res.insurance ? res.insurance.name : null,
                employer: res.pay_by_employer ? res.employer.name : null,
                phone: res.user_phone
              };
            }

            availabilitiesApiService
              .getByWeek(availDate, availDate, vm.practiceLocationId, doctorId, $scope.practiceLocationIdParam)
              .then(function(avails) {
                timeoff = _.filter(avails, function(timeoff) {
                  return timeoff.start_time === res.time && timeoff.end_time && res.endtime && timeoff.off === true;
                });
              })
              .then(function() {

                // Check for portal appt
                var apptTypeIndex = 0;

                // Check for bookdoc appt
                if (vm.isBookDocAppointment) {
                  apptTypeIndex = 1;
                }

                // Check for no show
                if (res.hidden) {
                  apptTypeIndex = 2;
                }

                appointmentInformation.eventSources[apptTypeIndex].events.push({
                  id: res.id,
                  title: res.patient.name,
                  start: TimeZoneService.renderCalendar(res.time),
                  end: TimeZoneService.renderCalendar(res.endtime),

                  appointment_type: res.appointment_type,
                  insurance: res.patient.insurance,
                  employer: res.patient.employer,
                  phone: res.patient.phone,
                  reason: res.reason,
                  location: res.location,
                  time: res.time,
                  typeOfEvent: vm.isBookDocAppointment ? "bookdoc" : "webapp",
                  timeoffId: timeoff.length ? timeoff[0].id : null,
                  note: res.note,
                  doctor_note: res.doctor_note,
                  first_name: res.patient.first_name,
                  last_name: res.patient.last_name,
                  patient_address: res.patient_address,
                  medical_certification: res.medical_certification,
                  pay_by_employer: res.pay_by_employer,
                  is_panelship: res.is_panelship,
                  seen_doctor: res.seen_doctor,
                  hidden: res.hidden,
                  email: res.email,
                  calendar_sent: res.calendar_sent,
                  corporate: res.corporate,
                  checkup_specific: res.checkup_specific,
                  checkup: res.checkup,
                  nric: res.nric,
                  doctor: res.doctor
                });

                if (vm.isEditAppointment) {
                  var appointmentIndex = _.findIndex(appointmentInformation.eventSources[apptTypeIndex].events, {'id': appointmentInformation.appointmentId});
                  // Remove old appointment
                  appointmentInformation.eventSources[apptTypeIndex].events.splice(appointmentIndex, 1);

                  appointmentInformation.calendar.fullCalendar('removeEvents', appointmentInformation.appointmentId);
                }

                // Send sms
                if (!vm.isBookDocAppointment && vm.sendSmsAfter && vm.canSendSms) {
                  sendSms(res);
                }

                // Close detail appointment and edit appointment modals
                $modalStack.dismissAll();
                vm.isCreatingAppt = false;

              });
          },

          function (error) {
            if (error.status === 422) {
              var msg = _.values(error.data);
              msg = msg[0][0] || msg[0];

              if (error.data && error.data.error) {
                msg = error.data.error;
              }

              CommonModals.openBaseModal(msg);

            }
            console.log(error);
            vm.isCreatingAppt = false;
          }
        )['finally'](function () {
          // Enable click on Create appt button
        });

    });

  };

  // Date picker calendar
  vm.toggleMin = function() {
    vm.minDate = vm.minDate ? null : new Date();
  };

  vm.toggleMin();

  vm.maxDate = new Date(moment(moment(new Date())).add(APP_CONSTANT.availableMonths, 'months'));

  vm.showButtonBar = false;

  vm.open = function($event) {
    vm.status.opened = true;
  };

  vm.status = {
    opened: false
  };

  vm.openLastSeenAt = function($event) {
    vm.lastSeenAtStatus.opened = true;
  };

  vm.lastSeenAtStatus = {
    opened: false
  };

  vm.checkSelectedLocation = function() {
    vm.noLocationSelected = !vm.newAppt.location;
  };

  function sendSms(_appointment){
    var msg = "Hi {name}, your appointment on {date}, {time} has been confirmed.";
    
    var smsParams = {
      id: _appointment.id,
      sms_content: msg,
      eb_location_id: _appointment.location.id,
      eb_doctor_id: doctorId
    };

    referApiService.sendSms(smsParams)
      .then(
        function (data) {
          // do nothing
        },
        function (error) {
          console.log('errors = ',error)
          // if (error.status === 422 && error.data) {
          //   referService.showSmsError(error.data);
          // }
        }
      );
  };

  function getSmsAllocation(practiceLocationId, ebDoctorId) {
    // Show indicator
    vm.isLoading = true;

    var requestParams = {
      eb_location_id: practiceLocationId,
      eb_doctor_id: ebDoctorId
    };

    AppointmentAPI.getSmsAllocation(requestParams)
    .then(function (data) {
        if(data){
          vm.smsAllocation = data.doctor_sms_allocation;
          vm.canSendSms = vm.currentLocation && vm.currentLocation.sms_enabled && vm.smsAllocation && vm.smsAllocation.balance > 0;
        }else{
          vm.canSendSms = false;
        }
      }, function(error) {
        console.log(error);
      }
    )
    .finally(function() {
      vm.isLoading = false;
    });
  }

  vm.sendSmsChange = function() {
    if(vm.sendSmsAfter){
      vm.phoneNumberPattern = /^(\+?60)[0-46-9]*[0-9]{7,8}$/;
    }else{
      vm.phoneNumberPattern = vm.defaultPhoneNumberPattern;
    }
  };
};

CreateAppointmentCtrl.$inject = [
  '$modalInstance',
  'sendData',
  'moment',
  'appointment.api.service',
  'appointment.common.service',
  'DataTimeService',
  '$modal',
  'bookdoc.availabilities.api',
  'CommonModals',
  '$q',
  'APP_CONSTANT',
  '$scope',
  'ValidateMessagesService',
  'appointmentService',
  '$rootScope',
  'LockMechanismService',
  'TimeZoneService',
  '$modalStack',
  'referApiService',
  'referService'
];

module.exports = CreateAppointmentCtrl;
