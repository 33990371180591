'use strict';

var StateChangeService = function(RoleValidation, $state) {
  return {
    // Check is public url
    isPublicUrl: function(stateName) {
      return stateName === "login" || stateName === "loginGroup" || stateName === "main.signup" || stateName === "thankyou";
    },

    // Check is not exited currentUser
    currentUser: function(currentUser, stateName) {
      return !currentUser && stateName !== "main.terms" && stateName !== "main.privacy";
    },

    // Handle redirect to login page
    redirectToLogin: function(isAssistant) {
      var redirectUrl = isAssistant ? 'loginGroup' : 'login';
      $state.go(redirectUrl);
    },

    // Check if not existed profile
    profiles: function(currentUser, doctorProfile, assistantProfile) {
      if (RoleValidation.isDoctor(currentUser)) {
        return !doctorProfile;
      } else if (RoleValidation.isAssistant(currentUser)) {
        return !assistantProfile;
      }
    },

    // Check if is first time login
    isFirstTimeLogin: function(currentUser) {
      return currentUser && currentUser.first_time_login;
    },

    // Check if not first time login
    notFirstTimeLogin: function(currentUser) {
      return currentUser && !currentUser.first_time_login;
    },

    checkFirstTimeLogin: function(currentUser, toStateName) {
      var isFirstTime = this.isFirstTimeLogin(currentUser);
      if (isFirstTime && toStateName !== 'firstTimeLogin') {
        $state.go('firstTimeLogin');
      }
    },

    canSubscribeNotification: function(enableNotification, currentUser, params, state) {
      var notFirstTime = this.notFirstTimeLogin(currentUser),
          isDoctor = RoleValidation.isDoctor(currentUser),
          isAssistant = RoleValidation.isAssistant(currentUser),
          isPublicPage = this.isPublicUrl(state);

      return !enableNotification && notFirstTime && (isDoctor || (isAssistant && params.doctorId)) && !isPublicPage;
    },

    needToGetAlert: function(fromState, stateParams) {
      return fromState === "main.grouping" && stateParams && stateParams.doctorId && stateParams.practiceLocationId;
    }
  };
};

StateChangeService.$inject = [
  'RoleValidation',
  '$state',
];

module.exports = StateChangeService;