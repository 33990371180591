/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.footer.controllers',
    [
    ]
  )
  .controller('FooterCtrl', require('./footer.controller'));
