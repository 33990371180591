"use strict";

function ConfirmSubmitClaimDialogCtrl($modalInstance, totalPrice, claimLimit) {
  var ctrl = this;
  ctrl.totalPrice = totalPrice;
  ctrl.claimLimit = claimLimit;
  
  ctrl.onConfirmBtnClicked = function () {
    $modalInstance.close(true);
  };

  ctrl.onDismissBtnClicked = function () {
    $modalInstance.close(false);
  };
}

ConfirmSubmitClaimDialogCtrl.$inject = ["$modalInstance", "totalPrice", "claimLimit"];

module.exports = ConfirmSubmitClaimDialogCtrl;
