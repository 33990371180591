'use strict';

var hospitalDirective = function($rootScope, EducationService, ArrayService, ValidateMessagesService, HospitalRegistrationService) {
  return {
    restrict: 'E',
    scope: {
      hospitals: '=hospitals',
      hospitalActive: '=hospitalActive'
    },
    templateUrl: 'registration/views/hospital.html',
    link: function(scope, elem, attrs) {

      scope.messages = ValidateMessagesService;
      scope.errors = {
        duplicate: false
      };

      // Check duplicate hospital
      scope.validate = function () {

        scope.errors.duplicate = ArrayService.checkDuplicateItem(scope.hospitals, 'name');

      };

      // Handle when textbox changes]
      scope.inputChanged = function() {
        scope.validate();
      };

      /**
       * Add new hospital
       * @param {object} form
       */
      scope.addHospital = function (form) {

        scope.validate();

        // Check form is valid
        if (form.$valid && !scope.errors.duplicate) {

          scope.hospitals.push({name: '', updated: true, isNewHospital: true});

        }

      };

      /**
       * Remove hospital by index
       * @param  {int} index
       * @return {array}
       */
      scope.removeHospital = function (index) {
        scope.hospitals.splice(index, 1);
        scope.validate();
      };

      scope.$on('validateHospitalForm', function(event) {
        scope.hospitalForm.$setSubmitted();
        if (scope.hospitalForm.$valid && !scope.errors.duplicate) {
          scope.hospitals = HospitalRegistrationService.deleteProperty(scope.hospitals);
          // $rootScope.$broadcast('submitAdditionalInfor');
          $rootScope.$broadcast('validateLanguageForm');
        } else {
          scope.hospitalActive = true;
        }
      });
    }
  };
};

hospitalDirective.$inject = [
  '$rootScope',
  'EducationService',
  'ArrayService',
  'ValidateMessagesService',
  'HospitalRegistrationService'
];

module.exports = hospitalDirective;
