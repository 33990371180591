'use strict';

var profileAPI = function ($modal) {

  return {

    openModalChangePW: function () {

      $modal.open({
        animation: true,
        templateUrl: 'profile/views/change-password-modal.html',
        controller: require('../controllers/update-password.controller'),
        controllerAs: 'updatePWCtrl',
        windowClass: 'modal fade modal-password',
        backdropClass: 'modal-backdrop'

      });

    }

  };

};

profileAPI.$inject = ['$modal'];

module.exports = profileAPI;
