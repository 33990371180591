'use strict';

var directive = angular
    .module('Auth')
    .directive('resetValidator', [

      function () {

        return {

          require: 'ngModel',

          scope: {
            validator: '@validator'
          },

          link: function (scope, element, attrs, ngModel) {

            ngModel.$validators[scope.validator] = function () {
              return true;
            };

          }

        };

      }

    ]);

module.exports = directive;
