'use strict';

module.exports =
  angular
    .module('bookdoc.signup', [
      'ui.bootstrap',
      'ui.router',
      'ngSanitize',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('./controllers').name,
      require('./services').name,
      require('./directives').name
    ]);

require('./registration.routes');
