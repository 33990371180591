'use strict';

module.exports =
  angular.module('bookdoc.common.directives', [])
  .directive('ngEnter', require('./ng-enter.directive'))
  .directive('ngEsc', require('./ng-esc.directive'))
  .directive('ngPhone', require('./ng-phone-number.directive'))
  .directive('indicator', require('./indicator.directive'))
  .directive('autoFocus', require('./autofocus.directive'))
  .directive('operatingHours', require('./operating-hours.directive'))
  .directive('selectBoxRequired', require('./select-box-required.directive'))
  .directive('indicatorCircle', require('./indicator-circle.directive'))
  .directive('autoClick', require('./auto-click.directive'))
  .directive('whenScrolled', require('./when-scrolled.directive'));
