'use strict';

var _ = require('lodash');

var carouselCustom = function($timeout) {
  return {
    scope: {
      next: '&',
      prev: '&',
      isFirstStep: '=',
      isLastStep: '=',
      currentStep: '=currentStep',
      formValidate: '=formValidate'
    },
    link:function(scope, elem, attr) {
      $timeout(function() {
        var carousel = elem.find('div')[1];

        var carouselCtrl = angular.element(carousel).isolateScope();

        var goToNextStep = carouselCtrl.next;
        var goToPrevStep = carouselCtrl.prev;

        // Total of sliders
        var slidersTotal = carouselCtrl.slides.length;

        // Check current slide
        function checkSlide() {

          scope.isFirstStep = false;
          scope.isLastStep = false;

          scope.isFirstStep = scope.currentStep === 1;
          scope.isLastStep = scope.currentStep === slidersTotal;

        };

        checkSlide();

        // Custom next event
        carouselCtrl.next = function() {

          scope.next();

          // Check current form is valid
          if (scope.formValidate.valid) {
            goToNextStep();
            scope.currentStep++;
            checkSlide();
            scope.formValidate.valid = false;
          }

        };

        // Custom prev event
        carouselCtrl.prev = function() {
          scope.prev();
          goToPrevStep();
          scope.currentStep--;
          checkSlide();
        };
      });
    }
  };
};

carouselCustom.$inject = [
  '$timeout'
];

module.exports = carouselCustom;