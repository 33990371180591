"use strict";

function PrescriptionController(
  $scope,
  prescriptionService,
  SnackbarService,
  $modal,
  moment,
  $rootScope
) {
  var ctrl = this;

  ctrl.slots = [
    {
      displayName: "",
      name: "",
      quantity: "",
      unitPrice: "",
      selected: false,
      medicine: null,
      showDropbox: false,
      searchResult: [],
      dosage: {},
    },
  ];
  ctrl.noPrescription = false;
  ctrl.prescriptionEnable = false;
  ctrl.moment = moment;
  ctrl.profile = $rootScope.profiles;

  ctrl.searchMedicine = function (index) {
    ctrl.slots[index].dosage = {};
    ctrl.slots[index].selected = false;
    ctrl.slots[index].unitPrice = false;
    ctrl.slots[index].quantity = false;
    prescriptionService.searchMedicine(
      ctrl.slots[index].displayName,
      function (res) {
        var selectedMedicine = ctrl.slots
          .filter(function (slot) {
            return slot.selected && slot.medicine;
          })
          .map(function (slot) {
            return slot.medicine;
          })
          .map(function (medicine) {
            return medicine.id;
          });
        ctrl.slots[index].searchResults = res.result.filter(function (
          medicine
        ) {
          return !selectedMedicine.includes(medicine.id);
        });
        ctrl.slots[index].showDropbox = true;
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ctrl.selectMedicine = function (medicine, index) {
    $modal.open({
      animation: true,
      resolve: {
        medicineName: function () {
          return (
            medicine.name +
            " (" +
            medicine.dosage +
            "), " +
            medicine.dosage_form
          );
        },
        onCompleteMedicinePrescription: function () {
          return function (dosage) {
            ctrl.slots[index].displayName =
              medicine.name +
              " (" +
              medicine.dosage +
              "), " +
              medicine.dosage_form;
            ctrl.slots[index].name = medicine.name;
            ctrl.slots[index].medicine = medicine;
            ctrl.slots[index].quantity = "";
            ctrl.slots[index].unitPrice = "";
            ctrl.slots[index].selected = true;
            ctrl.slots[index].searchResults = [];
            ctrl.slots[index].showDropbox = false;
            ctrl.slots[index].dosage = dosage;
          };
        },
      },
      templateUrl: "chat/views/prescriptionDosage.html",
      controller: require("./prescriptionDosage.controller"),
      controllerAs: "$ctrl",
      windowClass: "modal-incorrect modal-medium modal-prescription-dosage",
    });
  };

  ctrl.deleteMedicine = function (index) {
    if (ctrl.slots.length === 1) {
      ctrl.slots[index].displayName = "";
      ctrl.slots[index].name = "";
      ctrl.slots[index].medicine = null;
      ctrl.slots[index].quantity = "";
      ctrl.slots[index].unitPrice = "";
      ctrl.slots[index].selected = false;
      ctrl.slots[index].searchResults = [];
      ctrl.slots[index].showDropbox = false;
      ctrl.slots[index].dosage = {};
    } else {
      ctrl.slots = ctrl.slots.filter(function (_, slotIndex) {
        console.log(_.dosage);
        return index !== slotIndex;
      });
    }
  };

  ctrl.addNewSlot = function () {
    ctrl.slots.push({
      displayName: "",
      name: "",
      quantity: "",
      unitPrice: "",
      selected: false,
      medicine: null,
      showDropbox: false,
      searchResult: [],
      dosage: {},
    });
  };

  ctrl.getTotal = function () {
    var total = 0.0;
    ctrl.slots.forEach(function (slot) {
      if (
        slot.selected &&
        !isNaN(parseInt(slot.quantity)) &&
        !isNaN(parseFloat(slot.unitPrice))
      ) {
        total += parseFloat(slot.unitPrice) * parseInt(slot.quantity);
      }
    });
    return total.toFixed(2);
  };

  ctrl.getItemPrice = function (slot) {
    if (isNaN(parseInt(slot.quantity)) || isNaN(parseFloat(slot.unitPrice))) {
      return "0.00";
    } else {
      return (parseInt(slot.quantity) * parseFloat(slot.unitPrice)).toFixed(2);
    }
  };

  ctrl.shouldShowSave = function () {
    return ctrl.slots.reduce(function (acc, slot) {
      return acc || slot.selected;
    }, false);
  };

  ctrl.print = function () {
    var printWindow = window.open("", "", "height=400,width=1000");
    printWindow.document.write(document.head.outerHTML);
    var rootElement = printWindow.document.createElement("div");
    rootElement.className = "prescription";
    rootElement.innerHTML = document.querySelector(
      ".prescription-print"
    ).outerHTML;
    printWindow.document.write(rootElement.outerHTML);
    printWindow.document.close(); // necessary for IE >= 10

    printWindow.onload = function () {
      printWindow.document.querySelector(".prescription-print").style.display =
        "block";
      setTimeout(function () {
        printWindow.focus(); // necessary for IE >= 10
        printWindow.print();
        printWindow.close();
      }, 500);
    };
  };

  ctrl.save = function () {
    if (ctrl.prescription.e_delivery) {
      var selectSlots = ctrl.slots.filter(function (slot) {
        return slot.selected && isNaN(parseFloat(slot.unitPrice));
      });
      if (selectSlots.length > 0) {
        SnackbarService.showSnakeBar("Unit price is required");
        return;
      }
      var selectSlotsWithMin = ctrl.slots.filter(function (slot) {
        return slot.selected && parseFloat(slot.unitPrice) <= 0.0;
      });
      if (selectSlotsWithMin.length > 0) {
        SnackbarService.showSnakeBar("The price should be greater than 0.00");
        return;
      }
    }
    var selectedWithoutQuantitySlots = ctrl.slots.filter(function (slot) {
      return (
        slot.selected &&
        (isNaN(parseFloat(slot.quantity)) || parseFloat(slot.quantity) <= 0.0)
      );
    });
    if (selectedWithoutQuantitySlots.length > 0) {
      SnackbarService.showSnakeBar("The quantity should be greater than 0.00");
      return;
    }
    $modal.open({
      animation: true,
      resolve: {
        chatSessionId: function () {
          return ctrl.chatSessionId;
        },
        medicines: function () {
          return ctrl.slots
            .filter(function (slot) {
              return slot.selected;
            })
            .map(function (slot) {
              return Object.assign({}, slot, {
                unitPrice: isNaN(parseInt(slot.unitPrice))
                  ? "0.00"
                  : parseFloat(slot.unitPrice).toFixed(2),
                price: ctrl.getItemPrice(slot),
              });
            });
        },
        total: function () {
          return ctrl.getTotal();
        },
        pickupLocation: function () {
          return ctrl.pickupLocation;
        },
      },
      templateUrl: "chat/views/createPrescriptionConfirmation.html",
      controller: require("./createPrescriptionConfirmation.controller"),
      controllerAs: "$ctrl",
      windowClass: "modal-incorrect modal-medium modal-create-prescription",
    });
  };

  var showHideDropbox = function (e) {
    ctrl.slots.forEach((_, index) => {
      if (
        document.querySelector(".dropbox-" + index).contains(e.target) ||
        document.querySelector(".name-input-" + index).contains(e.target)
      ) {
      } else {
        ctrl.slots[index].showDropbox = false;
      }
    });
  };

  window.addEventListener("click", showHideDropbox);

  ctrl.togglePrescription = function () {
    if (ctrl.noPrescription) {
      $modal.open({
        animation: true,
        resolve: {
          chatSessionId: function () {
            return ctrl.chatSessionId;
          },
          cancel: function () {
            return function () {
              ctrl.noPrescription = false;
            };
          },
        },
        templateUrl: "chat/views/disablePrescriptionConfirmation.html",
        controller: require("./disablePrescriptionConfirmation.controller"),
        controllerAs: "$ctrl",
        windowClass: "modal-incorrect modal-medium modal-disable-prescription",
      });
    } else {
      prescriptionService.requiredPrescription(ctrl.chatSessionId, {
        e_prescription: !ctrl.noPrescription,
      });
    }
  };

  ctrl.$onDestroy = function () {
    window.removeEventListener("click", showHideDropbox);
  };

  ctrl.$onChanges = function (changes) {
    if (changes != null && changes.prescriptionDetails != null) {
      ctrl.prescriptionDetails = changes.prescriptionDetails.currentValue;
      console.log("changed: ", ctrl.prescriptionDetails);
    }

    if (
      changes.sessionDetails != null &&
      changes.sessionDetails.currentValue != null
    ) {
      var isInBuffer = new moment().isBetween(
        new moment(ctrl.sessionDetails.start_at * 1000).subtract(
          ctrl.sessionDetails.chat_buffer
        ),
        new moment(ctrl.sessionDetails.start_at * 1000)
      );
      var enabledCondition =
        ctrl.sessionDetails.video_session &&
        (ctrl.sessionDetails.video_session.start_at < new moment().unix() ||
          isInBuffer);

      ctrl.prescriptionEnabled =
        ctrl.sessionDetails.video_session != null && enabledCondition;
    }
  };
}

PrescriptionController.$inject = [
  "$scope",
  "prescriptionService",
  "SnackbarService",
  "$modal",
  "moment",
  "$rootScope",
];

var prescriptionComponent = function () {
  return {
    templateUrl: "chat/views/prescription.html",
    controller: PrescriptionController,
    bindings: {
      chatSessionId: "<",
      prescription: "<",
      prescriptionDetails: "<",
      pickupLocation: "<",
      sessionDetails: "<",
    },
  };
};

module.exports = prescriptionComponent();
