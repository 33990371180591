'use strict';

module.exports =
  angular
    .module('bookdoc', [
      'ui.bootstrap',
      'ui.router',
      'restangular',
      'config',
      'ngAudio',
      'ja.qr',
      'ngRateIt',
      'infinite-scroll',
      require('../../../tmp/templates').name,
      require('../common').name,

      // Require Auth module
      require('../auth').name,

      //  Require modules
      require('../dashboard').name,
      require('../profile').name,
      require('../calendar').name,
      require('../alerts').name,
      require('../audits').name,
      require('../contact').name,
      require('../vendor').name,
      require('../header').name,
      require('../footer').name,
      require('../terms').name,
      require('../privacy').name,
      require('../change-password-first-time').name,
      require('../registration').name,
      require('../grouping').name,
      require('../referral').name,
      require('../medical-news').name,
      require('../chat').name,
      require('../teleconsult').name,
      require('../employee-benefits').name
    ])
    .constant('APP_CONSTANT', require('./app.constant'))
    .service('checkRouter', require('./check.router.service'));

require('./app.routes');
require('./app.config');
require('./app.run');
