'use strict';

var states = function ($stateProvider) {
  // var token = Session.token;

  $stateProvider
    .state('main.teleconsult', {
      url: 'teleconsult?isGrouping/',
      templateUrl: "teleconsult/views/teleconsult.html",
      controller: 'TeleconsultCtrl',
      controllerAs: 'teleconsult'
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.teleconsult')
  .config(states);
