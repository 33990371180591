function EmptySessionsPlaceHolderCtrl() {
}

var emptySessionsPlaceholder = function () {
  return {
    transclude: true,
    templateUrl: 'chat/views/emptySessionsPlaceHolder.html',
    controller: EmptySessionsPlaceHolderCtrl,
    bindings: {
      isOngoing: '='
    }
  };
};

EmptySessionsPlaceHolderCtrl.$inject = [];

module.exports = emptySessionsPlaceholder();
