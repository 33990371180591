'use strict';

var SearchDropdownService = function ($timeout) {
  return {

    // Focus on input when open dropdown
    setAutoFocus: function () {
      $timeout(function () {
        angular.element('.open .dropdown-menu .auto-complete').focus();
      }, 100);
    }
  };
};

SearchDropdownService.$inject = [
  '$timeout'
];

module.exports = SearchDropdownService;
