'use strict';

module.exports =
  angular
    .module('bookdoc.referral', [
      'ui.bootstrap',
      'ui.router',
      'ct.ui.router.extras.dsr',
      'ngSanitize',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('./refer-to').name,
      require('./referred-by').name,
      require('./controllers').name
    ])
    .config(require('./referral.routes'));