'use strict';

var _ = require('lodash');
var DetailAppointmentCtrl = function($modalInstance, $modal, $rootScope, sendData, appointmentApiService, addToCalendarService, CommonModals) {
  var vm = this;
  vm.appointmentDetail = sendData.appointmentDetail;
  vm.eventSources = sendData.eventSources;
  vm.calendar = sendData.calendar;
  var isAssistant = sendData.isAssistant;
  vm.isCancelingAppt = false;

  vm.closeModal = function() {
    $modalInstance.dismiss();
  };

  vm.cancelAppointment = function() {
    if (vm.isCancelingAppt) {
      return;
    }

    vm.isCancelingAppt = true;

    appointmentApiService
      .cancelAppointment(vm.appointmentDetail.id)
      .then(function(data) {
        if (!vm.canCancel) {
          var id = vm.appointmentDetail.id,
              idx = 0;
          if (vm.appointmentDetail.typeOfEvent === "bookdoc") {
            idx = _.findIndex(sendData.eventSources[1].events, {'id': id});
            sendData.eventSources[1].events.splice(idx, 1);
          } else {
            idx = _.findIndex(sendData.eventSources[0].events, {'id': id});
            sendData.eventSources[0].events.splice(idx, 1);
          }
          // Remove from calendar
          sendData.calendar.fullCalendar('removeEvents', id);
          if (!!data.time_rule) {
            sendData.eventSources[3].events.push({
              location_id : vm.appointmentDetail.location.id,
              id: vm.appointmentDetail.timeoffId,
              typeOfEvent: "timeoff",
              start: vm.appointmentDetail.start,
              end: vm.appointmentDetail.end,
              cancel_by: "doctor",
              timeOffNote: isAssistant ? 'Appt Cancelled by Assistant' : 'Appt Cancelled by Doctor'
            });
          }
        }

        // Delete google calendar event when cancel success
        if (data.google_event_id && !isAssistant) {
          var request = addToCalendarService.deleteGoogleCalendarEvent(data.google_event_id);

          request.execute(function(event) {
            console.log(event);
          });
        }

        vm.isCancelingAppt = false;
        vm.closeModal();
      }, function(errors) {
        if (errors.status === 422 && errors.data.employer) {
          CommonModals.openBaseModal(errors.data.employer[0]);
          vm.closeModal();
        }
        vm.isCancelingAppt = false;
      });
  };
};

DetailAppointmentCtrl.$inject = [
  '$modalInstance',
  '$modal',
  '$rootScope',
  'sendData',
  'appointment.api.service',
  'addToCalendarService',
  'CommonModals'
];

module.exports = DetailAppointmentCtrl;
