'use strict';

var _ = require('lodash');

var ConfirmationCtrl = function ($rootScope, $modalInstance, $modalStack, sendData, appointmentApiService, CommonModals) {
  var vm = this;

  /**
   * [cancel description] cancel popup
   * @return {[type]} [description]
   */
  vm.cancel = function () {
    $modalInstance.dismiss();
  };

  /**
   * [send description] send information about medical certificate
   * @return {[type]} [description]
   */
  vm.send = function () {
    appointmentApiService
      .sendMCData(sendData.apptId, sendData.sendMCData)
      .then(function (data) {
        $rootScope.$broadcast('updateMcInfo', data);
        $modalStack.dismissAll();
      }, function(errors) {
        if (errors.status === 422 && errors.data.employer) {
          CommonModals.openBaseModal('Please contact BookDoc Admin for further support.');
          vm.cancel();
        }
      });
  };
};

ConfirmationCtrl.$inject = [
  '$rootScope',
  '$modalInstance',
  '$modalStack',
  'sendData',
  'appointment.api.service',
  'CommonModals'
];

module.exports = ConfirmationCtrl;
