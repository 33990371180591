'use strict';

var _ = require('lodash');

var ReferToDoctor = function ($modal, $scope, $state, CachingQueryParams, APP_CONSTANT, referApiService, referService, $rootScope) {
  var vm = this;
  var specialties = [];

  vm.isReferToPage = $state.current && $state.current.name && $state.current.name === 'main.referral.refer';

  vm.paginationSize = APP_CONSTANT.pagination.size;
  vm.referralLimit = APP_CONSTANT.pagination.limit;

  // Listen to updateSpecialties event
  $scope.$on('updateSpecialties', function (event, specialtiesData) {
    // Update specialties
    specialties = specialtiesData;
  });
  
  function initDataFromParams() {

    var parameters = CachingQueryParams.get();
    var currentPage = Number(parameters.page);
    var sortBy = parameters.sort_by;

    vm.currentPage = (!currentPage) ? 1 : currentPage;
    vm.currentSortBy = (sortBy === 'Oldest') ? sortBy : 'Newest';
  }

  vm.changeSortBy = function(repeatVal) {

    vm.currentSortBy = repeatVal === 'newest' ? 'Newest' : 'Oldest';

    vm.getReferralData();

  };

  vm.getReferralData = function() {

    // show indicator
    $rootScope.showIndicator = true;
    vm.noReferralAvailable = false;

    var parameters = {'page': vm.currentPage, 'sort_by': vm.currentSortBy};

    // Caching query params
    CachingQueryParams.set(parameters);


    referApiService.getReferral(vm.currentPage, vm.currentSortBy.toLowerCase(), vm.isReferToPage)
      .then(
        function(data) {

          // Get no tification list, total pages and total count
          vm.referrals = data.referrals;
          vm.totalPages = data.meta.total_pages;
          vm.totalCount = data.meta.total_count;

          if (!vm.totalCount) {
            vm.noReferralAvailable = true;
            return;
          }

        },
        function(err) {
          console.log(err);
        }
      ).finally(function() {
        // hide indicator
        $rootScope.showIndicator = false;
      });

  };

  initDataFromParams();
  vm.getReferralData();

  // Show Referral form
  vm.showReferralForm = function() {
    $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            specialties: specialties,
            referFromCalendar: false
          };
        }
      },
      templateUrl: 'referral/refer-to/views/refer-doctor-modal.html',
      controller: require('./refer-doctor.controller'),
      controllerAs: 'refer',
      windowClass: 'modal-info'
    });
  };

  // View Note
  vm.viewNote = function(referralNote) {
    $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            referralNote: referralNote
          };
        }
      },
      templateUrl: 'referral/refer-to/views/referral-note-modal.html',
      controller: require('./referral-note.controller'),
      controllerAs: 'note',
      windowClass: 'modal-incorrect modal-confirm referral-confirm-modal'
    });
  };

  $scope.$on('getReferral', function () {
    vm.currentPage = 1;
    vm.currentSortBy = 'Newest';
    vm.getReferralData();
  });
};

ReferToDoctor.$inject = [
  '$modal',
  '$scope',
  '$state',
  'CachingQueryParams',
  'APP_CONSTANT',
  'referApiService',
  'referService',
  '$rootScope'
];

module.exports = ReferToDoctor;
