'use strict';

module.exports = angular
  .module('bookdoc.calendar.availability', [
    'ui.bootstrap',
    'ui.router',
    'restangular',

    require('../../../../tmp/templates').name,
    require('../../common').name,
    require('./directives').name,
    require('./controllers').name
  ])
  .constant('AVAILABILITY_API_ROUTE', require('./availability.constant'))
  .filter('findByProperty', require('./filters/find-by-property.filter'))
  .factory('bookdoc.dateRangeService', require('./services/date-range.service'))
  .factory('bookdoc.availabilities.api', require('./services/availabilities.service'))
  .factory('bookdoc.availabilities.formatDataService', require('./services/availabilities-format-data.service'));

require('./availability.route');
