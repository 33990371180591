"use strict";
var VideoStreamMerger = require("video-stream-merger");
window.VideoStreamMerge = VideoStreamMerger;
function VideoChatCtrl($rootScope, quickBloxService, chatService) {
  /////////////////////////////////// view model variables /////////////////////////////////////////
  var ctrl = this;
  ctrl.audioMuted = false;
  ctrl.videoMuted = false;
  ctrl.state = 'Connecting...';
  ctrl.patientName = '';
  ctrl.videoSession = null;
  ctrl.callOngoing = false;
  ctrl.merger = null;
  ctrl.recorder = null;
  ctrl.data = [];
  ctrl.streamMerger = null;
  ctrl.showVideoChat = false;
  
  var setCallStatusToDefault = function () {
    ctrl.audioMuted = false;
    ctrl.videoMuted = false;
    ctrl.state = 'Connecting...';
  };

  ctrl.onHangUp = function () {
    if (false) {
      try {
        ctrl.recorder.onstop = function () {
          chatService.requestUploadFile(ctrl.sessionDetails.id, function (response) {
            var formData = new FormData();
            Object.keys(response.fields).forEach(function(param) {
              if (param === "key") {
                formData.append("key", response.fields[param].split("$")[0] + "recordedVideo.webm");
              } else {
                formData.append(param, response.fields[param])
              }
            })
            formData.append("file", new Blob(ctrl.data, { type: "video/webm" }));
            var request = new XMLHttpRequest();
            request.open("POST", response.url);
            request.send(formData);
          })
        }
        ctrl.recorder.stop();
        ctrl.merger.destroy();
      } catch(error) {
        console.error(error)
      }
    }
    ctrl.showVideoChat = false;
    ctrl.callOngoing = false;
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
  };

  ctrl.onMute = function () {
    if (ctrl.audioMuted) {
      quickBloxService.unmuteAudioSession(ctrl.videoSession);
    } else {
      quickBloxService.muteAudioSession(ctrl.videoSession);
    }
    ctrl.audioMuted = !ctrl.audioMuted;
  };

  ctrl.onCloseCamera = function () {
    if (ctrl.videoMuted) {
      quickBloxService.unmuteVideoSession(ctrl.videoSession);
    } else {
      quickBloxService.muteVideoSession(ctrl.videoSession);
    }
    ctrl.videoMuted = !ctrl.videoMuted;
  };

  var onCallAcceptedCallback = function () {
    ctrl.state = 'Accepted';
    ctrl.callOngoing = true;
    // ctrl.streamMerger = streamMergerService.init(
    //   "#main_video",
    //   "#remove_main_video"
    // );
    // // ctrl.streamMerger.getMergedStream().then(function (stream) {
    // //   ctrl.recorder = new MediaRecorder(stream);
    // //   ctrl.data = [];
    // //   ctrl.recorder.ondataavailable = (event) => ctrl.data.push(event.data);
    // //   ctrl.recorder.start();
    // //   console.log(stream)
    // // })
    if (false) {
      try {
        ctrl.merger = new VideoStreamMerger();
        ctrl.merger.addStream(
          document.querySelector("#main_video").captureStream(),
          {
            x: 0,
            y: 0,
            width: ctrl.merger.width / 2,
            height: ctrl.merger.height,
          }
        );
        setTimeout(() => {
          ctrl.merger.addStream(document.querySelector("#remote_main_video").captureStream(),
          {
            x: ctrl.merger.width / 2,
            y: 0,
            width: ctrl.merger.width / 2,
            height: ctrl.merger.height
          });
          ctrl.merger.start();
          ctrl.recorder = new MediaRecorder(ctrl.merger.result);
          ctrl.data = [];
          ctrl.recorder.ondataavailable = (event) => ctrl.data.push(event.data);
          ctrl.recorder.start();
        }, 1000)
      } catch (error) {
        console.log(error)
      }
    }
  };

  var onUserNotAnswerCallback = function () {
    ctrl.state = 'User did not answered!';
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
  };

  var onCallRejectCallback = function () {
    ctrl.state = 'User has rejected the call';
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
  };

  var sessionInitializedCallback = function (session) {
    ctrl.videoSession = session;
  };

  var onStopCallCallback = function () {
    if (false) {
      try {
        ctrl.recorder.onstop = function () {
          chatService.requestUploadFile(ctrl.sessionDetails.id, function (
            response
          ) {
            var formData = new FormData();
            Object.keys(response.fields).forEach(function (param) {
              if (param === "key") {
                formData.append(
                  "key",
                  response.fields[param].split("$")[0] + "recordedVideo.webm"
                );
              } else {
                formData.append(param, response.fields[param]);
              }
            });
            formData.append("file", new Blob(ctrl.data, { type: "video/webm" }));
            var request = new XMLHttpRequest();
            request.open("POST", response.url);
            request.send(formData);
          });
        };
        ctrl.recorder.stop();
      } catch (error) {
        console.error(error);
      };
    }
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
    ctrl.callOngoing = false;
    ctrl.state = "Patient has ended the call";
    setTimeout(function () {
      ctrl.showVideoChat = false;
      ctrl.state = "Connecting...";
    }, 1000);
  };
};

var videoChatComponent = function () {
  return {
    transclude: true,
    templateUrl: 'chat/views/videoChat.html',
    controller: VideoChatCtrl,
    bindings: {
      'chatDialogId': '<',
      'sessionDetails': '<'
    }
  };
};

VideoChatCtrl.$inject = [
  '$rootScope',
  'quickBloxService',
  'chatService',
];

module.exports = videoChatComponent();
