/*globals moment*/

'use strict';

require('moment-timezone');
require('moment-range');
var _ = require('lodash');

var bdUpdateDurationDirective = function ($filter, dateRangeService, DataTimeService, TimeZoneService) {
  return {
    restrict: 'E',
    templateUrl: 'calendar/availability/views/update-duration.html',
    // require: '^bdAvailDay',
    replace: true,
    scope: {
      timeslot: '=timeslotData',
      locations: '=locationsData',
      day: '=day',
      index: '=index',
      originalTimeSlot: '=originalTimeSlot',
      practiceLocationId: '=practiceLocationId'
    },
    link: function (scope) {

      scope.hasVerifiedLocation = _.countBy(scope.locations, function(location) {
        return location.verified;
      });
      scope.origTimeSlot = angular.copy(scope.originalTimeSlot);

      scope.availabilityDuration = DataTimeService.dataTime;

      function setDirty(isDirty) {
        scope.day.isDirty = isDirty;
      }

      function findAvaiability() {
        return _.find(scope.day.timeslots, function (timeslot) {
          return !timeslot.off;
        });
      }

      function checkTimeslotIsValid() {
        if (!scope.timeslot._destroy) {
          var isAvailabilitiesNotEmpty = !!findAvaiability();
          var timeslotValidation = dateRangeService.timeslotValidation(scope.timeslot, scope.day.timeslots);
          scope.timeslot.inValidData = timeslotValidation.inValidData;
          scope.timeslot.divisible = timeslotValidation.divisible;

          if (dateRangeService.isDeleteTimeSlot(scope.origTimeSlot, scope.timeslot)) {
            var prevRange = moment.range(scope.origTimeSlot.start_time, scope.origTimeSlot.end_time),
                currentRange = moment.range(scope.timeslot.start_time, scope.timeslot.end_time);

            if (!currentRange.overlaps(prevRange)) {
              delete scope.timeslot.id;
              scope.origTimeSlot._destroy = true;
              scope.origTimeSlot.isUpdated = true;
              scope.origTimeSlot.date = scope.day.date;
              scope.origTimeSlot.removeByUpdated = true;
              scope.day.timeslots.push(scope.origTimeSlot);
            }
          }
        } else {
          scope.timeslot.inValidData = false;
        }

      }

      function initData() {

        scope.timeStartChanged = $filter('timeFilter')(scope.timeslot.start_time) || '8:00 am';
        scope.timeEndChanged = $filter('timeFilter')(scope.timeslot.end_time) || '8:00 am';
        scope.appointmentLength = scope.timeslot.appointment_length || 0;
        scope.locationSelected = $filter('findByProperty')(scope.timeslot.location_id, 'id', scope.locations) || {name: 'Select Location'};

        switch (scope.timeslot.type) {
          case 1:
            scope.repeatType = {
              label: 'Only this week',
              value: 1
            };
            break;

          case 2:
            scope.repeatType = {
              label: 'Only this month',
              value: 2
            };
            break;

          default:
            scope.repeatType = {
              label: 'Every week',
              value: 3
            };
            scope.timeslot.type = scope.repeatType.value;

        }

        // console.log(scope );
      }

      initData();

      /**
       * [updateTime update availability time]
       * @param  {[string]} inputTime
       * @param  {[string]} isStartTime - true is start time, false is end time
       *
       */
      scope.updateTime = function (inputTime, isStartTime) {
        // var time = moment.utc(scope.day.date + " " + inputTime, "YYYY-MM-DD h:mm a").valueOf();
        var time = TimeZoneService.timeToTimeStamp(scope.day.date, inputTime);
        if (isStartTime) {

          scope.timeStartChanged = inputTime;
          scope.timeslot.start_time = time;

        } else {
          scope.timeEndChanged = inputTime;
          scope.timeslot.end_time = time;
        }
        scope.timeslot.isUpdated = true;

        setDirty(true);
        //checkTimeslotIsValid();
      };

      /**
       * [updateAppointmentLength update appointment length]
       * @param  {[string]} duration
       *
       */
      scope.updateAppointmentLength = function (duration) {

        scope.timeslot.appointment_length = duration;
        scope.appointmentLength = duration;
        scope.timeslot.isUpdated = true;

        setDirty(true);

      };

      /**
       * [updateLocation update location id]
       * @param  {[string]} location
       *
       */
      scope.updateLocation = function (location) {

        scope.locationSelected = location;
        scope.timeslot.location = location;
        scope.timeslot.isUpdated = true;

        setDirty(true);

      };

      scope.updateRecurring = function(e) {
        scope.repeatType = e;
        scope.timeslot.type = scope.repeatType.value;
        scope.timeslot.isUpdated = true;
      };

      scope.toggled = function (open) {
        DataTimeService.scrollDropdown(open);
      };

      /**
       * [remove remove one slot]
       * @param  {[string]} timeslot
       *
       */
      scope.remove = function () {
        var isAvailabilitiesNotEmpty;

        if (!scope.day.timeslots[scope.index].id) {
          scope.day.timeslots.splice(scope.index, 1);
        } else {
          scope.day.timeslots[scope.index]._destroy = true;
          scope.timeslot.isUpdated = true;
        }

        // Check valid time slots after destroy each availability
        checkTimeslotIsValid();

        isAvailabilitiesNotEmpty = !!findAvaiability();

        if (!isAvailabilitiesNotEmpty) {
          scope.day.inValidData = false;
        }

        setDirty(true);

      };

      scope.$on('availabilitys:durations:update', function (evt, data) {

        scope.updateAppointmentLength(data && data.duration);

      });

      scope.$watch('day', function (newValue, oldValue) {

        if (newValue) {
          checkTimeslotIsValid();
        }

      }, true);

      // Define times for dropdown
      scope.times = DataTimeService.dataTime;

    }

  };

};

bdUpdateDurationDirective.$inject = [
  '$filter',
  'bookdoc.dateRangeService',
  'DataTimeService',
  'TimeZoneService'
];

module.exports = bdUpdateDurationDirective;
