'use strict';

var ValidateMessagesService = function () {

  return {

    required: function (name) {
      return name + ' is required.';
    },

    invalid: function (name, isMany) {
      return name + (isMany ? ' are' : ' is') + ' invalid.';
    },

    exists: function (name) {
      return name + ' already exists.';
    },

    notExist: function (name) {
      return 'This ' + name + ' does not exist.';
    },

    minLength: function (name, minLength) {
      return name + ' is invalid. It should has at least ' + minLength + ' characters.';
    },

    notMatch: function (name, comparedName) {
      return name + ' doesn\'t match with ' + comparedName + '.';
    },

    incorrect: function (name) {
      return 'Incorrect ' + name + '.';
    },

    duplicate: function (name) {
      return name + ' is duplicate.';
    },

    serverError: function () {
      return 'Server Error.';
    },

    fileZise: function () {
      return 'File size must be less than 4MB.';
    },

    practiceLocationError: function () {
      return 'Please click "Save" to key in more details for your Practice Location before proceeding to next step.';
    },

    pleaseChoose: function (name) {
      return 'Please choose ' + name + '.';
    },

    referralNoteLimit: function () {
      return 'Limit is 160 characters';
    },

    referralDoctorSpecialtyError: function () {
      return 'Selected doctor does not have this specialty';
    },

    needSelectLocationFirst: function() {
      return 'Please select a location below first.';
    },
    
    overlapBreakTime: function() {
      return 'Break 1 and Break 2 should not be overlapped.';
    }, 

    smsMessageLimit: function(limit){
      return 'Limit is '+limit+' characters';
    }

  };

};

ValidateMessagesService.$inject = [
];

module.exports = ValidateMessagesService;
