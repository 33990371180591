'use strict';

var _ = require('lodash');

var ValidateHospitalService = function() {

  return {

    duplicate: false,

    /**
     * Validate required and duplicate for specialty
     * @param  {array} array
     */
    checkDuplicate: function (array) {

      var uniqArray = _.uniq(array, function (item) {
        return item.name;
      });

      this.duplicate = uniqArray.length !== array.length;
      console.log(this.duplicate);

    },

    // Delete isNewHospital property before send to server
    deleteProperty: function (hospitals) {

      var hospitalsData = angular.copy(hospitals);

      _.forEach(hospitalsData, function(hospital) {
        delete hospital.isNewHospital;
      });

      return hospitalsData;
    }

  };
};

ValidateHospitalService.$inject = [
];

module.exports = ValidateHospitalService;
