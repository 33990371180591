'use strict';

var authConfig = function ($compileProvider, AuthProvider, RestangularProvider, ENV) {
  // Get server IP from ENV constant
  var serverID = 'https:' + ENV.api.ip;
  // var serverID = 'http:' + ENV.api.ip;

  AuthProvider.setEndpoints({
    login: serverID + '/api/doctors/login',
    logout: serverID + '/api/doctors/logout',
    assistantLogin: serverID + '/api/assistants/login',
    assistantLogout: serverID + '/api/assistants/logout',
  });

  // Set default api
  RestangularProvider.setBaseUrl(serverID + '/api/');

  AuthProvider.setTokenKey('auth_token');
};

authConfig.$inject = [
  '$compileProvider',
  'Auth.AuthProvider',
  'RestangularProvider',
  'ENV'
];

module.exports = angular
  .module('bookdoc')
  .config(authConfig);
