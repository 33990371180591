"use strict";

var ChatCtrl = function (
  $rootScope,
  $stateParams,
  ENV,
  quickBloxService,
  chatService,
  prescriptionService
) {
  /////////////////////////////////////  Variables  /////////////////////////////////////////////////
  var vm = this;
  vm.selectedSessionDetails = {};
  vm.prescriptionDetails = null;
  vm.pickupLocation = null;
  vm.loadingPrescription = false;
  //////////////////////////////////////////////////////////////////////////////////////////////////

  if ($rootScope.profiles.pickup_location_id) {
    vm.pickupLocation = $rootScope.profiles.locations.find(function (location) {
      return location.id === $rootScope.profiles.pickup_location_id;
    });
    console.log("pickup: ", vm.pickupLocation);
  }

  $rootScope.showIndicator = false;

  $rootScope.startVideoChat = function () {
    $rootScope.initVideoCall(vm.selectedSessionDetails, vm.selectedDialogId);
  };

  this.$onChanges = function (changes) {
    console.log(changes);
  };

  $rootScope.setPrescriptionDetails = function (prescription) {
    vm.prescriptionDetails = prescription;
  };

  var loadChatSessionInformation = function () {
    var successCallback = function (session) {
      vm.selectedSessionDetails = session.chat_session;
      vm.loadingPrescription = true;
      if (vm.selectedSessionDetails.e_prescription.prescription_id) {
        prescriptionService.getPrescription(
          vm.selectedSessionDetails.e_prescription.prescription_id,
          function (prescription) {
            console.log("prescriptionDetails", prescription);
            vm.prescriptionDetails = prescription;
            vm.loadingPrescription = false;
          },
          function (error) {
            console.log(error);
          }
        );
      } else {
        setTimeout(function () {
          vm.prescriptionDetails = null;
          vm.loadingPrescription = false;
        }, 1000);
      }
    };

    var failureCallback = function (error) {
      console.log(error);
    };

    console.log(vm.selectedDialogId);
    if (vm.selectedDialogId.dialogId) {
      chatService.getChatSession(
        vm.selectedDialogId.dialogId,
        successCallback,
        failureCallback
      );
    } else if (vm.selectedDialogId.twilioDialogId) {
      chatService.getChatSessionTwilio(
        vm.selectedDialogId.twilioDialogId,
        successCallback,
        failureCallback
      );
    }
  };

  vm.selectSession = function (dialogId, twilioDialogId) {
    if (dialogId || twilioDialogId) {
      vm.selectedDialogId = {
        dialogId: dialogId,
        twilioDialogId: twilioDialogId,
      };
      loadChatSessionInformation();
    } else {
      vm.selectedDialogId = null;
    }
  };

  if ($stateParams.chatId && $rootScope.qbReady) {
    vm.selectSession($stateParams.chatId);
  }

  $rootScope.$on("quickbloxIsReady", function () {
    if ($stateParams.chatId) {
      vm.selectSession($stateParams.chatId);
    }
  });
};

ChatCtrl.$inject = [
  "$rootScope",
  "$stateParams",
  "ENV",
  "quickBloxService",
  "chatService",
  "prescriptionService",
];

module.exports = ChatCtrl;
