'use strict';

module.exports =
  angular
    .module('bookdoc.vendor', [
      require('../../../tmp/templates').name,
      require('../common').name
    ]);

(typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);
require('./calendar');
require('./fullcalendar');
require('./gcal');