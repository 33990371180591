/* global window*/
'use strict';

var _ = require('lodash');

var AvailabilityCtrl = function ($scope, $location, $stateParams, moment, $cookieStore, availabilitiesAPI, formatDataService, appointmentAPI, dateRangeService, CommonModals, DataTimeService, $rootScope, LockMechanismService, APP_CONSTANT) {
  var vm = this;
  var DATE_HYPHEN_FORMAT = 'YYYY-MM-DD';
  var DATE_NONE_HYPHEN_FORMAT = 'YYYYMMDD';
  var isDirty = false;

  vm.isMobile = APP_CONSTANT.isMobile;
  vm.startDay = DataTimeService.initWeekDate($stateParams.start, DATE_HYPHEN_FORMAT, true);
  vm.endDay = DataTimeService.initWeekDate($stateParams.end, DATE_HYPHEN_FORMAT, false);

  vm.disablePrevTime = false;
  vm.disableNextTime = false;

  checkDisableTimeButton();

  vm.appointmentLen = 15;

  vm.repeatType = {
    label: 'Every week',
    value: 3
  };

  vm.isAssistant = !!$scope.doctorIdParam;

  // Listen to change mode event
  $scope.$on('changeMode', function (event, changeModeData) {

    $scope.$apply(function() {
      vm.isUpdating = false;
      vm.availabilitys = angular.copy(vm.availabilitysBackup);

      vm.disableEditAvailability = LockMechanismService.checkAllLocationInViewMode(vm.isAssistant, $rootScope.profiles.locations, $scope.practiceLocationIdParam);
      // Update ViewMode/EditMode status for each timeslot
      _.forEach(vm.availabilitys, function(availability) {
        _.forEach(availability.timeslots, function(timeslot) {
          if (timeslot.location_id === changeModeData.id) {
            timeslot.inViewMode = LockMechanismService.checkLocationInViewMode($rootScope.profiles.locations, timeslot.location_id);
          }
        });
      });
    });

  });


  function cachingQueryParams(start, end, location_id, doctor_id) {

    $location.search({start: start, end: end, location_id: location_id, doctor_id: doctor_id});
    $stateParams.start = start;
    $stateParams.end = end;

  }

  /**
   * [fetchAvailabilityData fetch data when view load]
   *
   */
  function fetchAvailabilityData(startDay, endDay) {
    // fetch availability by current week
    var start = moment(startDay, DATE_HYPHEN_FORMAT).format(DATE_NONE_HYPHEN_FORMAT);
    var end = moment(endDay, DATE_HYPHEN_FORMAT).format(DATE_NONE_HYPHEN_FORMAT);

    isDirty = false;
    vm.availabilitys = [];

    cachingQueryParams(startDay, endDay, $scope.practiceLocationIdParam, $scope.doctorIdParam);
    // show indicator
    $rootScope.showIndicator = true;

    // fetch availability by current week
    availabilitiesAPI
      .getByWeek(start, end, null, $scope.doctorIdParam, $scope.practiceLocationIdParam)
      .then(function (data) {

        data = formatDataService.transformDataByWeekday(data, vm.locations);
        data = formatDataService.addEmptyDay(start, data);

        vm.availabilitys = data;
        vm.availabilitysBackup = angular.copy(data);

      }, function (error) {
        CommonModals.showInvalidPracticeLocationModal(error, !!$scope.doctorIdParam);
      })
      .finally(function() {
        // hide indicator
        $rootScope.showIndicator = false;
      });

  }

  // Implement to get availability when change from view mode to edit mode.
  $scope.$on('getAvailability', function (event) {
    $scope.$apply(function() {
      fetchAvailabilityData(vm.startDay, vm.endDay);
    });
  });

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {

    vm.isUpdating = false;
    vm.availabilitys = [];
    vm.availabilitysBackup = [];
    vm.locations = [];
    vm.store = $cookieStore.get('AuthSession');
    vm.locations = $rootScope.profiles.locations;

    if (vm.isAssistant) {
      vm.groupingLocation = _.find(vm.locations, function(location) {
        return location.id === $scope.practiceLocationIdParam;
      });
      // check disable edit availability button for Assistant
      vm.disableEditAvailability = vm.isAssistant && vm.groupingLocation && vm.groupingLocation.inViewMode;
    } else {

      // check disable edit availability button for Doctor
      vm.disableEditAvailability = LockMechanismService.checkAllLocationInViewMode(false, $rootScope.profiles.locations, null);
    }

    fetchAvailabilityData(vm.startDay, vm.endDay);
  }

  // init data

  createVariables();
  // cachingQueryParams();

  /**
   * Clear durations in week
   * @return {array}
   */
  vm.clearAll = function () {

    isDirty = true;
    vm.isSubmitting = true;

    formatDataService.formatClearAllAvailabilities(vm.availabilitysBackup, vm.availabilitys, vm.isAssistant, $scope.practiceLocationIdParam)
      .then(function(avails) {
        // update model after format
        vm.availabilitys = avails;
        vm.isSubmitting = false;
      });
  };

  /**
   * [changeEditMode handle when click edit and cancel button]
   *
   */
  vm.changeEditMode = function () {

    if (vm.disableEditAvailability) {
      return;
    }

    isDirty = false;

    if (vm.isUpdating) {
      // when click cancel button
      vm.isUpdating = !vm.isUpdating;
      vm.availabilitys = angular.copy(vm.availabilitysBackup);
      return;
    }

    // when click edit button
    vm.isUpdating = !vm.isUpdating;

  };

  /**
   * [getDateOfWeek description]
   * @param  {[number]} startDay
   * @param  {[number]} endDay
   *
   */
  vm.getDateOfWeek = function (startDay, endDay) {
    var stMonth = moment(startDay).format('MMM'),
        etMonth = moment(endDay).format('MMM'),
        stFormat = "DD";
        console.log(stMonth, etMonth);

    if (stMonth !== etMonth) {
      stFormat = "DD MMM";
    }

    vm.startWeek = moment(startDay).format(stFormat);
    vm.endWeek = moment(endDay).format('DD MMM YYYY');

  };

  /**
   * [checkDisablePrevTime] - disable prev button when time is over 3 months from now
   *
   */
  function checkDisableTimeButton(isNextTime) {
    var timeRange = isNextTime ? APP_CONSTANT.availableMonths : -3;
    // check to disable prev button when time is over 3 months from now
    var minDate = moment().add(timeRange, 'months').utc().format(DATE_HYPHEN_FORMAT);
    var minDateTimestamp = moment(minDate, DATE_HYPHEN_FORMAT).utc().valueOf();
    var startDateTimestamp = moment(vm.startDay).add(-7, 'days').valueOf();
    vm.disableNextTime = false;
    vm.disablePrevTime = false;
    if (isNextTime) {
      vm.disableNextTime =  minDateTimestamp < startDateTimestamp;
    } else {
      vm.disablePrevTime =  minDateTimestamp > startDateTimestamp;
    }
  }

  // /**
  //  * [prevWeek select prev week]
  //  *
  //  */
  var showPrevAvailabilities =  _.debounce(function() {
    fetchAvailabilityData(vm.startDay, vm.endDay);
  }, 500);

  vm.prevWeek = function () {
    if (!vm.disablePrevTime) {
      changeWeek(false);
      showPrevAvailabilities();
    }

  };

  // /**
  //  * [nextWeek select next week]
  //  *
  //  */
  var showNextAvailabilities =  _.debounce(function() {
    fetchAvailabilityData(vm.startDay, vm.endDay);
  }, 500);

  vm.nextWeek = function () {
    if (!vm.disableNextTime) {
      changeWeek(true);
      showNextAvailabilities();
    }
  };

  function changeWeek (isNext) {
    vm.startDay = isNext ? moment(vm.endDay).add('days', 1).format(DATE_HYPHEN_FORMAT) : moment(vm.startDay).add(-7, 'days').format(DATE_HYPHEN_FORMAT);
    vm.endDay = isNext ? moment(vm.endDay).add('days', 7).format(DATE_HYPHEN_FORMAT) : moment(vm.endDay).add(-7, 'days').format(DATE_HYPHEN_FORMAT);

    vm.getDateOfWeek(vm.startDay, vm.endDay);
    checkDisableTimeButton(isNext);
    vm.isUpdating = false;
  }

  vm.getDateOfWeek(vm.startDay, vm.endDay);


  // Get appointment length when click on dropdown list
  vm.updateAppointmentLenVal = function (e) {

    vm.appointmentLen = e.target.attributes.data.value;
    $scope.$broadcast('availabilitys:durations:update', {duration: vm.appointmentLen});

  };

  // Get repeat status when click on dropdown list
  vm.updateRepeatWeekVal = function (e) {

    isDirty = true;
    vm.repeatType = e;

  };

  vm.update = function () {

    if (vm.isSubmitting) {
      return;
    }


    var availsFormated = formatDataService.formatDataBeforeUpdate(vm.availabilitys, vm.repeatType.value, vm.locations);
    var data = availsFormated.results;
    if (data.rules.length > 0) {
      vm.isSubmitting = true;
      var avails = angular.copy(vm.availabilitys);

      // Checking valid timeoff
      _.each(avails, function (item) {

        vm.isInvalidData = item.inValidData;
        isDirty = isDirty || item.isDirty;

        // Checking valid data input
        _.each(item.timeslots, function(timeslot) {
          if (timeslot.inValidData) {
            vm.isInvalidData = true;
            return false;
          }
        });

        if (vm.isInvalidData) {
          CommonModals.openBaseModal("Invalid data. Please check your availability settings again.");
          vm.isSubmitting = false;
          return false;
        }
      });

      if (vm.isInvalidData) {
        return;
      }

      console.log(avails);


      if (vm.availabilitys.length === 0) {
        if (!isDirty) {
          vm.changeEditMode();
          return;
        }

      }
      console.log(data);

      // Use doctor_id and location_id params for add/update/delete available duration request that made by Assistant
      if (vm.isAssistant) {
        data.doctor_id = $scope.doctorIdParam;
        data.location_id = $scope.practiceLocationIdParam;
      }

      availabilitiesAPI
        .update(data)
        .then(
          function (data) {
            console.log(data);
            var start = moment(vm.startDay, DATE_HYPHEN_FORMAT).format(DATE_NONE_HYPHEN_FORMAT);
            data = formatDataService.transformDataByWeekday(data, vm.locations);
            data = formatDataService.addEmptyDay(start, data);

            vm.availabilitys = data;
            vm.availabilitysBackup = angular.copy(data);

            vm.changeEditMode();

          },
          function (error) {
            if (error.status === 422 && error.data !== null) {
              var msg = _.values(error.data);
              msg = msg[0][0] || msg[0];
              CommonModals.openBaseModal(msg);

            } else {
              CommonModals.showInvalidPracticeLocationModal(error, vm.isAssistant);
            }
            console.log(error);
          }
        )['finally'](function () {
          vm.isSubmitting = false;
        });

    } else {
      vm.changeEditMode();
    }

  };

  vm.setInvalidTimelosts = function () {
    vm.isInvalidData = true;
  };

};

AvailabilityCtrl.$inject = [
  '$scope',
  '$location',
  '$stateParams',
  'moment',
  '$cookieStore',
  'bookdoc.availabilities.api',
  'bookdoc.availabilities.formatDataService',
  'appointment.api.service',
  'bookdoc.dateRangeService',
  'CommonModals',
  'DataTimeService',
  '$rootScope',
  'LockMechanismService',
  'APP_CONSTANT'
];

module.exports = AvailabilityCtrl;
