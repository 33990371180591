'use strict';

var constants = angular
  .module('Auth')
  .constant('Auth.EVENTS', {
    loginSuccess: 'auth:login:success',
    loginFailed: 'auth:login:failed',
    logoutSuccess: 'auth:logout:success',
    logoutFailed: 'auth:logout:failed',
    sessionTimeout: 'auth:session:timeout',
    unauthenticated: 'auth:unauthenticated',
    unauthorized: 'auth:unauthorized',
    loginGrouping: 'auth:login:grouping'
  })
  .constant('Auth.ROLES', {
    admin: 'admin',
    user: 'user',
    guest: 'guest'
  })
  .constant('Auth.API_ROUTES', {
    doctors: 'doctors',
    password: 'password',
    invites: 'invites',
    assistants: 'assistants'
  });

module.exports = constants;
