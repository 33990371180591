'use strict';

var _ = require('lodash');
require("moment-range");

var OperatingTimeService = function (moment) {
  return {
    timeIsValid: function(startHour, endHour, dayOfWeek, operatingHours) {
      var isValid = true,
          isOverlapBreakTime = false,
          startTime = this.formatOperatingTime(startHour),
          endTime = this.formatOperatingTime(endHour);

      // Only check overlap for break time
      if (dayOfWeek === 'lunch_break' || dayOfWeek === 'dinner_break') {
        isOverlapBreakTime = this.isOverlapBreakTime(startTime, endTime, dayOfWeek, operatingHours);
      }

      return {
        isValid: true,
        startHour: moment(startTime).format('HH:mm'),
        endHour: moment(endTime).format('HH:mm'),
        isOverlapBreakTime: isOverlapBreakTime
      };
    },

    // Check overlap break time
    isOverlapBreakTime: function(startTime, endTime, dayOfWeek, operatingHours) {
      var breakTimeRange = moment.range(startTime, endTime),
          breakTimeType = dayOfWeek === 'lunch_break' ? 'dinner_break' : 'lunch_break';

      var breakTimeIndex = _.findIndex(operatingHours, function(operating) {
        return operating.day_of_week === breakTimeType;
      });

      var otherBreakTimeStart = this.formatOperatingTime(operatingHours[breakTimeIndex].open_time),
          otherBreakTimeEnd = this.formatOperatingTime(operatingHours[breakTimeIndex].close_time),
          otherBreakTimeRange = moment.range(otherBreakTimeStart, otherBreakTimeEnd);

      return breakTimeRange.overlaps(otherBreakTimeRange);
    },

    formatOperatingTime: function(time) {
      var currentDate = moment().format("YYYY/MM/DD"),
          timeFormat = moment(currentDate + ' ' + time, "YYYY/MM/DD HH:mm a").format();
      return timeFormat;
    },
  };
};

OperatingTimeService.$inject = [
  'moment'
];

module.exports = OperatingTimeService;
