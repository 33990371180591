'use strict';

var _ = require('lodash');

var HospitalService = function (ArrayService, InformationAPI, $q) {
  var obj = {

    removeIdUpdated: function (array) {
      var arr = _.sortBy(array, "name");
      _.forEach(arr, function(item) {
        delete item.id;
        delete item.verified;
      });

      return arr;
    },

    /**
     * Compare two array
     * @param  {array} arrOne
     * @param  {array} arrTwo
     * @param  {Boolean} updated
     * @return {array}
     */
    compareArray: function(arrOne, arrTwo, updated) {
      var result = [];
      var item = {};
      var name;
      var count;

      _.forEach(arrOne, function (itemOne) {
        name = itemOne.name;
        count = 0;

        _.forEach(arrTwo, function (itemTwo) {
          if (name === itemTwo.name) {
            count++;
          }
        });

        if (!count) {
          item = {
            "name": name,
            "updated": updated
          };
          result.push(item);
        }
      });

      return result;
    },
    
    // Check update hospital
    hasUpdated: function(existedHospitals, newHospitals) {
      existedHospitals = obj.removeIdUpdated(existedHospitals);
      newHospitals = obj.removeIdUpdated(newHospitals);

      var hospitals = _.sortBy(newHospitals, "name");
      var hospitalsBackup = _.sortBy(existedHospitals, "name");

      if (angular.equals(hospitals, hospitalsBackup)) {
        return false;
      }

      return true;
    },

    formatHospital: function(existedHospitals,hospitals) {
      
      var hospitalsAdd =  obj.compareArray(hospitals, existedHospitals, true);
      var hospitalsRemove = obj.compareArray(existedHospitals, hospitals, false);
      obj.hasAddHospital = hospitalsAdd.length;

      return hospitalsAdd.concat(hospitalsRemove);
    }
  };

  return obj;
};

HospitalService.$inject = [
  'ArrayService',
  'bookdoc.information.api',
  '$q'
];

module.exports = HospitalService;
