'use strict';

var referralStates = function ($stateProvider, $urlRouterProvider) {

  $urlRouterProvider.when('/referral/', '/referral/referred-by/');

  $stateProvider
    .state('main.referral', {
      url: 'referral/',
      templateUrl: 'referral/views/referral.html',
      abstract: true,
      controller: 'ReferralCtrl',
      controllerAs: 'referral',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',

          function ($rootScope, ProfileAPI) {
            return $rootScope.profiles || ProfileAPI.get($rootScope.currentUser && $rootScope.currentUser.id);
          }
        ]
      }
    });
};

referralStates.$inject = ['$stateProvider', '$urlRouterProvider'];

module.exports = referralStates;
