/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.calendar.appointments.controllers',
    [
    	'restangular',
      'bookdoc.profile',
      'ui.router',
    ]
  )
  .controller('AppointmentsCtrl', require('./appointment.controller'));
