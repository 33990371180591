/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.header.controllers',
    [
    ]
  )
  .controller('HeaderCtrl', require('./header.controller'));
