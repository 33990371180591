'use strict';

var _ = require('lodash');

var ChainRequestService = function($q) {
  return {
    startQueue: function () {
      var deferred = $q.defer();
      deferred.resolve();
      return deferred.promise;
    }
  };
};

ChainRequestService.$inject = [
  '$q'
];

module.exports = ChainRequestService;
