'use strict';
module.exports =
  angular
    .module('bookdoc.chat', [
      'ngFileUpload',
      'restangular',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('./directives').name,
      require('./controllers').name,
      require('./components').name,
      require('./services').name
    ]);

require('./chat.routes');
