'use strict';

var _ = require('lodash');

var IssueMCCtrl = function ($modalInstance, $modal, sendData, $scope, moment, ValidateMessagesService) {
  var vm = this;

  vm.appointmentDetail = sendData.apptData;
  vm.minDate = new Date();
  vm.maxDate = new Date(2100, 5, 22);
  vm.showButtonBar = false;
  vm.newDate = {
    startDate: '',
    endDate: ''
  };
  vm.status = {
    openedStartDate: false,
    openedEndDate: false
  };
  vm.messages = ValidateMessagesService;
  vm.errors = {
    invalidDate: false
  };

  /**
   * [close description] close modal medical certificate
   * @return {[type]} [description]
   */
  vm.close = function () {
    $modalInstance.dismiss();
  };

  /**
   * [send description] send medical certificate data
   * @return {[type]} [description]
   */
  vm.send = function () {
    var issueDate = moment().format('DD/MM/YYYY');
    var startDate = moment(vm.newDate.startDate).format('DD/MM/YYYY');
    var endDate = moment(vm.newDate.endDate).format('DD/MM/YYYY');

    var startDateValue = moment(startDate, 'DD/MM/YYYY').valueOf(),
        endDateValue = moment(endDate, 'DD/MM/YYYY').valueOf();

    vm.errors.invalidDate = startDateValue > endDateValue;

    var time = [startDate, endDate, issueDate].join('|');

    if ($scope.setDateForm.$valid && !vm.errors.invalidDate) {
      // create an object to contain all data
      var sendMCData = {
        time: time
      };

      $modal.open({
        animation: true,
        resolve: {
          sendData: function () {
            return {
              apptId: vm.appointmentDetail.id,
              sendMCData: sendMCData
            };
          }
        },
        templateUrl: 'calendar/appointments/views/confirmation-modal.html',
        controller: require('./confirmation.controller'),
        controllerAs: 'confirm',
        windowClass: 'modal-incorrect modal-confirm'
      });
    }
  };
};

IssueMCCtrl.$inject = [
  '$modalInstance',
  '$modal',
  'sendData',
  '$scope',
  'moment',
  'ValidateMessagesService'
];

module.exports = IssueMCCtrl;
