'use strict';

var states = function ($stateProvider) {
  $stateProvider
    .state('main.signup', {
      url: 'sign-up/',
      templateUrl: "registration/views/registration.html",
      controller: 'SignupCtrl',
      controllerAs: 'signup',
      data: {
        isPublic: true
      }
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.signup')
  .config(states);
