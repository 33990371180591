
'use strict';

var UpdatePasswordCtrl = function ($scope, $modalInstance, AuthService, ValidateMessagesService) {

  var vm = this;

  // validation messages
  vm.messages = ValidateMessagesService;
  $scope.customErrMessage = "Error";

  ///////////////////////////////////////////////////////////////////
  // private function

  function closeModal() {
    $modalInstance.dismiss('cancel');
  }

  function update() {
    $scope.updatePasswordForm.$setSubmitted();

    vm.isUpdating = true;

    AuthService
      .updatePassword(vm.currentPassword, vm.newPassword, vm.confirmPassword)
      .then(

        function () {
          closeModal();
        },

        function (err) {
          if(Array.isArray(err.data.errors)){
            $scope.updatePasswordForm.currentPassword.$setValidity('passwordInvalid', false);
          }else{
            if(err.data.errors.password){
              $scope.updatePasswordForm.newPassword.$setValidity('newPasswordInvalid', false);
              $scope.customErrMessage = "Password " +  err.data.errors.password[0]
            }
          }

          vm.isUpdating = false;
        }

      )['finally'](function () {
      });

  }

  function getPassword() {

    $scope.forgotPasswordForm.$setSubmitted();

    vm.isGetting = true;

    AuthService
      .getPassword(vm.email)
      .then(

        function () {
          closeModal();
        },

        function () {
          $scope.forgotPasswordForm.email.$setValidity('emailInvalid', false);
          vm.isGetting = false;
        }

      )['finally'](function () {
      });

  }

  ////////////////////////////////////////////////////////////////
  // public function

  vm.update = update;

  vm.getPassword = getPassword;

  vm.closeModal = closeModal;

};

UpdatePasswordCtrl.$inject = [
  '$scope',
  '$modalInstance',
  'Auth.AuthService',
  'ValidateMessagesService'
];

module.exports = UpdatePasswordCtrl;
