'use strict';

var states = function ($stateProvider) {

  $stateProvider
    .state('main.eb', {
      url: 'employee-benefits/',
      templateUrl: "employee-benefits/views/eb.html",
      controller: 'EBCtrl',
      controllerAs: 'eb'
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.eb')
  .config(states);
