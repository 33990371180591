'use strict';

var selectBoxValidateDirective = function () {
  return {
    scope: {
      // [object] contain value of selectbox that user selects
      selectBoxData: '=selectBoxData',

      // [object] contain form object, use for $setValidity
      formData: '=formData',

      // [string] use to set name for validation
      // in case form have many select box, it use to distinguish select box
      validationNameData: '=validationNameData'
    },
    link:
      function (scope, elem, attr) {
        // watch select box changes
        scope.$watch('selectBoxData', function () {
          scope.formData.$setValidity(scope.validationNameData, !!scope.selectBoxData);
        }, true);
      }

  };
};

selectBoxValidateDirective.$inject = [
];

module.exports = selectBoxValidateDirective;
