'use strict';

var informationAPI = function (BOOKDOC_PROFILE_API_ROUTE, Restangular) {

  var transformRequest = function (tdata) {
    var form = new FormData();

    form.append('document_file', tdata);

    return form;
  };

  var transformRequestForInsurance = function (tdata) {
    var form = new FormData();

    form.append('file', tdata);

    return form;
  };

  return {

    qualifications: {

      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.qualifications),

      updateQualification: function (sendData) {
        return this.root.customPOST(sendData, 'change');
      }

    },

    memberships: {

      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.memberships),

      updateMembership: function (sendData) {
        return this.root.customPOST(sendData, 'change');
      }

    },

    insurances: {

      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.insurances),

      getInsurances: function () {
        return this.root.getList();
      },

      saveInsurances: function (insurances) {
        return this.root.customPOST(insurances, 'change');
      },

      uploadLetter: function (file) {
        return Restangular.all('letter_appointments').withHttpConfig({transformRequest: transformRequestForInsurance}).customPOST(file, undefined, undefined, {'Content-Type': undefined });
      },

      deleteLetter: function (id) {
        // var data = {
        //   'id': id
        // }
        return Restangular.one("letter_appointments", id).remove();
      }

    },

    locations: {

      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.locations),

      searchLocation: function (searchKey, timezoneId) {
        var paramsSearch = {
          q: searchKey,
          timezone_id: timezoneId
        };
        return this.root.getList(paramsSearch);
      },

      addNewLocation: function (sendData) {
        return this.root.post(sendData);
      },

      addExistedLocation: function (sendData) {
        return this.root.put(sendData);
      }

    },

    country: {
      getListCountries: function (timezoneId) {
        var params = {
          timezone_id: timezoneId
        };
        return Restangular.all(BOOKDOC_PROFILE_API_ROUTE.countries).customGET("", params);
      }
    },

    documents: {
      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.documents),

      postDocument: function (file) {
        return this.root.withHttpConfig({transformRequest: transformRequest}).customPOST(file, undefined, undefined, {'Content-Type': undefined });
      }
    },

    educations: {
      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.educations),

      updateEducations: function (sendData) {
        return this.root.customPOST(sendData, 'change');
      }
    },

    languages: {
      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.languages),

      saveLanguages: function (sendData) {
        return this.root.post(sendData);
      }

    },

    hospitals: {

      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.hospitals),

      updateHospitals: function (sendData) {
        return this.root.customPOST(sendData, 'change');
      }

    },

    emailRecipients: {

      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.doctors),

      updateEmail: function (sendData) {
        return this.root.customPOST(sendData, 'update_recipients_emails');
      }

    },

    pins: {
      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.pins),

      generate: function (phone) {
        return this.root.customPOST(null, 'generate');
      },

      verify: function (pin) {
        return this.root.customPUT(pin, 'verify');
      }

    },

    operatingHours: {
      root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.operatingHours),

      updateOperatingHours: function(operatingData) {
        return this.root.customPOST(operatingData, 'change');
      }
    }
  };

};

informationAPI.$inject = ['BOOKDOC_PROFILE_API_ROUTE', 'Restangular'];

module.exports = informationAPI;
