/*globals moment*/

'use strict';

var _ = require('lodash');

// var moment = require('moment');

require('moment-range');

var dateRangeService = function (TimeZoneService) {

  function formatDateRange(range) {
    var start = moment.utc(range.start_time);
    var end = moment.utc(range.end_time);

    return end.diff(start) <= 0 ? null : moment.range(start, end);
  }

  function checkRangeIsInfuture(range) {
    var start = TimeZoneService.convertTimestamp(range.start_time);
    var currentTime = TimeZoneService.currentTime();
    var now = moment(currentTime);
    
    return start.diff(now) >= 0;
  }

  function formatDateRanges(ranges, escapeId, isOnlyTimeOn, isOnlyTimeOff) {
    var arr = [];
    var item;

    _.each(ranges, function (range) {

      if (escapeId !== range.$$hashKey && !range._destroy) {
        item = formatDateRange(range);

        if (item) {

          if (isOnlyTimeOn && !range.off) {
            arr.push(item);
          }

          if (isOnlyTimeOff && range.off) {
            arr.push(item);
          }

        }

      }

    });

    return arr;
  }

  function dateRangeIsValid(range, ranges) {
    var isValid = true;
    var arr;
    var momentRange;
    var rangeIsInfuture;

    rangeIsInfuture = checkRangeIsInfuture(range);
    momentRange = formatDateRange(range);

    if (!momentRange || !rangeIsInfuture) {
      return false;
    }

    // get timeons
    arr = formatDateRanges(ranges, range.$$hashKey, true, false);

    _.each(arr, function (_range) {

      isValid = !momentRange.overlaps(_range);

      return isValid;
    });

    return isValid;
  }

  function timeslotValidation(timeslot, dayTimeslot) {
    var range = {
      $$hashKey: timeslot.$$hashKey,
      start_time: timeslot.start_time,
      end_time: timeslot.end_time,
    };
    var duration = (timeslot.end_time - timeslot.start_time) / 60 /1000;
    var divisible = (duration % timeslot.appointment_length === 0);
    return {
      inValidData: !(timeslot.appointment_length && timeslot.location && timeslot.location.id && dateRangeIsValid(range, dayTimeslot) && timeslot.appointment_length <= duration && divisible),
      divisible: !(divisible && timeslot.appointment_length && timeslot.start_time && timeslot.end_time)
    } ;
  }

  function isDeleteTimeSlot(origTimeSlot, timeslot) {
    return origTimeSlot.id && !timeslot.inValidData && !timeslot.divisible && !origTimeSlot._destroy && origTimeSlot.id === timeslot.id;
  }
  
  return {
    dateRangeIsValid: dateRangeIsValid,
    timeslotValidation: timeslotValidation,
    isDeleteTimeSlot: isDeleteTimeSlot
  };

};

dateRangeService.$inject = [
  'TimeZoneService'
];

module.exports = dateRangeService;
