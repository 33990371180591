/* Define controller for footer module*/
'use strict';

var FooterCtrl = function ($rootScope, $scope, RoleValidation) {
  var vm = this;

  vm.firstTimeLogin = ($rootScope.currentUser) ? $rootScope.currentUser.first_time_login : true;
  vm.grouping = RoleValidation.isAssistant($rootScope.currentUser) || false;
  vm.year = new Date().getFullYear();

  /**
   * [contact description] open modal contact us
   * @return {[type]} [description]
   */
  vm.contact = function () {
    $scope.$emit('showModal');
  };
};

FooterCtrl.$inject = [
  '$rootScope',
  '$scope',
  'RoleValidation'
];

module.exports = FooterCtrl;
