'use strict';

var _ = require('lodash');

var LocationWithAddressCtrl = function($modalInstance, sendData, InformationAPI, $scope, OpenInfoModalService, $rootScope, APP_CONSTANT, OperatingHour, ValidateMessagesService, ValidationFileTypeService, ChainRequestService, RegistrationAPI, PracticeLocationService) {
  var vm = this;

  var OTHER = 'Other';

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {

    vm.isRegistration = sendData.isRegistration;

    vm.listCountries = sendData.listCountries;

    // pattern to check phone, postal code
    vm.patterns = APP_CONSTANT.patterns;

    // all validation messages
    vm.messages = ValidateMessagesService;

    vm.isUpdating = false;

    // use to show/hide input for location information
    vm.isCollapsed = true;

    vm.isDeleteLisenseDocument = false;

    vm.isAddLisenceDocument = false;

    // list search result of locations
    vm.locations = sendData.locations;

    // push other location to list locations
    vm.locations.push(PracticeLocationService.getLocationObj());
    vm.locations[vm.locations.length - 1].name = OTHER;

    vm.selectedLocation = sendData.selectedLocation;

    if (vm.selectedLocation.location.id) {
      vm.selectedLocationName = vm.selectedLocation.location.name;
    } else {
      vm.selectedLocationName = OTHER;
    }

    // object contain data that send with api
    vm.sendObject = {};

    // switch between form with address and without address
    vm.isShowLocationInfo = !vm.selectedLocation.location.id;

    vm.errors = {
      document: {
        wrongType: false,
        fileSize: false
      },
      operatingHours: {
        invalidTime: false,
        isOverlapBreakTime: false
      }
    };

    vm.operatingHourData = [];

    // contain lisence document object
    vm.lisenceDocument = {
      name: vm.selectedLocation.clinic_license_name || undefined
    };

    // lisence document object use to send request
    vm.sendRequestDocument = {};

    // format of lisence document
    vm.documentsFormat = APP_CONSTANT.documentFileFormat;

    vm.countrySelected = {};
  }

  createVariables();

  /**
   * [deleteLisenceDocument - call api to delete lisence document]
   *
   */
  function deleteLisenceDocument() {
    return RegistrationAPI.locationLisence.deleteLisence(vm.selectedLocation.clinic_license_id);
  }

  /**
   * [uploadLisenceDocument - call api to upload lisence document]
   *
   */
  function uploadLisenceDocument() {
    return RegistrationAPI.locationLisence.uploadLisence(vm.sendRequestDocument);
  }

  /**
   * [addNewPracticeLocation - call api to add new practice location]
   *
   */
  function addNewPracticeLocation() {
    return InformationAPI.locations.addNewLocation(vm.sendObject);
  }

  /**
   * [changeFile - handle when file upload changes]
   * @param [object] element
   *
   */
  $scope.changeFile = function(element) {
    if (element.files.length === 0) {
      return;
    }

    vm.sendRequestDocument = element.files[0];
    var fileType = vm.sendRequestDocument.type;

    // validate file type
    vm.errors.document.wrongType = !ValidationFileTypeService.checkFileType(fileType, vm.documentsFormat);

    if (!vm.errors.document.wrongType) {
      // file type valid

      $scope.$apply(function() {
        var fileSize = vm.sendRequestDocument.size;

        // validate file size
        vm.errors.document.fileSize = ValidationFileTypeService.checkFileSize(fileSize);

        if (!vm.errors.document.fileSize) {
          // file size valid

          vm.lisenceDocument = vm.sendRequestDocument;

          vm.isDeleteLisenseDocument = !!vm.selectedLocation.clinic_license_id;

          vm.isAddLisenceDocument = true;

        }
        // file type valid
        vm.errors.document.wrongType = false;
      });

    } else {
      $scope.$apply(function() {
        // file type invalid
        vm.errors.document.wrongType = true;
      });
    }
  };

  /**
   * [deleteDocument - handle when user click delete lisence document]
   *
   */
  vm.deleteDocument = function () {
    // delete lisence document

    angular.element('#uploadDocument').val(null);
    vm.lisenceDocument = {};
    vm.isDeleteLisenseDocument = true;
    vm.isAddLisenceDocument = false;
  };

  $scope.$on('changeOperating', function () {
    // check operating hour
    vm.errors.operatingHours.invalidTime = OperatingHour.validationOperating(vm.selectedLocation.operating_hours);
  });

  /**
   * [updateLocationSelected handle when selectbox changed]
   * @param  {[object]} e - event object
   *
   */
  vm.updateLocationSelected = function(location, form) {
    // set selected location object
    vm.selectedLocation.location = (location.name === OTHER) ? PracticeLocationService.getLocationObj() : location;

    vm.selectedLocationName = location.name;

    vm.isShowLocationInfo = !vm.selectedLocation.location.id;

    // reset form
    vm.error = {};
    form.$setPristine();
  };

  /**
   * [updateState handle when state selectbox changed]
   * @param  {[object]} e - event object
   *
   */
  vm.updateState = function(state) {
    vm.selectedLocation.location.state_id = state.id;
    vm.selectedLocation.location.state = state.name;
  };

  vm.updateCountry = function(country) {
    vm.countrySelected = country;
    vm.selectedLocation.location.country_id = country.id;
    vm.selectedLocation.location.country = country;

    // Reset state when choose country
    vm.selectedLocation.location.state_id = null;
    vm.selectedLocation.location.state = null;
  };

  /**
   * [save - handle user click save button]
   *
   */
  vm.save = function(form) {

    // format operating data before send to server
    vm.operatingHourData = OperatingHour.formatOperatingData(vm.selectedLocation.operating_hours, false);

    if (!form.$valid || vm.errors.operatingHours.invalidTime || vm.errors.operatingHours.isOverlapBreakTime) {
      return;
    }

    if (!vm.isUpdating) {
      vm.isUpdating = true;
    } else {
      return;
    }

    var chain = ChainRequestService.startQueue();

    $scope.$emit('updateLoadingStatus', true);

    if (vm.selectedLocation.clinic_license_id && vm.isDeleteLisenseDocument) {
      // delete old lisence document
      chain = chain.then(deleteLisenceDocument);
      chain = chain.then(function (data) {
        vm.selectedLocation.clinic_license_id = null;
      });
    }

    if (vm.isAddLisenceDocument) {
      // upload lisence document
      chain = chain.then(uploadLisenceDocument);
      chain = chain.then(function (data) {
        vm.selectedLocation.clinic_license_id = data.id;
      });
    }

    chain = chain.then(function (data) {
      // prepare send object to call api or return to view

      // format phone/fax number
      var phone = vm.selectedLocation.phone.replace(vm.patterns.formatPhoneNumber,'');
      var fax = vm.selectedLocation.fax.replace(vm.patterns.formatPhoneNumber,'');

      // object that send with api
      vm.sendObject = vm.selectedLocation;
      vm.sendObject.phone = phone;
      vm.sendObject.fax = fax;
      vm.sendObject.operating_hours = vm.operatingHourData;

      if (vm.isRegistration) {
        // in case registration

        vm.sendObject.clinic_license_name = vm.lisenceDocument.name;

        _.forEach(vm.sendObject.operating_hours, function(operating) {
          operating.isOpen = operating.open_time !== '';
        });

      }
    });

    if (!vm.isRegistration) {
      // send POST to create new location here
      // add other location
      chain = chain.then(addNewPracticeLocation);
      chain = chain.then(function (data) {
          $rootScope.profiles.locations.push(data);
          vm.isUpdating = false;
          $modalInstance.close();
      });
    }

    // final handle of queue
    chain = chain.then(function (data) {
      vm.isUpdating = false;

      if (vm.isRegistration) {
        // remove other item in location list
        sendData.locations.splice(sendData.locations.length - 1, 1);
        $modalInstance.close(vm.sendObject);
      }
    }, function (err) {
      vm.isUpdating = false;
    })
    .finally(function() {
      $scope.$emit('updateLoadingStatus', false);
    });

  };

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }

    if (reason === 'backdrop click' || reason === 'cancel') {
      // remove other item in location list
      sendData.locations.splice(sendData.locations.length -1, 1);
    }
  });

  /**
   * [cancel - handle when user click cancel button]
   *
   */
  vm.cancel = function() {
    if (!vm.isUpdating) {
      $modalInstance.dismiss('cancel');
    } else {
      return;
    }
  };

  /**
   * Open contact us modal
   */
  vm.contact = function() {
    $scope.$emit('showModal', {
      remove_location: true
    });
  };
};

LocationWithAddressCtrl.$inject = [
  '$modalInstance',
  'sendData',
  'bookdoc.information.api',
  '$scope',
  'OpenInfoModalService',
  '$rootScope',
  'APP_CONSTANT',
  'OperatingHour',
  'ValidateMessagesService',
  'ValidationFileTypeService',
  'ChainRequestService',
  'RegistrationAPI',
  'PracticeLocationService'
];

module.exports = LocationWithAddressCtrl;
