'use strict';

var _ = require('lodash');

var InformationCtrl = function ($scope, $modal, $rootScope, OperatingHour, moment, profileData, InformationApi) {
  var vm = this;

  vm.profiles = $rootScope.profiles = profileData || $rootScope.profiles;

  // FIX ME - remove this code when backend have consistant between postal and zipcode
  _.forEach(vm.profiles.locations, function (location) {
    location.zipcode = location.postal;
    delete location.postal;
  });

  vm.operatingDefault = angular.copy(OperatingHour.operatingData);

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {

    vm.modalConfig = {
      isAnimation: true
    };

    vm.listStates = [];
  }

  var updateLoadingStatusListener = $rootScope.$on('updateLoadingStatus', function (e, isLoading) {
    vm.isLoading = isLoading;
  });

  $rootScope.$on('$destroy', function () {
    updateLoadingStatusListener();
  });

  createVariables();

  var timezoneId = vm.profiles && vm.profiles.timezone && vm.profiles.timezone.id;

  InformationApi.country.getListCountries(timezoneId)
    .then(function(data) {
      vm.listCountries = data;
    })
    .finally(function() {
      $rootScope.showIndicator = false;
    });

  // Push operating data default if operating data is null
  _.forEach(vm.profiles.locations, function(location) {
    var operatingDataLength = location.operating_hours.length;
    // FIX ME: remove (operatingDataLength === 1 && location.operating_hours[0].day_of_week === 'lunch_break')
    // when server has been updated
    if (operatingDataLength === 0 || (operatingDataLength === 1 && location.operating_hours[0].day_of_week === 'lunch_break')) {
      location.operating_hours = angular.copy(vm.operatingDefault);
    }

    // Check if have any insurance that not verified yet
    var insuranceNotApprovedIndex = _.findIndex(location.insurances, function(insurance) {
      return !insurance.verified;
    });

    // Update status of approved in Insurance panel
    location.showNotApprovedInsurance = insuranceNotApprovedIndex >= 0;

  });

  /**
   * [openLocationModal open add location form]
   *
   */
  vm.openLocationModal = function () {

    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/location.modal.html',
      controller: require('./search-location.controller'),
      controllerAs: 'loca',
      windowClass: 'modal-info',

      resolve: {
        sendData: function () {
          return {
            listCountries: vm.listCountries,
            timezoneId: timezoneId
          };
        }
      }
    });

    modalInstance.result.then(function (data) {
      console.log(data);
    }, function () {
      console.log('Modal dismissed at: ' + new Date());
    });
  };

  /**
   * [openOperatingHoursModal open to edit operating hours]
   *
   */
  vm.openOperatingHoursModal = function (hospital) {
    var operatingPerLocation = hospital.operating_hours;
    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/operating-hours.modal.html',
      controller: require('./operating-hours.controller.js'),
      controllerAs: 'operateHour',
      windowClass: 'modal-info',

      resolve: {
        operating: function() {
          return {
            locations: vm.profiles.locations,
            operatingData: operatingPerLocation,
            practiceLocationId: hospital.id
          };
        }
      }
    });
  };
  //   [openGeneralInfoModal open edit general infomation modal]

  vm.openGeneralInfoModal = function () {
    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/general-info.modal.html',
      controller: require('./edit-general-info.controller'),
      controllerAs: 'genInfo',
      windowClass: 'modal-info modal-info--general',
      backdropClass: 'modal-backdrop',

      resolve: {
        profile: function () {
          return {
            data: vm.profiles
          };
        }
      }
    });
  };

  /**
   * [openCredentials]
   *
   */
  vm.openCredentialsModal = function (size) {
    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/credential.modal.html',
      controller: require('./credential.controller'),
      controllerAs: 'credential',
      size: size,
      windowClass: 'modal-info',

      resolve: {
        sendData: function () {
          return {
            memberships: vm.profiles.memberships,
            qualifications: vm.profiles.qualifications,
            mmc: vm.profiles.mmc,
            nsr: vm.profiles.nsr
          };
        }
      }

    });
  };

  vm.openInsuranceEmployersModal = function (viewInsurance, index) {
    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/insurance-employers.modal.html',
      controller: require('./insurance-employers.controller'),
      controllerAs: 'ie',
      windowClass: 'modal-info modal-info--tabs',
      backdropClass: 'modal-backdrop',
      resolve: {
        profile: function () {
          return {
            data: vm.profiles,
            viewInsurance: viewInsurance,
            practiceLocationIndex: index
          };
        }
      }
    });
  };

  /**
   * [openAdvanceInfoModal open Advance Info modal]
   */
  vm.openAdvanceInfoModal = function () {
    $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/advance-info.modal.html',
      controller: require('./advance-info.controller'),
      controllerAs: 'advaInfo',
      windowClass: 'modal-info modal-info--advance'
    });
  };

  vm.openApptEmailRecipientsModal = function () {
    $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/appt-email-recipient.modal.html',
      controller: require('./email-recipient.controller'),
      controllerAs: 'emailRp',
      windowClass: 'modal-info',
      resolve: {
        sendData: function () {
          return {
            emails: vm.profiles.recipients_emails
          };
        }
      }
    });
  };

  // Open service modal
  vm.openServiceModal = function (services, panelShips) {
    $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/service.modal.html',
      controller: require('./service.controller'),
      controllerAs: 'serviceCrl',
      windowClass: 'modal-info',
      resolve: {
        sendData: function () {
          return {
            services: services,
            panelShips: panelShips
          };
        }
      }
    });
  };

  //  open teleconsult modal
  vm.openTeleconsultModal = function () {
    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      templateUrl: 'profile/views/information/teleconsult.modal.html',
      controller: require('./edit-teleconsult.controller'),
      controllerAs: 'telInfo',
      windowClass: 'modal-info modal-info--general',
      backdropClass: 'modal-backdrop',

      resolve: {
        profile: function () {
          return {
            data: vm.profiles
          };
        }
      }
    });
  };
};

InformationCtrl.$inject = [
  '$scope',
  '$modal',
  '$rootScope',
  'OperatingHour',
  'moment',
  'profileData',
  'bookdoc.information.api'
];

module.exports = InformationCtrl;
