'use strict';

var dashboardConstant = {
  statictis: {
    totalAppointments: 'Total Appointments',
    doctorCancel: "Doctor's Cancellation",
    patientCancel: "Patient's Cancellation",
    noShow: 'No Shows'
  },

  chartType: 'lineChart',

  DATE_HYPHEN_FORMAT: 'YYYY-MM-DD',
  DATE_NONE_HYPHEN_FORMAT: 'YYYYMMDD'
};

module.exports = dashboardConstant;
