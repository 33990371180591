"use strict";

function ChatDialogCtrl(
  $rootScope,
  $scope,
  $element,
  moment,
  quickBloxService,
  twilioChatService,
  quillService,
  chatService,
  $anchorScroll,
  $location,
  ENV
) {
  /////////////////////////////////// view model variables /////////////////////////////////////////
  var ctrl = this;
  ctrl.msgs = [];
  ctrl.msgInput = "";
  ctrl.loadingMsgs = true;
  ctrl.isChatSessionEnded = false;
  ctrl.videoEnabled = false;
  ctrl.chatDisabled = false;
  ctrl.twilioChannel = null;
  var datesDic = [];
  var msgsIdsDic = [];
  var lastIdBeforeLoadingNewMsgs = null;
  //////////////////////////////////////////////////////////////////////////////////////////////////

  var joinedGroupChat = false;

  var resetDialog = function () {
    ctrl.msgInput = "";
    ctrl.msgs = [];
    ctrl.loadingMsgs = true;
    datesDic = [];
    lastIdBeforeLoadingNewMsgs = null;
    msgsIdsDic = [];
  };

  var loadChatThroughQuickblox = function () {
    resetDialog();

    quickBloxService.subscribeToMessageListener(function onNext(event) {
      onMessageReceived(event.userId, event.message);
    });

    var successCallback = function () {
      loadChatDialogMsgs(true);
    };

    var errorCallback = function () {
      console.log("Error in joining the dialog.");
    };

    quickBloxService.joinQBDialog(
      ctrl.chatDialogId,
      successCallback,
      errorCallback
    );
  };

  var addTwilioMsg = function (message) {
    var createDate = new moment(message.dateCreated).format("DD-MM-YYYY");
    if (!datesDic[createDate]) {
      datesDic[createDate] = true;
      addDateHeaderToDialog(message.dateCreated);
    }

    if (message.type === "media") {
      var msgViewModel = {
        isMsg: true,
        isAttachment: true,
        imageUrl: null,
        isLeftMsg: !message.author.includes("Doctor-"),
        dateSent: new moment(message.dateCreated).format("h:mm a"),
      };
      message.media.getContentTemporaryUrl().then(function (url) {
        msgViewModel.imageUrl = url;
        scrollToBottom();
      });
      ctrl.msgs.push(msgViewModel);
    } else {
      var msgViewModel = {
        isMsg: true,
        text: message.body,
        isLeftMsg: !message.author.includes("Doctor-"),
        dateSent: new moment(message.dateCreated).format("h:mm a")
      };
      ctrl.msgs.push(msgViewModel);
    }
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  };

  var loadChatThroughTwilio = function () {
    resetDialog();
    var loadMessagesCallback = function (messages) {
      console.log(messages.items);
      messages.items.forEach(function (message) {
        addTwilioMsg(message);
      });
      ctrl.loadingMsgs = false;
    };
    twilioChatService.getChannel(ctrl.chatDialogTwilioId, function (channel) {
      ctrl.twilioChannel = channel;
      channel.getMessages().then(function (messages) {
        loadMessagesCallback(messages);
      });
      channel.on("messageAdded", function (message) {
        console.log(message);
        addTwilioMsg(message);
      });
    });
  };

  var loadChatDialog = function () {
    chatService.updateReadStatus(
      ctrl.chatDialogId || ctrl.chatDialogTwilioId,
      1
    );
    if (ctrl.chatDialogId) {
      loadChatThroughQuickblox();
    } else if (ctrl.chatDialogTwilioId) {
      loadChatThroughTwilio();
    }
  };

  var loadChatDialogMsgs = function (firstLoad) {
    ctrl.loadingMsgs = true;
    var successCallback = function (messages) {
      ctrl.loadingMsgs = false;
      if (
        messages === undefined ||
        messages == null ||
        (messages.items !== undefined && messages.items.length == 0)
      )
        return;
      for (var msg in messages.items) {
        var date = new moment(messages.items[msg].date_sent * 1000).format(
          "DD-MM-YYYY"
        );
        if (!datesDic[date]) {
          datesDic[date] = true;
          addMessageToDialog(messages.items[msg], false);
          addDateHeaderToDialog(messages.items[msg].date_sent * 1000);
        } else {
          addMessageToDialog(messages.items[msg], true);
        }
      }
      if (firstLoad) scrollToBottom();
    };

    var errorCallback = function (error) {
      console.log(error);

      ctrl.loadingMsgs = false;
    };

    quickBloxService.loadDialogMsgs(
      ctrl.chatDialogId,
      ctrl.msgs.length - datesDic.length,
      successCallback,
      errorCallback
    );
  };

  var joinGroupChatIfNotJoined = function (callback) {
    if (joinedGroupChat) {
      callback(joinedGroupChat);
      return;
    }
    quickBloxService.joinQBDialog(
      ctrl.chatDialogId,
      function () {
        joinedGroupChat = true;
        callback();
      },
      function () {
        callback();
      }
    );
  };

  var sendQBMsg = function (msg) {
    joinGroupChatIfNotJoined(function () {
      if (!joinedGroupChat) return;
      quickBloxService.sendMessage(ctrl.chatDialogId, msg);
    });
  };

  var sendTwilioMsg = function (msg) {
    twilioChatService.sendMessage(ctrl.twilioChannel, msg.body);
  };

  var sendMsg = function (msg) {
    chatService.updateHasReplied(ctrl.chatDialogId || ctrl.chatDialogTwilioId);
    chatService.updateReadStatus(
      ctrl.chatDialogId || ctrl.chatDialogTwilioId,
      0
    );
    if (ctrl.chatDialogId) {
      sendQBMsg(msg);
    } else if (ctrl.chatDialogTwilioId) {
      sendTwilioMsg(msg);
    }
    ctrl.msgInput = "";
  };

  var onMessageReceived = function (userId, msg) {
    if (msg.dialog_id != ctrl.chatDialogId) return; // the incoming message is not related to the current dialog.

    if (msg.body === ctrl.msgInput) {
      ctrl.msgInput = "";
    }
    var newMsg = fillNewMessage(msg, userId);
    addMessageToDialog(newMsg, false, true);
    scrollToBottom();
    $rootScope.$broadcast("totalChatNotificationChanged", 1);
  };

  var fillNewMessage = function (msg, userId) {
    var message = {
      _id: msg.id,
      attachments: [],
      created_at: +msg.extension.date_sent || Date.now(),
      date_sent: +msg.extension.date_sent || Date.now(),
      message: msg.body,
      chat_dialog_id: msg.extension.dialog_id,
      sender_id: userId,
    };

    if (msg.extension.attachments) {
      var attachments = msg.extension.attachments;
      message.attachments = attachments;
    }

    if (message.message === "[attachment]") {
      message.message = "";
    }

    return message;
  };

  var addMessageToDialog = function (msg, afterDate, isReceived) {
    // check if the message has been added before or not to fix the race condition issue.
    if (msgsIdsDic[msg._id]) return;

    var msgViewModel = {
      isMsg: true,
    };

    if (msg.attachments.length > 0) {
      msgViewModel.imageUrl = quickBloxService.getPrivateUrl(
        msg.attachments[0].id
      );
      msgViewModel.isAttachment = true;
    } else if (msg.body) {
      msgViewModel.text = msg.body;
    } else {
      msgViewModel.text = msg.message;
    }

    msgViewModel.dateSent = new moment(msg.date_sent * 1000).format("h:mm a");
    msgViewModel.isLeftMsg = msg.sender_id != $rootScope.qbUser.id;
    msgViewModel.isMsg = true;
    msgViewModel.id = msg._id;

    if (afterDate) {
      var dateHeader = ctrl.msgs.shift();
      ctrl.msgs.unshift(msgViewModel);
      ctrl.msgs.unshift(dateHeader);
    } else {
      if (isReceived) {
        ctrl.msgs.push(msgViewModel);
      } else {
        ctrl.msgs.unshift(msgViewModel);
      }
    }
    msgsIdsDic[msg._id] = true;
  };

  var addDateHeaderToDialog = function (dateSent, push) {
    var dateViewModel = {
      isDateHeader: true,
    };

    var days = getDaysDiff(dateSent);
    if (days < 1) {
      dateViewModel.dateSent = "Today";
    } else if (days < 2) {
      dateViewModel.dateSent = "Yesterday";
    } else {
      var format = "ddd[.] D MMMM YYYY";
      dateViewModel.dateSent = new moment(dateSent).format(format);
    }

    dateViewModel.id = dateViewModel.dateSent;

    if (push) {
      ctrl.msgs.push(dateViewModel);
    } else {
      ctrl.msgs.unshift(dateViewModel);
    }
  };

  var getDaysDiff = function (date) {
    var now = moment(new Date()); //todays date
    var end = moment(date); // another date
    var duration = moment.duration(now.diff(end));
    var days = duration.asDays();
    return days;
  };

  var scrollToBottom = function () {
    setTimeout(function () {
      $element[0].getElementsByClassName("dialog_msgs_container")[0].scrollTop =
        $element[0].getElementsByClassName(
          "dialog_msgs_container"
        )[0].scrollHeight;
    }, 100);
  };

  var uploadPicQB = function (file) {
    var successCallback = function (res) {
      var fileUID = res.uid;
      // prepare a message
      var msg = {
        type: "groupchat",
        body: "attachment",
        extension: {
          save_to_history: 1,
          attachments: [{ id: fileUID, type: "photo" }],
        },
        // , markable: 1
      };

      sendMsg(msg);
    };

    var failureCallback = function (error) {
      console.error(error);
    };
    quickBloxService.uploadAttachment(file, successCallback, failureCallback);
  };

  var uploadPicTwilio = function (file) {
    const formData = new FormData();
    formData.append("file", file);
    twilioChatService.sendMessage(ctrl.twilioChannel, formData);

    // After sending image, update unread status of recipient to true and read status of doctor to true
    chatService.updateHasReplied(ctrl.chatDialogId || ctrl.chatDialogTwilioId);

    chatService.updateReadStatus(
      ctrl.chatDialogId || ctrl.chatDialogTwilioId,
      0
    );
  };

  ctrl.uploadPic = function (file) {
    if (file && ctrl.chatDialogId) {
      uploadPicQB(file);
    } else if (file && ctrl.chatDialogTwilioId) {
      uploadPicTwilio(file);
    }
  };

  /////////////////////////////////// click listeners //////////////////////////////////////////////

  ctrl.onSendBtnClicked = function () {
    if (ctrl.isChatSessionEnded) return; // This to prevent the user even if he shows the chat controllers.
    var msgBody = ctrl.msgInput;
    if (msgBody) {
      var msg = {
        type: "groupchat",
        body: msgBody,
        extension: {
          save_to_history: 1,
        },
        // , markable: 1
      };
      sendMsg(msg);
    }
  };

  ctrl.onVideoBtnClicked = function () {
    if (ctrl.isChatSessionEnded || ctrl.showVideoChat) return;
    $rootScope.startVideoChat();
  };

  ctrl.onTwilioVideoBtnClicked = function (event, href) {
    event.preventDefault();
    if (ctrl.isChatSessionEnded || ctrl.showVideoChat) return;
    var notificationPayload = {
      user_id: ctrl.sessionDetails.payment.payer.id,
    };
    if (ctrl.sessionDetails.video_session.twilio_video_enabled) {
      notificationPayload.room =
        ctrl.sessionDetails.video_session.twilio_video_room;
    }
    chatService.initializeCall(notificationPayload, function () {
      window.location = href;
    });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  this.$onChanges = function (changes) {
    if (changes != null && changes.chatDialogId != null) {
      quillService.init();
      var newChatDialogId = changes.chatDialogId.currentValue;
      if (newChatDialogId) {
        ctrl.chatDialogId = newChatDialogId.dialogId;
        ctrl.chatDialogTwilioId = newChatDialogId.twilioDialogId;
        if (ctrl.twilioChannel) {
          ctrl.twilioChannel.removeAllListeners();
        }
        ctrl.twilioChannel = null;
        loadChatDialog();
      }
    }

    if (
      changes.sessionDetails != null &&
      changes.sessionDetails.currentValue != null
    ) {
      var isInBuffer = new moment().isBetween(
        new moment(ctrl.sessionDetails.start_at * 1000).subtract(
          ctrl.sessionDetails.chat_buffer
        ),
        new moment(ctrl.sessionDetails.start_at * 1000)
      );
      var enabledCondition =
        (ENV.name == "qa" &&
          ctrl.sessionDetails.video_session &&
          ctrl.sessionDetails.video_session.start_at < new moment().unix()) ||
        (ctrl.sessionDetails.video_session &&
          ctrl.sessionDetails.video_session.start_at < new moment().unix() &&
          new moment().unix() < ctrl.sessionDetails.video_session.end_at) ||
        isInBuffer;

      ctrl.videoEnabled =
        ctrl.sessionDetails.video_session != null && enabledCondition;

      var canChat =
        isInBuffer || ctrl.sessionDetails.start_at < new moment().unix();
      ctrl.chatDisabled = !canChat;
    }

    if (
      changes.sessionDetails != null &&
      changes.sessionDetails.currentValue != null
    ) {
      ctrl.patientDetails = ctrl.sessionDetails;

      ctrl.isChatSessionEnded =
        ctrl.sessionDetails.server_current_time >= ctrl.sessionDetails.end_at &&
        !ctrl.sessionDetails.open_session;
    }
  };

  this.$onDestroy = function () {
    if (ctrl.twilioChannel) {
      ctrl.twilioChannel.removeAllListeners();
    }
    ctrl.twilioChannel = null;
  };

  ctrl.onScrollCallback = function () {
    if (!ctrl.loadingMsgs) {
      lastIdBeforeLoadingNewMsgs = "" + ctrl.msgs[1].id;
      loadChatDialogMsgs(false);
      return true;
    }
    return false;
  };

  $scope.$on("onMsgRenderFinished", function (onMsgRenderFinished) {
    if (lastIdBeforeLoadingNewMsgs == null) return;
    $location.hash(lastIdBeforeLoadingNewMsgs);
    lastIdBeforeLoadingNewMsgs = null;
    $anchorScroll();
  });
}

var chatDialogComponent = function () {
  return {
    transclude: true,
    templateUrl: "chat/views/chatDialog.html",
    controller: ChatDialogCtrl,
    bindings: {
      chatDialogId: "<",
      sessionDetails: "<",
      showVideoChat: "<",
    },
  };
};

ChatDialogCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$element",
  "moment",
  "quickBloxService",
  "twilioChatService",
  "quillService",
  "chatService",
  "$anchorScroll",
  "$location",
  "ENV",
];

module.exports = chatDialogComponent();
