'use strict';

var _ = require('lodash');

var ReferralNote = function ($modalInstance, $modal, sendData) {
  var vm = this;
  
  vm.referralNote = sendData.referralNote;
  // Close referral modal
  vm.closeModal = function () {
    $modalInstance.dismiss();
  };
};

ReferralNote.$inject = [
  '$modalInstance',
  '$modal',
  'sendData'
];

module.exports = ReferralNote;