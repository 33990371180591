/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.dashboard.controllers',
    []
  )
  .controller("QRCodeModalController", require('./qrcode-modal.controller'))
  .controller('DashboardCtrl', require('./dashboard.controller'));
