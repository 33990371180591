'use strict';

var _ = require('lodash');

var MedicalNewsService = function($rootScope, $http, APP_CONSTANT) {

  return {

    /**
     * [checkAvailableCountry - Check MIMS available country]
     * @param  {string} currentCountryCode
     */
    checkAvailableCountry: function (currentCountryCode) {

      $rootScope.availableCountry = _.find(APP_CONSTANT.mims.availableCountries, function(country) {
        return country.code === currentCountryCode;
      }) || APP_CONSTANT.mims.defaultCountry;
    },

    /**
     * [getMimsData - Get MIMS RSS use YQL API]
     */
    getMimsData: function (countryCode) {
      $rootScope.showIndicator = true;

      var headers = {
        headers: {
          'Authorization': undefined
        }
      };

      var rss = APP_CONSTANT.mims.api + countryCode + '.xml';

      // Use YQL API to load RSS Feed
      var url = APP_CONSTANT.mims.xml2JsonApi + "'" + rss + "'";

      return $http.get(
        url,
        headers
      );
    },

    /**
     * [formatData - to by pass the articles contains some keywords from constant]
     * @param  {array} data
     * @return {array}
     */
    formatData: function (data) {
      var results = [];
      var isValid = true;
      var keywords = APP_CONSTANT.mims.avoidKeywords;

      if (data && data.length) {

        for (var i = 0, dataLength = data.length; i < dataLength; i ++) {
          for (var j = 0, keywordsLength = keywords.length; j < keywordsLength; j++) {

            // Check data is valid
            isValid = data[i].title.toLowerCase().indexOf(keywords[j].toLowerCase()) === -1 && data[i].description.toLowerCase().indexOf(keywords[j].toLowerCase()) === -1;

            // Break out for loop if in valid data
            if (!isValid) {
              break;
            }
          }

          if (isValid) {
            results.push(data[i]);
          }
        }
      }

      return results;
    }

  };
};

MedicalNewsService.$inject = [
  '$rootScope',
  '$http',
  'APP_CONSTANT'
];

module.exports = MedicalNewsService;
