/* Define controller for Terms of User module*/
'use strict';

var TermsCtrl = function($rootScope) {
  var vm = this;
  vm.title = "Terms of Use";
  $rootScope.showIndicator = false;
};

TermsCtrl.$inject = [
  '$rootScope'
];

module.exports = TermsCtrl;
