/*globals FormData*/

'use strict';

var _ = require('lodash');

var findByProperty = function () {

  return function (value, property, arr) {

    return _.find(arr, function (item) {
      return item[property] === value;
    });

  };

};

findByProperty.$inject = [];

module.exports = findByProperty;
