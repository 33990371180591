'use strict';

module.exports = 
  angular
    .module('bookdoc.audits', [
      'ui.bootstrap',
      'ui.router',
      'restangular',
      'angularMoment',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('./services').name,
      require('./controllers').name
    ])

    // routes
    .config(require('./audits.routes'))
    .constant('AUDITS_API_ROUTE', require('./audits.constant'));
