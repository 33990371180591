"use strict";

var CreatePrescriptionConfirmationCtrl = function (
  $rootScope,
  $scope,
  $modalInstance,
  prescriptionService,
  chatSessionId,
  medicines,
  total,
  pickupLocation
) {
  var ctrl = this;
  console.log("pickup", pickupLocation);

  ctrl.medicines = medicines;
  ctrl.total = total;
  ctrl.pickupLocation = pickupLocation;
  ctrl.showSuccess = false;
  ctrl.loading = false;
  ctrl.submitted = false;

  ctrl.save = function () {
    ctrl.loading = true;
    ctrl.submitted = true;
    prescriptionService.savePrescription(
      {
        chat_session_id: chatSessionId,
        medicines: medicines.map(function (medicine) {
          var sendObject = {
            medicine_id: medicine.medicine.id,
            quantity: medicine.quantity,
            unit_price: medicine.unitPrice,
          };
          var consumptionFields = {
            quantity: "consumption_quantity",
            unit: "consumption_unit",
            perDay: "consumption_per_day",
            days: "consumption_days",
            beforeMeal: "consume_before_meal",
            afterMeal: "consume_after_meal",
            whenNecessary: "consume_when_necessary",
            toComplete: "consumption_to_complete",
            causeDrowsiness: "consumption_cause_drowsiness",
            remarks: "remarks",
          };
          Object.keys(consumptionFields).forEach(function (key) {
            if (medicine.dosage[key] !== null) {
              sendObject[consumptionFields[key]] = medicine.dosage[key];
            }
          });
          return sendObject;
        }),
      },
      function (prescription) {
        ctrl.showSuccess = true;
        $rootScope.setPrescriptionDetails(prescription);
        ctrl.loading = false;
      }
    );
  };

  ctrl.closeModal = function () {
    $modalInstance.dismiss();
  };
};

CreatePrescriptionConfirmationCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$modalInstance",
  "prescriptionService",
  "chatSessionId",
  "medicines",
  "total",
  "pickupLocation",
];

module.exports = CreatePrescriptionConfirmationCtrl;
