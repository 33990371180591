'use strict';

var ConflictLocationAppointmentCtrl = function($modalInstance, $modal, sendData) {
  var vm = this;

  vm.closeModal = function() {
    $modalInstance.dismiss();
  };

  vm.createAppointment = function() {
		sendData.callback();
		vm.closeModal();
  };
};

ConflictLocationAppointmentCtrl.$inject = [
  '$modalInstance',
  '$modal',
  'sendData'
];

module.exports = ConflictLocationAppointmentCtrl;
