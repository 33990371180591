'use strict';

var states = function ($stateProvider, $urlRouterProvider) {

  $urlRouterProvider.when('/calendar/', '/calendar/appointments/');

  $stateProvider
    .state('main.calendar', {

      url: 'calendar/',
      templateUrl: "calendar/views/calendar.html",
      abstract: true,

      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',

          function ($rootScope, ProfileAPI) {
            return ProfileAPI.get($rootScope.currentUser && $rootScope.currentUser.id);
          }

        ]
      },

      controller: 'CalendarCtrl',
      controllerAs: 'calendar'

    })

    // Define state for grouping calendar
    .state('main.groupingCalendar', {

      url: 'doctor/:doctorId/:practiceLocationId/:practiceLocationName/calendar/',
      templateUrl: "calendar/views/calendar.html",
      abstract: true,

      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',
          '$stateParams',
          'GroupingAPI',
          'ChainRequestService',

          function ($rootScope, ProfileAPI, $stateParams, GroupingAPI, ChainRequestService) {
            var practiceLocationId = Number($stateParams.practiceLocationId);
            var doctorId = Number($stateParams.doctorId);
            
            return ChainRequestService.startQueue()
                    .then(function() {
                      return GroupingAPI.lockMechanism(practiceLocationId);
                    })
                    .then(function (data) {
                      return ProfileAPI.getDoctorProfile(doctorId);
                    });
          }

        ]
      },

      controller: 'CalendarCtrl',
      controllerAs: 'calendar'

    });
};

states.$inject = ['$stateProvider', '$urlRouterProvider'];

module.exports = angular
  .module('bookdoc.calendar')
  .config(states);
