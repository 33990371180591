'use strict';

var _ = require('lodash');

var TeleconsultModalCtrl = function ($modalInstance, $modal, profile, $q, $scope, OpenInfoModalService, profileAPI, APP_CONSTANT) {
  var vm = this;

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {
    // Get profile list
    vm.profile = profile.data;
    vm.teleconsult_enabled = vm.profile.teleconsult_enabled;

    vm.isUpdating = false;
  }

  createVariables();

  function toggleTeleconsult(){
    var updateParams = {
      'teleconsult_enabled': vm.teleconsult_enabled
    };
    return profileAPI.toggleTeleconsult(updateParams, vm.profile.id);
  }

  /**
   * [save - handle user click save button]
   *
   */
  vm.save = function (form) {
    var deferred = $q.defer();
    deferred.resolve();
    var chain = deferred.promise;

    if (!vm.isUpdating) {
        vm.isUpdating = true;
    } else {
        return;
    }

    $scope.$emit('updateLoadingStatus', true);

    if (form.$valid) {
        // Call api to update phone
        chain = chain.then(toggleTeleconsult);
        chain = chain.then(function (data) {
            if(data){
                $scope.$emit('UpdateTeleconsult', data.teleconsult_enabled);
            }
        })
        .finally(function() {
            vm.isUpdating = false;
            $modalInstance.close();
            $scope.$emit('updateLoadingStatus', false);
        });
    }
  };

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }
  });

  /**
   * [close be applied for close button of modal]
   */
  vm.close = function () {
    if (!vm.isUpdating) {
      $modalInstance.dismiss('cancel');
    } else {
      return;
    }
  };

};

TeleconsultModalCtrl.$inject = [
  '$modalInstance',
  '$modal',
  'profile',
  '$q',
  '$scope',
  'OpenInfoModalService',
  'bookdoc.profile.api',
  'APP_CONSTANT'
];

module.exports = TeleconsultModalCtrl;
