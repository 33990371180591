'use strict';

var referApiService = function (Restangular) {

  var transformRequest = function (tdata) {
    var form = new FormData();

    form.append('file', tdata);

    return form;
  };

  return {
    
    searchReferees: function (params) {
      return Restangular.all("doctors").customGET('', params);
    },

    // Upload referral letter
    addReferralLetter: function (file) {
      return Restangular.all('referral_letters').withHttpConfig({transformRequest: transformRequest}).customPOST(file, undefined, undefined, {'Content-Type': undefined });
    },

    // Delete referral letter
    deleteReferralLetter: function(id) {
      return Restangular.one("referral_letters", id).remove();
    },

    referDoctor: function(referData) {
      return Restangular.all("referrals").customPOST(referData, '');
    },

    suggestDoctor: function(suggestData) {
      return Restangular.all("referrals").customPOST(suggestData, 'suggest_doctor');
    },

    getReferral: function (page, sort_by, isReferTo) {
      var referralApiRoute = isReferTo ? 'referrers' : 'referees'; 
      
      var getReferralParams = {
        page: page,
        sort_by: sort_by
      };
      return Restangular.all(referralApiRoute).customGET('', getReferralParams);
    },

    sendSms: function (smsData) {
      return Restangular.all("dr/doctor_sms_reminders/send_sms").customPOST(smsData, '');
    }

  };

};

referApiService.$inject = [
  'Restangular'
];

module.exports = referApiService;
