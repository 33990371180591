'use strict';

var _ = require('lodash');

var EmailRecipientCtrl = function ($scope, $modalInstance, sendData, ValidateMessagesService, ArrayService, InformationAPI, APP_CONSTANT) {
  var vm = this;

  vm.emails = angular.copy(sendData.emails);
  _.forEach(vm.emails, function (item) {
    item.updated = true;
  });

  vm.messages = ValidateMessagesService;
  vm.patterns = APP_CONSTANT.patterns;
  vm.maxAppointmentEmailRecipients = APP_CONSTANT.maxAppointmentEmailRecipients;
  vm.isUpdating = false;
  vm.validate = {
    isDuplicate: false
  };

  /**
   * [addEmail description] Add email recipients
   */
  vm.addEmail = function () {
    if (vm.emails.length < vm.maxAppointmentEmailRecipients) {
      vm.emails.push({
        email: '',
        updated: true
      });
    }
  };

  /**
   * [deleteEmail description] Delete email
   * @param  {[number]} $index [description] position of email in array
   * @return {[type]}        [description]
   */
  vm.deleteEmail = function (index, form) {
    vm.emails.splice(index, 1);
    vm.validate.isDuplicate = ArrayService.checkDuplicateItem(vm.emails, 'email');
  };

  vm.inputChange = function () {
    vm.validate.isDuplicate = ArrayService.checkDuplicateItem(vm.emails, 'email');
  };

  /**
   * [save description] Save email when user click button save
   * @return {[type]} [description]
   */
  vm.save = function (form) {
    // don't handle when has error
    if (form.$invalid || vm.validate.isDuplicate) {
      return;
    }

    // get removed emails
    var data = ArrayService.getLeftDifferent(angular.copy(sendData.emails), angular.copy(vm.emails), 'email');

    // get new emails
    data = data.concat(ArrayService.getLeftDifferent(angular.copy(vm.emails), angular.copy(sendData.emails), 'email'));

    // update 'updated' property for all emails
    _.forEach(data, function (item) {
      if(!item.hasOwnProperty('updated')) {
        // item that not have updated property mean it is removed item
        item.updated = false;
      }
    });

    // only call api when has update
    if (data.length > 0) {

      if (!vm.isUpdating) {
        vm.isUpdating = true;
      } else {
        return;
      }

      // call api
      InformationAPI.emailRecipients.updateEmail({recipients_emails: data})
        .then(function (data) {
          $scope.$emit('UpdateEmailRecipients', data);

          // enable form before close modal
          vm.isUpdating = false;
          $modalInstance.close();
        }, function(err) {
          vm.isUpdating = false;
        });
    }
  };

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }
  });

  /**
   * [cancel description] Cancel modal
   * @return {[type]} [description]
   */
  vm.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
};

EmailRecipientCtrl.$inject = [
  '$scope',
  '$modalInstance',
  'sendData',
  'ValidateMessagesService',
  'ArrayService',
  'bookdoc.information.api',
  'APP_CONSTANT'
];

module.exports = EmailRecipientCtrl;
