/* Define controller for appointments module*/
'use strict';

var ProfileCtrl = function ($rootScope, ModalService) {

  var vm = this;

  vm.openModalChangePW = ModalService.openModalChangePW;

  var cleanUpPhoneNumberListener = $rootScope.$on('UpdatePhoneNumber', function(event, data) {
    $rootScope.profiles.phone_number = data;
  });

  var cleanUpSpecialtiesListener = $rootScope.$on('UpdateSpecialties', function(event, data) {
    $rootScope.profiles.specialties = data;
  });

  var cleanUpQualificationListener = $rootScope.$on('UpdateQualification', function(event, data) {
    $rootScope.profiles.qualifications = data;
  });

  var cleanUpMembershipListener = $rootScope.$on('UpdateMembership', function(event, data) {
    $rootScope.profiles.memberships = data;
  });

  var cleanUpLocationListener = $rootScope.$on('UpdateLocations', function(event, data) {
    $rootScope.profiles.locations.push(data);
  });

  var cleanUpEducationListener = $rootScope.$on('UpdateEducations', function(event, data) {
    $rootScope.profiles.educations = data;
  });

  var cleanUpHospitalsListener = $rootScope.$on('UpdateHospitals', function(event, data) {
    $rootScope.profiles.hospitals = data;
  });

  var cleanUpLanguagesListener = $rootScope.$on('UpdateLanguages', function(event, data) {
    $rootScope.profiles.languages = data;
  });

  var clearUpUpdatePhoneListener = $rootScope.$on('UpdatePhone', function(event, data) {
    $rootScope.profiles.phone_number = data;
  });

  var cleanUpEmailRecipients = $rootScope.$on('UpdateEmailRecipients', function(event, data) {
    $rootScope.profiles.recipients_emails = data;
  });

  var cleanUpTeleconsult = $rootScope.$on('UpdateTeleconsult', function(event, data) {
    $rootScope.profiles.teleconsult_enabled = data;
  });

  $rootScope.$on('$destroy', function() {
    cleanUpPhoneNumberListener();
    cleanUpQualificationListener();
    cleanUpMembershipListener();
    cleanUpLocationListener();
    cleanUpEducationListener();
    cleanUpSpecialtiesListener();
    cleanUpHospitalsListener();
    cleanUpLanguagesListener();
    clearUpUpdatePhoneListener();
    cleanUpEmailRecipients();
    cleanUpTeleconsult();
  });

};

ProfileCtrl.$inject = [
  '$rootScope',
  'bookdoc.modal.service'
];

module.exports = ProfileCtrl;
