'use strict';

var auditsFormatMessage = function (moment) {

  return {

    formatAvailabilityType: function (availabilityType) {
      var repeatOption = '';

      // Check repeat
      switch(availabilityType) {
        case 1:
          repeatOption = 'Only this week';
          break;
        case 2:
          repeatOption = 'Only this month';
          break;
        case 3:
          repeatOption = 'Every week';
          break;
      };

      return  repeatOption;
    }
  };
};

auditsFormatMessage.$inject = [
  'moment'
];

module.exports = auditsFormatMessage;