'use strict';

var _ = require('lodash');

var LocationWithAddressCtrl = function($modalInstance, sendData, InformationAPI, $scope, OpenInfoModalService, $rootScope, APP_CONSTANT, OperatingHour, ValidateMessagesService) {
  var vm = this;

  vm.operatingData = angular.copy(OperatingHour.operatingData);

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {
    vm.locations = sendData.locations;
    var other = {
      id: '',
      name: 'Other',
      otherLocation: true
    };
    vm.locations.push(other);

    if (sendData.selectedLocation.id) {
      vm.selectedLocation = sendData.selectedLocation;
    } else {
      vm.selectedLocation = vm.locations[vm.locations.length - 1];
    }

    vm.practiceLocationData = resetPracticeLocationData();
    vm.practiceLocationData.name = sendData.selectedLocation.name;
    vm.error = {};
    vm.isUpdating = false;
    vm.phonePattern = APP_CONSTANT.phonePattern;
    vm.postalCodePattern = APP_CONSTANT.postalCodePattern;
    vm.isCollapsed = true;

    vm.operatingError = {
      invalidTime: false,
      isOverlapBreakTime: false
    };

    vm.listCountries = sendData.listCountries;

    vm.currentState = {
      id: 0,
      name: ''
    };

    vm.operatingHourData = [];

    // validation messages
    vm.messages = ValidateMessagesService;
  }

  createVariables();


  $scope.$on('changeOperating', function () {
    // check operating hour
    vm.operatingError.invalidTime = OperatingHour.validationOperating(vm.operatingData);
  });

  /**
   * [resetPracticeLocationData clear all input by reset practiceLocationData]
   *
   */
  function resetPracticeLocationData() {
    var practiceLocationData = {
      name: '',
      address: '',
      postalCode: '',
      city: '',
      state: null,
      assistant: '',
      phone: '',
      fax: ''
    };

    return practiceLocationData;
  }

  /**
   * [updateLocationSelected handle when selectbox changed]
   * @param  {[object]} e - event object
   *
   */
  vm.updateLocationSelected = function(e, form) {
    var id = e.target.attributes.data.value;
    vm.selectedLocation = _.find(vm.locations, function(location) {
      return location.id.toString() === id;
    });
    vm.practiceLocationData = resetPracticeLocationData();
    vm.error = {};
    form.$setPristine();
  };

  /**
   * [updateState handle when state selectbox changed]
   * @param  {[object]} state - id + name
   *
   */
  vm.updateState = function(state) {
    vm.currentState = state;
    vm.practiceLocationData.state = state.id;
  };

  vm.updateCountry = function(country) {
    vm.currentCountry = country;
    vm.practiceLocationData.country = country.id;

    // Reset state when choose country
    vm.currentState = null;
    vm.practiceLocationData.state =  null;
  };

  /**
   * [save - handle user click save button]
   *
   */
  vm.save = function(form) {
    // format operating data before send to server
    vm.operatingHourData = OperatingHour.formatOperatingData(vm.operatingData, true);

    console.log( vm.operatingHourData);

    if (!form.$valid || vm.operatingError.invalidTime || vm.operatingError.isOverlapBreakTime) {
      return;
    }

    var phone = vm.practiceLocationData.phone.replace(/[^a-zA-Z0-9]/g,'');
    var fax = vm.practiceLocationData.fax.replace(/[^a-zA-Z0-9]/g,'');

    var sendObject = {
      assistant: vm.practiceLocationData.assistant,
      phone: phone,
      fax: fax,
      location: {},
      operating_hours: vm.operatingHourData
    };

    console.log(sendObject);

    if (vm.selectedLocation.id === '') {
      // add other location
      sendObject.location.name = vm.practiceLocationData.name;
      sendObject.location.address = vm.practiceLocationData.address;

      sendObject.location.country_id = vm.practiceLocationData.country;
      sendObject.location.city = vm.practiceLocationData.city;
      sendObject.location.state_id = vm.practiceLocationData.state || null;
      sendObject.location.postal = vm.practiceLocationData.postalCode;
    } else {
      // add existed location
      sendObject.location.id = vm.selectedLocation.id;
    }

    if (!vm.isUpdating) {
      vm.isUpdating = true;
    } else {
      return;
    }

    $scope.$emit('updateLoadingStatus', true);

    // send POST to create new location here
    // add other location
    InformationAPI
      .locations
      .addNewLocation(sendObject)
      .then(function(data) {

        // FIX ME - remove this code when backend have consistant between postal and zipcode
        data.zipcode = data.postal;
        delete data.postal;

        $rootScope.profiles.locations.push(data);
        vm.isUpdating = false;
        $modalInstance.close();
        // open thanks popup
        OpenInfoModalService.open(
          'profile/views/information/location-thank.modal.html',
          require('./location-thank.controller'),
          'locaTks',
          'modal-info modal-thank-update'
        );
      },
      function(data) {
        vm.isUpdating = false;
      })
      .finally(function() {
        $scope.$emit('updateLoadingStatus', false);
      });

  };

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }
  });

  /**
   * [cancel - handle when user click cancel button]
   *
   */
  vm.cancel = function() {
    if (!vm.isUpdating) {
      $modalInstance.dismiss('cancel');
    } else {
      return;
    }
  };

  /**
   * Open contact us modal
   */
  vm.contact = function() {
    $scope.$emit('showModal', {
      remove_location: true
    });
  };
};

LocationWithAddressCtrl.$inject = [
  '$modalInstance',
  'sendData',
  'bookdoc.information.api',
  '$scope',
  'OpenInfoModalService',
  '$rootScope',
  'APP_CONSTANT',
  'OperatingHour',
  'ValidateMessagesService'
];

module.exports = LocationWithAddressCtrl;
