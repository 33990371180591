/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.changePasswordFirstTime.controllers',
    [
    ]
  )
  .controller('ChangePasswordFirstTimeCtrl', require('./change-password-first-time.controller'));
