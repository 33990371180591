/* global moment*/

'use strict';

(typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);

var dateTimeToYear = function (TimeZoneService) {
  return function (dateTime) {
    return dateTime ? TimeZoneService.timeToTimezoneFormat(dateTime, 'YYYY') : '';
  };

};

dateTimeToYear.$inject = [
  'TimeZoneService'
];

module.exports = dateTimeToYear;
