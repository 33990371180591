"use strict";

function OpenClaimsCtrl(ebService, $modal) {
  var ctrl = this;
  init();

  function init() {
    ctrl.claims = [];
    ctrl.page = 1;
    ctrl.per_page = 10;
    ctrl.total_pages = 1;
    loadOpenClaims();
  }

  function loadOpenClaims() {
    ctrl.isLoading = true;
    var successCallback = function (claimsResponse) {
      ctrl.total_pages = claimsResponse.metadata.total_pages;
      ctrl.claims = ctrl.claims.concat(claimsResponse.claims);
      ctrl.isLoading = false;
      if (ctrl.claims.length === 0 && ctrl.page === 1)
        ctrl.listIsEmpty = true;
    };

    var errorCallback = function () {
    };
    ebService.getClaimsList(ctrl.page, ctrl.per_page, null, successCallback, errorCallback);
  }

  ctrl.onCreateClaimBtnClicked = function (claim) {
    console.log(claim);

    var modalInstance = $modal.open({
      animation: true,
      backdrop: false,
      windowClass: 'submit-claim-backdrop-modal',
      resolve: {
        ebService: function () {
          return ebService;
        },
        claim: function () {
          return claim;
        },
        modal: function () {
          return $modal;
        }
      },
      templateUrl: 'employee-benefits/views/submit-claim-form-modal.html',
      controller: require("../controllers/submit-claim-modal.controller"),
      controllerAs: '$ctrl'
    });

    modalInstance.result.then(function (shouldUpdate) {
      if (shouldUpdate) {
        init();
      }
    });
  };

  ctrl.onLoadMore = function () {
    if (ctrl.page >= ctrl.total_pages) return;

    ctrl.page++;
    loadOpenClaims();
  };
}

var openClaimsComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/open-claims.html',
    controller: OpenClaimsCtrl,
  };
};

OpenClaimsCtrl.$inject = ['ebService', '$modal'];

module.exports = openClaimsComponent();
