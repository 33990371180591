/* global moment*/

'use strict';

(typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);

var timeFilter = function (TimeZoneService) {
  return function (input, format) {
  	var timeZone = TimeZoneService.timeZone;
    format = format || 'h:mm a';
    return (input && moment.tz(input, timeZone).format(format)) || input;
  };

};

timeFilter.$inject = [
	'TimeZoneService'
];

module.exports = timeFilter;

