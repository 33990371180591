/* Define controller for registration step 1 module*/
'use strict';

var ListPracticeCtrl = function ($scope, APP_CONSTANT, ValidateMessagesService, SpecialtiesApi, ListPracticeService, $rootScope, SearchDropdownService, RegistrationAPI, InformationApi) {
  var vm = this;

  // Create variables
  function createVariables () {

    vm.patterns             = APP_CONSTANT.patterns;
    vm.importantSpecialties = APP_CONSTANT.importantSpecialties;
    vm.messages             = ValidateMessagesService;

    // Create specialties array with two object
    vm.specialtiesSelect = [
      {
        id: '',
        name: '',
        show: true
      },
      {
        id: '',
        name: '',
        show: true
      }
    ];

    // Create specialty validate
    vm.validate = {
      required: true,
      duplicate: false
    };

    vm.specialtiesData = [];

    vm.timeZoneList = [];
  }

  function getSpecialties () {
    // Get specialties list
    SpecialtiesApi.getList()
      .then(function(data) {
          vm.specialtiesList = data;
          vm.specialtiesData = data;
          // Move GP and General Dentist as first 2 options in dropdown menu
          ListPracticeService.addPriorityItemsToTop(vm.specialtiesList, vm.importantSpecialties[1]);
          ListPracticeService.addPriorityItemsToTop(vm.specialtiesList, vm.importantSpecialties[0]);
      }, function(error) {
        console.log(error);
      })
      .finally(function() {
        // Hide indicator
        $rootScope.showIndicator = false;
      });
  }

  createVariables();
  getSpecialties();

  /**
   * Get specialty ids
   * @return {array} list specialty ids
   */
  vm.getSpecialtyIds = function () {

    var specialtyIds = [];
    specialtyIds.push(vm.specialtiesSelect[0].id);

    // Check specialty id 2
    if (vm.specialtiesSelect[1].id) {
      specialtyIds.push(vm.specialtiesSelect[1].id);
    }

    return specialtyIds;
  };

  // Get specialties name
  vm.getSpecialtiesName = function () {

    var specialtiesName = [];
    specialtiesName.push(vm.specialtiesSelect[0].name);

    // Check specialty id 2
    if (vm.specialtiesSelect[1].name) {
      specialtiesName.push(vm.specialtiesSelect[1].name);
    }

    return specialtiesName;
  };

  /**
   * Submit list practice
   */
  vm.submitListPractice = function () {

    // Set submit form
    vm.registrationStepOneForm.$setSubmitted();

    // Check valid data
    if (vm.registrationStepOneForm.$valid && !vm.validate.required && !vm.validate.duplicate && !!vm.timezoneSelected) {

      // Remove plus chracter before phone number
      var phone = vm.phoneNumber.replace(vm.patterns.formatPhoneNumber, '');

      // Set properties for registration data
      $scope.registrationData.title         = vm.title;
      $scope.registrationData.first_name    = vm.firstName;
      $scope.registrationData.last_name     = vm.lastName;
      $scope.registrationData.email         = vm.email;
      $scope.registrationData.phone_number  = phone;
      $scope.registrationData.specialty_ids = vm.getSpecialtyIds();
      $scope.registrationData.area_code     = vm.areaCode;
      $scope.registrationData.nric          = vm.nric;
      $scope.specialtiesData                = vm.getSpecialtiesName();
      $scope.registrationData.timezone_id     = vm.timezoneSelected.id;
      // Set current form is valid
      $scope.formValidate.valid = true;

      $rootScope.$broadcast('updateSignatureFormUrl', $scope.specialtiesData);

    }
  };

  $scope.$on('submitListPractice', function(e) {

    vm.submitListPractice();

  });

  // Get list of time zone
  function getTimezoneList() {
    RegistrationAPI.timezone.getTimezone()
      .then(
        function (data) {
          vm.timeZoneList = data;
        },

        function (error) {
          console.log(error);
        }
      );
  }

  getTimezoneList();

  function getCountriesList(timezoneId) {
    InformationApi.country.getListCountries(timezoneId)
    .then(function (data) {
      $scope.registrationData.listCountries = data;
    });
  }

  vm.selectTimezone = function(timezone) {
    vm.timezoneSelected = timezone;
    getCountriesList(vm.timezoneSelected.id);
  };

  // handle when toggle dropdown
  vm.toggleDropdown = function() {
    vm.searchTimezone = '';
    SearchDropdownService.setAutoFocus();
  };

  // keep dropdown open when click on input tab
  vm.keepDropdownOpen = function($event) {
    $event.stopPropagation();
  };

};

ListPracticeCtrl.$inject = [
  '$scope',
  'APP_CONSTANT',
  'ValidateMessagesService',
  'bookdoc.specialties.api',
  'ListPracticeService',
  '$rootScope',
  'SearchDropdownService',
  'RegistrationAPI',
  'bookdoc.information.api'
];

module.exports = ListPracticeCtrl;
