/* Define controller for registration step 2 module*/
'use strict';

var CredentialsCtrl = function (ValidateMessagesService, $scope) {

  $scope.messages = ValidateMessagesService;

  $scope.qualifications = [{
    name: "",
    updated: true
  }];

  $scope.submitCredentials = function () {
    $scope.credentialsForm.$setSubmitted();
    if ($scope.credentialsForm.$valid && !$scope.validate.qualifications.isDuplicate) {
      // Set properties for registration data
      $scope.registrationData.qualifications = angular.copy($scope.qualifications);
      $scope.registrationData.mmc = $scope.mmc;
      $scope.registrationData.nsr = $scope.nsr;

      // Current form is valid
      $scope.formValidate.valid = true;
    }
  };

  $scope.$on('submitCredentials', function() {
    $scope.submitCredentials();
  });
};

CredentialsCtrl.$inject = [
  'ValidateMessagesService',
  '$scope'
];

module.exports = CredentialsCtrl;