/* global moment*/
'use strict';

var modalDirective = function(autoFocusService) {
  return {
    restrict: 'AE',
    templateUrl: 'contact/views/contact.html',
    transclude: true,
    link: function($scope, elem, attrs) {

      $scope.$on('showModal', function(e, location) {
        var emailInput = angular.element('.contact-email');
        autoFocusService.setAutoFocus(emailInput); 
        if (location && location.remove_location) {
          $scope.isLocation = true;
        } else {
          $scope.isLocation = false;
        }
        $scope.showContacModal = true;
        $scope.hasContactModal = true;
      });

      $scope.$on('hideModal', function(e) {
        $scope.showContacModal = false;
        $scope.hasContactModal = false;
      });

      $scope.closeModal = function(form) {
        $scope.showContacModal = false;
        $scope.hasContactModal = false;
        form.$setPristine();
      };

      $scope.myFunct = function(keyEvent) {
        if (keyEvent.which === 27) {
          console.log('Key!!!');
        }
      };
    },
    controller: require('../controllers/contact.controller'),
    controllerAs: 'ct',
  };
};

modalDirective.$inject = [
  'autoFocusService'
];

module.exports = modalDirective;
