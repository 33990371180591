'use strict';

var _ = require('lodash');

var InsuranceService = function () {
  return {
    /**
   * Sort array by id
   * @param  {array} arr
   * @return {array}
   */
    sortById: function(data) {
      var dataAfterSort =  _.sortBy(data, function (item) {
        return item.id;
      });

      return dataAfterSort;
    },

    // Check if new insurance miss name or file name
    isEmptyElement: function(data) {
      // get array of empty items
      var isExistedEmpty = _.filter(data, function(item) {
        if (!item.id) {
          item.id = null;
        }
        return item.id === null;
      }) || [];

      return isExistedEmpty.length === 0;
    },

    /**
     * Check duplicate value in array
     * @param  {array}  array
     * @return {Boolean}
     */
    hasDuplicates: function(data) {
      var uniqArray = _.uniq(data, function (item) {
        return item.id;
      });

      return uniqArray.length !== data.length;
    },

    // check if do not select insurance 
    checkNoExitedInsuranceName: function(data) {
      var count = 0;
      _.forEach(data, function (item) {

        if (!item.id) {
          count++;
        }
      });

      return count;
    },

    /**
     * Compare two array
     * @param  {array} arrOne
     * @param  {array} arrTwo
     * @param  {Boolean} updated
     * @return {array}
    */
    compareArray: function(arrOne, arrTwo, updated) {
      console.log(arrOne);
      var result = [];
      var item = {};
      var id;
      var letterAppointment;
      var count;

      _.forEach(arrOne, function (itemOne) {
        id = itemOne.id;
        letterAppointment = itemOne.letter_appointment;
        count = 0;

        _.forEach(arrTwo, function (itemTwo) {
          if (id === itemTwo.id && letterAppointment === itemTwo.letter_appointment) {
            count++;
          }
        });

        if (!count) {
          item = {
            "id": id,
            "updated": updated
          };

          if (itemOne.letter_appointment) {
            item.letter_appointment = letterAppointment;
          }
          result.push(item);
        }
      });

      return result;
    },

    // Format insurances data
    formatData: function (insurances) {
      _.forEach(insurances, function (item) {
        if (item.letter_appointment) {
          item.letter_appointment = item.letter_appointment.id;
        }
      });

      return insurances;
    }

  };
};

InsuranceService.$inject = [
];

module.exports = InsuranceService;
