"use strict";

var runOnce = function (
  $rootScope,
  $state,
  $stateParams,
  $modalStack,
  Restangular,
  CommonModals,
  NotificationsService,
  ALERTS_API_SERVICE,
  ProfileAPI,
  addToCalendarService,
  Session,
  NotificationSoundService,
  RoleValidation,
  MobileDeviceService,
  StateChangeService,
  AlertService,
  LockMechanismService,
  TimeZoneService,
  ENV,
  quickBloxService,
  chatService,
  twilioChatService
) {
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;
  $rootScope.enableNotification = false;
  $rootScope.isEditMode = true;
  $rootScope.loginUrl = "login";
  $rootScope.showIndicator = false;
  $rootScope.twilio_tokens = [];

  function disconnectFaye() {
    $rootScope.enableNotification = false;
    NotificationsService.unsubscribe("/notifications/doctor/");
    NotificationsService.unsubscribe("/lock_mechanism/");
    NotificationsService.unsubscribe("/notifications/clinic/");
  }

  function unAuthenticated() {
    Session.destroy();
    $rootScope.currentUser = null;
    $rootScope.isAuthenticated = null;
    delete $rootScope.profiles;
    delete $rootScope.assistantProfile;
    disconnectFaye();
    $state.go($rootScope.loginUrl);

    // Close modal
    $modalStack.dismissAll();
  }

  function initQuickBlox() {
    var user = {
      login: $rootScope.currentUser.quickblox_account.login,
      pass: $rootScope.currentUser.quickblox_account.password,
      resource: "DoctorWebPortal",
    };
    $rootScope.qbUser = user;

    quickBloxService.init(
      ENV.QBconfig.credentials.appId,
      ENV.QBconfig.credentials.authKey,
      ENV.QBconfig.credentials.authSecret,
      ENV.QBconfig.credentials.accountKey,
      ENV.QBconfig.appConfig,
      user
    );

    var quickBloxLoginSuccess = function () {
      $rootScope.qbReady = true;
      $rootScope.$broadcast("updateUnreadChatCount");
      $rootScope.$broadcast("quickbloxIsReady");
    };
    var quickBloxLoginError = function (err) {
      console.log(err);
    };

    quickBloxService.login(quickBloxLoginSuccess, quickBloxLoginError);
  }

  function initTwilioChat() {
    chatService.getTwilioTokens(function (tokens) {
      $rootScope.twilio_tokens = tokens;
    });
  }

  $rootScope.$on("auth:unauthenticated", function () {
    unAuthenticated();
  });

  $rootScope.$on("auth:unauthorized", function () {
    unAuthenticated();
  });

  $rootScope.$on("auth:logout:success", function () {
    unAuthenticated();
  });

  $rootScope.$on("auth:login:failed", function () {
    disconnectFaye();
    $state.go($rootScope.loginUrl);
  });

  $rootScope.$on("auth:login:success", function () {
    $state.go("main.dashboard");
  });

  // Redirect to grouping page if grouping user
  $rootScope.$on("auth:login:grouping", function () {
    $state.go("main.grouping");
  });

  $rootScope.$on("$stateChangeError", function (event) {
    // Use event.preventDefault() to prevent the $UrlRouter from reverting the URL to the previous valid location
    event.preventDefault();
  });

  $rootScope.$on(
    "$stateChangeStart",
    function (event, toState, toParams, fromState, $stateParams) {
      // Show indicator
      $rootScope.showIndicator = true;

      if (StateChangeService.isPublicUrl(toState.name)) {
        return;
      }

      if (
        StateChangeService.currentUser($rootScope.currentUser, toState.name)
      ) {
        event.preventDefault();
        StateChangeService.redirectToLogin(RoleValidation.isAssistantUrl());
        return;
      }

      if (
        StateChangeService.profiles(
          $rootScope.currentUser,
          $rootScope.profiles,
          $rootScope.assistantProfile
        )
      ) {
        event.preventDefault();
        var isAssistant = RoleValidation.isAssistant($rootScope.currentUser);
        ProfileAPI.get(
          $rootScope.currentUser && $rootScope.currentUser.id,
          isAssistant
        ).then(
          function (data) {
            if (data.clinic_id) {
              $rootScope.assistantProfile = data;
            } else {
              $rootScope.profiles = data;

              // Update time zone
              TimeZoneService.setTimezone($rootScope.profiles);

              // Check google calendar authentication
              addToCalendarService.checkAuth();

              // Init twilio chat
              initTwilioChat();

              // Init Quickblox for chat.
              $rootScope.chatEnabled = $rootScope.currentUser.enable_chat;
              //initQuickBlox();

              $rootScope.ebEnabled = $rootScope.currentUser.eb_enabled;
            }
            $state.go(toState.name, toParams);
          },
          function (error) {
            if (error.status === 401) {
              unAuthenticated();
            }
          }
        );
      }
    }
  );

  $rootScope.$on(
    "$stateChangeSuccess",
    function (event, toState, toParams, fromState) {
      // Checking mobile device to update height for body
      if (!$rootScope.checkedMobileDevice) {
        MobileDeviceService.setBodyHeight();
        $rootScope.checkedMobileDevice = true;
      }

      var doctorId = Number($stateParams.doctorId) || null;
      var practiceLocationId = Number($stateParams.practiceLocationId) || null;

      // Get doctor profile
      if (
        RoleValidation.isAssistant($rootScope.currentUser) &&
        $stateParams.doctorId
      ) {
        ProfileAPI.getDoctorProfile(doctorId).then(
          function (data) {
            $rootScope.currentDoctor = data;
            $rootScope.currentDoctor.practiceLocationId =
              $stateParams.practiceLocationId;
            $rootScope.currentDoctor.practiceLocationName =
              $stateParams.practiceLocationName;
            // Set timezone when Assistant select to view doctor
            TimeZoneService.setTimezone($rootScope.profiles);
          },
          function (error) {
            CommonModals.showInvalidPracticeLocationModal(error, true);
          }
        );
      }

      // Check is first time login
      StateChangeService.checkFirstTimeLogin(
        $rootScope.currentUser,
        toState.name
      );

      if (
        StateChangeService.canSubscribeNotification(
          $rootScope.enableNotification,
          $rootScope.currentUser,
          $stateParams,
          toState.name
        )
      ) {
        // Set header authentication
        NotificationsService.setHeader($stateParams.doctorId, false);

        AlertService.subscribeDoctorNotification(doctorId, practiceLocationId);

        LockMechanismService.subscribeLockMechanism();

        $rootScope.enableNotification = true;
      }

      // Change notification number
      if (
        StateChangeService.notFirstTimeLogin($rootScope.currentUser) &&
        !StateChangeService.isPublicUrl(toState.name)
      ) {
        AlertService.changeNotificationNumber();
      }

      if (StateChangeService.needToGetAlert(fromState.name, $stateParams)) {
        AlertService.getAlerts(doctorId, practiceLocationId);
      }
    }
  );

  Restangular.setErrorInterceptor(function (
    response,
    deferred,
    responseHandler
  ) {
    // 422: status code of register account when the email already exists in the system
    if (response.status === 503) {
      CommonModals.openBaseModal(
        "BookDoc is currently undergoing scheduled maintenance/deployment. Please check back in a couple of hours. Sorry for the inconvenience."
      );
    } else if (response.status !== 422 && response.status !== 401) {
      CommonModals.openBaseModal("Server error!");
    }

    $rootScope.showIndicator = false;
  });

  const gaScript = document.createElement('script');
  gaScript.async = true;
  gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${ENV.gaId}`;
  document.head.appendChild(gaScript);

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${ENV.gaId}');
  `;
  document.head.appendChild(inlineScript);
};

runOnce.$inject = [
  "$rootScope",
  "$state",
  "$stateParams",
  "$modalStack",
  "Restangular",
  "CommonModals",
  "NotificationsService",
  "ALERTS_API_SERVICE",
  "bookdoc.profile.api",
  "addToCalendarService",
  "Auth.AuthSession",
  "NotificationSoundService",
  "RoleValidation",
  "MobileDeviceService",
  "StateChangeService",
  "AlertService",
  "LockMechanismService",
  "TimeZoneService",
  "ENV",
  "quickBloxService",
  "chatService",
  "twilioChatService",
];

module.exports = angular.module("bookdoc").run(runOnce);
