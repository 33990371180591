/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.calendar.appointments.services',
    [
      'ngResource'
    ]
  )
  .factory('appointmentService', require('./appointment.service'))
  .factory('addToCalendarService', require('./add-to-calendar.service'));
  require('./appointment.common.service');
  require('./appointment.api.service');
