/*globals FormData*/

'use strict';

var _ = require('lodash');

var availabilitiesAPI = function (AVAILABILITY_API_ROUTE, Restangular, formatDataService, TimeZoneService) {

  return {

    root: Restangular.all(AVAILABILITY_API_ROUTE.availabilities),

    getByWeek: function (begin, end, location_id, doctor_id, current_location_id) {

      return this.root.customGETLIST('', 
        { start: TimeZoneService.dateToTimestamp(begin)/1000,
          end: TimeZoneService.endOfADayTimestamp(end)/1000,
          location: location_id || null,
          doctor_id: doctor_id || null,
          current_location_id: current_location_id || null
        });
    },

    update: function (data) {
      return this.root.post(data);
    }

  };

};

availabilitiesAPI.$inject = ['AVAILABILITY_API_ROUTE', 'Restangular', 'bookdoc.availabilities.formatDataService', 'TimeZoneService'];

module.exports = availabilitiesAPI;
