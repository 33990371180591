'use strict';

var _ = require('lodash');

var Sms = function ($modalInstance, referApiService, sendData,APP_CONSTANT, ValidateMessagesService, referService, moment) {
  var vm = this;
  
  vm.referFromCalendar = sendData.referFromCalendar;
  vm.appointment = sendData.appointment || null;
  vm.patientName = sendData.patientName || '';
  vm.patientPhone = sendData.patientPhone || null;
  vm.patientEmail = sendData.patientEmail || '';
  vm.practiceLocation = sendData.practiceLocation || '';
  vm.smsDetails = sendData.practiceLocation.sms_details;
  vm.smsHeader = '(' + vm.smsDetails.sms_header + ') ' || '';
  vm.previewSmsMsg = "";
  vm.showPatientEmail = !!sendData.patientEmail;
  vm.pattern = APP_CONSTANT.patterns;

  // Define characters limit of referral note
  vm.charactersLimit = 120;
  vm.smsCharactersCount = 0;

  vm.validateMessages = ValidateMessagesService;


  vm.smsMessage = '';


  // Close referral modal
  vm.closeModal = function () {
    $modalInstance.dismiss();
  };

  vm.send = function() {
    vm.smsForm.$setSubmitted();

    if (vm.smsForm.$invalid) {
      return;
    }

    var smsParams = {
      id: vm.appointment.id,
      sms_content: vm.previewSmsMsg,
      eb_location_id: vm.practiceLocation.id,
      eb_doctor_id: vm.appointment.doctor.id
    };

    referApiService.sendSms(smsParams)
      .then(
        function (data) {
          if(data){
            vm.closeModal();
            referService.showSmsHasSendModal();
          }
        },

        function (error) {
          console.log('errors = ',error)
          if (error.status === 422 && error.data) {
            referService.showSmsError(error.data);
          }
        }
      );
  };

  /**
   * Set preview message
   */
  vm.messageChange = function() {
    var appointmentDate = moment(vm.appointment.start)
    vm.smsMessage = vm.smsMessage || ""
    var _temp_msg = vm.smsMessage.replace('{name}',vm.patientName.split(' ')[0])
    .replace('{date}',appointmentDate.format("DD/MM/YYYY"))
    .replace('{time}',appointmentDate.format("h:mm A"))
    .replace('{day}',appointmentDate.format('dddd'));
    
    vm.smsMessage = vm.previewSmsMsg = _temp_msg
    vm.smsCharactersCount = vm.previewSmsMsg.length;
  };
};

Sms.$inject = [
  '$modalInstance',
  'referApiService',
  'sendData',
  'APP_CONSTANT',
  'ValidateMessagesService',
  'referService',
  'moment'
];

module.exports = Sms;
