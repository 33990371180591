'use strict';

function ReviewsController($rootScope, chatService) {
  var ctrl = this;
  ctrl.min = 0.0;
  ctrl.max = 5.0;
  ctrl.step = 1.0;
  ctrl.readOnly = true;

  function init() {
    if (!$rootScope.chatEnabled) return;

    var successCallback = function (doctorChatProfileInfo) {
      console.log(doctorChatProfileInfo);
      ctrl.reviews = doctorChatProfileInfo.chat_reviews;
      ctrl.rating = doctorChatProfileInfo.rating;
      ctrl.isNoRatingYet = ctrl.rating == null;
      ctrl.noOfRatings = doctorChatProfileInfo.chat_reviews_count;
    };

    var errorCallback = function (error) {
      console.log(error);
    };
    chatService.getDoctorChatReviews($rootScope.currentUser.id, successCallback, errorCallback);
  }

  init();
};

var reviewsComponent = function () {
  return {
    templateUrl: 'dashboard/views/reviews.html',
    controller: ReviewsController,
    bindings: {}
  };
};

ReviewsController.$inject = ['$rootScope', 'chatService'];

module.exports = reviewsComponent();
