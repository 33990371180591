"use strict";

function ConfirmAttendanceModalController($modalInstance, visit, ebService) {
  console.log("Confirm Attendance modal controller");
  var ctrl = this;

  ctrl.visit = visit;
  ctrl.visit_secret_no = "";

  ctrl.onConfirmVisitAttendance = function () {
    if (validateForm()) {
      var successCallback = function (response) {
        if (response.status === "visit_no mismatched") {
          showErrorMsg();
          $modalInstance.close(false);
        } else {
          $modalInstance.close(true);
        }
      };

      var errorCallback = function (error) {
        showErrorMsg();
      };


      var showErrorMsg = function () {
        window.alert("Visit number is not valid");
      };

      ebService.confirmVisitAttendance(ctrl.visit.id, ctrl.visit_secret_no, successCallback, errorCallback);
    }
  };

  ctrl.onSecretVisiNoChanged = function () {
    if (ctrl.visit_secret_no)
      ctrl.secretVisitNoError = false;
  };

  function validateForm() {
    ctrl.secretVisitNoError = false;
    if (!ctrl.visit_secret_no || ctrl.visit_secret_no === "") {
      ctrl.secretVisitNoError = true;
    }
    return !ctrl.secretVisitNoError;
  }

  ctrl.onCancelBtnClicked = function () {
    $modalInstance.dismiss();
  };
}

ConfirmAttendanceModalController.$inject = ['$modalInstance', 'visit', 'ebService'];

module.exports = ConfirmAttendanceModalController;
