'use strict';

var interceptor = angular
  .module('Auth')
  .factory('Auth.AuthInterceptor', [
    '$rootScope', '$q', 'Auth.EVENTS', 'Auth.AuthSession', 'RoleValidation', function($rootScope, $q, AUTH_EVENTS, Session, RoleValidation) {
      return {
        responseError: function(response) {

          var isAssistant = RoleValidation.isAssistant($rootScope.currentUser);

          $rootScope.loginUrl = isAssistant ? 'loginGroup' : $rootScope.loginUrl;
          if (response.status === 401 &&

              // fixe me: pls remove when the api update password and forgot password are finish
              response.data && response.data.errors && response.data.errors[0] === 'Invalid authentication token.') {

              // remove section, token
              Session.destroy();
              $rootScope.currentUser = null;
              $rootScope.isAuthenticated = null;

            $rootScope.$emit(AUTH_EVENTS.unauthenticated, response);
          }
          if (response.status === 403) {
            $rootScope.$emit(AUTH_EVENTS.unauthorized, response);
          }

          if (response.status === 404) {
            $rootScope.$emit(AUTH_EVENTS.unauthenticated, response);
          }

          if (response.status === 419 || response.status === 440) {
            $rootScope.$emit(AUTH_EVENTS.sessionTimeout, response);
          }
          return $q.reject(response);
        }
      };
    }
  ]);

module.exports = interceptor;
