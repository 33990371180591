/* Define controller for registration step 4 module*/
'use strict';

var AdditionalInfoCtrl = function ($scope) {
  var vm = this;

  $scope.registrationData.educations = [];

  $scope.registrationData.hospitals = [];

  vm.apcID = '';

  $scope.registrationData.apc_id = '';

  $scope.registrationData.signature_form_id = '';

  vm.signatureFormId = '';

  $scope.educationActive = true;
  $scope.signatureFormActive = false;
  $scope.languagesActive = false;
  $scope.hospitalActive = false;
  $scope.educationsData = {};

  $scope.languages = [
    {
      name: ''
    }
  ];
};

AdditionalInfoCtrl.$inject = [
  '$scope'
];

module.exports = AdditionalInfoCtrl;
