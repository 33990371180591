'use strict';

var InitialsService = function () {
  return {
    getInitials: function (name) {
      if (name === undefined) return "";
      var patienFullNameArray = name.split(' ');
      var patienInitials = patienFullNameArray[0].substring(0, 1);
      if (patienFullNameArray.length > 1)
        patienInitials += patienFullNameArray[1].substring(0, 1);
      return patienInitials;
    }
  };
};

InitialsService.$inject = [];

module.exports = InitialsService;
