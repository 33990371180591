'use strict';

var states = function ($stateProvider) {

  $stateProvider
    .state('main.medicalNews', {
      url: 'medical-news/',
      templateUrl: "medical-news/views/medical-news.html",
      controller: 'MedicalNewsCtrl',
      controllerAs: 'mn',

      resolve: {
        CurrentCountry: [
          '$rootScope',
          '$http',
          'MedicalNewsService',
          'APP_CONSTANT',

          function ($rootScope, $http, MedicalNewsService, APP_CONSTANT) {
            if (!$rootScope.currentCountry) {
              $rootScope.showIndicator = true;

              var headers = {
                headers: {
                  'Authorization': undefined
                }
              };

              return $http.get(
                APP_CONSTANT.geoApi,
                headers
              ).then(
                function (data) {
                  MedicalNewsService.checkAvailableCountry(data.data.country_code);
                  $rootScope.showIndicator = false;
                },
                function (error) {
                  MedicalNewsService.checkAvailableCountry();
                  $rootScope.showIndicator = false;
                });
            }
          }
        ]
      }
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.medicalNews')
  .config(states);
