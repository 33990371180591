/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.teleconsult.controllers',
    [
    ]
  )
  .controller('TeleconsultCtrl', require('./teleconsult.controller'));
