/*globals FormData*/

'use strict';

var avatarsAPI = function (BOOKDOC_PROFILE_API_ROUTE, Restangular) {

  var transformRequest = function (tdata) {
    var form = new FormData();

    form.append('avatar_file', tdata);

    return form;
  };

  return {

    root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.avatars),

    postAvatar: function (file) {
      return this.root.withHttpConfig({transformRequest: transformRequest}).customPOST(file, undefined, undefined, {'Content-Type': undefined });
    }

  };

};

avatarsAPI.$inject = ['BOOKDOC_PROFILE_API_ROUTE', 'Restangular'];

module.exports = avatarsAPI;
