'use strict';

var auditsConstant = {
  audits: 'audit_logs',
  doctor: 'doctors',
  pagination: {
    size: 9,
    limit: 12
  }
};

module.exports = auditsConstant;
