/*global moment*/

'use strict';

var session = angular
  .module('Auth')
  .service("Auth.AuthSession", [
    '$cookieStore',
    '$cookies',
    '$rootScope',
    'RoleValidation',
    function($cookieStore, $cookies, $rootScope, RoleValidation) {
      var isAssistantUser = RoleValidation.isAssistantUrl();

      var store = isAssistantUser ? $cookieStore.get('AssistantSession') : $cookieStore.get('AuthSession');

      return {
        token: store && store.token,
        user: store && store.user,
        create: function(token, user) {

          var sessionKey = 'AuthSession';

          if ($rootScope.isAssistant) {
            sessionKey = 'AssistantSession';
            store = $cookieStore.get(sessionKey);
          }

          this.token = token;
          this.user = user;

          var expireDate = moment();
          expireDate.add(10, 'year');

          // Setting a cookie
          var cookieObj = {
            token: this.token
          };

          if (!user.first_time_login) {
            cookieObj.user = this.user;
          }

          if (user.first_time_login || !$rootScope.rememberMe.isRememberMe) {

            $cookies.put(sessionKey, angular.toJson(cookieObj), {
              'path': '/'
            });

          } else {

            $cookies.put(sessionKey, angular.toJson(cookieObj), {
              'path': '/',
              'expires': expireDate._d
            });

          }

        },
        destroy: function() {
          this.token = null;
          this.user = null;

          if ($rootScope.isAssistant || RoleValidation.isAssistant($rootScope.currentUser)) {
            $cookieStore.remove('AssistantSession');
          } else {
            $cookieStore.remove('AuthSession');
          }
        }
      };
    }
  ])

  .service("Auth.RegisterSession", [

    '$cookieStore',

    function ($cookieStore) {
      var store = $cookieStore.get('RegisterSession');

      return {
        token: store && store.token,
        user: store && store.user,

        create: function (token, user) {

          this.token = token;
          this.user = user;

          $cookieStore.put('RegisterSession', {
            token: this.token,
            user: this.user
          });

        },
        destroy: function () {

          this.token = null;
          this.user = null;

          $cookieStore.remove('RegisterSession');
        }

      };

    }

  ]);

module.exports = session;
