'use strict';
module.exports =
  angular
    .module('bookdoc.eb', [
      'restangular',
      require('../../../tmp/templates').name,
      require('../common').name,
      // require('./directives').name,
      require('./controllers').name,
      require('./components').name,
      require('./services').name
    ]);

require('./eb.routes');
