'use strict';

var states = function ($stateProvider) {
  $stateProvider
    .state('main.calendar.availability', {
      url: 'availability/?start&end',
      reloadOnSearch: false,
      views: {
        'availability': {
          templateUrl: 'calendar/availability/views/availability.html',
          controller: 'AvailabilityCtrl',
          controllerAs: 'avail'
        }
      }
    })

    // Define state for grouping availability
    .state('main.groupingCalendar.availability', {
      url: 'availability/?start&end',
      reloadOnSearch: false,
      views: {
        'availability': {
          templateUrl: 'calendar/availability/views/availability.html',
          controller: 'AvailabilityCtrl',
          controllerAs: 'avail'
        }
      }
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.calendar.availability')
  .config(states);

