"use strict";

var _ = require("lodash");
var DetailAppointmentCtrl = function (
  $timeout,
  $modalInstance,
  $modal,
  $rootScope,
  $state,
  sendData,
  moment,
  appointmentApiService,
  $scope,
  CommonModals,
  appointmentService,
  addToCalendarService,
  APP_CONSTANT,
  LockMechanismService,
  chatService
) {
  var vm = this;

  vm.appointmentDetail = sendData.apptData;
  vm.rescheduleCallback = sendData.rescheduleCallback;
  vm.startTime = moment(vm.appointmentDetail.start).format("h:mm a");
  vm.date = moment(vm.appointmentDetail.start).format("DD/MM/YYYY");
  vm.endTime = moment(vm.appointmentDetail.end).format("h:mm a");
  vm.invalidAppt = sendData.invalidAppt;
  vm.canCancel = sendData.canCancel;
  vm.isSetCalendar = sendData.isSetCalendar;
  vm.canSetCalendar = sendData.canSetCalendar;
  vm.noShow = sendData.noShow;
  vm.isEnableIssueMC = sendData.isEnableIssueMC;
  vm.isPortalAppointment = vm.appointmentDetail.appointment_type === "portal";
  vm.isBookDocAppointment = vm.appointmentDetail.appointment_type === "bookdoc";
  vm.calendarTooltip = sendData.calendarTooltip;
  vm.canEdit =
    !vm.appointmentDetail.virtual &&
    vm.appointmentDetail.start.unix() > moment().unix();
  vm.shouldShowGoTo =
    vm.appointmentDetail.end.unix() > moment().unix() &&
    vm.appointmentDetail.virtual;
  vm.shouldShowFollowUp =
    vm.appointmentDetail.start.unix() < moment().unix() &&
    vm.appointmentDetail.virtual;

  vm.isViewMode = LockMechanismService.checkLocationInViewMode(
    $rootScope.profiles.locations,
    vm.appointmentDetail.location.id
  );
  vm.isVirtual = vm.appointmentDetail.virtual;
  vm.smsDetails = vm.appointmentDetail.location.sms_details;
  vm.allowSms = vm.appointmentDetail.location.sms_enabled && vm.canEdit && vm.smsDetails && vm.smsDetails.balance > 0;

  // mimisecond of a day
  vm.millisecondOfADay = APP_CONSTANT.millisecondOfADay;
  vm.chatSession = null;
  vm.body_parts = [];
  vm.isLoading = false;

  var specialties = sendData.specialties;
  var doctorIdParam = sendData.doctorIdParam;
  var practiceLocationIdParam = sendData.practiceLocationIdParam;
  vm.isAssistant = !!doctorIdParam;
  if (vm.appointmentDetail.virtual) {
    vm.isLoading = true;

    // Abit of hard coding, they are the same actually.
    if (vm.appointmentDetail.twilioSid) {
      chatService.getChatSessionTwilio(vm.appointmentDetail.twilioSid, function (chat) {
        vm.chatSession = chat.chat_session;
        console.log(
          vm.appointmentDetail.start.unix(),
          vm.appointmentDetail.start.unix() -
            chat.chat_session.chat_buffer / 1000,
          moment().unix()
        );
        vm.canEdit =
          vm.canEdit ||
          vm.appointmentDetail.start.unix() -
            chat.chat_session.chat_buffer / 1000 >
            moment().unix() ||
          !vm.isBookDocAppointment;
        var bodyParts = Object.keys(vm.chatSession.body_part);
        for (var i = 0; i < bodyParts.length; i++) {
          vm.body_parts.push(
            bodyParts[i] +
              " - " +
              vm.chatSession.body_part[bodyParts[i]].join(", ")
          );
        }
        vm.isLoading = false;
      });
    } else {
      chatService.getChatSession(vm.appointmentDetail.qbId, function (chat) {
        vm.chatSession = chat.chat_session;
        console.log(
          vm.appointmentDetail.start.unix(),
          vm.appointmentDetail.start.unix() -
            chat.chat_session.chat_buffer / 1000,
          moment().unix()
        );
        vm.canEdit =
          vm.canEdit ||
          vm.appointmentDetail.start.unix() -
            chat.chat_session.chat_buffer / 1000 >
            moment().unix() ||
          !vm.isBookDocAppointment;
        var bodyParts = Object.keys(vm.chatSession.body_part);
        for (var i = 0; i < bodyParts.length; i++) {
          vm.body_parts.push(
            bodyParts[i] +
              " - " +
              vm.chatSession.body_part[bodyParts[i]].join(", ")
          );
        }
        vm.isLoading = false;
      });
    }
  }

  // Changed status of buttons on appointment time
  if (
    sendData.remainingTime > 0 &&
    vm.millisecondOfADay > sendData.remainingTime
  ) {
    $timeout(function () {
      vm.invalidAppt = false;
      vm.canCancel = true;
      vm.canSetCalendar = false;
      vm.noShow = vm.appointmentDetail.hidden;
      vm.isEnableIssueMC =
        !vm.appointmentDetail.hidden &&
        !!vm.appointmentDetail.pay_by_employer &&
        !vm.appointmentDetail.medical_certification;
    }, sendData.remainingTime);
  }

  // Close date picker
  if (
    sendData.reminderCountDown > 0 &&
    vm.millisecondOfADay > sendData.reminderCountDown
  ) {
    $timeout(function () {
      vm.status.opened = false;
      vm.reminderDate = null;
    }, sendData.reminderCountDown);
  }

  var reminderData = vm.appointmentDetail.reminder
    ? moment(vm.appointmentDetail.reminder).format("YYYY-MM-DD")
    : null;

  vm.reminderDate = vm.appointmentDetail.reminder
    ? new Date(moment(vm.appointmentDetail.reminder).format("YYYY-MM-DD"))
    : null;

  // Hide Set Reminder after the follow-up (based on reminder) has been sent out
  if (vm.appointmentDetail.reminder) {
    var reminderIsPassTime = appointmentService.checkPassTime(
      vm.appointmentDetail.reminder
    );
    vm.reminderDate = reminderIsPassTime ? null : vm.reminderDate;
  }

  $scope.$on("updateMcInfo", function (e, data) {
    vm.appointmentDetail.medical_certification = data.medical_certification;
  });

  $scope.$on("changeMode", function (event, changeModeData) {
    $scope.$apply(function () {
      vm.isViewMode = LockMechanismService.checkLocationInViewMode(
        $rootScope.profiles.locations,
        vm.appointmentDetail.location.id
      );
    });
  });

  // Listen to updateSpecialties event
  $scope.$on("updateSpecialties", function (event, specialtiesData) {
    // Update specialties
    specialties = specialtiesData;
  });

  /**
   * [closeModal description] close modal appointment detail
   * @return {[type]} [description]
   */
  vm.closeModal = function () {
    $modalInstance.dismiss();
  };

  /**
   * [showMedicalCertificate description] show modal medical certificate
   * @return {[type]} [description]
   */
  vm.showMedicalCertificate = function () {
    if (vm.isViewMode) {
      return;
    }

    $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            apptData: vm.appointmentDetail,
          };
        },
      },
      templateUrl: "calendar/appointments/views/issue-mc-modal.html",
      controller: require("./issue-mc.controller"),
      controllerAs: "issueMC",
      windowClass: "modal-info",
    });
  };

  vm.openFollowUpModal = function () {
    console.log(vm.chatSession);
    $modal.open({
      animation: true,
      resolve: {
        chatSessionId: function () {
          return vm.chatSession.id;
        },
        videoSessionId: function () {
          return vm.chatSession.video_session.id;
        },
        userInfo: function () {
          return {
            name:
              vm.chatSession.user_info.first_name +
              " " +
              vm.chatSession.user_info.last_name,
            user_id: vm.chatSession.user_info.id,
            phone: vm.chatSession.user_info.phone,
            email: vm.chatSession.user_info.email,
            healthProblem: vm.chatSession.description,
            body_parts: vm.body_parts,
          };
        },
      },
      templateUrl:
        "calendar/appointments/views/create-follow-up-appointment.html",
      controller: require("./create-follow-up-appointment.controller"),
      controllerAs: "$ctrl",
      windowClass: "modal-info modal-info--tabs modal-info--appointment",
    });
  };

  vm.showConfirmationCancel = function () {
    if (
      vm.canCancel &&
      !vm.appointmentDetail.cancel_by &&
      !vm.appointmentDetail.hidden &&
      !vm.isViewMode
    ) {
      $modal.open({
        animation: true,
        resolve: {
          sendData: function () {
            return {
              calendar: sendData.calendar,
              eventSources: sendData.eventSources,
              appointmentDetail: vm.appointmentDetail,
              isAssistant: vm.isAssistant,
            };
          },
        },
        templateUrl:
          "calendar/appointments/views/confirmation-cancel-modal.html",
        controller: require("./cancel-appointment.controller"),
        controllerAs: "cancelAppt",
        windowClass: "modal-incorrect incorrect-duration",
      });
      vm.closeModal();
    }
  };

  vm.pushFollowUpNotificaton = function () {
    if (!vm.invalidAppt && !vm.isPortalAppointment && !vm.isViewMode) {
      var pushNotificationParams = {
        doctor_id: doctorIdParam || null,
        location_id:
          practiceLocationIdParam || vm.appointmentDetail.location.id,
      };

      appointmentApiService
        .pushFollowUpNotification(
          vm.appointmentDetail.id,
          pushNotificationParams
        )
        .then(function (data) {
          console.log(data);
          vm.closeModal();
        });
    }
  };

  vm.noShowAppointment = function () {
    if (!vm.noShow && !vm.appointmentDetail.cancel_by && !vm.isViewMode) {
      appointmentApiService.noShowAppt(vm.appointmentDetail.id).then(
        function (res) {
          var id = vm.appointmentDetail.id,
            idx = 0;
          if (vm.appointmentDetail.typeOfEvent === "bookdoc") {
            idx = _.findIndex(sendData.eventSources[1].events, { id: id });
            sendData.eventSources[1].events.splice(idx, 1);
          } else {
            idx = _.findIndex(sendData.eventSources[0].events, { id: id });
            sendData.eventSources[0].events.splice(idx, 1);
          }
          // Remove from calendar
          sendData.calendar.fullCalendar("removeEvents", id);

          // TODO: Add time-off slot
          sendData.eventSources[2].events.push({
            id: res.id,
            title: vm.appointmentDetail.title,
            typeOfEvent: "noshow",
            start: vm.appointmentDetail.start,
            end: vm.appointmentDetail.end,
            insurance: vm.appointmentDetail.insurance,
            employer: vm.appointmentDetail.employer,
            phone: vm.appointmentDetail.phone,
            reason: vm.appointmentDetail.reason,
            location: vm.appointmentDetail.location,
            hidden: true,
            email: vm.appointmentDetail.email,
          });
          vm.closeModal();
        },
        function (error) {
          vm.noShow = true;
        }
      );
    }
  };

  vm.sendIcsFile = function () {
    if (
      !vm.appointmentDetail.cancel_by &&
      !vm.isViewMode &&
      vm.isPortalAppointment
    ) {
      appointmentApiService
        .sendIcsFile(vm.appointmentDetail.id)
        .then(function (data) {
          vm.appointmentDetail.calendar_sent = true;
        });
    }
  };

  // Listen change reminder date
  $scope.$watch(
    angular.bind(vm, function () {
      return vm.reminderDate;
    }),
    function (newReminderDate) {
      var newReminderData = moment(newReminderDate).format("YYYY-MM-DD");
      if (
        newReminderDate &&
        newReminderData !== reminderData &&
        !vm.isPortalAppointment &&
        !vm.isViewMode
      ) {
        vm.setReminder(vm.appointmentDetail.id, newReminderData, true);
      }
    }
  );

  // Call API to set reminder for send notification
  vm.setReminder = function (appointmentID, date, isSetReminder) {
    var reminderParams = {
      id: appointmentID,
      remind_at: date,
      doctor_id: doctorIdParam || vm.appointmentDetail.doctor.id,
      location_id: practiceLocationIdParam || vm.appointmentDetail.location.id,
    };

    // Need to remove remind_at param when delete reminder. This is API's requirement
    if (!isSetReminder) {
      delete reminderParams.remind_at;
    }

    appointmentApiService.setReminder(reminderParams).then(
      function (data) {
        vm.appointmentDetail.reminder = data
          ? moment(data).format("YYYY-MM-DD")
          : null;
      },
      function (error) {
        CommonModals.openBaseModal("Server error!");
      }
    );
  };

  vm.goToChat = function () {
    $state.go("main.teleconsult", {
      chatId: vm.appointmentDetail.qbId,
      future: moment().unix() < vm.appointmentDetail.start.unix(),
    });
    $modalInstance.dismiss();
  };

  /**
   * [removeDate description] remove date to reminder for Follow-up notification
   * @return {[type]} [description]
   */
  vm.removeDate = function () {
    if (vm.isViewMode) {
      return;
    }

    vm.reminderDate = null;
    vm.setReminder(vm.appointmentDetail.id, null, false);
  };

  // Open date picker to remind when send a push notification
  vm.toggleMin = function () {
    vm.minDate = vm.minDate ? null : new Date();
  };

  vm.toggleMin();

  // Get next year from now
  vm.getNextYear = function () {
    var nextYear = moment().add(1, "years");
    return new Date(nextYear);
  };

  // Set max date for reminder date picker
  vm.reminderMaxDate = vm.getNextYear();

  vm.showButtonBar = false;

  // Disable next button
  function disableNextButton(currentMonth, maxMonth, nextButton) {
    if (currentMonth === maxMonth) {
      nextButton.addClass("disabled");
    }
  }

  // get month of date picker
  function getMonthDatePicker() {
    var datePickerCurrentMonth = angular
      .element(".reminder-date-picker .dropdown-menu button strong")
      .text();
    var currentMonthFormat = moment(datePickerCurrentMonth).format("MM/YYYY");
    return currentMonthFormat;
  }

  vm.open = function ($event) {
    vm.status.opened = !vm.status.opened;

    if (vm.status.opened) {
      $timeout(function () {
        var nextButton = angular.element(
          ".reminder-date-picker .dropdown-menu button.pull-right"
        );
        var prevButton = angular.element(
          ".reminder-date-picker .dropdown-menu button.pull-left"
        );

        var reminderMaxMonth = angular.copy(vm.reminderMaxDate);
        reminderMaxMonth = moment(reminderMaxMonth).format("MM/YYYY");

        var monthDatePicker = getMonthDatePicker();

        disableNextButton(monthDatePicker, reminderMaxMonth, nextButton);

        // Handle click on next button
        nextButton.bind("click", function () {
          monthDatePicker = getMonthDatePicker();
          disableNextButton(monthDatePicker, reminderMaxMonth, nextButton);
        });

        // Handle click on prev button
        prevButton.bind("click", function () {
          if (nextButton.hasClass("disabled")) {
            nextButton.removeClass("disabled");
          }
        });
      }, 100);
    }
  };

  vm.status = {
    opened: false,
  };

  // Disable today on date picker if current time is greater than 08:00 AM of today
  vm.disabled = function (date, mode) {
    var datePickerDate = moment(date).format("YYYY/MM/DD");
    var isPassTime = appointmentService.checkPassTime(datePickerDate);
    return (
      mode === "day" &&
      datePickerDate === moment().format("YYYY/MM/DD") &&
      isPassTime
    );
  };

  // Add event to google calendar
  vm.addEventToCalendar = function () {
    if (!vm.isSetCalendar && vm.canSetCalendar && !vm.isViewMode) {
      // Show modak confirm
      $modal.open({
        templateUrl:
          "calendar/appointments/views/add-to-calendar-confirm-modal.html",
        controllerAs: "cf",
        controller: [
          "$rootScope",
          "$modalInstance",
          "TimeZoneService",
          "addToCalendarService",
          "calendarData",

          function (
            $rootScope,
            $modalInstance,
            TimeZoneService,
            addToCalendarService,
            calendarData
          ) {
            var vm = this;

            vm.appointmentDetail = calendarData.appointmentDetail;
            vm.setGoogleEventIDToAppointment =
              calendarData.setGoogleEventIDToAppointment;

            vm.closeModal = function () {
              $modalInstance.dismiss();
            };

            vm.addToCalendar = function () {
              var timezone = $rootScope.profiles.timezone;
              timezone =
                (timezone && timezone.utc_timezone_text) || "UTC+08:00";

              // Create google event data
              var event = {
                  summary:
                    "BookDoc Appointment with " + vm.appointmentDetail.title,
                  location: vm.appointmentDetail.location.address,
                  description: "At " + vm.appointmentDetail.location.name,
                  start: {
                    dateTime: TimeZoneService.timeToTimezoneFormat(
                      vm.appointmentDetail.start,
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    timeZone: timezone,
                  },
                  end: {
                    dateTime: TimeZoneService.timeToTimezoneFormat(
                      vm.appointmentDetail.end,
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    timeZone: timezone,
                  },
                  recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
                  attendees: [],
                  reminders: {
                    useDefault: false,
                    overrides: [
                      { method: "email", minutes: 10 },
                      { method: "popup", minutes: 10 },
                    ],
                  },
                },
                request;

              if ($rootScope.authCalendar) {
                request = addToCalendarService.addToCalendar(event);
                request.execute(function (event) {
                  vm.setGoogleEventIDToAppointment(event.id);
                  vm.closeModal();
                });
              } else {
                addToCalendarService.authCalendar(function () {
                  request = addToCalendarService.addToCalendar(event);
                  request.execute(function (event) {
                    vm.setGoogleEventIDToAppointment(event.id);
                    vm.closeModal();
                  });
                });
              }
            };
          },
        ],
        resolve: {
          calendarData: function () {
            return {
              appointmentDetail: vm.appointmentDetail,
              setGoogleEventIDToAppointment: vm.setGoogleEventIDToAppointment,
            };
          },
        },
        windowClass: "modal-info--confirm",
      });
    }
  };

  vm.setGoogleEventIDToAppointment = function (eventID) {
    appointmentApiService
      .setGoogleEventID(vm.appointmentDetail.id, eventID)
      .then(function (res) {
        vm.appointmentDetail.google_event_id = res.google_event_id;
        vm.isSetCalendar = true;
        vm.calendarTooltip = "Added to Google Calendar";
      });
  };

  // Show Referral form
  vm.showReferralForm = function () {
    $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            specialties: specialties,
            referFromCalendar: true,
            patientName: vm.appointmentDetail.title,
            patientPhone: vm.appointmentDetail.phone,
            patientEmail: vm.appointmentDetail.email,
          };
        },
      },
      templateUrl: "referral/refer-to/views/refer-doctor-modal.html",
      controller: require("../../../referral/refer-to/controllers/refer-doctor.controller"),
      controllerAs: "refer",
      windowClass: "modal-info",
    });
  };

  vm.editAppointment = function () {
    if (
      (!vm.isPortalAppointment && !vm.isBookDocAppointment) ||
      vm.isViewMode
    ) {
      return;
    }

    var currentLocation = [];
    currentLocation.push(vm.appointmentDetail.location);

    var requestParams = {
      practice_location_id:
        vm.appointmentDetail.location && vm.appointmentDetail.location.id,
    };

    appointmentApiService.getPanelShipReason(requestParams).then(
      function (data) {
        openEditAppointmentModal(currentLocation, data);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  // Show SMS form
  vm.showSmsForm = function () {
    $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            referFromCalendar: true,
            appointment: vm.appointmentDetail,
            patientName: vm.appointmentDetail.title,
            patientPhone: vm.appointmentDetail.phone,
            patientEmail: vm.appointmentDetail.email,
            practiceLocation: vm.appointmentDetail.location
          };
        },
      },
      templateUrl: "sms/views/sms-modal.html",
      controller: require("../../../referral/refer-to/controllers/sms.controller"),
      controllerAs: "sms",
      windowClass: "modal-info",
    });
  };

  function openEditAppointmentModal(currentLocation, reasonForVisitList) {
    var modalInstance = $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            start: vm.startTime,
            end: vm.endTime,
            date: vm.date,
            locations: sendData.locations,
            reason: vm.appointmentDetail.reason,
            currentLocation: currentLocation,
            eventSources: sendData.eventSources,
            insurance: vm.appointmentDetail.insurance,
            employer: vm.appointmentDetail.employer,
            phone: vm.appointmentDetail.phone,
            first_name: vm.appointmentDetail.first_name,
            last_name: vm.appointmentDetail.last_name,
            isEditAppointment: true,
            note: vm.appointmentDetail.doctor_note,
            timeOffId: vm.appointmentDetail.timeoffId,
            appointmentId: vm.appointmentDetail.id,
            calendar: sendData.calendar,
            doctorId: doctorIdParam || null,
            reasonForVisitList: reasonForVisitList || null,
            isBookDocAppointment: vm.isBookDocAppointment,
            email: vm.appointmentDetail.email,
            rescheduleCallback: vm.rescheduleCallback,
          };
        },
      },
      templateUrl: "calendar/appointments/views/create-appointment-modal.html",
      controller: require("./create-appointment.controller"),
      controllerAs: "createAppt",
      windowClass: "modal-info modal-info--tabs modal-info--appointment",
    });
  }
};

DetailAppointmentCtrl.$inject = [
  "$timeout",
  "$modalInstance",
  "$modal",
  "$rootScope",
  "$state",
  "sendData",
  "moment",
  "appointment.api.service",
  "$scope",
  "CommonModals",
  "appointmentService",
  "addToCalendarService",
  "APP_CONSTANT",
  "LockMechanismService",
  "chatService",
];

module.exports = DetailAppointmentCtrl;
