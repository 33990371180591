'use strict';

var viewPracticeLocationDirective = function($modal) {
  return {
    restrict: 'E',
    scope: false,
    templateUrl: 'registration/views/view-practice-location.html',
    link: function (scope, element, attrs){

      /**
       * [removePracticeLocation - remove all practice location]
       *
       */
      scope.removePracticeLocation = function () {
        scope.locations = [];
      };

      /**
       * [updatePracticeLocation - open modal to update practice location]
       *
       */
      scope.updatePracticeLocation = function (index) {
        var addLocationModalInstance = $modal.open({
          animation: true,
          resolve: {
            sendData: function () {
              return {
                locations: [],
                selectedLocation: angular.copy(scope.locations[index]),
                isRegistration: true,
                isUpdate: true,
                listCountries: scope.registrationData.listCountries
              };
            }
          },
          templateUrl: 'registration/views/location-with-address.modal.html',
          controller: require('../controllers/location-with-address.controller'),
          controllerAs: 'locaAdd',
          windowClass: 'modal-info other-location'
        });

        // handle when user close modal
        addLocationModalInstance.result.then(function (data) {
          // user submit modal
          scope.locations[index] = data;
        }, function (reason) {
          // user cancel modal
        });

        if (scope.isRegistration) {
          // remove error message when user begin add location
          scope.errors.hasNoLocation = false;
        }
      };
    }
  };
};

viewPracticeLocationDirective.$inject = [
  '$modal'
];

module.exports = viewPracticeLocationDirective;
