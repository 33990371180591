'use strict';

var _ = require('lodash');

var checkRouterService = function() {
  return {

    // List of grouping router
    groupingRouterList: [
      'main.grouping',
      'main.groupingDashboard',
      'main.groupingProfile.information',
      'main.groupingProfile.photo',
      'main.groupingAlerts',
      'main.groupingCalendar.appointments',
      'main.groupingCalendar.availability',
      'loginGroup'
    ],

    // Check next router is a grouping router
    isGroupingRouter: function(nextRouter) {
      var routerIndex = _.findIndex(this.groupingRouterList, function(router) {
        return router === nextRouter;
      });

      return routerIndex >= 0;
    },

  };
};

checkRouterService.$inject = [
];

module.exports = checkRouterService;