'use strict';

var _ = require('lodash');

var ListPracticeService = function() {

  return {

    required: false,
    duplicate: false,

    /**
     * Validate required and duplicate for specialty
     * @param  {array} array
     */
    validateSpecialty: function (array) {
      this.required = !array[0].id;

      if (array[0].id) {

        this.duplicate = array[0].id === array[1].id;

      }
    },

    /**
     * Find item at a index and move it to top
     * @param  {array} array
     * @param  {int} fromIndex
     * @param  {int} toIndex
     * @return {array}
     */
    arrangeItems: function (array, fromIndex, toIndex) {
      array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
      return array;
    },

    /**
     * Make item with a name to first of array
     * @param  {array} array
     * @param  {string} name
     */
    addPriorityItemsToTop: function (array, name) {

      // Find item and move this item to first of array
      for (var i = 0, length = array.length; i < length; i++) {

        if (array[i].name === name) {
          array = this.arrangeItems(array, i, 0);
        }

      }
    }


  };
};

ListPracticeService.$inject = [
];

module.exports = ListPracticeService;
