'use strict';

var _ = require('lodash');

var controllers = angular
  .module('Auth')
  .controller('Auth.LoginController', [
    '$rootScope',
    '$scope',
    'Auth.EVENTS',
    'Auth.AuthService',
    'Auth.ModalsService',
    '$state',
    'CommonModals',
    'StateChangeService',

    function ($rootScope, $scope, AUTH_EVENTS, AuthService, FPModalsService, $state, CommonModals, StateChangeService) {
      var updateAuthState;

      // Reset title for login page
      window.document.title = 'BookDoc - Portal';

      $scope.isAssistant = $state.current.name === 'loginGroup';

      $rootScope.loginUrl = $scope.isAssistant ? 'loginGroup' : 'login';

      $scope.openModalForgotPW = function () {
        FPModalsService.openModalForgotPW($scope.isAssistant);
      };

      $scope.redirectToLoginPage = function () {
        StateChangeService.redirectToLogin(!$scope.isAssistant);
      };

      $scope.credentials = {};

      $scope.loginMessage = {
        incorrect: false,
        serverError: false
      };

      $scope.isLogging = false;

      $rootScope.rememberMe = {
        isRememberMe: false
      };

      $scope.login = function (credentials, form) {

        /* Check form required */
        if (form.$valid) {

          $scope.isLogging = true;

          delete $rootScope.profiles;
          delete $rootScope.assistantProfile;

          AuthService.login(credentials, $scope.isAssistant).then(function (res) {
            updateAuthState();

            // Check grouping user or doctor user
            if (res.clinic_id) {
              $rootScope.$emit(AUTH_EVENTS.loginGrouping);
            } else {
              $rootScope.$emit(AUTH_EVENTS.loginSuccess);
            }

            form.$setPristine();
            form.$setSubmitted();

            $scope.loginMessage.serverError = false;
            $scope.loginMessage.incorrect = false;
            $scope.isLogging = false;

          }, function (err) {
            $rootScope.$emit(AUTH_EVENTS.loginFailed);

            $scope.isLogging = false;

            /* Check incorrect email or password */
            if (err.status === 401) {

              $scope.loginMessage.incorrect = true;
              $scope.loginMessage.serverError = false;

            } else if (err.status === 503) {
              CommonModals.openBaseModal('BookDoc is currently undergoing scheduled maintenance/deployment. Please check back in a couple of hours. Sorry for the inconvenience.');
            } else {

              /* Server error */
              $scope.loginMessage.serverError = true;
              $scope.loginMessage.incorrect = false;

            }

            $scope.isLogging = false;
            console.log('Error: ' + err.status + ' ' + err.statusText);
          });

        } else {

          /* Reset form, message */
          form.$setPristine();
          form.$setSubmitted();
          $scope.loginMessage.serverError = false;
          $scope.loginMessage.incorrect = false;
          $scope.isLogging = false;

        }

      };

      $scope.goToSignUp = function () {
        $state.go('main.signup');
      };

      updateAuthState = function () {
        $rootScope.currentUser = AuthService.currentUser();
        $rootScope.isAuthenticated = AuthService.isAuthenticated();
        // $rootScope.isAuthorized = AuthService.isAuthorized;
      };

      // Hide indicator
      $rootScope.showIndicator = false;
    }
  ])
  .controller('Auth.LogoutController', [
    '$rootScope',
    '$scope',
    'Auth.EVENTS',
    'Auth.AuthService',
    'GroupingAPI',
    '$stateParams',
    'LockMechanismService',
    'NotificationsService',
    function ($rootScope, $scope, AUTH_EVENTS, AuthService, GroupingAPI, $stateParams, LockMechanismService, NotificationsService) {

      var isAssistant = !!$rootScope.currentUser.clinic_id;

      $rootScope.loginUrl = isAssistant ? 'loginGroup' : 'login';
      var updateAuthState;
      updateAuthState = function () {
        $rootScope.currentUser = AuthService.currentUser();
        $rootScope.isAuthenticated = AuthService.isAuthenticated();
        // $rootScope.isAuthorized = AuthService.isAuthorized;
      };

      $scope.handleBeforeLogout = function () {
        // show indicator
        $rootScope.showIndicator = true;
        var practiceLocationIdParam = Number($stateParams.practiceLocationId);

        var inViewMode = practiceLocationIdParam ? LockMechanismService.checkLocationInViewMode($rootScope.profiles.locations, practiceLocationIdParam) : false;

        if (isAssistant && !inViewMode && practiceLocationIdParam) {

          var locationIndex = LockMechanismService.findLocationIndex($rootScope.profiles.locations, practiceLocationIdParam);
          // Disable forced out of Edit mode after 30 minutes
          $rootScope.profiles.locations[locationIndex].checkRemainingTime = false;

          NotificationsService.unsubscribe('/lock_mechanism/');
          $rootScope.enableNotification = false;
          GroupingAPI.unLockMechanism(practiceLocationIdParam)
            .then(
              function (data) {
                $scope.logout();
              },
              function (error) {
                console.log(error);
              }
            );

        } else {
          $scope.logout();
        }
      };

      $scope.logout = function (credentials) {
        AuthService.logout(isAssistant).then(function () {
        }, function () {
        })
          .finally(function () {
            $rootScope.$emit(AUTH_EVENTS.logoutSuccess);
            // hide indicator
            $rootScope.showIndicator = false;
          });
      };
    }
  ])

  .controller('Auth.ThankyouController', [
    '$scope',
    '$location',
    'Auth.AuthService',
    '$rootScope',
    function ($scope, $location, AuthService, $rootScope) {
      $scope.newAccount = AuthService.registerUser();

      if (!$scope.newAccount) {
        $location.path('/');
        return;
      }

      function createVariables() {
        $scope.inviteDoctorsList = [
          {
            name: '',
            email: ''
          }
        ];

        $scope.invitesMessage = {
          duplicates: false,
          success: false,
          isGetting: false
        };

        $scope.doctorFullname = $scope.newAccount.first_name + ' ' +
          $scope.newAccount.last_name || '';

        $scope.doctorFullnameBackup = angular.copy($scope.doctorFullname);
      }

      createVariables();

      /**
       * Check duplicate value in array
       * @param  {array}  array
       * @return {Boolean}
       */
      function hasDuplicates(array) {
        var uniqArray = _.uniq(array, function (item) {
          return item.email;
        });

        return uniqArray.length !== array.length;
      }

      function checkDuplicate(callback) {

        if (hasDuplicates($scope.inviteDoctorsList)) {
          $scope.invitesMessage.duplicate = true;
          return;
        }

        $scope.invitesMessage.duplicate = false;
        callback();
      }

      /**
       * Add new invite doctor
       */
      $scope.addInvite = function (form) {

        /* Check if form valid */
        if (form.$valid) {

          checkDuplicate(function () {
            var newInvite = {
              name: '',
              email: ''
            };

            $scope.inviteDoctorsList.push(newInvite);
          });


        } else {
          form.$setPristine();
          form.$setSubmitted();
        }
      };

      /**
       * Remove invited doctor form
       */
      $scope.removeInvite = function (index) {
        $scope.inviteDoctorsList.splice(index, 1);
        checkDuplicate(function () {
        });
      };

      $scope.homePage = function () {
        $location.path('/');
      };

      /**
       * Invite new doctors
       */
      $scope.inviteDoctors = function (form) {
        var doctorsListLen = $scope.inviteDoctorsList.length;

        /* Check if form valid */
        if (form.$valid && doctorsListLen) {

          /* Check duplicates */
          checkDuplicate(function () {

            $scope.invitesMessage.isGetting = true;

            /* Send data */
            AuthService.inviteDoctors($scope.inviteDoctorsList)
              .then(
                function (data) {
                  $scope.invitesMessage.success = true;
                  $scope.invitesMessage.isGetting = false;
                  AuthService.removeRegisterSession();
                  console.log(data);
                },
                function (err) {
                  $scope.invitesMessage.isGetting = false;
                  console.log(err);
                }
              );
          });

        } else {
          form.$setPristine();
          form.$setSubmitted();
        }
      };

      // Hide indicator
      $rootScope.showIndicator = false;
    }
  ]);

module.exports = controllers;
