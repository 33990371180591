'use strict';

var specialtiesAPI = function (BOOKDOC_PROFILE_API_ROUTE, Restangular) {

  return {

    root: Restangular.all(BOOKDOC_PROFILE_API_ROUTE.specialties),

    getList: function () {
      return this.root.getList();
    },

    save: function(specialties) {
      return this.root.customPOST(specialties, 'change');
    }

  };

};

specialtiesAPI.$inject = ['BOOKDOC_PROFILE_API_ROUTE', 'Restangular', '$rootScope'];

module.exports = specialtiesAPI;
