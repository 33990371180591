'use strict';

var autoClickDirective = function ($timeout, APP_CONSTANT) {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      scope.$watch(attrs.autoClick, function (newVal) {
        if (newVal) {
          $timeout(function () {
            elem[0].click();
          }, APP_CONSTANT.focusDelayTime);
        }
      });
    }
  };
};

autoClickDirective.$inject = [
  '$timeout',
  'APP_CONSTANT'
];

module.exports = autoClickDirective;