"use strict";

function PrescriptionDosage(
  medicineName,
  onCompleteMedicinePrescription,
  $modalInstance,
  SnackbarService
) {
  var ctrl = this;

  ctrl.medicineName = medicineName;
  ctrl.dosage = {
    quantity: null,
    unit: null,
    perDay: null,
    days: null,
    beforeMeal: false,
    afterMeal: false,
    whenNecessary: false,
    toComplete: false,
    causeDrowsiness: false,
    remarks: "",
  };

  function isValid() {
    console.log(
      parseInt(ctrl.dosage.quantity),
      ctrl.dosage.quantity,
      ctrl.dosage
    );
    if (
      ctrl.dosage.quantity === null &&
      ctrl.dosage.unit === null &&
      ctrl.dosage.perDay === null &&
      ctrl.dosage.days === null &&
      ctrl.dosage.beforeMeal === false &&
      ctrl.dosage.afterMeal === false &&
      ctrl.dosage.whenNecessary === false &&
      ctrl.dosage.toComplete === false &&
      ctrl.dosage.causeDrowsiness === false &&
      ctrl.dosage.remarks === ""
    ) {
      SnackbarService.showSnakeBar("Please input in order to proceed");
      return false;
    }
    if (
      ctrl.dosage.quantity !== null &&
      parseFloat(ctrl.dosage.quantity) <= 0
    ) {
      SnackbarService.showSnakeBar("Please input a number greater than 0");
      return false;
    }
    if (ctrl.dosage.unit !== null && ctrl.dosage.quantity === null) {
      SnackbarService.showSnakeBar("Please fill in the quantity");
      return false;
    }
    if (ctrl.dosage.quantity !== null && ctrl.dosage.unit === null) {
      SnackbarService.showSnakeBar("Please select Dosage Form");
      return false;
    }
    if (ctrl.dosage.perDay !== null && parseInt(ctrl.dosage.perDay) <= 0) {
      SnackbarService.showSnakeBar("Please input a number greater than 0");
      return false;
    }
    if (ctrl.dosage.days !== null && parseInt(ctrl.dosage.days) <= 0) {
      SnackbarService.showSnakeBar("Please input a number greater than 0");
      return false;
    }

    return true;
  }

  ctrl.add = function () {
    if (isValid()) {
      onCompleteMedicinePrescription(ctrl.dosage);
      ctrl.close();
    }
  };

  ctrl.close = function () {
    $modalInstance.dismiss();
  };
}

PrescriptionDosage.$inject = [
  "medicineName",
  "onCompleteMedicinePrescription",
  "$modalInstance",
  "SnackbarService",
];

module.exports = PrescriptionDosage;
