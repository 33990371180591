'use strict';

module.exports = angular
  .module('bookdoc.contact.services')
  .factory("contact.api.service", ["Restangular", function (Restangular) {
    var service = {
      sendContactUs: sendContactUs
    };

    function sendContactUs(contactData) {
      return Restangular.all("feedbacks").customPOST(contactData, null);
    }

    return service;
  }]);
