'use strict';

var _ = require('lodash');

var referService = function (Restangular, $modal, CommonModals) {

  return {
    
    checkDoctorSpecialty: function (doctorSpecialties, specialtyId) {

      var specialtyIndex = _.findIndex(doctorSpecialties, function(specialty) { 
        return specialty.id === specialtyId; 
      });

      return specialtyIndex >= 0;
    },

    showConfirmModal: function (isReferSuccessfully) {
      $modal.open({
        animation: true,
        templateUrl: 'referral/refer-to/views/confirmation-refer-modal.html',
        controller: require('../controllers/confirmation-refer.controller'),
        controllerAs: 'confirmRefer',
        windowClass: 'modal-incorrect modal-confirm'
      });
    },

    showReferralError: function (errorData) {
      var errorKey = Object.keys(errorData)[0];

      switch(errorKey) {
        case 'unverified_referee':
          CommonModals.openBaseModal('Referee doctor must be verified');
          break;
        case 'unverified_referrer':
          CommonModals.openBaseModal('Referrer doctor must be verified');
          break;
        case 'patient_phone_number':
          CommonModals.openBaseModal('Patient phone number is invalid');
          break;
        case 'specialty_id':
          CommonModals.openBaseModal('Selected doctor does not have this specialty');
          break;
      };
    },

    showSmsError: function (err){
      var errMsg = "Error Occured"
      if(err.errors){
        errMsg = err.errors.join(', ')
      }else if (err.error){
        errMsg = err.error
      }
      CommonModals.openBaseModal(errMsg);
    },

    showSmsHasSendModal: function (){
      CommonModals.openBaseModal('SMS has been send');
    }

  };

};

referService.$inject = [
  'Restangular',
  '$modal',
  'CommonModals'
];

module.exports = referService;
