'use strict';

var photoAPI = function (BOOKDOC_PROFILE_API_ROUTE, Restangular) {

  return {

    getPhotos: function (locationId) {
      return Restangular.one(BOOKDOC_PROFILE_API_ROUTE.locations, locationId).customGETLIST(BOOKDOC_PROFILE_API_ROUTE.photos);
    }

  };

};

photoAPI.$inject = ['BOOKDOC_PROFILE_API_ROUTE', 'Restangular'];

module.exports = photoAPI;
