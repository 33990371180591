/*globals FormData*/

'use strict';

var moment = (typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);
var dateUtc = function (TimeZoneService) {

  return function (dateTime) {
    return dateTime ? TimeZoneService.alertCreateAtFormat(dateTime) : '';
  };

};

dateUtc.$inject = [
  'TimeZoneService'
];

module.exports = dateUtc;
