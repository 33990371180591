/*globals window*/

'use strict';

var _ = require('lodash');

var CredentialCtrl = function ($q, $scope, $modalInstance, InformationAPI, sendData, ArrayService, OpenInfoModalService, ValidateMessagesService) {
  var vm = this;
  var handleNameProperty = 'name';
  var sendQualificationObject = {};

  vm.existedQualifications = sendData.qualifications;
  vm.existedMemberships = sendData.memberships;
  vm.mmc = sendData.mmc;
  vm.nsr = sendData.nsr;
  vm.qualifications = [];
  vm.memberships = [];
  vm.validate = {
    qualifications: {
      disableAdd: false,
      isEmpty: false,
      isDuplicate: false
    },
    memberships: {
      disableAdd: false,
      isEmpty: false,
      isDuplicate: false
    }
  };

  vm.isUpdating = false;

  // validation messages
  vm.messages = ValidateMessagesService;

  /**
   * [checkExistEmptyTextbox description] check whether any textbox is empty or not
   * param {Array} arrs
   *
   */
  function checkExistEmptyTextbox(arrs) {
    var isExistedEmptyTextbox = _.filter(arrs, function (item) {
      if (!item.name) {
        item.name = '';
      }
      return item.name.trim() === '';
    }) || [];

    return isExistedEmptyTextbox.length > 0;
  }

  /**
   * [resetQualificationValidateError description] disable all validate of qualification
   *
   */
  function resetQualificationValidateError() {
    vm.validate.qualifications.isEmpty = false;
    vm.validate.qualifications.isDuplicate = false;
  }

  /**
   * [resetMembershipValidateError description] disable all membership of qualification
   *
   */
  function resetMembershipValidateError() {
    vm.validate.memberships.isEmpty = false;
    vm.validate.memberships.isDuplicate = false;
  }

  /**
   * [startQueue description] return a resolve defer
   *
   */
  function startQueue() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  /**
   * [inputChanged description] handle when user input name to textbox
   * @params {boolean} isMembership - true for membership, false for qualification
   * @params {string} text - text for checking
   *
   */
  vm.inputChanged = function(isMembership, text) {
    if (isMembership) {
      vm.validate.memberships.disableAdd = ArrayService.checkEmptyItem(vm.memberships, handleNameProperty);
      return;
    }

    vm.validate.qualifications.disableAdd = ArrayService.checkEmptyItem(vm.qualifications, handleNameProperty);
  };

  /**
   * [addItem description] handle event Add membership when user
   * @params {boolean} isMembership - true for membership, false for qualification
   *
   */
  vm.addItem = function (isMembership) {
    if (isMembership) {
      vm.validate.memberships.disableAdd = true;
      vm.memberships.push({name: ''});
      resetMembershipValidateError();
      return;
    }

    vm.validate.qualifications.disableAdd = true;
    vm.qualifications.push({name: ''});
    resetQualificationValidateError();
  };

  /**
   * [removeItem description] remove item in memberships/qualifications
   * @params {boolean} isMembership - true for membership, false for qualification
   * @params {number} index - position of delete item in array
   */
  vm.removeItem = function(isMembership, index) {
    if (isMembership) {
      vm.memberships.splice(index, 1);
      vm.validate.memberships.disableAdd = ArrayService.checkEmptyItem(vm.memberships, handleNameProperty);
      return;
    }
    vm.qualifications.splice(index, 1);
    vm.validate.qualifications.disableAdd = ArrayService.checkEmptyItem(vm.qualifications, handleNameProperty);
  };

  /**
   * [sendQualificationRequest description] send request to add qualification
   *
   */
  function hasUpdateQualification() {
    var currentQualifications = sendData.qualifications;
    var qualificationsResult = ArrayService.removeExistedItem(vm.qualifications, currentQualifications, handleNameProperty);
    var qualificationSendObject = {
      qualifications: []
    };

    _.forEach(qualificationsResult, function (qualification) {
      qualificationSendObject.qualifications.push({
        name: qualification.name,
        updated: true
      });
    });

    if (qualificationSendObject.qualifications.length === 0) {
      return false;
    }

    sendQualificationObject = qualificationSendObject;
    return true;
  }

  function updateQualification() {
    return InformationAPI
      .qualifications
      .updateQualification(sendQualificationObject);
  }
  /**
   * [sendMembershipRequest description] send request to add membership
   *
   */
  function sendMembershipRequest() {
    var currentMemberships = sendData.memberships;
    var membershipsResult = ArrayService.removeExistedItem(vm.memberships, currentMemberships, handleNameProperty);
    var membershipSendObject = {
      memberships: []
    };

    _.forEach(membershipsResult, function (membership) {
      membershipSendObject.memberships.push({
        name: membership.name,
        updated: true
      });
    });

    if (membershipSendObject.memberships.length === 0) {
      return;
    }

    return InformationAPI
      .memberships
      .updateMembership(membershipSendObject);
  }


  /**
   * [validate description] validate before send request
   *
   */
  function validate() {
    var isEmptyError = false;
    var isdDuplicate = false;

    resetMembershipValidateError();
    resetQualificationValidateError();

    // validate qualifications
    if (ArrayService.checkEmptyItem(vm.qualifications, handleNameProperty)) {
      vm.validate.qualifications.isEmpty = true;
      isEmptyError = true;
    }

    // validate memberships
    if (ArrayService.checkEmptyItem(vm.memberships, handleNameProperty)) {
      vm.validate.memberships.isEmpty = true;
      isEmptyError = true;
    }

    if (isEmptyError) {
      // if has error
      return true;
    }

    if (ArrayService.checkDuplicateItem(vm.qualifications, handleNameProperty)) {
      vm.validate.qualifications.isDuplicate = true;
      isdDuplicate = true;
    }

    if (ArrayService.checkDuplicateItem(vm.memberships, handleNameProperty)) {
      vm.validate.memberships.isDuplicate = true;
      isdDuplicate = true;
    }

    if (isdDuplicate) {
      // if has error
      return true;
    }

    return false;
  }

  /**
   * [save description] handle event save when user click button save
   *
   */
  vm.save = function () {

    if (validate()) {
      return;
    }

    var chain = startQueue();

    if (!hasUpdateQualification()) {
      vm.cancel();
      return;
    }

    if (!vm.isUpdating) {
      vm.isUpdating = true;
    } else {
      return;
    }

    $scope.$emit('updateLoadingStatus', true);

    chain = chain.then(updateQualification);

    chain = chain.then(function(data) {
      // receive response and update UI
      if (Array.isArray(data)) {
        $scope.$emit('UpdateQualification', data);
      }
    });

    chain = chain
      .then(function () {
        vm.isUpdating = false;
        $modalInstance.close();
        OpenInfoModalService.open(
          'profile/views/information/location-thank.modal.html',
          require('./location-thank.controller'),
          'locaTks',
          'modal-info modal-thank-update'
        );
      }, function (data) {
        vm.isUpdating = false;
      })
      .finally(function() {
        $scope.$emit('updateLoadingStatus', false);
      });

  };

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }
  });

  /**
   * [cancel description] handle event cancel when user click button cancel
   *
   */
  vm.cancel = function () {
    if (!vm.isUpdating) {
      $modalInstance.dismiss('cancel');
    } else {
      return;
    }
  };

};

CredentialCtrl.$inject = [
  '$q',
  '$scope',
  '$modalInstance',
  'bookdoc.information.api',
  'sendData',
  'ArrayService',
  'OpenInfoModalService',
  'ValidateMessagesService'
];

module.exports = CredentialCtrl;
