'use strict';

var _ = require('lodash');

var OpenInfoModalService = function ($modal) {
  return {
    open: function (templateUrl, controller, controllerAs, windowClass) {
      $modal.open({
        animation: false,
        templateUrl: templateUrl,
        controller: controller,
        controllerAs: controllerAs,
        windowClass: windowClass
      });
    }
  };
};

OpenInfoModalService.$inject = [
  '$modal'
];

module.exports = OpenInfoModalService;
