/* Define controller for appointments module*/
"use strict";

// require lodash
var _ = require("lodash");
require("moment-timezone");
require("moment-range");

var AppointmentsCtrl = function (
  $scope,
  $timeout,
  $stateParams,
  $location,
  DataTimeService,
  uiCalendarConfig,
  appointmentApiService,
  moment,
  $cookieStore,
  $modal,
  availabilitiesApiService,
  CommonModals,
  $rootScope,
  LockMechanismService,
  appointmentService,
  TimeZoneService,
  formatDataService,
  APP_CONSTANT
) {
  var vm = this;
  var DATE_NONE_HYPHEN_FORMAT = "YYYYMMDD";
  var calendarType;
  var defaultDate;

  var specialties = [];

  if ($scope.practiceLocationIdParam) {
    vm.locationId = $scope.practiceLocationIdParam;
  }

  // Implement to get availability when change from view mode to edit mode.
  $scope.$on("getAvailability", function (event) {
    $scope.$apply(function () {
      debouceGetAvailabilities();
    });
  });

  // Listen to updateSpecialties event
  $scope.$on("updateSpecialties", function (event, specialtiesData) {
    // Update specialties
    specialties = specialtiesData;
  });

  var availDataRes = [];
  vm.isSafari = /constructor/i.test(window.HTMLElement);

  function cachingQueryParams(
    start,
    end,
    view,
    locationId,
    doctor_id,
    redirectFromAlert
  ) {
    if (redirectFromAlert) {
      return;
    }

    $location.search({
      start: start,
      end: end,
      location_id: locationId,
      on: view,
      doctor_id: doctor_id,
    });
    $stateParams.start = start;
    $stateParams.end = end;
    $stateParams.location_id = locationId;
    $stateParams.on = view;
    $stateParams.doctor_id = doctor_id;
  }

  function initDataFromParams() {
    var appointmentStartTime = $stateParams.appointment_time || null;

    if (appointmentStartTime) {
      appointmentStartTime = moment(moment.unix(appointmentStartTime).format())
        .utc()
        .format();
      appointmentStartTime = moment(
        DataTimeService.initWeekDate(
          appointmentStartTime,
          DATE_NONE_HYPHEN_FORMAT,
          true,
          currentView
        ),
        DATE_NONE_HYPHEN_FORMAT
      );
    }
    var currentView = $stateParams.on;

    vm.currentView = currentView || "Week";

    defaultDate = appointmentStartTime
      ? appointmentStartTime
      : moment(
          DataTimeService.initWeekDate(
            $stateParams.start,
            DATE_NONE_HYPHEN_FORMAT,
            true,
            currentView
          ),
          DATE_NONE_HYPHEN_FORMAT
        );

    calendarType = currentView && currentView.toLowerCase();

    if (calendarType) {
      switch (calendarType) {
        case "month":
          calendarType = "month";
          break;

        case "day":
          calendarType = "agendaDay";

          break;

        default:
          calendarType = "agendaWeek";
      }
    }

    if ($stateParams.location_id) {
      var currentLocation = _.find(vm.locations, function (location) {
        return location.id.toString() === $stateParams.location_id;
      });

      vm.locationId = (currentLocation && currentLocation.id) || undefined;
      vm.currentLocation =
        (currentLocation && currentLocation.name) || "All locations";
    } else {
      vm.currentLocation = "All locations";
    }

    if ($scope.practiceLocationIdParam) {
      var groupingLocation = _.find(vm.locations, function (location) {
        return location.id === $scope.practiceLocationIdParam;
      });

      vm.currentLocation = groupingLocation && groupingLocation.name;
    }
  }

  vm.locations = $rootScope.profiles.locations;

  initDataFromParams();

  handleDisableAddAppointment(vm.locationId);

  var isHomeNursingCare =
    _.find($rootScope.profiles.specialties, function (item) {
      return item.name === "Home Nursing Care";
    }) || null;

  vm.eventSources = [];
  vm.apptTimeoff = {
    color: "#dadada",
    textColor: "#171717",
    events: [],
  };

  $scope.$on("changeMode", function (event, changeModeData) {
    handleDisableAddAppointment(vm.locationId);
  });

  if ($stateParams.location_id) {
    var currentLocation = _.find(vm.locations, function (location) {
      return location.id.toString() === $stateParams.location_id;
    });

    vm.locationId = currentLocation.id;
    vm.currentLocation = currentLocation.name;
  }

  vm.alertOnDrop = function () {};

  vm.alertOnResize = function () {};

  vm.downloadCurrentDay = function () {
    var start = vm.start.format("YYYYMMDD");
    var end = moment(vm.end.format("YYYYMMDD"), "YYYYMMDD")
      .add(-1, "days")
      .format("YYYYMMDD");
    appointmentApiService
      .getDayAppts(start, end, vm.locationId)
      .then(function (result) {
        pdfMake.fonts = {
          Roboto: {
            normal:
              "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
            bold:
              "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
            italics:
              "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
            bolditalics:
              "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
          },
        };

        var tableHeaderText = [
          "Time",
          "Name",
          "Health problem",
          "Phone number",
        ].map((thElement) => ({ text: thElement, style: "tableHeader" }));

        var tableDataAsRows = result.map((appt) => [
          appt.time,
          appt.patient_name,
          appt.health_problem,
          appt.patient_number,
        ]);

        var docDefinition = {
          header: { text: "Day appointments", alignment: "center" },
          footer: function (currentPage, pageCount) {
            return {
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: "center",
            };
          },
          content: [
            {
              style: "tableExample",
              table: {
                headerRows: 1,
                body: [tableHeaderText, ...tableDataAsRows],
              },
              layout: {
                fillColor: function (rowIndex) {
                  if (rowIndex === 0) {
                    return "#0f4871";
                  }
                  return rowIndex % 2 === 0 ? "#f2f2f2" : null;
                },
              },
            },
          ],
          styles: {
            tableExample: {
              margin: [0, 20, 0, 80],
            },
            tableHeader: {
              margin: 12,
              color: "white",
            },
            tableData: {
              margin: 12,
            },
          },
        };
        pdfMake.createPdf(docDefinition).download("Day appointments");
      });
  };

  vm.changeView = function (view, calendar, currentView) {
    $rootScope.showIndicator = true;

    // Change default date
    if (vm.isGoToDate) {
      uiCalendarConfig.calendars[calendar].fullCalendar("gotoDate", vm.start);
    }

    uiCalendarConfig.calendars[calendar].fullCalendar("changeView", view);

    // Update dropdown value
    vm.currentView = currentView || "Week";
  };

  // Events handlers
  vm.dayClickEvent = function (currentDate) {
    // Check to click on day in month mode, go to this day in day mode
    if (vm.currentView === "Month") {
      vm.start = currentDate;
      vm.end = moment(currentDate, "YYYYMMDD").add(1, "days");
      vm.isGoToDate = true;

      vm.changeView("agendaDay", "calendar", "Day");
    }
  };

  // Update start/end/date when select time on calendar
  vm.startSelectedTime = function (start, end) {
    // Disabled for month mode
    if (vm.currentView === "Month") {
      return;
    }

    var currentTime = TimeZoneService.currentTime(),
      startTime = TimeZoneService.calendarSelectedTime(start);

    var availableMonths = APP_CONSTANT.availableMonths;

    if (
      startTime > moment(currentTime).add(availableMonths, "months").valueOf()
    ) {
      CommonModals.openBaseModal(
        "Time must be in " + availableMonths + " months."
      );
    } else if (currentTime <= startTime) {
      vm.addAppointment(start, end);
    } else {
      CommonModals.openBaseModal("Time must be in the future.");
    }
  };

  vm.addAppointment = function (start, end) {
    var startTime = moment(moment(start).format("YYYY-MM-DD HH:mm"));
    var endTime = moment(moment(end).format("YYYY-MM-DD HH:mm"));

    // Implement suggestion location based on avaialabilities time range
    var suggestLocation = null,
      date = moment(start).format("DD/MM/YYYY");
    if (vm.locationId) {
      // Using current calendar location
      suggestLocation = _.where(vm.locations, { id: vm.locationId });
    } else {
      // Suggest location based on availabilities settings
      _.each(availDataRes, function (avail) {
        var availabilityStart = TimeZoneService.availabilityTimeFormat(
          avail.start_time
        );
        var availabilityEnd = TimeZoneService.availabilityTimeFormat(
          avail.end_time
        );

        var availRange = moment.range(availabilityStart, availabilityEnd);
        if (
          availRange.contains(startTime) &&
          availRange.contains(endTime) &&
          !avail.off
        ) {
          suggestLocation = _.where(vm.locations, { id: avail.location_id });
          return;
        }
      });
    }

    // Disable add appointment if Assistant is in ViewMode or all locations of Doctor are in ViewMode
    if (
      (suggestLocation && suggestLocation[0].inViewMode) ||
      vm.disableAddAppointment
    ) {
      return;
    }

    // Format times
    if (start) {
      start = moment(start).format("h:mm a");
    } else {
      start = "";
    }

    if (end) {
      end = moment(end).format("h:mm a");
    } else {
      end = "";
    }

    var modalInstance = $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            start: start,
            end: end,
            date: date,
            locations: vm.locations,
            currentLocation: suggestLocation,
            eventSources: vm.eventSources,
            apptTimeoff: vm.apptTimeoff,
            availDataRes: availDataRes,
            calendar: uiCalendarConfig.calendars.calendar,
            doctorId: $scope.doctorIdParam || null,
          };
        },
      },
      templateUrl: "calendar/appointments/views/create-appointment-modal.html",
      controller: require("./create-appointment.controller"),
      controllerAs: "createAppt",
      windowClass: "modal-info modal-info--tabs modal-info--appointment",
    });

    modalInstance.result.then(
      function (data) {
        console.log(data);
      },
      function () {
        console.log(vm.eventSources);
      }
    );
    console.log("Add appointment");
  };

  // Calendar configurations
  console.log(defaultDate);
  var clearAllEventResources = function () {
    // Clean all events before render new one
    _.each(vm.eventSources, function (events, idx) {
      var len = events.events.length;
      for (var i = 0; i < len; i++) {
        uiCalendarConfig.calendars.calendar.fullCalendar(
          "removeEvents",
          vm.eventSources[idx].events[i].id
        );
        len--;
      }
    });
  };
  var debouceGetAvailabilities = _.debounce(function () {
    clearAllEventResources();
    var redirectFromAlert = !!$stateParams.appointment_time;
    vm.getAvailabities(
      vm.start.format("YYYYMMDD"),
      vm.end.format("YYYYMMDD"),
      vm.locationId,
      $scope.doctorIdParam,
      redirectFromAlert
    );
  }, 500);
  vm.uiConfig = {
    calendar: {
      height: 500,
      timeFormat: "h:mm a",
      scrollTime: "08:00:00",
      slotDuration: "00:15:00",
      allDaySlot: false,
      defaultView: calendarType || "agendaWeek",
      defaultDate: defaultDate,
      weekMode: "liquid",
      firstDay: 1,
      header: {
        left: "prev,title,next",
        center: "",
        right: "",
      },
      views: {
        month: {
          titleFormat: "DD MMM YYYY",
        },
        day: {
          titleFormat: "DD MMM YYYY",
        },
        week: {
          titleFormat: "DD MMM YYYY",
        },
      },
      eventBackgroundColor: "rgba(0,0,0,0.8)",
      eventTextColor: "#171717",
      eventBorderColor: "transparent",
      selectable: true,
      selectHelper: true,
      editable: true,
      eventOverlap: function (stillEvent, movingEvent) {
        console.log(stillEvent, movingEvent);
      },
      eventReceive: function () {},
      selectOverlap: function (event) {
        console.log("Duplicated time range");
      },
      slotEventOverlap: false,
      select: vm.startSelectedTime,
      eventStartEditable: false,
      eventDurationEditable: false,
      disableResizing: true,
      eventLimit: 5,
      eventClick: function (appointmentData) {
        vm.checkTypeOfEvent(appointmentData);
      },
      eventRender: function () {},
      viewRender: function (view, element) {
        if (!vm.isGoToDate) {
          vm.start = view.intervalStart;
          vm.end = view.intervalEnd;
        }

        // Reset is go to date
        vm.isGoToDate = false;
        debouceGetAvailabilities();
      },
      dayClick: function (dayData) {
        vm.dayClickEvent(dayData);
      },
      eventResize: vm.alertOnResize,
    },
  };

  // Check type of event
  vm.checkTypeOfEvent = function (appointmentData) {
    if (
      appointmentData.typeOfEvent === "bookdoc" ||
      appointmentData.typeOfEvent === "webapp" ||
      appointmentData.typeOfEvent === "noshow"
    ) {
      vm.showAppointmentDetailsPopup(appointmentData);
    } else {
      vm.showTimeOffPopup(appointmentData);
    }
  };

  // Show appointment details popup
  vm.showAppointmentDetailsPopup = function (appointmentData) {
    if (appointmentData.reminder) {
      var reminderTime = TimeZoneService.reminderTimeStamp(
        appointmentData.reminder
      );
    }

    var currentTime = TimeZoneService.currentTime();
    var invalidAppt = false,
      canCancel = false,
      canSetCalendar = false,
      isSetCalendar = !!appointmentData.google_event_id,
      noShow = appointmentData.hidden,
      calendarTooltip = "Add to Google Calendar",
      isEnableIssueMC =
        !appointmentData.hidden &&
        !!appointmentData.pay_by_employer &&
        !appointmentData.medical_certification &&
        appointmentData.isBookedForMe;

    if (currentTime < appointmentData.time * 1000) {
      invalidAppt = true;
      canCancel = true;
      noShow = true;
      isEnableIssueMC = false;
      canSetCalendar = true;
    }

    if (appointmentData.virtual) {
      canCancel = true;
    }

    if (isSetCalendar) {
      calendarTooltip = "Added to Google Calendar";
    }

    if (appointmentData.hidden) {
      noShow = true;
    }

    var modalInstance = $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            apptData: appointmentData,
            invalidAppt: invalidAppt,
            canCancel: canCancel,
            noShow: noShow,
            apptId: appointmentData.id,
            eventSources: vm.eventSources,
            calendar: uiCalendarConfig.calendars.calendar,
            canSetCalendar: canSetCalendar,
            isSetCalendar: isSetCalendar,
            remainingTime: appointmentData.time * 1000 - currentTime,
            reminderCountDown: appointmentData.reminder
              ? reminderTime - currentTime
              : null,
            isEnableIssueMC: isEnableIssueMC,
            calendarTooltip: calendarTooltip,
            specialties: specialties,
            practiceLocationIdParam: $scope.practiceLocationIdParam || null,
            doctorIdParam: $scope.doctorIdParam || null,
            locations: vm.locations,
            calendar_sent: appointmentData.calendar_sent,
            email: appointmentData.email,
            description: appointmentData.description,
            rescheduleCallback: debouceGetAvailabilities,
          };
        },
      },
      templateUrl: "calendar/appointments/views/appointment-detail-popup.html",
      controller: require("./detail-appointment.controller"),
      controllerAs: "detailAppt",
      windowClass: "appointment-detail modal-appoint-info",
    });

    modalInstance.result.then(
      function (data) {},
      function () {}
    );
  };

  // Show time off popup
  vm.showTimeOffPopup = function (appointmentData) {
    var currentLocation = null;
    if (vm.locationId) {
      currentLocation = _.where(vm.locations, { id: vm.locationId });
    }

    vm.timeOffStart = moment(appointmentData.start).format("h:mm a");
    vm.timeOffEnd = moment(appointmentData.end).format("h:mm a");
    vm.timeOffDate = moment(appointmentData.start).format("DD/MM/YYYY");

    var modalInstance = $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            start: vm.timeOffStart,
            end: vm.timeOffEnd,
            date: vm.timeOffDate,
            id: appointmentData.id,
            eventSources: vm.eventSources,
            apptData: appointmentData,
            calendar: uiCalendarConfig.calendars.calendar,
            currentLocation: currentLocation,
            availDataRes: availDataRes,
            doctorId: $scope.doctorIdParam || null,
          };
        },
      },
      templateUrl: "calendar/appointments/views/time-off-popup.html",
      controller: require("./create-appointment.controller"),
      controllerAs: "timeOff",
      windowClass: "modal-info appointment-detail modal-appoint-info",
    });

    modalInstance.result.then(
      function (data) {},
      function () {}
    );
  };

  vm.getAvailabities = function (
    start,
    end,
    location_id,
    doctor_id,
    redirectFromAlert
  ) {
    // show indicator
    $rootScope.showIndicator = true;
    // Clear all events before render new
    vm.eventSources.splice(0, vm.eventSources.length);

    var notAvailableTimes = {
        color: "#dadada",
        textColor: "#171717",
        events: [],
      },
      timeOffEvents = {
        color: "transparent",
        textColor: "#171717",
        events: [],
      };
    // Update end time value, it depends on date not time
    end = moment(end, "YYYYMMDD").add(-1, "days").format("YYYYMMDD");

    // using timeout to make sure vm.currentView been updated
    $timeout(function () {
      cachingQueryParams(
        start,
        end,
        vm.currentView,
        location_id,
        doctor_id,
        redirectFromAlert
      );
    });

    availabilitiesApiService
      .getByWeek(
        start,
        end,
        location_id,
        doctor_id,
        $scope.practiceLocationIdParam
      )
      .then(
        function (res) {
          availDataRes = res;
          vm.collectDates = {};
          var resByLocation = res;
          if (vm.locationId) {
            resByLocation = _.filter(resByLocation, function (avail) {
              return avail.location_id === vm.locationId;
            });
          }

          // Update date base on timezone setting
          resByLocation = formatDataService.updateDateBasedOnTimezone(
            resByLocation
          );

          _.map(_.groupBy(resByLocation, "date"), function (avails) {
            avails = _.sortBy(avails, function (a, b) {
              return new Date(a.start_time);
            });

            var len = avails.length;
            vm.collectDates[avails[0].date] = true;

            // Implemented if no availabilities in a day
            var collectAvails = _.filter(avails, function (item) {
              return item.off === false;
            });

            if (
              collectAvails.length === 0 &&
              avails.length >= 1 &&
              avails[0].off
            ) {
              var _startDate = TimeZoneService.defaultStartDate(avails[0].date),
                _startVl = TimeZoneService.unixTimeStamp(_startDate),
                _nextDate = moment(avails[0].date).add(1, "days"),
                _endDate = TimeZoneService.defaultStartDate(_nextDate);
              notAvailableTimes.events.push({
                start: _startDate,
                end: _endDate,
                rendering: "background",
                location_id: avails[0].location_id,
                typeOfEvent: "notavailable",
              });
            }

            for (var i = 0; i < len; i++) {
              // Create first/last time of date
              var startDate = TimeZoneService.defaultStartDate(avails[i].date),
                startVl = TimeZoneService.unixTimeStamp(startDate),
                nextDate = moment(avails[i].date).add(1, "days"),
                endDate = TimeZoneService.defaultStartDate(nextDate);
              // Define Not available event
              var notAvail = {
                rendering: "background",
                isShowed: "",
                typeOfEvent: "notavailable",
              };

              if (avails[i].off) {
                var timeoff = {
                  location_id: avails[i].location_id,
                  start: TimeZoneService.renderCalendar(avails[i].start_time),
                  end: TimeZoneService.renderCalendar(avails[i].end_time),
                  typeOfEvent: "timeoff",
                  id: avails[i].id,
                  timeOffNote: avails[i].note,
                };
                if (location_id) {
                  if (avails[i].location_id === location_id) {
                    timeoff.isShowed = "";
                  } else {
                    timeoff.isShowed = "isHidden";
                  }
                }
                timeOffEvents.events.push(timeoff);
              } else {
                // Upadte availability to Not available when difference location
                if (location_id && avails[i].location_id !== location_id) {
                  var otherAvailability = angular.copy(notAvail);
                  otherAvailability.start = TimeZoneService.renderCalendar(
                    avails[i].start_time
                  );
                  otherAvailability.end = TimeZoneService.renderCalendar(
                    avails[i].end_time
                  );
                  otherAvailability.isShowed = "";
                  notAvailableTimes.events.push(otherAvailability);
                }

                var idx = _.findIndex(avails, function (avail) {
                  return avail.off === false;
                });
                if (i === idx && startVl < avails[idx].start_time) {
                  var starttimeNotAvail = angular.copy(notAvail);
                  starttimeNotAvail.start = startDate;
                  starttimeNotAvail.end = TimeZoneService.renderCalendar(
                    avails[idx].start_time
                  );
                  notAvailableTimes.events.push(starttimeNotAvail);
                }

                if (i < len - 1) {
                  // Checking in case next availability is time off
                  var nextAvail = {};
                  for (var j = i + 1; j < len; j++) {
                    if (!avails[j].off) {
                      nextAvail = avails[j];
                      break;
                    }
                  }
                  if (nextAvail && nextAvail.off === false) {
                    var _notAvail = angular.copy(notAvail);
                    _notAvail.start = TimeZoneService.renderCalendar(
                      avails[i].end_time
                    );
                    _notAvail.end = TimeZoneService.renderCalendar(
                      nextAvail.start_time
                    );
                    if (_notAvail.start !== _notAvail.end) {
                      notAvailableTimes.events.push(_notAvail);
                    }
                  }
                }
              }

              if (i === len - 1) {
                // Find last index of availabilities
                var lastIdx = _.findLastIndex(avails, "off", false);

                if (lastIdx !== -1) {
                  var endtimeNotAvail = angular.copy(notAvail);
                  endtimeNotAvail.start = TimeZoneService.renderCalendar(
                    avails[lastIdx].end_time
                  );
                  endtimeNotAvail.end = endDate;
                  notAvailableTimes.events.push(endtimeNotAvail);
                }
              }
            }
          });
        },
        function (error) {
          CommonModals.showInvalidPracticeLocationModal(
            error,
            !!$scope.doctorIdParam
          );
        }
      )
      .then(function () {
        // Implemented missed dates to render Not Available time
        var firstDate = moment(vm.start).format("YYYY-MM-DD"),
          lastDate = moment(vm.end).format("YYYY-MM-DD"),
          // Get total of Dates on current time range
          total =
            (moment(lastDate).valueOf() - moment(firstDate).valueOf()) /
            (60 * 60 * 24 * 1000);

        for (var i = 0; i < total; i++) {
          var date = moment(firstDate).add(i, "days").format("YYYY-MM-DD");
          if (!vm.collectDates[date]) {
            // Create Not available start/end time
            var startDate = TimeZoneService.defaultStartDate(date),
              startVl = TimeZoneService.unixTimeStamp(startDate),
              nextDate = moment(date).add(1, "days"),
              endDate = TimeZoneService.defaultStartDate(nextDate);

            // Pushed Not available times to calendar
            notAvailableTimes.events.push({
              start: startDate,
              end: endDate,
              rendering: "background",
              typeOfEvent: "notavailable",
            });
          }
        }
      })
      .then(function () {
        // Get appointment & render on calendar
        if (vm.start.format("YYYYMMDD") === start) {
          vm.getAppointmentsByTime(
            start,
            end,
            location_id,
            timeOffEvents,
            notAvailableTimes,
            doctor_id
          );
        }
      });
  };

  vm.getAppointmentsByTime = function (
    start,
    end,
    location_id,
    timeOffEvents,
    notAvailableTimes,
    doctor_id
  ) {
    // Helper function to get fullname
    function getFullname(user) {
      // Check if user is not a object
      user = user || {};
      return [user.first_name, user.last_name].join(" ").trim();
    }

    // set default event duration is 15 mins
    var eventDuration = 15 * 60;
    appointmentApiService
      .getAppointments(start, end, location_id, doctor_id)
      .then(
        function (data) {
          var bookdocEventBg = "#c6c3fa",
            doctorEventBg = "#C3F9D0",
            eventTxtColor = "#171717",
            noShowEventBg = "#F9625D",
            doctorEventMonthTxtColor = "#3eb95d",
            bookdocEventMonthTxtColor = "#5a54c1",
            noShowEventMonthTxtColor = "#F9625D",
            corpEventBg = "#289fd1";

          var len = data.length,
            // Define events types
            doctorEvents = {
              color: doctorEventBg,
              textColor: eventTxtColor,
              monthColor: doctorEventMonthTxtColor,
              events: [],
            },
            bookdocEvents = {
              color: bookdocEventBg,
              textColor: eventTxtColor,
              monthColor: bookdocEventMonthTxtColor,
              corpColor: corpEventBg,
              events: [],
            },
            noShowEvents = {
              color: noShowEventBg,
              textColor: eventTxtColor,
              monthColor: noShowEventMonthTxtColor,
              events: [],
            };

          if (len === 0) {
            // push doctor appt & bookdoc appt to calendar
            vm.eventSources.push(
              doctorEvents,
              bookdocEvents,
              noShowEvents,
              timeOffEvents,
              notAvailableTimes
            );
          } else {
            for (var i = 0; i < len; i++) {
              var insurance = "",
                employer = "";

              if (data[i].patient) {
                insurance = data[i].patient.insurance;
                employer = data[i].patient.employer;
              } else if (data[i].visitor && data[i].insurance) {
                insurance = data[i].insurance.name;
              }

              if (data[i].employer) {
                employer = data[i].employer.name;
              }

              if (data[i].cancel_by !== "user") {
                var endTime = data[i].endtime;
                var appt = {
                  id: data[i].id,
                  start: TimeZoneService.renderCalendar(data[i].time),
                  end: TimeZoneService.renderCalendar(endTime),
                  appointment_type: data[i].appointment_type,
                  insurance: insurance,
                  employer: employer,
                  hidden: data[i].hidden,
                  reason: data[i].reason,
                  location: data[i].location,
                  time: data[i].time,
                  cancel_by: data[i].cancel_by,
                  reminder: data[i].remind_at,
                  employee: data[i].employee_info,
                  doctor: data[i].doctor,
                  google_event_id: data[i].google_event_id,
                  medical_certification: data[i].medical_certification,
                  pay_by_employer: data[i].pay_by_employer,
                  is_panelship: data[i].is_panelship,
                  seen_doctor: data[i].seen_doctor,
                  patient_address: isHomeNursingCare
                    ? data[i].patient_address
                    : null,
                  note: data[i].note,
                  doctor_note: data[i].doctor_note,
                  calendar_sent: data[i].calendar_sent,
                  email: data[i].email,
                  corporate: data[i].corporate,
                  description: data[i].description,
                  checkup_specific: data[i].checkup_specific,
                  checkup: data[i].checkup,
                  nric: data[i].nric,
                  virtual: data[i].virtual,
                  qbId: data[i].qb_id,
                  twilioSid: data[i].twilio_sid
                };
                if (location_id) {
                  if (appt.location.id === location_id) {
                    appt.isShowed = "";
                  } else {
                    appt.isShowed = "isHidden";
                  }
                }

                var timeoffLen = timeOffEvents.events.length;
                for (var j = 0; j < timeoffLen; j++) {
                  var timeOffStartVal =
                      moment(timeOffEvents.events[j].start).utc().valueOf() /
                      1000,
                    timeOffEndVal =
                      moment(timeOffEvents.events[j].end).utc().valueOf() /
                      1000;
                  if (
                    data[i].time === timeOffStartVal &&
                    data[i].endtime === timeOffEndVal &&
                    !data[i].cancel_by
                  ) {
                    appt.timeoffId = timeOffEvents.events[j].id;
                    timeOffEvents.events.splice(j, 1);
                    timeoffLen--;
                  }
                }

                if (data[i].hidden) {
                  // Change UI for no show event
                  appt.typeOfEvent = "noshow";

                  if (data[i].visitor && data[i].visitor.first_name) {
                    appt.title = getFullname(data[i].visitor);
                    appt.first_name = data[i].visitor.first_name;
                    appt.last_name = data[i].visitor.last_name;
                  } else {
                    data[i].user = data[i].user || data[i].patient;
                    appt.title = getFullname(data[i].user);
                    appt.first_name = data[i].user.first_name;
                    appt.last_name = data[i].user.last_name;
                  }
                  appt.phone = (data[i].user && data[i].user.phone) || "";
                  appt.email =
                    (data[i].visitor && data[i].visitor.email) ||
                    (data[i].user && data[i].user.email) ||
                    "";
                  noShowEvents.events.push(appt);
                } else {
                  if (data[i].cancel_by === "doctor") {
                  } else if (data[i].appointment_type === "portal") {
                    appt.typeOfEvent = "webapp";
                    appt.note = data[i].note;
                    appt.title = data[i].patient.name;
                    appt.first_name = data[i].patient.first_name;
                    appt.last_name = data[i].patient.last_name;
                    appt.phone = data[i].patient.phone;
                    doctorEvents.events.push(appt);
                  } else {
                    appt.note = data[i].note;
                    appt.typeOfEvent = "bookdoc";
                    appt.isBookedForMe =
                      data[i].visitor && data[i].visitor.relationship === "Me";
                    if (data[i].visitor && data[i].visitor.first_name) {
                      appt.title = getFullname(data[i].visitor);
                    } else {
                      appt.title = getFullname(data[i].user);
                    }

                    appt.email =
                      (data[i].visitor && data[i].visitor.email) ||
                      (data[i].user && data[i].user.email) ||
                      "";

                    if (data[i].user && data[i].user.phone) {
                      appt.phone = data[i].user.phone;
                    } else {
                      appt.phone = "";
                    }
                    bookdocEvents.events.push(appt);
                  }
                }
              }

              if (i === len - 1) {
                // push doctor appt & bookdoc appt to calendar
                vm.eventSources.push(
                  doctorEvents,
                  bookdocEvents,
                  noShowEvents,
                  timeOffEvents,
                  notAvailableTimes
                );
              }
            }
          }

          console.log(vm.eventSources);
        },
        function (error) {
          CommonModals.showInvalidPracticeLocationModal(
            error,
            !!$scope.doctorIdParam
          );
        }
      )

      .finally(function () {
        // hide indicator
        $rootScope.showIndicator = false;
        if ($stateParams && $stateParams.appointment_time) {
          // Find appointment id
          var appointmentId = appointmentService.findAppointmentId(
            $stateParams.appointment_time,
            vm.eventSources
          );
          // Open detail appointment pop-up
          if (appointmentId) {
            appointmentService.openDetailAppointmentModal(appointmentId);
            $stateParams.appointment_time = null;
          }
        }
      });
  };

  vm.changeLocation = function (e, locationId) {
    // clear all current resources
    vm.locationId = locationId;

    clearAllEventResources();
    // Update dropdown value
    var repeatVal = e.target.attributes.data.value;

    vm.getAvailabities(
      moment(vm.start).format("YYYYMMDD"),
      moment(vm.end).format("YYYYMMDD"),
      locationId,
      $scope.doctorIdParam
    );
    vm.currentLocation = $scope.doctorIdParam ? vm.currentLocation : repeatVal;
    handleDisableAddAppointment(vm.locationId);
  };

  // Handle Disable Add Appointment button
  function handleDisableAddAppointment(locationId) {
    if (locationId) {
      vm.disableAddAppointment = LockMechanismService.checkLocationInViewMode(
        $rootScope.profiles.locations,
        locationId
      );
    } else {
      vm.disableAddAppointment = LockMechanismService.checkAllLocationInViewMode(
        !!$scope.practiceLocationIdParam,
        $rootScope.profiles.locations,
        $scope.practiceLocationIdParam
      );
    }
  }
};

AppointmentsCtrl.$inject = [
  "$scope",
  "$timeout",
  "$stateParams",
  "$location",
  "DataTimeService",
  "uiCalendarConfig",
  "appointment.api.service",
  "moment",
  "$cookieStore",
  "$modal",
  "bookdoc.availabilities.api",
  "CommonModals",
  "$rootScope",
  "LockMechanismService",
  "appointmentService",
  "TimeZoneService",
  "bookdoc.availabilities.formatDataService",
  "APP_CONSTANT",
];

module.exports = AppointmentsCtrl;
