"use strict";

var ebService = function (restangular, $http) {
  var service = {
    getPreRegistrationsList: getPreRegistrationsList,
    confirmVisitAttendance: confirmVisitAttendance,
    getClaimsList: getClaimsList,
    getClaimAutoCompleteList: getClaimAutoCompleteList,
    submitClaim: submitClaim
  };

  function getPreRegistrationsList(page, per_page, successCallback, errorCallback) {
    var params = {
      page: page,
      per: per_page
    };
    restangular.all("/eb/dr/visits")
      .customGET('', params)
      .then(function (visits) {
        successCallback(visits);
      }, function (error) {
        errorCallback(error);
      });
  }

  function confirmVisitAttendance(visitId,
                                  visitSecretNo,
                                  successCallback,
                                  errorCallback) {
    var body = {
      visit: {
        visit_no: visitSecretNo
      }
    };
    restangular
      .one('/eb/dr/visits', visitId)
      .customPUT(body, null)
      .then(
        function (response) {
          successCallback(response);
        }, function (error) {
          errorCallback(error);
        }
      );
  }

  function getClaimsList(page, per_page, filter, successCallback, errorCallback) {
    var params = {
      page: page,
      per: per_page,
    };

    if (filter != null) {
      params.filter = filter;
    }

    restangular.all("/eb/dr/claims")
      .customGET('', params)
      .then(function (visits) {
        successCallback(visits);
      }, function (error) {
        errorCallback(error);
      });
  }

  function getClaimAutoCompleteList(query, claim_id, association_type) {
    var params = {
      page: 1,
      per: 30,
      association_type: association_type,
      query: query
    };

    return restangular.all("/eb/dr/claims/" + claim_id + "/search")
      .customGET('', params);
  }

  function submitClaim(claim_id, claimRequest, successCallback, errorCallback) {
    restangular
      .one('/eb/dr/claims', claim_id)
      .customPUT(claimRequest, null)
      .then(
        function (response) {
          successCallback(response);
        }, function (error) {
          errorCallback(error);
        }
      );
  }

  return service;
};


ebService.$inject = ['Restangular', '$http'];

module.exports = ebService;
