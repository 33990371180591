"use strict";

function SubmittedClaimServiceItemCtrl() {
  var ctrl = this;
}

var SubmittedClaimServiceItemComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/submitted-claim-service-item.html',
    controller: SubmittedClaimServiceItemCtrl,
    bindings: {
      service: '<',
    }
  };
};

SubmittedClaimServiceItemComponent.$inject = [];

module.exports = SubmittedClaimServiceItemComponent();
