"use strict";
var EXIF = require("exif-js");

var onImageLoad = function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.bind('load', function () {
        EXIF.getData(element[0], function () {
          var orientation = EXIF.getTag(this, "Orientation");
          checkIfImageNeedToBeRotatedOrNot(element[0], orientation);
        });
      });

      function checkIfImageNeedToBeRotatedOrNot(img, orientation) {
        if (orientation === 6) {
          img.style.transform = "rotate(90deg)";
        } else if (orientation === 8) {
          img.style.transform = "rotate(-90deg)";
        } else if (orientation === 3) {
          img.style.transform = "rotate(180deg)";
        }
      }
    }
  };
};

module.exports = onImageLoad;

