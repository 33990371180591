'use strict';

var states = function ($stateProvider) {
  $stateProvider
    .state('main.calendar.appointments', {
      url: 'appointments/?start&end&location_id&on&appointment_time',
      reloadOnSearch: false,
      views: {
        'appointments': {
          templateUrl: 'calendar/appointments/views/appointments.html',
          controller: 'AppointmentsCtrl',
          controllerAs: 'appts'
        }
      }
    })

    // Define state for grouping appointment
    .state('main.groupingCalendar.appointments', {
      url: 'appointments/?start&end&location_id&on&appointment_time',
      reloadOnSearch: false,
      views: {
        'appointments': {
          templateUrl: 'calendar/appointments/views/appointments.html',
          controller: 'AppointmentsCtrl',
          controllerAs: 'appts'
        }
      }
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.calendar.appointments')
  .config(states);
