/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.alerts.services',
    [
      'ngResource'
    ]
  )
  .factory('ALERTS_API_SERVICE', require('./alerts.api'))
  .factory('NotificationSoundService', require('./notification-sound.service'))
  .factory('AlertService', require('./alert.service'));