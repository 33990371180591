'use strict';

module.exports =
  angular
    .module('bookdoc.dashboard.components', [
      require('../../../../tmp/templates').name,
      require('../../common').name
    ])
    .component('review', require('./review.component'))
    .component('reviews', require('./reviews.component'));
