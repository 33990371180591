'use strict';

var operatingHoursDirective = function(OperatingHour, moment, OperatingValidation) {
  return {
    restrict: 'E',
    templateUrl: 'common/views/operating-hours.html',
    scope: {
      dayData: '=dayData',
      operatingHours: '=operatingHours',
      operatingError: '= operatingError'
    },
    link: function(scope, elem, attrs) {

      // Define minutes data
      scope.operatingMinutesData = OperatingHour.operatingMinutes;

      OperatingHour.formatHourData(scope.dayData);

      scope.isBreakTime = scope.dayData.day_of_week === 'lunch_break' || scope.dayData.day_of_week === 'dinner_break';

      scope.operatingTimeStart = !scope.isBreakTime ? (scope.dayData.operatingHourStart || "08") : (scope.dayData.day_of_week === 'lunch_break' ? scope.dayData.operatingHourStart || "12" : scope.dayData.operatingHourStart || "05");
      scope.operatingTimeEnd = !scope.isBreakTime ? (scope.dayData.operatingHourEnd || "05") : ( scope.dayData.day_of_week === 'lunch_break' ? scope.dayData.operatingHourEnd || "01" : scope.dayData.operatingHourEnd || "06");

      scope.operatingMinuteStart = scope.dayData.operatingMinuteStart || "00";
      scope.operatingMinuteEnd = scope.dayData.operatingMinuteEnd || "00";

      scope.operatingTypeHoursStart = !scope.isBreakTime ? (scope.dayData.operatingTypeHoursStart || "AM") : (scope.dayData.operatingTypeHoursStart || "PM");
      scope.operatingTypeHoursEnd = scope.dayData.operatingTypeHoursEnd || "PM";

      scope.isShow = scope.dayData.isOpen;

      scope.hoursData = OperatingHour.operatingHour;
      scope.timeIsValid = true;

      // handle when click change on-off
      scope.changeOnOff = function(isOpen) {
        scope.isShow = isOpen;
        if (!isOpen) {
          scope.timeIsValid = true;
          scope.dayData.isValid = true;
          scope.dayData.open_time =  "";
          scope.dayData.close_time = "";

          if (scope.isBreakTime) {
            scope.operatingError.isOverlapBreakTime = false;
          }
        } else {
          scope.checkIsValidTime();
        }
        scope.$emit('changeOperating');
      };
      
      // Check time
      scope.checkIsValidTime = function() {

        scope.startHour = scope.operatingTimeStart + ':' + scope.operatingMinuteStart + ' ' + scope.operatingTypeHoursStart;

        scope.endHour = scope.operatingTimeEnd + ':' + scope.operatingMinuteEnd + ' ' + scope.operatingTypeHoursEnd;

        scope.checkTime = OperatingValidation.timeIsValid(scope.startHour, scope.endHour, scope.dayData.day_of_week, scope.operatingHours);

        scope.timeIsValid = scope.checkTime.isValid;
        scope.dayData.isValid = scope.timeIsValid;
        
        // Check overlap when edit break time
        if (scope.isBreakTime) {
          scope.operatingError.isOverlapBreakTime = scope.checkTime.isOverlapBreakTime;
        }

        if (scope.timeIsValid) { 
          scope.dayData.open_time =  scope.checkTime.startHour;
          scope.dayData.close_time = scope.checkTime.endHour;
        } else {
          scope.dayData.open_time =  "";
          scope.dayData.close_time = "";
        }

        scope.$emit('changeOperating');
      };

      scope.checkIsValidTime();

      // choose operating start time
      scope.chooseTimeStart = function(time) {
        scope.operatingTimeStart = time;
        scope.checkIsValidTime();
      };

      // choose operating end time
      scope.chooseTimeEnd = function(time) {
        scope.operatingTimeEnd = time;
        scope.checkIsValidTime();
      };

      // choose minute Start
      scope.chooseMinuteStart = function(minute) {
        scope.operatingMinuteStart = minute;
        scope.checkIsValidTime();
      };

      // choose minute End
      scope.chooseMinuteEnd = function(minute) {
        scope.operatingMinuteEnd = minute;
        scope.checkIsValidTime();
      };

      // choose type of hours for start time (AM or PM)
      scope.chooseTypeHourStart = function(type) {
        scope.operatingTypeHoursStart = type;
        scope.checkIsValidTime();
      };

      // choose type of hours for end time (AM or PM)
      scope.chooseTypeHourEnd = function(type) {
        scope.operatingTypeHoursEnd = type;
        scope.checkIsValidTime();
      };
    }
  };
};

operatingHoursDirective.$inject = [
  'OperatingHour',
  'moment',
  'OperatingValidation'
];

module.exports = operatingHoursDirective;
