"use strict";

function ServiceFormItemCtrl(ebService) {
  var ctrl = this;
  ctrl.feeRegex = "\\d+(\\.\\d{1,2})?";

  ctrl.$onInit = function () {
    if (!ctrl.validateServiceObsberver)
      ctrl.validateServiceObsberver = ctrl.validateServiceSubject.subscribe(function () {
        validateService();
      });
  };

  function validateService() {
    ctrl.name_has_error = !ctrl.service.service_id;
    ctrl.fee_has_error = !ctrl.service.fee || ctrl.service.fee < 0;
  }

  ctrl.onRemoveServiceClicked = function () {
    ctrl.validateServiceObsberver.dispose();
    ctrl.onRemoveBtnClicked({index: ctrl.index});
  };

  ctrl.onServicePriceChanged = function () {
    if (ctrl.service.fee_limit)
      ctrl.service.feeExceededLimit = ctrl.service.fee > ctrl.service.fee_limit;
    ctrl.onServiceUpdated();
    validateService();
  };

  ctrl.getServicesList = function (query) {
    console.log("search query: " + query);
    console.log("banned: ", ctrl.bannedServices);

    return ebService
      .getClaimAutoCompleteList(query, ctrl.claim.id, "service")
      .then(function (response) {
        return response.result.filter(function (service) {
          return !ctrl.bannedServices.includes(service.name);
        });
      });
  };

  ctrl.onSelectService = function (item, model, label) {
    ctrl.service.service_id = item.id;
    ctrl.service.name = item.name;
    ctrl.service.fee_limit = item.fee_limit;
    console.log(item);
  };

  ctrl.onClearServiceName = function () {
    ctrl.service.service_id = null;
    ctrl.service.name = "";
    ctrl.service.fee_limit = null;
    if (ctrl.service.id)
      ctrl.service.hasBeenEdited = true;
  };
}

var ServiceFormItemComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/service-form-item.html',
    controller: ServiceFormItemCtrl,
    bindings: {
      service: '=',
      index: '<',
      claim: '<',
      bannedServices: '=',
      validateServiceSubject: '<',
      onRemoveBtnClicked: '&',
      onServiceUpdated: '&'
    }
  };
};

ServiceFormItemCtrl.$inject = ['ebService'];

module.exports = ServiceFormItemComponent();
