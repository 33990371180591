'use strict';


var latestApcDirective = function($rootScope, APP_CONSTANT, ValidationFileTypeService, RegistrationAPI, CommonModals, ValidateMessagesService, ChainRequestService) {
  return {
    restrict: 'E',
    scope: {
      apcID: '=apcId',
      signatureFormActive: '=signatureFormActive'
    },
    templateUrl: 'registration/views/latest-apc.html',
    link: function(scope, elem, attrs) {

      scope.documentFileFormat = APP_CONSTANT.documentFileFormat;

      scope.validateMessages = ValidateMessagesService;

      scope.invalidApc = {
        fileSize: false,
        wrongType: false
      };

      scope.apcFileName = '';

      scope.isUploading = false;

      scope.documentData = {};

      scope.uploadElement = angular.element('#uploadApc');

      // Handle when select file
      scope.changeFile = function(element) {
        var documentFile = element.files[0];
        if (!documentFile) {
          return;
        }
        var fileType = documentFile.type;
        var isValidType = ValidationFileTypeService.checkFileType(fileType, scope.documentFileFormat);

        scope.invalidApc.wrongType = isValidType;

        if (isValidType) {

          scope.$apply(function(scope) {

            scope.invalidApc.wrongType = false;

            scope.fileSize = documentFile.size;

            scope.invalidApc.fileSize = ValidationFileTypeService.checkFileSize(scope.fileSize);

            if (!scope.invalidApc.fileSize) {
              scope.documentData = documentFile;
              scope.isUploading = true;

              var chain = ChainRequestService.startQueue();

              if (scope.apcID) {
                // delete old document
                chain = chain.then(deleteAPC);
                chain = chain.then(function (data) {
                  handleDeleteSuccess();
                });
              }

              // upload document
              chain = chain.then(uploadAPC);
              chain = chain.then(function (data) {
                handleUploadSuccess(data);
                scope.apcFileName = documentFile.name;
              }, function (err) {
                CommonModals.openBaseModal("Server error!");
              });
            } else {

              scope.apcFileName = '';
            }

          });

        } else {
          scope.$apply(function(scope) {
            scope.apcFileName = '';
            scope.invalidApc.wrongType = true;
          });
        }
      };

      // Call service to upload APC
      function uploadAPC() {
        return RegistrationAPI.latestApc.addAPC(scope.documentData); 
      };

      // Call service to delete APC
      function deleteAPC() {
        return RegistrationAPI.latestApc.deleteAPC(scope.apcID); 
      };

      // Handle when upload success
      function handleUploadSuccess(data) {
        scope.apcID = data.id;
        scope.isUploading = false;
      };

      // Handle when delete success
      function handleDeleteSuccess() {
        scope.uploadElement.val(null);
        scope.apcFileName = '';
        scope.apcID = null;
      };

      // Delete APC document
      scope.deleteApcDocument = function() {
        var chain = ChainRequestService.startQueue();
        chain = chain.then(deleteAPC);
        chain = chain.then(function (data) {
          handleDeleteSuccess();
        });
      };

      scope.$on('validateLatestApcForm', function(event) {
        scope.latestApcForm.$setSubmitted();
        if (scope.latestApcForm.$valid) {
          $rootScope.$broadcast('sendRegistrationRequest');
        } else {
          scope.signatureFormActive = true;
        }
      });
    }
  };
};

latestApcDirective.$inject = [
  '$rootScope',
  'APP_CONSTANT',
  'ValidationFileTypeService',
  'RegistrationAPI',
  'CommonModals',
  'ValidateMessagesService',
  'ChainRequestService'
];

module.exports = latestApcDirective;
