'use strict';

module.exports =
  angular
    .module('bookdoc.alerts', [
      'ui.bootstrap',
      'ui.router',
      'restangular',
      'angularMoment',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('./services').name,
      require('./controllers').name
    ])

    // routes
    .config(require('./alerts.routes'))

    .constant('ALERTS_API_ROUTE', require('./alerts.constant'))
    .filter('dateUtc', require('./filters/filter.date-utc'))
    .filter('dateUnix', require('./filters/filter.date-unix'));

