'use strict';

var _ = require('lodash');

var LanguageService = function (ArrayService, BOOKDOC_PROFILE_API_ROUTE, InformationAPI, $q) {
  var obj = {

    processServiceLanguages: function(serviceLanguages) {
      var languages = [];

      // Check if services languages is falsy
      if (serviceLanguages && serviceLanguages !== "") {

        // Split data by "|"
        var tmpArray = serviceLanguages.split("|");

        // Create an array that contains language object {language:"abc"}
        _.forEach(tmpArray, function (item) {

          languages.push({
            name: item
          });

        });

      } else {

        // Add an empty element when service data is falsy
        // languages.push({
        //   language: ""
        // });

      }

      return languages;
    },

    capitalizeFirstLetter: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    formatLanguageRequest: function (request) {

      _.forEach(request, function (item) {
        item.name = obj.capitalizeFirstLetter(item.name);
      });

      // Remove empty choice
      request = ArrayService.removeEmptyElement(request, 'name');

      // Make request from language list that has format as a string "English|Vietnamese"
      var tmp = [];
      _.forEach(request, function (item) {

        tmp.push(item.name);

      });

      // Join request as format {languages:"English|Vietnamese"}
      request = {
        languages: tmp.join('|')
      };

      return request;

    },

    hasUpdated: function(languages, languagesBackupdata) {
      // delete isOpenDropdown property before check has updated languages
      _.forEach(languages, function (language) {
        delete language.isOpenDropdown;
      });

      var languagesNew = _.sortBy(languages, "name");
      var languagesBackup = _.sortBy(languagesBackupdata, "name");

      if (angular.equals(languagesNew, languagesBackup)) {
        return false;
      }

      return true;
    }
  };

  return obj;
};

LanguageService.$inject = [
  'ArrayService',
  'BOOKDOC_PROFILE_API_ROUTE',
  'bookdoc.information.api',
  '$q'
];

module.exports = LanguageService;
