'use strict';

var profileConstant = {
  doctors: 'doctors',
  specialties: 'specialties',
  avatars: 'avatars',
  qualifications: 'qualifications',
  memberships: 'memberships',
  insurances: 'insurances',
  // FIX ME: hide ability to add corporate from doctor portal
  // employers: 'employers',
  locations: 'locations',
  locationByUser: 'location',
  documents: 'documents',
  educations: 'educations',
  languages: 'languages',
  hospitals: 'hospitals',
  photos: 'photos',
  operatingHours: 'operating_hours',
  locationLisence: 'clinic_licenses',
  countries: 'countries',
  languagesList: [
    {code: 'Abkhazian', name: 'Abkhazian'},
    {code: 'Afar', name: 'Afar'},
    {code: 'Afrikaans', name: 'Afrikaans'},
    {code: 'Albanian', name: 'Albanian'},
    {code: 'Amharic', name: 'Amharic'},
    {code: 'Arabic', name: 'Arabic'},
    {code: 'Armenian', name: 'Armenian'},
    {code: 'Assamese', name: 'Assamese'},
    {code: 'Aymara', name: 'Aymara'},
    {code: 'Azerbaijani', name: 'Azerbaijani'},
    {code: 'Bashkir', name: 'Bashkir'},
    {code: 'Basque', name: 'Basque'},
    {code: 'Bengali', name: 'Bengali'},
    {code: 'Bhutani', name: 'Bhutani'},
    {code: 'Bihari', name: 'Bihari'},
    {code: 'Bulgarian', name: 'Bulgarian'},
    {code: 'Burmese', name: 'Burmese'},
    {code: 'Cambodian', name: 'Cambodian'},
    {code: 'Catalan', name: 'Catalan'},
    {code: 'Chinese', name: 'Chinese'},
    {code: 'Corsican', name: 'Corsican'},
    {code: 'Czech', name: 'Czech'},
    {code: 'Danish', name: 'Danish'},
    {code: 'Dutch', name: 'Dutch'},
    {code: 'English', name: 'English'},
    {code: 'Esperanto', name: 'Esperanto'},
    {code: 'Fiji', name: 'Fiji'},
    {code: 'French', name: 'French'},
    {code: 'Gaelic', name: 'Gaelic'},
    {code: 'Georgian', name: 'Georgian'},
    {code: 'German', name: 'German'},
    {code: 'Greek', name: 'Greek'},
    {code: 'Greenlandic', name: 'Greenlandic'},
    {code: 'Guarani', name: 'Gujarati'},
    {code: 'Hausa', name: 'Hausa'},
    {code: 'Hebrew', name: 'Hebrew'},
    {code: 'Hindi', name: 'Hindi'},
    {code: 'Hungarian', name: 'Hungarian'},
    {code: 'Icelandic', name: 'Icelandic'},
    {code: 'Indonesian', name: 'Indonesian'},
    {code: 'Interlingua', name: 'Interlingua'},
    {code: 'Interlingue', name: 'Interlingue'},
    {code: 'Inupiak', name: 'Inupiak'},
    {code: 'Irish', name: 'Irish'},
    {code: 'Italian', name: 'Italian'},
    {code: 'Japanese', name: 'Japanese'},
    {code: 'Javanese', name: 'Javanese'},
    {code: 'Kannada', name: 'Kannada'},
    {code: 'Kashmiri', name: 'Kashmiri'},
    {code: 'Kazakh', name: 'Kazakh'},
    {code: 'Kinyarwanda', name: 'Kinyarwanda'},
    {code: 'Korean', name: 'Korean'},
    {code: 'Kurdish', name: 'Kurdish'},
    {code: 'Laothian', name: 'Laothian'},
    {code: 'Latin', name: 'Latin'},
    {code: 'Lingala', name: 'Lingala'},
    {code: 'Macedonian', name: 'Macedonian'},
    {code: 'Malaysia', name: 'Malaysia'},
    {code: 'Mongolian', name: 'Mongolian'},
    {code: 'Nepali', name: 'Nepali'},
    {code: 'Mongolian', name: 'Mongolian'},
    {code: 'Persian', name: 'Persian'},
    {code: 'Polish', name: 'Polish'},
    {code: 'Portuguese', name: 'Portuguese'},
    {code: 'Romanian', name: 'Romanian'},
    {code: 'Russian', name: 'Russian'},
    {code: 'Samoan', name: 'Samoan'},
    {code: 'Sangro', name: 'Sangro'},
    {code: 'Serbian', name: 'Serbian'},
    {code: 'Shona', name: 'Shona'},
    {code: 'Slovak', name: 'Slovak'},
    {code: 'Slovenian', name: 'Slovenian'},
    {code: 'Somali', name: 'Somali'},
    {code: 'Spanish', name: 'Spanish'},
    {code: 'Tajik', name: 'Tajik'},
    {code: 'Thailand', name: 'Thailand'},
    {code: 'Turkish', name: 'Turkish'},
    {code: 'Turkmen', name: 'Turkmen'},
    {code: 'Ukrainian', name: 'Ukrainian'},
    {code: 'Urdu', name: 'Urdu'},
    {code: 'Vietnamese', name: 'Vietnamese'},
    {code: 'Yiddish', name: 'Yiddish'},
    {code: 'Yoruba', name: 'Yoruba'},
    {code: 'Zulu', name: 'Zulu'}
  ],
  pins: 'pins/doctor'
};

module.exports = profileConstant;
