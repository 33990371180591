/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.privacy.controllers',
    [
    ]
  )
  .controller('PrivacyCtrl', require('./privacy.controller'));
