/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.referral.refer.services',
    [
      'ngResource'
    ]
  )
  .factory('referApiService', require('./refer.api.service'))
  .factory('referService', require('./refer.service'));