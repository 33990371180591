"use strict";

function HealthInfoController(
  sessionDetails,
  chat,
  $modalInstance,
  $scope,
  moment
) {
  var ctrl = this;
  ctrl.page = 1;
  ctrl.startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
  ctrl.startDateOpen = false;
  ctrl.endDate = moment().format("YYYY-MM-DD");
  ctrl.endDateOpen = false;
  ctrl.sessionDetails = sessionDetails;
  ctrl.totalPages = 1;
  ctrl.historyInfo = [];

  ctrl.closeModal = function () {
    $modalInstance.dismiss();
  };

  ctrl.openStartDate = function () {
    ctrl.startDateOpen = true;
  };

  ctrl.openEndDate = function () {
    ctrl.endDateOpen = true;
  };

  ctrl.nextPage = function () {
    if (ctrl.page === ctrl.totalPages) return;
    ctrl.page = ctrl.page + 1;
  };

  ctrl.previousPage = function () {
    if (ctrl.page === 1) return;
    ctrl.page = ctrl.page - 1;
  };

  ctrl.parseDate = function (date) {
    return moment(date).format("DD.MM.YYYY");
  };

  var getChatHealth = function () {
    chat.getChatHealthInfoHistory(
      function (history) {
        ctrl.historyInfo = history.chat_health_infos;
        ctrl.totalPage = history.meta?.total_pages || 1;
      },
      function () {},
      ctrl.sessionDetails.id,
      ctrl.page,
      moment(ctrl.startDate).format("YYYY-MM-DD"),
      moment(ctrl.endDate).format("YYYY-MM-DD")
    );
  };

  $scope.$watch(
    angular.bind(ctrl, function () {
      return ctrl.startDate;
    }),
    function () {
      getChatHealth();
    }
  );

  $scope.$watch(
    angular.bind(ctrl, function () {
      return ctrl.endDate;
    }),
    function () {
      getChatHealth();
    }
  );

  $scope.$watch(
    angular.bind(ctrl, function () {
      return ctrl.page;
    }),
    function () {
      getChatHealth();
    }
  );
}

HealthInfoController.$inject = [
  "sessionDetails",
  "chatService",
  "$modalInstance",
  "$scope",
  "moment",
];

module.exports = HealthInfoController;
