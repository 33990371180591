'use strict';


var CreateFollowUpAppointmentCtrl = function ($rootScope, $scope, APP_CONSTANT, $modalInstance, AppointmentAPI, chatService, chatSessionId, videoSessionId, TimeZoneService, DataTimeService, appointmentCommonService, userInfo) {
  var vm = this;

  vm.userInfo = userInfo;
  vm.isVirtual = true;
  vm.rangeIsValid = true;
  vm.theTimeSelectedIsNotAvailable = false;
  vm.slots = [];
  vm.reasonForVisitList = [];
  vm.emptySlots = false;
  vm.newVideoSession = {
    date: new Date(),
    location: $rootScope.profiles.virtual_clinic_id,
    chatSessionId: chatSessionId,
    videoSessionId: videoSessionId,
    slot: {
      displayName: ''
    },
  };
  vm.locations = $rootScope.profiles.locations;
  vm.currentLocation = vm.locations[0];
  vm.newAppt = {
    date: new Date(),
    slot: {
      displayName: ''
    },
    location: vm.currentLocation,
    reason: null,
    note: ''
  };
  vm.isLoading = false;
  vm.isCollapsedApptNote = true;
  vm.charactersLimit = APP_CONSTANT.charactersLimit;
  vm.dataTimeDropdown = DataTimeService.dataTime;

  vm.closeModal = function () {
    $modalInstance.dismiss();
  }

  vm.changeSlot = function (slot) {
    vm.newVideoSession.slot = slot;
  }

  vm.changePhysicalSlot = function (slot) {
    vm.newAppt.slot = slot;
  }

  vm.createVirtualAppt = function () {
    vm.isLoading = true;
    chatService.createFollowUpVideoSession({
      chat_session_id: vm.newVideoSession.chatSessionId,
      video_session_id: vm.newVideoSession.videoSessionId,
      start_time: vm.newVideoSession.slot.start,
      end_time: vm.newVideoSession.slot.end,
    }, function () {
      $modalInstance.dismiss();
      vm.isLoading = false;
    }, function (error) {
      vm.isLoading = false;
      console.error(error);
    })
  };

  vm.changeLocation = function (e, location) {
    vm.currentLocation = location;
    vm.newAppt.location = location;
    getPanelShipReason(vm.currentLocation.id);
    vm.checkSelectedLocation();
  };

  function getPanelShipReason(practiceLocationId) {
    // Show indicator
    vm.isLoading = true;

    var requestParams = {
      practice_location_id: practiceLocationId
    };

    AppointmentAPI.getPanelShipReason(requestParams)
      .then(function (data) {
        vm.reasonForVisitList = data;
        if (vm.newAppt.reason) {
          var reasonIndex = _.findIndex(vm.reasonForVisitList, function (reason) {
            return reason.name === vm.newAppt.reason;
          });

          if (reasonIndex < 0) {
            vm.newAppt.reason = null;
          }
        }
      }, function (error) {
        console.log(error);
      }
      )
      .finally(function () {
        vm.isLoading = false;
      });
  };

  function checkRangeIsValid() {

    if (vm.newAppt.end && vm.newAppt.start && vm.newAppt.date) {
      vm.rangeIsValid = appointmentCommonService.rangeIsValid(vm.newAppt) && appointmentCommonService.timeIsInFuture(vm.newAppt);
    } else {
      vm.rangeIsValid = false;
    }

  }

  vm.changeStart = function (e) {

    // Update dropdown value
    var repeatVal = e.target.attributes.data.value;

    vm.newAppt.start = repeatVal;

    checkRangeIsValid();

  };

  vm.changeEnd = function (e) {

    // Update dropdown value
    var repeatVal = e.target.attributes.data.value;

    vm.newAppt.end = repeatVal;
    // vm.rangeIsValid = false;

    checkRangeIsValid();

  };

  vm.changeReason = function (e) {

    // Update dropdown value
    var repeatVal = e.target.attributes.data.value;

    // vm.currentReason = repeatVal;
    vm.newAppt.reason = repeatVal;

  };

  vm.createNewAppt = function () {
    vm.isLoading = true;
    var date = moment(vm.newAppt.date).format('YYYY/MM/DD'),
      startTime = TimeZoneService.timeToTimeStamp(date, moment.unix(vm.newAppt.slot.start).format("h:mm a")),
      endTime = TimeZoneService.timeToTimeStamp(date, moment.unix(vm.newAppt.slot.end).format("h:mm a"));
    AppointmentAPI.createFollowUpAppointment({
      start: startTime / 1000,
      end: endTime / 1000,
      date: TimeZoneService.appointmentDateFormat(date, moment.unix(vm.newAppt.slot.start).format("h:mm a")),
      location: vm.newAppt.location.id,
      reason: vm.newAppt.reason,
      doctor_id: null,
      user_id: vm.userInfo.user_id,
      note: vm.newAppt.note,
    }).then(function (response) {
      if (response.status === 442) {
        vm.theTimeSelectedIsNotAvailable = true;
        return;
      }
      $modalInstance.dismiss();
    }).catch(function () {
      vm.theTimeSelectedIsNotAvailable = true;
    }).finally(function () {
      vm.isLoading = false;
    });
  };

  getPanelShipReason(vm.currentLocation.id);

  function getAvailableSlots() {
    vm.isLoading = true;
    AppointmentAPI.getAvailableSlots(vm.newVideoSession.location, moment(vm.newVideoSession.date).format("YYYYMMDD")).then(function (res) {
      vm.slots = res.map(function (slot) {
        return {
          start: slot.start,
          end: slot.end,
          displayName: "" + moment.unix(slot.start - slot.offset).format("hh:mma") + " - " + moment.unix(slot.end - slot.offset).format("hh:mma"),
        };
      });
      vm.newVideoSession.slot = vm.slots[0] || { displayName: '' };
      vm.newAppt.slot = vm.slots[0] || { displayName: '' };
      if (vm.slots.length === 0) { vm.emptySlots = true } else { vm.emptySlots = false }
      vm.isLoading = false;
    });
  };

  function getPhysicalAvailableSlots() {
    vm.isLoading = true;
    AppointmentAPI.getAvailableSlots(vm.newAppt.location.id, moment(vm.newAppt.date).format("YYYYMMDD")).then(function (res) {
      vm.slots = res.map(function (slot) {
        return {
          start: slot.start,
          end: slot.end,
          displayName: "" + moment.unix(slot.start - slot.offset).format("hh:mma") + " - " + moment.unix(slot.end - slot.offset).format("hh:mma"),
        };
      });
      vm.newAppt.slot = vm.slots[0] || { displayName: '' };
      if (vm.slots.length === 0) { vm.emptySlots = true } else { vm.emptySlots = false }
      vm.isLoading = false;
    });
  };

  $scope.$watch(angular.bind(vm, function () {
    return vm.newVideoSession.date;
  }), function () {
    getAvailableSlots();
  });

  $scope.$watch(angular.bind(vm, function () {
    return vm.newAppt.date;
  }), function () {
    getPhysicalAvailableSlots();
  });

  vm.toggleMin = function () {
    vm.minDate = vm.minDate ? null : new Date();
  };

  vm.toggleMin();

  vm.maxDate = new Date(moment(moment(new Date())).add(APP_CONSTANT.availableMonths, 'months'));

  vm.showButtonBar = false;

  vm.open = function ($event) {
    vm.status.opened = true;
  };

  vm.status = {
    opened: false
  };
  checkRangeIsValid();
};

CreateFollowUpAppointmentCtrl.$inject = [
  '$rootScope',
  '$scope',
  'APP_CONSTANT',
  '$modalInstance',
  'appointment.api.service',
  'chatService',
  'chatSessionId',
  'videoSessionId',
  'TimeZoneService',
  'DataTimeService',
  'appointment.common.service',
  'userInfo'
];

module.exports = CreateFollowUpAppointmentCtrl;
