/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.referral.refer.controllers',
    [
      'restangular'
    ]
  )
  .controller('ReferToCtrl', require('./refer-to.controller'));