"use strict";

function ImageModalController(modalInstance, ImageUrl) {
  var ctrl = this;

  ctrl.imageUrl = ImageUrl;

  ctrl.closeModal = function () {
    modalInstance.dismiss();
  };
}

ImageModalController.$inject = ["$modalInstance", "ImageUrl"];

module.exports = ImageModalController;
