/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.audits.services',
    [
      'ngResource'
    ]
  )
  .factory('AUDITS_API_SERVICE', require('./audits.api'))
  .factory('AuditsFormatMessage', require('./audits.format.message.service'));
