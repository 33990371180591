"use strict";

var quillService = function ($rootScope) {
  var service = {
    init: init,
    boldItalic: boldItalic,
    normal: normal,
    bold: bold,
    italic: italic,
    alignRight: alignRight,
    alignCenter: alignCenter,
    alignLeft: alignLeft,
    alignJustify: alignJustify,
    getSaveableNotes: getSaveableNotes,
    setContent: setContent,
    getFormatType: getFormatType,
    NORMAL_FONT: 'normal',
    ITALIC_FONT: 'normal-italic',
    BOLD_FONT: 'bold',
    BOLD_ITALIC_FONT: 'bold-italic'
  };

  function init() {
    var options = {
      debug: "info",
      modules: {
        toolbar: false,
      },
      placeholder: "",
    }
    if (document.querySelector("#editor")) {
      window.editor = new window.Quill("#editor", options);
    } else {
      setTimeout(function () {
        init();
      }, 1000);
    }
  }

  function boldItalic() {
    window.editor.formatText(0, window.editor.getText().length, 'bold', true);
    window.editor.formatText(0, window.editor.getText().length, 'italic', true);
  }

  function normal() {
    window.editor.formatText(0, window.editor.getText().length, 'bold', false);
    window.editor.formatText(0, window.editor.getText().length, 'italic', false);
  }

  function bold() {
    window.editor.formatText(0, window.editor.getText().length, 'bold', true);
    window.editor.formatText(0, window.editor.getText().length, 'italic', false);
  }

  function italic() {
    window.editor.formatText(0, window.editor.getText().length, 'bold', false);
    window.editor.formatText(0, window.editor.getText().length, 'italic', true);
  }

  function alignRight () {
    window.editor.formatText(0, window.editor.getText().length, 'align', 'right');
  }

  function alignCenter () {
    window.editor.formatText(0, window.editor.getText().length, 'align', 'center');
  }

  function alignLeft () {
    window.editor.formatText(0, window.editor.getText().length, 'align', '');
  }

  function alignJustify () {
    window.editor.formatText(0, window.editor.getText().length, 'align', 'justify');
  }

  function getSaveableNotes () {
    return window.editor.root.innerHTML;
  }

  function setContent (content, focusCallback) {
    if (!window.editor || !window.editor.clipboard) return;
    window.editor.setContents(window.editor.clipboard.convert(content));
    window.editor.on("selection-change", function (range, oldRange, source) {
      if (range !== null && oldRange === null) {
        console.log("quill: ahmed")
        focusCallback(getFormatType());
      }
    });
  }

  function getFormatType () {
    if (!window.editor) return;
    var format = window.editor.getFormat();
    if (!format.bold && !format.italic) return service.NORMAL_FONT;
    if (format.bold && !format.italic) return service.BOLD_FONT;
    if (!format.bold && format.italic) return service.ITALIC_FONT;
    if (format.bold && format.italic) return service.BOLD_ITALIC_FONT;
  }

  return service;
};

quillService.$inject = ["$rootScope"];

module.exports = quillService;
