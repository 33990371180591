"use strict";

function SubmittedClaimsCtrl($rootScope, ebService, $modal) {
  var ctrl = this;
  init();

  ctrl.onFilterSelected = function (filter) {
    console.log(filter);
    ctrl.selectedFilter = filter.toLowerCase();
    ctrl.claims = [];
    ctrl.page = 1;
    ctrl.total_pages = 1;
    ctrl.isLoading = true;
    loadClaimsList();
  };

  function loadClaimsList() {
    $rootScope.showIndicator = true;
    var successCallback = function (claimsResponse) {
      ctrl.total_pages = claimsResponse.metadata.total_pages;
      $rootScope.showIndicator = false;
      ctrl.claims = ctrl.claims.concat(claimsResponse.claims);
      ctrl.isLoading = false;
      if (ctrl.claims.length === 0 && ctrl.page === 1)
        ctrl.listIsEmpty = true;
      else
        ctrl.listIsEmpty = false;

    };

    var errorCallback = function (error) {
      $rootScope.showIndicator = false;
      console.log("Error in loading the submitted claims");
    };

    ebService.getClaimsList(ctrl.page, ctrl.per_page, ctrl.selectedFilter, successCallback, errorCallback);
  }

  ctrl.onClaimClicked = function (claim) {
    $modal.open({
      animation: true,
      resolve: {
        claim: function () {
          return claim;
        }
      },
      templateUrl: 'employee-benefits/views/submitted-claim-details-modal.html',
      controller: require("../controllers/submitted-claim-details-modal.controller"),
      controllerAs: '$ctrl'
    });
  };

  ctrl.onLoadMore = function () {
    if (ctrl.page >= ctrl.total_pages) return;
    ctrl.isLoading = true;
    ctrl.page++;
    loadClaimsList();
  };

  function init() {
    ctrl.claims = [];
    ctrl.selectedFilter = "all";
    ctrl.page = 1;
    ctrl.per_page = 10;
    ctrl.total_pages = 1;
    ctrl.isLoading = true;
    loadClaimsList();
  }

}

var submittedClaimsComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/submitted-claims.html',
    controller: SubmittedClaimsCtrl
  };
};

SubmittedClaimsCtrl.$inject = ['$rootScope', 'ebService', '$modal'];

module.exports = submittedClaimsComponent();
