"use strict";

var CreatePrescriptionConfirmationCtrl = function (
  $modalInstance,
  prescriptionService,
  chatSessionId,
  cancel
) {
  var ctrl = this;
  ctrl.loading = false;

  ctrl.confirm = function () {
    ctrl.loading = true;
    prescriptionService.requiredPrescription(
      chatSessionId,
      {
        e_prescription: false,
      },
      function () {
        ctrl.loading = false;
        ctrl.close();
      }
    );
  };

  ctrl.close = function () {
    $modalInstance.dismiss();
  };

  ctrl.cancel = function () {
    cancel();
    ctrl.close();
  };
};

CreatePrescriptionConfirmationCtrl.$inject = [
  "$modalInstance",
  "prescriptionService",
  "chatSessionId",
  "cancel",
];

module.exports = CreatePrescriptionConfirmationCtrl;
