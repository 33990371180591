'use strict';
var _ = require('lodash');
var alertsService = function (NotificationsService, NotificationSoundService, $rootScope, ALERTS_API_SERVICE, $stateParams) {

  return {
    findAlertIndex: function(appointmentTime, alerts) {
      var alertIndex = _.findIndex(alerts, function(alert) { 
        return alert.payload && alert.payload.appointment_time === appointmentTime;
      });
      return alertIndex;
    },

    // subscribe doctor notification
    subscribeDoctorNotification: function(doctorId, practiceLocationId) {
      var that = this;
      // Subcribe notification
      NotificationsService.subscribe('/notifications/doctor/', function(data) {
        var notificationData = JSON.parse(data);
        if (!notificationData.doctor_id && !notificationData.practice_location_id && (!doctorId || ($stateParams.doctorId && notificationData.payload && (notificationData.payload.location === $stateParams.practiceLocationName)))) {
          NotificationSoundService.playNotificationAudio();
          $rootScope.$broadcast('newNotification', notificationData);
        }

        if (notificationData.doctor_id && notificationData.practice_location_id) {

          var alertsNumberUpdate = ALERTS_API_SERVICE.alertsNumberBackup;

          if (!doctorId && $rootScope.currentUser && notificationData.doctor_id === $rootScope.currentUser.id) {
            alertsNumberUpdate = notificationData.total;
          }

          if (doctorId && doctorId === notificationData.doctor_id && practiceLocationId === notificationData.practice_location_id) {
            alertsNumberUpdate = notificationData.unread;
          }

          if (ALERTS_API_SERVICE.alertsNumberBackup !== alertsNumberUpdate) {
            that.updateAlertNumber(alertsNumberUpdate);
            ALERTS_API_SERVICE.updateTitle(alertsNumberUpdate); 
          }
        }
      });
    },

    // change notification number
    changeNotificationNumber: function() {
      this.updateAlertNumber(ALERTS_API_SERVICE.alertsNumber);
    },

    // Get alerts
    getAlerts: function(doctorId, practiceLocationId) {
      var that = this;
      ALERTS_API_SERVICE.getAlerts(practiceLocationId, 1, 'newest', doctorId)
        .then(
          function(data) {

            that.updateAlertNumber(data.meta.unread_count);
            ALERTS_API_SERVICE.updateTitle(data.meta.unread_count);
          },
          function(err) {
            console.log(err);
          }
        );
    },

    updateAlertNumber: function(alertNumber) {
      ALERTS_API_SERVICE.alertsNumberBackup = ALERTS_API_SERVICE.alertsNumber = alertNumber;
      $rootScope.$broadcast('changeNotification', ALERTS_API_SERVICE.alertsNumber);
    }
  };

};

alertsService.$inject = [
  'NotificationsService',
  'NotificationSoundService',
  '$rootScope',
  'ALERTS_API_SERVICE',
  '$stateParams'
];

module.exports = alertsService;