'use strict';
var _ = require('lodash');
var appointmentService = function (moment, $timeout, TimeZoneService, APP_CONSTANT) {

  return {
    // check pass time for reminder
    checkPassTime: function (dateNeedCompare) {
      var TimeCompare = TimeZoneService.reminderTimeStamp(dateNeedCompare);
      var curentTime = TimeZoneService.currentTime();
      return curentTime > TimeCompare;
    },

    checkOverAvailableMonths: function(endTime) {
      var currentTime = new Date().valueOf();
      endTime = new Date(moment(endTime).format('LLL')).valueOf();

      return endTime > moment(currentTime).add(APP_CONSTANT.availableMonths, 'months').valueOf();
    },

    collectTimeOffFromAvailabilities: function(availsData, startVl, startTime, eventData, timeoff, stTime, rules, i, currentLocation) {
      var eventsAvail = [];

      // Find add appointment and time off within an availability time
      for (var j = 0; j < eventData.length; j++) {

        var eventStart = moment.utc(eventData[j].start).format();
        var eventEnd = moment.utc(eventData[j].end).format();
        var avSt = moment(moment.unix(availsData[i].start_time).format()).utc().format();
        var evEd = moment(moment.unix(availsData[i].end_time).format()).utc().format();

        if (eventStart >= startTime && eventStart >=  avSt && eventEnd <= evEd && eventData[j].location_id === currentLocation.id) {
          eventsAvail.push(eventData[j]);
        };
      }

      // Handle if have any appointment or time off within an availability time
      if (eventsAvail[0]) {

        var eventPerAvail = _.sortBy(eventsAvail, function(eventAvai) {
          return moment.utc(eventAvai.start).format();
        });

        for (var k = 0; k < eventPerAvail.length; k++) {

          var idx = _.findIndex(eventPerAvail, function(data) {
            var dataStart = moment.utc(data.start).format(),
                dataEnd = moment.utc(data.end).format();

            return dataStart >= startTime && dataEnd <= moment(moment.unix(availsData[i].end_time).format()).utc().format();
          });

          // Handle for the first event within an availability time
          if (k === idx) {

            var startResOff = angular.copy(timeoff);

            startResOff.end = eventPerAvail[idx].start;
            startResOff.end_time = TimeZoneService.unixTimeStamp(eventPerAvail[idx].start);

            if (availsData[i].start_time < startVl &&  availsData[i].end_time >= startVl) {

              startResOff.start = TimeZoneService.timeToTimezoneFormat(startTime);
              startResOff.start_time = TimeZoneService.unixTimeStamp(startResOff.start);

              if (startResOff.end > startResOff.start) {
                startResOff.appointment_length = (moment(startResOff.end).valueOf() - moment(startResOff.start).valueOf())/(60*1000);
                rules.rules.push(startResOff);
              }
            }

            if (availsData[i].start_time >= startVl) {

              startResOff.start = TimeZoneService.renderCalendar(availsData[i].start_time);
              startResOff.start_time = availsData[i].start_time;
              if (startResOff.end > startResOff.start) {
                startResOff.appointment_length = (moment(startResOff.end).valueOf() - moment(startResOff.start).valueOf())/(60*1000);
                rules.rules.push(startResOff);
              }
            }
          }

          // Handle for other event within an availability time
          if (k < eventPerAvail.length - 1) {
            var nextAvail = {};
            for (var m = k + 1; m < eventPerAvail.length; m++) {
              if (moment.utc(eventPerAvail[m].start).format() >= moment.utc(eventPerAvail[k].end).format()) {
                nextAvail = eventPerAvail[m];
                break;
              }
            }
            if (nextAvail ) {
              var _resOff = angular.copy(timeoff);
              _resOff.start = eventPerAvail[k].end;
              _resOff.end = eventPerAvail[m].start;

              _resOff.start_time = TimeZoneService.unixTimeStamp(eventPerAvail[k].end);
              _resOff.end_time = TimeZoneService.unixTimeStamp(eventPerAvail[m].start);

              if ( moment.utc(_resOff.start).format() !== moment.utc(_resOff.end).format()) {
                _resOff.appointment_length = (moment(_resOff.end).valueOf() - moment(_resOff.start).valueOf())/(60*1000);
                rules.rules.push(_resOff);
              }
            }

          }

          if (k === eventPerAvail.length - 1) {
            // Find last index of event
            var lastIdx = _.findLastIndex(eventPerAvail);

            // Handle or the last event within an availability time
            if (lastIdx !== -1) {
              var endResOff = angular.copy(timeoff);

              endResOff.start = eventPerAvail[lastIdx].end;
              endResOff.end = TimeZoneService.renderCalendar(availsData[i].end_time);

              endResOff.start_time = TimeZoneService.unixTimeStamp(eventPerAvail[lastIdx].end);
              endResOff.end_time = availsData[i].end_time;


              if (endResOff.end > endResOff.start) {
                endResOff.appointment_length = (moment(endResOff.end).valueOf() - moment(endResOff.start).valueOf())/(60*1000);
                rules.rules.push(endResOff);
              }
            }
          }

        }
      } else {
        //  Handle if don't have any appointment or time off within an availability time
        var timeOffNew = angular.copy(timeoff);

        timeOffNew.end = TimeZoneService.renderCalendar(availsData[i].end_time);
        timeOffNew.end_time = availsData[i].end_time;

        if (availsData[i].start_time < startVl &&  availsData[i].end_time >= startVl) {
          timeOffNew.start = TimeZoneService.timeToTimezoneFormat(startTime);
          timeOffNew.start_time = TimeZoneService.unixTimeStamp(timeOffNew.start);

          if (timeOffNew.end > timeOffNew.start) {
            timeOffNew.appointment_length = (moment(timeOffNew.end).valueOf() - moment(timeOffNew.start).valueOf())/(60*1000);
            rules.rules.push(timeOffNew);
          }
        }

        if (availsData[i].start_time >= startVl) {

          timeOffNew.start = TimeZoneService.renderCalendar(availsData[i].start_time);
          timeOffNew.start_time = availsData[i].start_time;

          if (timeOffNew.end > timeOffNew.start) {
            timeOffNew.appointment_length = (moment(timeOffNew.end).valueOf() - moment(timeOffNew.start).valueOf())/(60*1000);
            rules.rules.push(timeOffNew);
          }
        }
      }

      return rules;
    },

    findAppointmentId: function(appointmentTime, eventSources) {
      var events = eventSources[0].events.concat(eventSources[1].events,eventSources[2].events);
      var appointmentData = _.find(events, function(event) {
        return event.time.toString() === appointmentTime;
      });

      return appointmentData.id;
    },

    openDetailAppointmentModal: function(appointmentId) {
      $timeout(function () {
        angular.element('#' + appointmentId).click();
      }, 100);
    }
  };

};

appointmentService.$inject = [
  'moment',
  '$timeout',
  'TimeZoneService',
  'APP_CONSTANT'
];

module.exports = appointmentService;
