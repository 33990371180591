'use strict';

function ChatMsgController($modal) {
  var ctrl = this;

  ctrl.onImgClicked = function () {
    $modal.open({
      animation: true,
      resolve: {
        ImageUrl: function () {
          return ctrl.msg.imageUrl;
        }
      },
      templateUrl: 'chat/views/image-modal.html',
      controller: require("../controllers/image-modal.controller"),
      controllerAs: '$ctrl',
      size: 'lg'
    });
  };
};

var chatMsgComponent = function () {
  return {
    templateUrl: 'chat/views/chatMsg.html',
    controller: ChatMsgController,
    bindings: {
      'msg': '=',
    }
  };
};

ChatMsgController.$inject = ['$modal'];

module.exports = chatMsgComponent();
