'use strict';
var _ = require('lodash');

var DataTimeService = function (moment) {
  return {
    operatingHour: [
      {
        hour: '01'
      },
      {
        hour: '02'
      },
      {
        hour: '03'
      },
      {
        hour: '04'
      },
      {
        hour: '05'
      },
      {
        hour: '06'
      },
      {
        hour: '07'
      },
      {
        hour: '08'
      },
      {
        hour: '09'
      },
      {
        hour: '10'
      },
      {
        hour: '11'
      },
      {
        hour: '12'
      }
    ],
    operatingData: [
      {
        day_of_week: 'mon',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: true,
        by_appointment_only: false
      },
      {
        day_of_week: 'tue',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: true,
        by_appointment_only: false
      },
      {
        day_of_week: 'wed',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: true,
        by_appointment_only: false
      },
      {
        day_of_week: 'thu',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: true,
        by_appointment_only: false
      },
      {
        day_of_week: 'fri',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: true,
        by_appointment_only: false
      },
      {
        day_of_week: 'sat',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: false,
        by_appointment_only: false
      },
      {
        day_of_week: 'sun',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: false,
        by_appointment_only: false
      },
      {
        day_of_week: 'ph',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: false,
        by_appointment_only: false
      },
      {
        day_of_week: 'lunch_break',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: false,
        by_appointment_only: false
      },
      {
        day_of_week: 'dinner_break',
        open_time: '',
        close_time: '',
        isValid: true,
        isOpen: false,
        by_appointment_only: false
      }
    ],

    // Define data for operating minutes
    operatingMinutes: [
      {
        minute: '00'
      },
      {
        minute: '15'
      },
      {
        minute: '30'
      },
      {
        minute: '45'
      },
    ],

    // Validate operating hours
    validationOperating: function(operatingData) {
      var i = 0,
          operatingArrayLength = operatingData.length,
          operatingInValid = false;

      for(i; i < operatingArrayLength; i++) {
        if (!operatingData[i].isValid) {
          operatingInValid = true;
          break;
        } else {
          operatingInValid = false;
        }
      }
      return operatingInValid;
    },

    // Format operating hours data
    formatOperatingData: function(dataFormat, isCreate) {

      var operatingHourData = angular.copy(dataFormat);

      _.forEach(operatingHourData, function(operating) {

        if (!operating.isOpen) {
          operating.open_time = '';
          operating.close_time = '';
        }

        // set by_appointment_only to false if have open_time or day_of_week = 'lunch_break'
        if (operating.open_time || operating.day_of_week === 'lunch_break') {
          operating.by_appointment_only = false;
        }

        if (isCreate) {
          delete operating.isValid;
        } else {
          delete operating.isValid;
          delete operating.operatingHourEnd;
          delete operating.operatingHourStart;
          delete operating.operatingMinuteEnd;
          delete operating.operatingMinuteStart;
          delete operating.operatingTimeEnd;
          delete operating.operatingTimeStart;
          delete operating.operatingTypeHoursEnd;
          delete operating.operatingTypeHoursStart;
        }

        delete operating.isOpen;
      });

      return operatingHourData;
    },

    // Format data to render to edit operating hours
    formatHour: function(hourData) {
      var currentDate = moment().format("YYYY/MM/DD"),
        dataTime = moment(currentDate + ' ' + hourData, "YYYY/MM/DD HH:mm").format('YYYY/MM/DD hh:mm A');

      return {
        HourFormat: moment(dataTime, 'YYYY/MM/DD hh:mm A').format('hh'),
        MinuteStart: moment(dataTime, 'YYYY/MM/DD hh:mm A').format('mm'),
        TypeHoursFormat: moment(dataTime, 'YYYY/MM/DD hh:mm A').format('A')
      };
    },

    formatHourData: function(dayData) {
      if(dayData.open_time) {

        var operatingStart = this.formatHour(dayData.open_time);

        dayData.operatingHourStart = operatingStart.HourFormat;
        dayData.operatingMinuteStart = operatingStart.MinuteStart;
        dayData.operatingTypeHoursStart = operatingStart.TypeHoursFormat;


        var operatingEnd = this.formatHour(dayData.close_time);

        dayData.operatingHourEnd = operatingEnd.HourFormat;
        dayData.operatingMinuteEnd = operatingEnd.MinuteStart;
        dayData.operatingTypeHoursEnd = operatingEnd.TypeHoursFormat;
      }

      return dayData;
    }

  };
};

DataTimeService.$inject = [
  'moment'
];

module.exports = DataTimeService;
