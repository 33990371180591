/*jshint -W069 */
'use strict';
var Faye = require('faye');
var notificationService = angular
  .module('Auth')
  .factory("NotificationsService", [
    '$cookieStore',
    'ENV',
    '$rootScope',
    '$state',
    'RoleValidation',
    'StateChangeService',
    function ($cookieStore, ENV, $rootScope, $state, RoleValidation, StateChangeService) {
      var FayeServerURL = window.location.protocol + (ENV.name === 'prod' ? '//socket.bookdoc.com' : (ENV.api.ip + '/socket'));
      // var FayeServerURL = window.location.protocol + (ENV.name === 'prod' ? '//socket.bookdoc.com' : ('https://qa-api.bookdoc.com' + '/socket'));
      var client = new Faye.Client(FayeServerURL);
      var id = null;

      return {
        setHeader: function(chanelId, isClinic) {
          var store = chanelId ? $cookieStore.get('AssistantSession') : $cookieStore.get('AuthSession');
          // Redirect to login/login-group page if current user does not existed on cookiesStore
          if (!store) {
            var isAssistant = RoleValidation.isAssistant($rootScope.currentUser);
            StateChangeService.redirectToLogin(isAssistant);
            return;
          }
          if (store.user) {
            id = store.user.id;
          }

          if (chanelId) {
            id = chanelId;
          }

          client.addExtension({
            outgoing: function (message, callback) {

              if (message.channel !== '/meta/subscribe') {
                return callback(message);
              }

              message.ext = message.ext || {};
              message.ext['Authorization'] = store.token;

              if (chanelId) {
                if (!isClinic) {
                  message.ext['doctorId'] = id;
                } else {
                  message.ext['clinicId'] = id;
                }
              }
              callback(message);

            }
          });

        },
        publish: function(channel, message) {
          if (id) {
            client.publish(channel + id, message);
          }
        },

        subscribe: function(channel, callback) {
          if (id) {
            client.subscribe(channel + id, callback);
          }

        },

        unsubscribe: function(channel) {
          if (id) {
            client.unsubscribe(channel + id);
          }
        }
      };

    }

  ]);

module.exports = notificationService;
