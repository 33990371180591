'use strict';

var states = function ($stateProvider) {
  $stateProvider
    .state('firstTimeLogin', {
      url: '/first-time-login/',
      templateUrl: "change-password-first-time/views/change-password-first-time.html",
      onEnter: [
        '$rootScope',
        '$state',
        function ($rootScope, $state) {
          if (!$rootScope.currentUser.first_time_login) {
            $state.go('main.dashboard');
          }
        }
      ],
      controller: 'ChangePasswordFirstTimeCtrl',
      controllerAs: 'changePWCtrl'
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.dashboard')
  .config(states);
