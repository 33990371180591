'use strict';

var _ = require('lodash');

var qualificationsDirective = function(ArrayService) {
  return {
    restrict: 'A',
    templateUrl: 'registration/views/qualifications.html',
    scope: false,
    link: function(scope, elem, attr) {

      scope.validate = {
        qualifications: {
          disableAdd: true,
          isDuplicate: false
        }
      };

      /**
       * [checkDuplicate description] check duplicate value
       * @return {[type]} [description]
       */
      function checkDuplicate () {
        scope.validate.qualifications.isDuplicate = ArrayService.checkDuplicateItem(scope.qualifications, 'name');
      }

      /**
       * [inputChange description] check text value in input name
       * @param  {[string]} textValue [description]
       * @return {[type]}           [description]
       */
      scope.inputChange = function (textValue) {
        if (textValue) {
          scope.validate.qualifications.disableAdd = false;
          checkDuplicate();
          return;
        }
        checkDuplicate();
      };

      /**
       * [addItem description] handle event add qualification
       *  when user click +Add Qualification
       */
      scope.addItem = function () {
        checkDuplicate();
        if (!scope.validate.qualifications.isDuplicate) {
          scope.qualifications.push({name:'', updated: true});
        }
      };

      /**
       * [removeItem description] handle event remove qualification
       * @param  {[number]} index [description] position of qualification in array
       * @return {[type]}       [description]
       */
      scope.removeItem = function (index) {
        scope.qualifications.splice(index, 1);
        checkDuplicate();
      };
    }
  };
};

qualificationsDirective.$inject = [
  'ArrayService'
];

module.exports = qualificationsDirective;
