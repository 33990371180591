'use strict';

var NotificationSoundService = function (ngAudio, APP_CONSTANT) {

  // Load notification audio
  var notificationAudio = ngAudio.load(APP_CONSTANT.notificationAudioUrl);
  
  return {
    // Play notification audio
    playNotificationAudio: function () {
      notificationAudio.play();
    }
  };
};

NotificationSoundService.$inject = [
  'ngAudio',
  'APP_CONSTANT'
];

module.exports = NotificationSoundService;
