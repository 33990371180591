/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.terms.controllers',
    [
    ]
  )
  .controller('TermsCtrl', require('./terms.controller'));
