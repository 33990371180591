/* Define controller for grouping module*/
'use strict';
var _ = require('lodash');
var GroupingCtrl = function(APP_CONSTANT, GroupingAPI, CachingQueryParams, $modal, $state, $rootScope, SpecialtiesApi, NotificationsService, $timeout, $scope, NotificationSoundService) {
  var vm = this;

  vm.paginationSize = APP_CONSTANT.pagination.size;
  vm.doctorsLimit = APP_CONSTANT.pagination.limit;
  vm.doctorList = [];
  vm.specialtySelected = {};
  var specialtiesList = [];
  // define list of specialties that selected to filter
  vm.specialtySelectedList = [];
  vm.searchKey = '';

  var clinicId = $rootScope.currentUser && $rootScope.currentUser.clinic_id;

  // Reset title for grouping page
  window.document.title = 'BookDoc - Portal';
  /**
   * [createVariables description] create all variables
   * @return {[type]} [description]
   */
  function createVariables () {
    vm.modalConfig = {
      isAnimation: true
    };
  }

  createVariables();

  function initDataFromParams() {
    vm.currentPage = 1;
  }

  // Get doctor list
  vm.getDoctor = function() {
    var specialtyIdList = _.pluck(vm.specialtySelectedList, 'id');

    var paramsRequest = {
      'page': vm.currentPage,
      'name': vm.searchKey,
      'specialty_ids[]': specialtyIdList
    };

    // show indicator
    $rootScope.showIndicator = true;

    // Caching query params
    GroupingAPI.getDoctors(paramsRequest)
      .then(
        function(data) {
          vm.doctorList = data.assistants;
          vm.totalPages = data.meta.total_pages;
          vm.totalCount = data.meta.total_count;

        },
        function(err) {
          console.log(err);
        }
      ).finally(function() {
        // hide indicator
        $rootScope.showIndicator = false;
      });
  };

  NotificationsService.setHeader(clinicId, true);
  // Subcribe notification
  NotificationsService.subscribe('/notifications/clinic/', function(data) {
    var notificationData = JSON.parse(data);
    var practiceLocationIndex = _.findIndex(vm.doctorList, function(practiceLocation) { 
      return practiceLocation.doctor.id === notificationData.doctor_id && practiceLocation.id === notificationData.practice_location_id; 
    });
    if (practiceLocationIndex >= 0 && vm.doctorList[practiceLocationIndex].unread_count !== notificationData.unread) {
      // Update alert number
      $scope.$apply(function() {
        // Only play notification sound if book/cancle appointnent alert
        if (vm.doctorList[practiceLocationIndex].unread_count < notificationData.unread) {
          NotificationSoundService.playNotificationAudio();
        }
        vm.doctorList[practiceLocationIndex].unread_count = notificationData.unread;
      });
    }
  });

  function getSpecialties () {
    // Get specialties list
    SpecialtiesApi.getList()
      .then(
        function (data) {
          specialtiesList = data;
        },

        function (error) {
          console.log(error);
        }
      );
  }

  initDataFromParams();
  vm.getDoctor();
  getSpecialties();

  function handleGetDoctorList () {
    // Reset page
    vm.currentPage = 1;
    vm.getDoctor();
  }

  var searchByName = _.debounce(function() {
    handleGetDoctorList();
  }, 200);

  /**
   * [searchDoctor description] handle event search doctor
   * @return {[type]} [description]
   */
  vm.searchDoctor = function () {
    searchByName();
  };

  /**
   * [removeSearchKey description] remove search key
   * @return {[type]} [description]
   */
  vm.removeSearchKey = function ($event) {
    vm.searchKey = '';
    $event.preventDefault();
    searchByName();
  };

  /**
   * [clearFilter description] handle event clear filter
   * @return {[type]} [description]
   */
  vm.clearFilter = function () {
    vm.specialtySelected = {};

    // Delete list of specialties that selected to filter
    vm.specialtySelectedList = [];

    // Reset searchKey value
    vm.searchKey = '';

    handleGetDoctorList();
  };

  /**
   * [openFilterModal description] open modal filter doctor
   * @return {[type]} [description]
   */
  vm.openFilterModal = function () {
    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      resolve: {
        specialtiesData: function () {
          return {
            specialtiesList: specialtiesList,
            specialtySelected: vm.specialtySelected,
            specialtySelectedList: vm.specialtySelectedList
          };
        }
      },
      templateUrl: 'grouping/views/filter-popup.html',
      controller: require('./filter.controller'),
      controllerAs: 'filter',
      windowClass: 'modal-info modal-filter',
    });
  };

  $rootScope.$on('handleFilterDoctor', function(event, data) {
    vm.specialtySelected = data;
    vm.specialtySelectedList.push(data);
    handleGetDoctorList();
  });

  vm.removeSpecialty = function (index) {
    vm.specialtySelectedList.splice(index, 1);
    handleGetDoctorList();
  };

};

GroupingCtrl.$inject = [
  'APP_CONSTANT',
  'GroupingAPI',
  'CachingQueryParams',
  '$modal',
  '$state',
  '$rootScope',
  'bookdoc.specialties.api',
  'NotificationsService',
  '$timeout',
  '$scope',
  'NotificationSoundService'
];

module.exports = GroupingCtrl;
