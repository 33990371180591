'use strict';

var referStates = function ($stateProvider) {

  $stateProvider
    .state('main.referral.refer', {
      url: 'refer-to/?page?sort_by',
      views: {
        'refer': {
          templateUrl: 'referral/refer-to/views/refer-to.html',
          controller: 'ReferToCtrl',
          controllerAs: 'referTo'
        }
      }
    });

};

referStates.$inject = ['$stateProvider'];

module.exports = referStates;
