'use strict';

module.exports =
  angular
    .module('bookdoc.calendar.availability.directives', [
      require('../../../../../tmp/templates').name,
      require('../../../common').name
    ])
    .directive('bdAvailDuration', require('./duration.directive'))
    .directive('bdUpdateAvailDuration', require('./update-duration.directive'))
    .directive('bdAvailDay', require('./day.directive'))
    .directive('slAvailWeek', require('./select-week.directive'));
