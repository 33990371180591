 'use strict';

var groupingAPI = function (Restangular) {

  return {

    getDoctors: function (params) {
      return Restangular.all('assistants').customGET('doctors', params);

    },

    lockMechanism: function (location_id) {
      var params = {
        location_id: location_id
      };

      return Restangular.one("lock_mechanism").customPUT(params, null);
    },

    unLockMechanism: function (location_id) {
      var params = {
        location_id: location_id
      };
      return Restangular.all("unlock_mechanism").customDELETE(null, params);
    }

  };

};

groupingAPI.$inject = [
  'Restangular'
];

module.exports = groupingAPI;