'use strict';

var moment = (typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);

var DataTimeService = function () {
  return {
    dataTime: [
      {
        time: '12:00 am'
      },
      {
        time: '12:15 am'
      },
      {
        time: '12:30 am'
      },
      {
        time: '12:45 am'
      },
      {
        time: '1:00 am'
      },
      {
        time: '1:15 am'
      },
      {
        time: '1:30 am'
      },
      {
        time: '1:45 am'
      },
      {
        time: '2:00 am'
      },
      {
        time: '2:15 am'
      },
      {
        time: '2:30 am'
      },
      {
        time: '2:45 am'
      },
      {
        time: '3:00 am'
      },
      {
        time: '3:15 am'
      },
      {
        time: '3:30 am'
      },
      {
        time: '3:45 am'
      },
      {
        time: '4:00 am'
      },
      {
        time: '4:15 am'
      },
      {
        time: '4:30 am'
      },
      {
        time: '4:45 am'
      },
      {
        time: '5:00 am'
      },
      {
        time: '5:15 am'
      },
      {
        time: '5:30 am'
      },
      {
        time: '5:45 am'
      },
      {
        time: '6:00 am'
      },
      {
        time: '6:15 am'
      },
      {
        time: '6:30 am'
      },
      {
        time: '6:45 am'
      },
      {
        time: '7:00 am'
      },
      {
        time: '7:15 am'
      },
      {
        time: '7:30 am'
      },
      {
        time: '7:45 am'
      },
      {
        time: '8:00 am'
      },
      {
        time: '8:15 am'
      },
      {
        time: '8:30 am'
      },
      {
        time: '8:45 am'
      },
      {
        time: '9:00 am'
      },
      {
        time: '9:15 am'
      },
      {
        time: '9:30 am'
      },
      {
        time: '9:45 am'
      },
      {
        time: '10:00 am'
      },
      {
        time: '10:15 am'
      },
      {
        time: '10:30 am'
      },
      {
        time: '10:45 am'
      },
      {
        time: '11:00 am'
      },
      {
        time: '11:15 am'
      },
      {
        time: '11:30 am'
      },
      {
        time: '11:45 am'
      },
      {
        time: '12:00 pm'
      },
      {
        time: '12:15 pm'
      },
      {
        time: '12:30 pm'
      },
      {
        time: '12:45 pm'
      },
      {
        time: '1:00 pm'
      },
      {
        time: '1:15 pm'
      },
      {
        time: '1:30 pm'
      },
      {
        time: '1:45 pm'
      },
      {
        time: '2:00 pm'
      },
      {
        time: '2:15 pm'
      },
      {
        time: '2:30 pm'
      },
      {
        time: '2:45 pm'
      },
      {
        time: '3:00 pm'
      },
      {
        time: '3:15 pm'
      },
      {
        time: '3:30 pm'
      },
      {
        time: '3:45 pm'
      },
      {
        time: '4:00 pm'
      },
      {
        time: '4:15 pm'
      },
      {
        time: '4:30 pm'
      },
      {
        time: '4:45 pm'
      },
      {
        time: '5:00 pm'
      },
      {
        time: '5:15 pm'
      },
      {
        time: '5:30 pm'
      },
      {
        time: '5:45 pm'
      },
      {
        time: '6:00 pm'
      },
      {
        time: '6:15 pm'
      },
      {
        time: '6:30 pm'
      },
      {
        time: '6:45 pm'
      },
      {
        time: '7:00 pm'
      },
      {
        time: '7:15 pm'
      },
      {
        time: '7:30 pm'
      },
      {
        time: '7:45 pm'
      },
      {
        time: '8:00 pm'
      },
      {
        time: '8:15 pm'
      },
      {
        time: '8:30 pm'
      },
      {
        time: '8:45 pm'
      },
      {
        time: '9:00 pm'
      },
      {
        time: '9:15 pm'
      },
      {
        time: '9:30 pm'
      },
      {
        time: '9:45 pm'
      },
      {
        time: '10:00 pm'
      },
      {
        time: '10:15 pm'
      },
      {
        time: '10:30 pm'
      },
      {
        time: '10:45 pm'
      },
      {
        time: '11:00 pm'
      },
      {
        time: '11:15 pm'
      },
      {
        time: '11:30 pm'
      },
      {
        time: '11:45 pm'
      }
    ],
    scrollDropdown: function (open) {
      if (open === true) {
        var selectElement =  angular.element('.open .selected');
        angular.element('.open .dropdown-menu').scrollToElement(selectElement);
      }
    },
    initWeekDate: function (time, format, isStart, view) {
      var momentTime;

      if (!time) {
        return (isStart && moment().startOf('isoweek').format(format)) || moment().endOf('isoweek').format(format);
      }

      if (view === "Month" || view === "Day") {
        momentTime = (isStart && moment(time, format));
      } else {
        momentTime = (isStart && moment(time, format).startOf('isoweek')) || moment(time, format).endOf('isoweek');
      }

      return (momentTime.isValid() && momentTime.format(format)) || (isStart && moment().startOf('isoweek').format(format)) || moment().endOf('isoweek').format(format);

    },

    currentTime: function () {
      var currentTime = new Date().valueOf();
      currentTime = moment.utc(currentTime).format("YYYY-MM-DD HH:mm:ss");
      currentTime = moment.tz(currentTime, 'Etc/UTC').utc().valueOf();
      return currentTime;
    }
  };
};

DataTimeService.$inject = [
];

module.exports = DataTimeService;
