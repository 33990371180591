'use strict';

var AutoFocusService = function ($timeout, APP_CONSTANT) {
  return {

    setAutoFocus: function (element) {
      $timeout(function () {
        element.focus();
      }, APP_CONSTANT.focusDelayTime);
    }
  };
};

AutoFocusService.$inject = [
  '$timeout',
  'APP_CONSTANT'
];

module.exports = AutoFocusService;