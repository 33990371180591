"use strict";
var Rx = require('rx');

function SubmitClaimCtrl(ebService, claim, modal, $modalInstance) {
  console.log("Submit claim modal ctrl");
  var ctrl = this;
  ctrl.claim = JSON.parse(JSON.stringify(claim));
  ctrl.bannedMedicines = [];
  ctrl.bannedServices = [];
  ctrl.bannedDiagnoses = [];
  init();

  ctrl.onAddDiagnoseBtnClicked = function () {
    if (ctrl.diagnoses.length > 0)
      updateBannedDiagnoses();

    ctrl.diagnoses.push({
      name: ""
    });
    ctrl.diagnosesIsMissing = false;
    ctrl.diagnosesListIsEmpty = false;
  };

  ctrl.onRemoveDiagnoseBtnClicked = function (index) {
    var diagnose = ctrl.diagnoses[index];

    if (diagnose.id) {
      diagnose._destroy = true;
    } else {
      ctrl.diagnoses.splice(index, 1);
    }

    updateBannedDiagnoses();
    showEmptyDiagnosisMsgIfApplicable();
  };

  function updateBannedDiagnoses() {
    ctrl.bannedDiagnoses = ctrl.diagnoses
      .filter(function (diagnose) {
        return !diagnose._destroy;
      })
      .map(function (diagnose) {
        return diagnose.name;
      });
  }

  function showEmptyDiagnosisMsgIfApplicable() {
    if (ctrl.diagnoses.length == 0) {
      ctrl.diagnosesListIsEmpty = true;
      return;
    }

    var allDiagnosesAreDestroyed = true;
    ctrl.diagnoses.forEach(function (diagnosis) {
      if (!diagnosis._destroy) allDiagnosesAreDestroyed = false;
    });
    ctrl.diagnosesListIsEmpty = allDiagnosesAreDestroyed;
  }

  ctrl.onAddServiceBtnClicked = function () {
    if (ctrl.services.length > 0)
      updateBannedServices();

    ctrl.services.push({
        fee: undefined,
        name: "",
      }
    );
    ctrl.servicesListIsEmpty = false;
  };

  ctrl.onRemoveServiceBtnClicked = function (index) {
    var service = ctrl.services[index];

    if (service.id) {
      service._destroy = true;
    } else {
      ctrl.services.splice(index, 1);
    }

    updateBannedServices();
    updateTotalGrandPrice();
    showEmptyServiceMsgIfApplicable();
  };

  function updateBannedServices() {
    ctrl.bannedServices = ctrl.services
      .filter(function (service) {
        return !service._destroy;
      })
      .map(function (service) {
        return service.name;
      });
  }

  function showEmptyServiceMsgIfApplicable() {
    if (ctrl.services.length == 0) {
      ctrl.servicesListIsEmpty = true;
      return;
    }

    var allServicesAreDestroyed = true;
    ctrl.services.forEach(function (service) {
      if (!service._destroy) allServicesAreDestroyed = false;
    });
    ctrl.servicesListIsEmpty = allServicesAreDestroyed;
  }

  ctrl.onAddMedicineBtnClicked = function () {
    if (ctrl.medications.length > 0)
      updateBannedMedicines();

    ctrl.medications.push({
      name: "",
      total_price: 0.0
    });
    ctrl.medicationsListIsEmpty = false;
  };

  ctrl.onRemoveMedicineBtnClicked = function (index) {
    var medication = ctrl.medications[index];

    if (medication.id) {
      medication._destroy = true;
    } else {
      ctrl.medications.splice(index, 1);
    }

    updateBannedMedicines();
    updateTotalGrandPrice();
    showEmptyMedicationMsgIfApplicable();
  };

  function updateBannedMedicines() {
    ctrl.bannedMedicines = ctrl.medications
      .filter(function (medication) {
        return !medication._destroy;
      })
      .map(function (medication) {
        return medication.name;
      });

    console.log(ctrl.bannedMedicines);
  }

  function showEmptyMedicationMsgIfApplicable() {
    if (ctrl.medications.length == 0) {
      ctrl.medicationsListIsEmpty = true;
      return;
    }

    var allMedicationsAreDestroyed = true;
    ctrl.medications.forEach(function (medication) {
      if (!medication._destroy) allMedicationsAreDestroyed = false;
    });
    ctrl.medicationsListIsEmpty = allMedicationsAreDestroyed;
  }


  function updateTotalGrandPrice() {
    var medicationPrice = 0.0;
    for (var i = 0; i < ctrl.medications.length; i++) {
      if (ctrl.medications[i].total_price && !ctrl.medications[i]._destroy)
        medicationPrice += parseFloat(ctrl.medications[i].total_price);
    }
    var servicesPrice = 0.0;
    for (i = 0; i < ctrl.services.length; i++) {
      if (ctrl.services[i].fee && !ctrl.services[i]._destroy)
        servicesPrice += ctrl.services[i].fee;
    }
    ctrl.totalGrandPrice = (medicationPrice + servicesPrice).toFixed(2);
    ctrl.totalPriceExceededLimit = ctrl.totalGrandPrice > ctrl.claim.claim_limit;
  }

  ctrl.updateTotalGrandPrice = function () {
    updateTotalGrandPrice();
  };

  ctrl.onCancelBtnClicked = function () {
    $modalInstance.dismiss();
  };

  function getSubmitClaimRequestBody() {
    var submitClaimRequest = {
      "medical_certificate_days": ctrl.medical_cert_days,
      "provider_notes": ctrl.additional_notes,
      "total_amount": ctrl.totalGrandPrice,
      "medical_bills_diagnoses_attributes": getDiagnosesForSubmitClaimRequest(),
      "medical_bills_services_attributes": getServicesForSubmitClaimRequest(),
      "medical_bills_medications_attributes": getMedicinesForSubmitClaimRequest()
    };
    return submitClaimRequest;
  }

  function getDiagnosesForSubmitClaimRequest() {
    var diagnoses = [];
    ctrl.diagnoses.forEach(function (diagnosis) {

      if (diagnosis.id && diagnosis.hasBeenEdited) {
        diagnoses.push({
          id: diagnosis.id,
          _destroy: true
        });
      }
      if (diagnosis.id !== diagnosis.diagnosis_id) {
        diagnoses.push({
          diagnosis_id: diagnosis.diagnosis_id,
          _destroy: diagnosis._destroy
        });
      } else if (diagnosis.id && diagnosis._destroy) {
        diagnoses.push({
          id: diagnosis.id,
          _destroy: diagnosis._destroy
        });
      }
    });
    return diagnoses;
  }

  function getServicesForSubmitClaimRequest() {
    var services = [];
    ctrl.services.forEach(function (service) {
      if (service.id && service._destroy) {
        services.push({
          id: service.id,
          service_id: service.service_id,
          _destroy: service._destroy
        });
      } else {
        services = services.filter(function(searchedService) {
          if (service.service_id === searchedService.service_id) {
            service.id = searchedService.id;
          }

          return searchedService.service_id !== service.service_id;
        });
        services.push(service);
      }
    });
    return services;
  }

  function getMedicinesForSubmitClaimRequest() {
    var medicines = [];
    ctrl.medications.forEach(function (medicine) {
      if (medicine.id && medicine._destroy) {
        medicines.push({
          id: medicine.id,
          medication_id: medicine.medication_id,
          _destroy: medicine._destroy
        });
      } else {
        medicines = medicines.filter(function(searchedMedicine) {
          if (medicine.medication_id === searchedMedicine.medication_id) {
            medicine.id = searchedMedicine.id;
          }

          return searchedMedicine.medication_id !== medicine.medication_id;
        });
        medicines.push(medicine);
      }
    });
    return medicines;
  }

  function submitClaim(isDraft) {
    var submitClaimRequest = getSubmitClaimRequestBody();
    console.log(submitClaimRequest);
    submitClaimRequest.is_draft = isDraft;
    ebService.submitClaim(ctrl.claim.id, submitClaimRequest, function () {
      console.log("Submit claim Success");
      $modalInstance.close(true);
    }, function (err) {
      console.log(err.data.error);
    });
  }

  ctrl.onSubmitClaimClicked = function () {
    if (!validateSubmitClaimForm()) {
      showSubmitClaimErrorDialog();
      return;
    }

    var modalResult = showConfirmDialog();
    modalResult.then(function (isConfirmed) {
      if (isConfirmed) {
        submitClaim(false);
      }
    });
  };

  ctrl.onSaveAsDraftBtnClicked = function () {
    if (!validateSubmitClaimForm()) {
      showSubmitClaimErrorDialog();
      return;
    }
    submitClaim(true);
  };

  function validateSubmitClaimForm() {
    var isValid = true;
    console.log(ctrl.diagnoses);
    console.log(ctrl.services);
    console.log(ctrl.medications);
    if (ctrl.medical_cert_days == null || ctrl.medical_cert_days < 0) {
      ctrl.medicalCertDaysError = true;
      isValid = false;
    }

    if (ctrl.diagnosesListIsEmpty) {
      ctrl.diagnosesIsMissing = true;
      isValid = false;
    }

    for (var i = 0; i < ctrl.diagnoses.length; i++) {
      if (!ctrl.diagnoses[0].diagnosis_id || ctrl.diagnoses[0].name === "") {
        isValid = false;
        ctrl.diagnosesCheckSubject.onNext();
        break;
      }
    }

    for (i = 0; i < ctrl.medications.length; i++) {
      var medication = ctrl.medications[i];
      if (medication.unitPriceExceededLimit)
        isValid = false;
      if (!medication.unit_price ||
        medication.unit_price < 0 ||
        !medication.quantity ||
        medication.quantity < 0 ||
        medication.quantity % 1 > 0) {
        isValid = false;
        ctrl.medicationCheckSubject.onNext();
        break;
      }
    }

    for (i = 0; i < ctrl.services.length; i++) {
      if (ctrl.services[i].feeExceededLimit || !ctrl.services[i].fee || ctrl.services[i].fee < 0) {
        ctrl.servicesCheckSubject.onNext();
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  function showConfirmDialog() {
    var modalInstance = modal.open({
      animation: true,
      resolve: {
        totalPrice: function () {
          return ctrl.totalGrandPrice;
        },
        claimLimit: function () {
          return ctrl.claim.claim_limit ? ctrl.claim.claim_limit : 0;
        }
      },
      templateUrl: 'employee-benefits/views/confirm-submit-claim-dialog.html',
      controller: require("../controllers/confirm-submit-claim-dialog.controller"),
      controllerAs: '$ctrl',
      size: 'sm'
    });

    return modalInstance.result;
  }


  function showSubmitClaimErrorDialog() {
    var modalInstance = modal.open({
      animation: true,
      resolve: {},
      templateUrl: 'employee-benefits/views/submit-claim-error-dialog.html',
      controller: require("../controllers/submit-claim-error-dialog.controller"),
      controllerAs: '$ctrl',

      size: 'sm'
    });

    return modalInstance.result;
  }

  ctrl.validateMedicalCert = function () {
    ctrl.medicalCertDaysError = false;
  };

  function init() {
    ctrl.numberRegex = "\\d+";
    ctrl.medicationCheckSubject = new Rx.Subject();
    ctrl.servicesCheckSubject = new Rx.Subject();
    ctrl.diagnosesCheckSubject = new Rx.Subject();

    ctrl.medical_cert_days = ctrl.claim.medical_certificate_days ? ctrl.claim.medical_certificate_days : 0;
    ctrl.additional_notes = ctrl.claim.provider_notes ? ctrl.claim.provider_notes : "";
    ctrl.services = ctrl.claim.services ? ctrl.claim.services : [];
    ctrl.medications = ctrl.claim.medications ? ctrl.claim.medications : [];
    ctrl.diagnoses = ctrl.claim.diagnosis ? ctrl.claim.diagnosis : [];
    ctrl.totalGrandPrice = ctrl.claim.total_amount ? ctrl.claim.total_amount : 0.0;

    showEmptyDiagnosisMsgIfApplicable();
    showEmptyServiceMsgIfApplicable();
    showEmptyMedicationMsgIfApplicable();
  }
}

SubmitClaimCtrl.$inject = ['ebService', 'claim', 'modal', '$modalInstance'];

module.exports = SubmitClaimCtrl;
