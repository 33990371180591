"use strict";
var VideoStreamMerger = require("video-stream-merger");
window.VideoStreamMerge = VideoStreamMerger;
function VideoCtrl(
  $rootScope,
  quickBloxService,
  chatService,
  moment
) {
  /////////////////////////////////// view model variables /////////////////////////////////////////
  var ctrl = this;
  ctrl.audioMuted = false;
  ctrl.videoMuted = false;
  ctrl.state = "Connecting...";
  ctrl.patientName = "";
  ctrl.videoSession = null;
  ctrl.callOngoing = false;
  ctrl.merger = null;
  ctrl.recorder = null;
  ctrl.data = [];
  ctrl.streamMerger = null;
  ctrl.showVideoChat = false;

  var setCallStatusToDefault = function () {
    ctrl.audioMuted = false;
    ctrl.videoMuted = false;
    ctrl.state = "Connecting...";
  };

  ctrl.onHangUp = function () {
    console.log("[debug] QB: Portal user closed the session by clicking, session: ", ctrl.videoSession);
    if (ctrl.sessionDetails.video_session.recording_enabled) {
      try {
        ctrl.recorder.onstop = function () {
          chatService.requestUploadFile(ctrl.sessionDetails.id, function (
            response
          ) {
            var formData = new FormData();
            Object.keys(response.fields).forEach(function (param) {
              if (param === "key") {
                formData.append(
                  "key",
                  response.fields[param].split("$")[0] +  "" + moment().unix() + "recordedVideo.webm"
                );
              } else {
                formData.append(param, response.fields[param]);
              }
            });
            formData.append(
              "file",
              new Blob(ctrl.data, { type: "video/webm" })
            );
            var request = new XMLHttpRequest();
            request.open("POST", response.url);
            request.send(formData);
          });
        };
        ctrl.recorder.stop();
        ctrl.merger.destroy();
      } catch (error) {
        console.error(error);
      }
    }
    ctrl.showVideoChat = false;
    ctrl.callOngoing = false;
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
  };

  ctrl.onMute = function () {
    if (ctrl.audioMuted) {
      quickBloxService.unmuteAudioSession(ctrl.videoSession);
    } else {
      quickBloxService.muteAudioSession(ctrl.videoSession);
    }
    ctrl.audioMuted = !ctrl.audioMuted;
  };

  ctrl.onCloseCamera = function () {
    if (ctrl.videoMuted) {
      quickBloxService.unmuteVideoSession(ctrl.videoSession);
    } else {
      quickBloxService.muteVideoSession(ctrl.videoSession);
    }
    ctrl.videoMuted = !ctrl.videoMuted;
  };

  var onCallAcceptedCallback = function () {
    console.log("[debug] QB: Call accepted ", ctrl.videoSession);
    ctrl.state = "Accepted";
    ctrl.callOngoing = true;
    // ctrl.streamMerger = streamMergerService.init(
    //   "#main_video",
    //   "#remove_main_video"
    // );
    // // ctrl.streamMerger.getMergedStream().then(function (stream) {
    // //   ctrl.recorder = new MediaRecorder(stream);
    // //   ctrl.data = [];
    // //   ctrl.recorder.ondataavailable = (event) => ctrl.data.push(event.data);
    // //   ctrl.recorder.start();
    // //   console.log(stream)
    // // })
    if (ctrl.sessionDetails.video_session.recording_enabled) {
      try {
        ctrl.merger = new VideoStreamMerger();
        ctrl.merger.addStream(
          document.querySelector("#main_video").captureStream(),
          {
            x: 0,
            y: 0,
            width: ctrl.merger.width / 2,
            height: ctrl.merger.height,
          }
        );
        setTimeout(function () {
          ctrl.merger.addStream(
            document.querySelector("#remote_main_video").captureStream(),
            {
              x: ctrl.merger.width / 2,
              y: 0,
              width: ctrl.merger.width / 2,
              height: ctrl.merger.height,
            }
          );
          ctrl.merger.start();
          ctrl.recorder = new MediaRecorder(ctrl.merger.result);
          ctrl.data = [];
          ctrl.recorder.ondataavailable = function (event) { 
            return ctrl.data.push(event.data);
          }
          ctrl.recorder.start();
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    }
  };

  var onUserNotAnswerCallback = function () {
    console.log("[debug] QB: User not Answer call from QB, ", ctrl.videoSession);
    ctrl.state = "User did not answered!";
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
  };

  var onCallRejectCallback = function () {
    console.log("[debug] QB: User rejected call from Mobile, ", ctrl.videoSession);
    ctrl.state = "User has rejected the call";
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
  };

  var sessionInitializedCallback = function (session) {
    console.log("[debug] QB: QB session initialized callback session: ", session);
    ctrl.videoSession = session;
  };

  var onStopCallCallback = function () {
    console.log("[debug] QB: QB or mobile has closed the session ", ctrl.videoSession);
    if (ctrl.sessionDetails.video_session.recording_enabled) {
      try {
        ctrl.recorder.onstop = function () {
          chatService.requestUploadFile(ctrl.sessionDetails.id, function (
            response
          ) {
            var formData = new FormData();
            Object.keys(response.fields).forEach(function (param) {
              if (param === "key") {
                formData.append(
                  "key",
                  response.fields[param].split("$")[0] + "" + moment().unix() + "recordedVideo.webm"
                );
              } else {
                formData.append(param, response.fields[param]);
              }
            });
            formData.append(
              "file",
              new Blob(ctrl.data, { type: "video/webm" })
            );
            var request = new XMLHttpRequest();
            request.open("POST", response.url);
            request.send(formData);
          });
        };
        ctrl.recorder.stop();
      } catch (error) {
        console.error(error);
      }
    }
    quickBloxService.hangUpVideoSession(ctrl.videoSession);
    ctrl.callOngoing = false;

    ctrl.state = "Patient has ended the call";
    setTimeout(function () {
      ctrl.showVideoChat = false;
      ctrl.state = "Connecting...";
    }, 1000);
  };

  function dragElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
      // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  $rootScope.initVideoCall = function (session, chatDialogId) {
    ctrl.sessionDetails = session;
    console.log(session)
    ctrl.showVideoChat = true;
    dragElement(document.getElementById("video-drag"));
    setCallStatusToDefault();
    ctrl.patientName = ctrl.sessionDetails.payment.payer.fullname;
    var caller_photo = $rootScope.profiles.avatar ? $rootScope.profiles.avatar.styles.origin.url : "";
    var recordingEnabled = ctrl.sessionDetails.video_session.recording_enabled;
    ctrl.videoSession = quickBloxService.joinQBVideo(
      chatDialogId.dialogId,
      {
        caller_name: $rootScope.profiles.name,
        caller_photo: caller_photo,
        recording_enabled: recordingEnabled,
      },
      onCallAcceptedCallback,
      onUserNotAnswerCallback,
      onCallRejectCallback,
      sessionInitializedCallback,
      onStopCallCallback,
      function () {
        console.log("[debug] QB: Media problem mic or video ", ctrl.videoSession);
        alert( "You don't have a mic or cam or enabled permission to start the video call." );
        quickBloxService.hangUpVideoSession(ctrl.videoSession);
        ctrl.callOngoing = false;
        ctrl.showVideoChat = false;
        ctrl.callOngoing = false;
      },
      function () {
        var notificationPayload = {
          user_id: ctrl.sessionDetails.payment.payer.id,
        };
        if (ctrl.sessionDetails.video_session.twilio_video_enabled) {
          notificationPayload.room =
            ctrl.sessionDetails.video_session.twilio_video_room;
        }
        chatService.initializeCall(notificationPayload, function () {});
      },
      {
        current_time: moment().unix(),
        name: ctrl.sessionDetails.payment.payer.fullname,
      },
      !ctrl.sessionDetails.video_session.twilio_video_enabled
    );
  };
}

VideoCtrl.$inject = [
  "$rootScope",
  "quickBloxService",
  "chatService",
  "moment",
];

module.exports = VideoCtrl;
