'use strict';
var EBCtrl = function ($rootScope) {
  console.log("Here is the EB CTRL");
  $rootScope.showIndicator = false;

  var ctrl = this;
  ctrl.activeTab = 1; //0 -> dashboard, 1 -> pre-registeration, 2 -> claims


  ctrl.onPreRegistrationsClicked = function () {
    console.log("On pre registrations clicked");
    ctrl.activeTab = 1;
  };

  ctrl.onClaimsClicked = function () {
    console.log("On claims clicked");
    ctrl.activeTab = 2;
  };
};

EBCtrl.$inject = ['$rootScope'];
module.exports = EBCtrl;
