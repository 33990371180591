/* Define controller for appointments module*/
'use strict';

var ReferralCtrl = function () {

  var vm = this;

};

ReferralCtrl.$inject = [];

module.exports = ReferralCtrl;
