"use strict";
var QRCodeModalController = function ($modalStack, QRCodeUrl, $rootScope) {
  var ctrl = this;
  ctrl.qrcodeUrl = QRCodeUrl;
  ctrl.profiles = $rootScope.profiles;
  ctrl.username = (ctrl.profiles.title ? ctrl.profiles.title + ' ' : '') + ctrl.profiles.name;

  console.log(ctrl.username);

  ctrl.onCloseClicked = function () {
    closeModal();
  };

  ctrl.onPrintClicked = function () {
    var printContents = document.getElementById("qr-code-container").innerHTML;
    var baseHtmlTag = document.getElementsByTagName("base")[0].outerHTML;
    console.log(baseHtmlTag);
    var popupWin = window.open('', '_blank', 'width=500,height=800');
    popupWin.document.open();
    popupWin.document.write('<html><head>' + baseHtmlTag + '<script>' +
      'function onLoadEvent() {\n' +
      '  setTimeout(function () {\n' +
      '    window.print();\n' +
      '  }, 1500);\n' +
      '}\n</script> <link rel="stylesheet" type="text/css" href="assets/print/print.css">' +
      '<link rel="stylesheet" type="text/css" media="print" href="assets/print/print.css" /></head><body onload="onLoadEvent()">' +
      printContents +
      '<div class="powered-by-container"><div class="powered-by">Powered by</div><img src="assets/images/logo.png" class="qr-logo"/></div></body></html>');
    popupWin.document.close();
  };

  var closeModal = function () {
    // Close modal
    $modalStack.dismissAll();
  };
};

QRCodeModalController.$inject = ['$modalStack', 'QRCodeUrl', '$rootScope'];

module.exports = QRCodeModalController;
