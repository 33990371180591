'use strict';

var specialtyDirective = function (APP_CONSTANT, ListPracticeService, SearchDropdownService) {
  return {
    restrict: 'E',
    templateUrl: 'registration/views/specialty.html',
    scope: {
      specialtyOne: '=specialtyOne',
      specialties: '=specialties',
      specialtiesSelect: '=specialtiesSelect',
      validate: '=validate'

    },
    link: function(scope, element, attrs) {

      scope.specialtyName = '';

      // Get list important specialties
      // Important specialties is GP Doctor (Aesthetic Medicine) and Dentist (General)
      scope.importantSpecialties = APP_CONSTANT.importantSpecialties;

      // Validate for specialty
      scope.validateSpecialty = function() {
        ListPracticeService.validateSpecialty(scope.specialtiesSelect);
        scope.validate.required = ListPracticeService.required;
        scope.validate.duplicate = ListPracticeService.duplicate;
      };

      // Get specialties list
      scope.selectSpecialty = function (id, name, index) {
        scope.specialtiesSelect[index].id = id;
        scope.specialtiesSelect[index].name = scope.specialtyName = name;

        // Validate specialty
        scope.validateSpecialty();

        var specialtyOneName = scope.specialtiesSelect[0].name;

        // check specialty one choose GP Doctor (Aesthetic Medicine) or Dentist (General) will hide select specialty two
        if (specialtyOneName === scope.importantSpecialties[0] || specialtyOneName === scope.importantSpecialties[1] || specialtyOneName === scope.importantSpecialties[2]) {
          scope.specialtiesSelect[1].show = false;

        } else {
          scope.specialtiesSelect[1].show = true;
        }

      };

      /* Handle auto complete for specialties dropdown*/

      // handle when toggle dropdown
      scope.toggleDropdown = function () {
        scope.searchKey = '';
        SearchDropdownService.setAutoFocus();
      };

      // keep dropdown open when click on input tab
      scope.keepDropdownOpen = function($event) {
        $event.stopPropagation();
      };

    }
  };
};

specialtyDirective.$inject = [
  'APP_CONSTANT',
  'ListPracticeService',
  'SearchDropdownService'
];

module.exports = specialtyDirective;
