'use strict';

var SnackbarService = function () {

  return {

    showSnakeBar: function (messages) {
      var snackbarElement = document.createElement('div');
      snackbarElement.innerText = messages;
      snackbarElement.id = "snackbar";
      document.body.append(snackbarElement);
      snackbarElement.className = "show";
      setTimeout(function () {
        snackbarElement.className = snackbarElement.className.replace("show", "");
        setTimeout(function () {
          snackbarElement.parentNode.removeChild(snackbarElement)
        }, 2500);
      }, 3000);
    }

  };

};

SnackbarService.$inject = [];

module.exports = SnackbarService;