/*globals FileReader*/

'use strict';

var UploadPhotoCtrl = function ($scope, $modalInstance, getProfiles, avatarsAPI, $timeout, ValidateMessagesService) {
  var vm = this;

  vm.profiles = getProfiles.profiles;

  // validation messages
  vm.messages = ValidateMessagesService;

  vm.files = [];

  vm.limitSubmit = false;

  vm.uploadAvatarError = {
    selectFile: false,
    wrongType: false,
    bigSize: false
  };

  vm.documentsFormat = 'image/jpeg,image/jpg,image/png';

  vm.isDeleteAvatar = false;

  vm.closeModal = function () {
    $modalInstance.dismiss('cancel');
  };

  vm.uploadFiles = function () {
    if (angular.element('#imageShow').attr('src') === 'assets/images/avatar-default.png' && !vm.profiles.avatar.styles.origin.url) {
      vm.uploadAvatarError.selectFile = true;
      return;
    }

    if (vm.files[0] && !vm.limitSubmit || vm.isDeleteAvatar ) {

      vm.limitSubmit = true;

      if (vm.isDeleteAvatar) {
        vm.uploadAvatarError.selectFile = false;
        vm.imageUpload = {};

      } else {
        vm.imageUpload = vm.files[0];
      }

      avatarsAPI
        .postAvatar(vm.imageUpload)
        .then(
          function (data) {
            // Using local image to show avatar when upload to avoid data response error 
            var newAvatar = {
              styles: {
                origin: {
                  url: angular.element('#imageShow').attr('src')
                }
              }
            };
            getProfiles.profiles.avatar = newAvatar;
            vm.limitSubmit = false;
            $modalInstance.dismiss('cancel');

          },
          function (data) {
            vm.limitSubmit = false;
          }
        );
    } else {
      vm.uploadAvatarError.selectFile = true;
    }

  };

  $scope.changeFile = function (element) {
    // Reset all validation
    vm.uploadAvatarError.wrongType = false;
    vm.uploadAvatarError.bigSize = false;
    vm.uploadAvatarError.selectFile = false;

    var fileType = element.files[0].type;
    var isAccepted = vm.documentsFormat.match(fileType);

    if (isAccepted && isAccepted.length > 0 && fileType !== "") {

      vm.isDeleteAvatar = false;
      $scope.$apply(function () {
        if (element.files[0].size > 4194304) {
          vm.uploadAvatarError.bigSize = true;
        } else {
          var reader = new FileReader();

          vm.files = element.files;

          reader.onload = function (e) {
            vm.appointmentLen = angular.element('#imageShow').attr('src', e.target.result);
          };
          if (vm.files[0]) {
            vm.uploadAvatarError.selectFile = false;
            vm.uploadAvatarError.wrongType = false;
            vm.uploadAvatarError.bigSize = false;
            reader.readAsDataURL(vm.files[0]);
          };
        }

      });

    } else {

      $scope.$apply(function () {
        vm.uploadAvatarError.wrongType = true;
      });

    }

  };

  vm.deleteAvatar = function () {
    vm.isDeleteAvatar = true;
    vm.uploadAvatarError.wrongType = false;
    vm.uploadAvatarError.bigSize = false;
    vm.uploadAvatarError.selectFile = false;
    angular.element('#imageShow')[0].src = 'assets/images/avatar-default.png';
  };
};

UploadPhotoCtrl.$inject = [
  '$scope',
  '$modalInstance',
  'getProfiles',
  'bookdoc.avatars.api',
  '$timeout',
  'ValidateMessagesService'
];

module.exports = UploadPhotoCtrl;
