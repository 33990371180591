/* Define controller for Terms of User module*/
'use strict';

var PrivacyCtrl = function($rootScope) {
  var vm = this;
  vm.title = "Privacy";
  $rootScope.showIndicator = false;
};

PrivacyCtrl.$inject = [
  '$rootScope'
];

module.exports = PrivacyCtrl;
