// TODO: handle event for Operating Hours modal
'use strict';

var _ = require('lodash');

var OperatingHoursCtrl = function($modalInstance, operating, OperatingHour, moment, InformationAPI, $scope, $rootScope, CommonModals, ValidateMessagesService) {
  var vm = this;

  vm.operatingHoursData = [];
  vm.operatingHourUpdate = [];
  vm.isUpdating = false;

  // validation messages
  vm.messages = ValidateMessagesService;

  var operatingDataLength = operating.operatingData.length;

  vm.operatingHoursData = operating.operatingData;

  // push practice_location_id and isOpen properties into operating hours
  _.forEach(vm.operatingHoursData, function(operatingHour) {

    if (!operatingHour.practice_location_id) {
      operatingHour.practice_location_id = operating.practiceLocationId;
    }

    operatingHour.isOpen = operatingHour.open_time !== '' && operatingHour.open_time !== null;

  });

  vm.operatingDataBinding = angular.copy(vm.operatingHoursData);

  vm.operatingError = {
    invalidTime: false,
    isOverlapBreakTime: false
  };

  $scope.$on('changeOperating', function () {
    // check operating hour
    vm.operatingError.invalidTime = OperatingHour.validationOperating(vm.operatingDataBinding);
  });

  /**
   * [cancel - handle when user click cancel button]
   *
   */
  vm.cancel = function() {
    $modalInstance.dismiss('cancel');
  };

  // Edit Operating hours
  vm.save = function() {

    if (vm.isUpdating || vm.operatingError.invalidTime || vm.operatingError.isOverlapBreakTime) {
      return;
    }

    vm.isUpdating = true;

    vm.operatingHourUpdate = OperatingHour.formatOperatingData(vm.operatingDataBinding, false);

    var operatingDataUpdate = {
      "operating_hours" : vm.operatingHourUpdate
    };

    InformationAPI
      .operatingHours
      .updateOperatingHours(operatingDataUpdate)
      .then(function(data) {
        _.forEach(operating.locations, function(location) {
          if (location.id === operating.practiceLocationId) {
            location.operating_hours = data;
          }
        });
        vm.cancel();
      },
      function(error) {

        CommonModals.openBaseModal("Server Error!");
      })
      .finally(function() {
        vm.isUpdating = false;
      });
  };
};

OperatingHoursCtrl.$inject = [
  '$modalInstance',
  'operating',
  'OperatingHour',
  'moment',
  'bookdoc.information.api',
  '$scope',
  '$rootScope',
  'CommonModals',
  'ValidateMessagesService'
];

module.exports = OperatingHoursCtrl;
