'use strict';

var dashboardApiService = function (Restangular) {

  return {
    
    getDoctorStatistic: function (params) {
      return Restangular.all('doctors').customGET("statistics", params);
    }

  };

};

dashboardApiService.$inject = [
  'Restangular'
];

module.exports = dashboardApiService;
