'use strict';

var _ = require('lodash');

var ArrayService = function() {
  return {
    removeEmptyElement: function(arr, property) {
      arr = _.filter(arr, function(item) {
        return item[property].trim() !== '';
      });

      return arr;
    },
    addProperty: function(arr, propertyName, value) {
      _.forEach(arr, function(item) {
        item[propertyName] = value;
      });
      return arr;
    },
    removeDuplicate: function(arr, propertyName) {
      // remove duplicate item by property
      arr = _.uniq(arr, propertyName);

      return arr;
    },
    removeExistedItem: function(arr, currentArr, propertyName) {
      // remove existed item in current profiles
      _.forEach(currentArr, function (currentItem) {
        _.remove(arr, {name: currentItem[propertyName].toString()});
      });

      return arr;
    },
    checkDuplicateItem: function(arr, propertyName) {

      var isEmptyObject = false;
      var removeDuplicateArr = _.uniq(arr, function(item) {

        if (item[propertyName]) {

          if (_.isNumber(item[propertyName])) {
            return item[propertyName];
          }

          return item[propertyName].toLowerCase();
        }

        isEmptyObject = true;

      });

      return isEmptyObject ? false : removeDuplicateArr.length < arr.length;

    },
    checkEmptyItem: function(arr, propertyName) {
      var isExistedEmptyTextbox = _.filter(arr, function (item) {
        if (!item[propertyName]) {
          item[propertyName] = '';
        }
        return item[propertyName].trim() === '';
      }) || [];

      return isExistedEmptyTextbox.length > 0;
    },
    /**
     * [arrayToString - concat item in array to string]
     * @param [array] arr
     * @param [string] propertyName
     * @param [string] delimiter - use to seperate item in string
     *
     */
    arrayToString: function(arr, propertyName, delimiter) {
      var outputString = '';
      _.forEach(arr, function(item) {
        outputString += item[propertyName] + delimiter;
      });

      // substring to remove the last semicolon
      return outputString.substring(0, outputString.length - 1);
    },

    /**
     * [getLeftDifferent - get object that contain in arr1 but not in arr2 (check by propertyName)]
     * @param [array] arr1
     * @param [array] arr2
     * @param [string] propertyName
     *
     */
    getLeftDifferent: function(arr1, arr2, propertyName) {
    _.forEach(arr2, function (item2) {
      _.remove(arr1, function (item1) {
        return item1[propertyName] === item2[propertyName];
      });
    });

    return arr1;
  }

  };
};

ArrayService.$inject = [
];

module.exports = ArrayService;
