'use strict';

var _ = require('lodash');

var ReferDoctor = function ($modalInstance, $modal, SpecialtiesApi, SearchDropdownService, referApiService, $rootScope, sendData,APP_CONSTANT, ValidateMessagesService, ValidationFileTypeService, $scope, ChainRequestService, referService, CommonModals) {
  var vm = this;
  
  vm.specialtiesList = [];
  vm.filterKey = '';
  vm.speciatySelected = {};
  vm.searchKey = '';
  vm.doctorList = [];
  vm.showSuggestLink = false;
  vm.doctorSelected = {};
  vm.referFromCalendar = sendData.referFromCalendar;

  var specialtyCount = sendData && sendData.specialties.length;

  vm.patientName = sendData.patientName || '';
  vm.patientPhone = sendData.patientPhone || null;
  vm.patientEmail = sendData.patientEmail || '';

  vm.showPatientEmail = !!sendData.patientEmail;

  vm.pattern = APP_CONSTANT.patterns;

  // Define characters limit of referral note
  vm.charectersLimit = APP_CONSTANT.charactersLimit;

  vm.documentFileFormat = APP_CONSTANT.documentFileFormat;

  vm.validateMessages = ValidateMessagesService;

  vm.showDoctorSpecialtyError = false;

  vm.invalidFile = {
    fileSize: false,
    wrongType: false
  };

  vm.invalidNote = false;

  vm.letterFileName = '';

  vm.isUploading = false;

  vm.documentData = {};

  vm.referLetterId = null;

  vm.referralNote = '';

  vm.isSearching = false;
  vm.isExistDoctorName = true;

  vm.isOpenDropdown = false;

  // Get specialties
  function getSpecialties() {
    SpecialtiesApi.getList()
      .then(
        function (data) {
          vm.specialtiesList = data;
          $rootScope.$broadcast('updateSpecialties', vm.specialtiesList);
        },

        function (error) {
          console.log(error);
        }
      );
  }

  if (!specialtyCount) {
    getSpecialties();
  } else {
    vm.specialtiesList = sendData.specialties;
  }

  // handle when toggle dropdown
  vm.toggleDropdown = function () {
    vm.filterKey = '';
    SearchDropdownService.setAutoFocus();
  };

  // keep dropdown open when click on input tab
  vm.keepDropdownOpen = function ($event) {
    $event.stopPropagation();
  };

  // Close referral modal
  vm.closeModal = function () {
    $modalInstance.dismiss();
  };

  vm.selectSpecialty = function (specialty) {
    vm.speciatySelected = specialty;
    checkErrorSpecialty();
    checkDoctorSpecialty();
    vm.searchDoctor();
  };

  var searchByName = _.debounce(function() {
    searchReferees();
  }, 200);

  // Search Doctor
  vm.searchDoctor = function () {
    vm.isOpenDropdown = true;
    if (vm.searchKey) {
      vm.isSearching = true;
      searchByName();
    } else {
      vm.showSuggestLink = false;
      vm.doctorList = [];
    }
  };

  vm.selectDoctor = function (doctor) {
    vm.doctorSelected = doctor;
    vm.searchKey = doctor.name;
    checkErrorDoctor();
    checkDoctorSpecialty();
  };

  function searchReferees() {
    var searchParams = {
      keyword: vm.searchKey,
      specialty_id : vm.speciatySelected.id,
      // To limit data respone when search doctor need to fixed pageindex and perpage params
      page: 1,
      per_page: 12
    };
    referApiService.searchReferees(searchParams)
      .then(
        function (data) {
          vm.doctorList = data.doctors;
          var doctorCount = vm.doctorList.length;
          vm.showSuggestLink = doctorCount ? false : true;
        },

        function (error) {
          console.log(error);
        }
      )
      .finally(function() {                
        vm.isSearching = false;              
      });
  }

  // Open suggest doctor modal
  vm.openSuggestModal = function () {
    vm.referForm.$setSubmitted();

    if (vm.referForm.$invalid) {
      CommonModals.openBaseModal('Please fill in Patient information to proceed.');
      return;
    }

    $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            referralData: {
              referral_letter_id: vm.referLetterId,
              patient_name: vm.patientName,
              specialty_id: vm.speciatySelected.id,
              patient_email: vm.patientEmail || null,
              patient_phone_number: vm.patientPhone.replace(vm.pattern.formatPhoneNumber, ''),
              note: vm.referralNote || null
            },
            referFromCalendar: vm.referFromCalendar
          };
        }
      },
      templateUrl: 'referral/refer-to/views/suggest-doctor-modal.html',
      controller: require('./suggest-doctor.controller'),
      controllerAs: 'suggest',
      windowClass: 'modal-info'
    });
  };

  // Handle when select file
  $scope.changeFile = function(element) {
    var documentFile = element.files[0];
    if (!documentFile) {
      return;
    }
    var fileType = documentFile.type;
    var isValidType = ValidationFileTypeService.checkFileType(fileType, vm.documentFileFormat);

    vm.invalidFile.wrongType = isValidType;

    if (isValidType) {

      $scope.$apply(function() {

        vm.invalidFile.wrongType = false;

        vm.fileSize = documentFile.size;

        vm.invalidFile.fileSize = ValidationFileTypeService.checkFileSize(vm.fileSize);

        if (!vm.invalidFile.fileSize) {
          vm.documentData = documentFile;
          vm.isUploading = true;

          var chain = ChainRequestService.startQueue();

          if (vm.referLetterId) {
            // delete old document
            chain = chain.then(deleteLetter);
            chain = chain.then(function (data) {
              handleDeleteSuccess();
            });
          }

          // upload document
          chain = chain.then(uploadLetter);
          chain = chain.then(function (data) {
            handleUploadSuccess(data);
            vm.referFileName = documentFile.name;
          }, function (err) {
            console.log('error');
          });
        } else {

          vm.referFileName = '';
        }

      });

    } else {
      $scope.$apply(function() {
        vm.referFileName = '';
        vm.invalidFile.wrongType = true;
      });
    }
  };

  // Call service to upload refer letter
  function uploadLetter() {
    return referApiService.addReferralLetter(vm.documentData); 
  };

  // Call service to delete refer letter
  function deleteLetter() {
    return referApiService.deleteReferralLetter(vm.referLetterId); 
  };

  // Handle when upload success
  function handleUploadSuccess(data) {
    vm.referLetterId = data.id;
    vm.isUploading = false;
  };

  // Handle when delete success
  function handleDeleteSuccess() {
    angular.element('#uploadReferLetter').val(null);
    vm.referFileName = '';
    vm.referLetterId = null;
  };

  // Delete refer letter document
  vm.deleteLetterDocument = function() {
    var chain = ChainRequestService.startQueue();
    chain = chain.then(deleteLetter);
    chain = chain.then(function (data) {
      handleDeleteSuccess();
    });
  };

  // Check error specialty
  function checkErrorSpecialty() {
    vm.isExistSpecialty = vm.speciatySelected.id;
  }

  // Check error Doctor name
  function checkErrorDoctor() {
    vm.isExistDoctorName = vm.doctorSelected.id;
  }

  function checkDoctorSpecialty() {
    if (vm.doctorSelected && vm.doctorSelected.specialties && vm.speciatySelected && vm.speciatySelected.id) {
      vm.showDoctorSpecialtyError = !referService.checkDoctorSpecialty(vm.doctorSelected.specialties, vm.speciatySelected.id); 
    }  
  }

  vm.referDoctor = function() {
    vm.referForm.$setSubmitted();
    checkErrorDoctor();

    if (vm.referForm.$invalid || vm.showDoctorSpecialtyError || !vm.isExistSpecialty || !vm.isExistDoctorName) {
      return;
    }

    var referParams = {
      referee_id: vm.doctorSelected.id,
      referral_letter_id: vm.referLetterId,
      patient_name: vm.patientName,
      specialty_id: vm.speciatySelected.id,
      patient_email: vm.patientEmail || null,
      patient_phone_number: vm.patientPhone.replace(vm.pattern.formatPhoneNumber, ''),
      note: vm.referralNote || null
    };

    referApiService.referDoctor(referParams)
      .then(
        function (data) {
          referService.showConfirmModal();
          if (!vm.referFromCalendar) {
            $rootScope.$broadcast('getReferral');
          }
        },

        function (error) {
          if (error.status === 422 && error.data) {
            referService.showReferralError(error.data);
          }
        }
      );
  };
};

ReferDoctor.$inject = [
  '$modalInstance',
  '$modal',
  'bookdoc.specialties.api',
  'SearchDropdownService', 
  'referApiService',
  '$rootScope',
  'sendData',
  'APP_CONSTANT',
  'ValidateMessagesService',
  'ValidationFileTypeService',
  '$scope',
  'ChainRequestService',
  'referService',
  'CommonModals'
];

module.exports = ReferDoctor;
