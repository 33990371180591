'use strict';

var _ = require('lodash');

var LocationModalCtrl = function ($modalInstance, InformationAPI, $modal, resolveData, $scope, CommonModals, autoFocusService) {

  var vm = this;

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {
    vm.locations = [];
    vm.selectedLocation = {};
    vm.isLoading = false;
    vm.showNoSearchResultMsg = false;
    vm.isOpenDropdown = false;
    vm.timezoneId = resolveData.timezoneId; 
  }

  createVariables();

  /**
   * [searchLocation - handle for autocomplete textbox]
   * @param  {string} searchKey
   *
   */
  vm.searchLocation = function (searchKey) {
    vm.isLoading = true;
    vm.isOpenDropdown = true;
    var searchExec = _.debounce(function() {
      InformationAPI
        .locations
        .searchLocation(searchKey, vm.timezoneId)
        .then(function(data) {

          var searchResultCount = data.length;

          vm.showNoSearchResultMsg = searchResultCount === 0;
          vm.locations = data;

        },
        function(data) {
          CommonModals.openBaseModal('Server error!');
        })
        .finally(function() {
          vm.isLoading = false;
        });
    },
    200);

    if (searchKey.trim() !== '') {
      searchExec();
    } else {
      vm.locations = [];
      vm.isLoading = false;
      vm.showNoSearchResultMsg = false;
    }
  };

  /**
   * [save - handle user click save button]
   *
   */
  vm.save = function() {
    $modalInstance.close();
    $modal.open({
      animation: true,
      resolve: {
        sendData: function () {
          return {
            locations: vm.locations,
            selectedLocation: vm.selectedLocation,
            listCountries: resolveData.listCountries
          };
        }
      },
      templateUrl: 'profile/views/information/location-with-address.modal.html',
      controller: require('./location-with-address.controller'),
      controllerAs: 'locaAdd',
      windowClass: 'modal-info other-location'
    });
  };

  /**
   * [cancel - handle user click cancel button]
   *
   */
  vm.cancel = function() {
    $modalInstance.dismiss('cancel');
    console.log('cancel');
  };

  /**
   * Open contact us modal
   */
  vm.contact = function() {
    $scope.$emit('showModal', {
      remove_location: true
    });
  };
};

LocationModalCtrl.$inject = [
  '$modalInstance',
  'bookdoc.information.api',
  '$modal',
  'sendData',
  '$scope',
  'CommonModals',
  'autoFocusService'
];

module.exports = LocationModalCtrl;
