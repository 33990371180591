'use strict';

var _ = require('lodash');
var educationDirective = function($rootScope, EducationRegistrationService, ValidationFileTypeService, ValidateMessagesService, CommonModals, APP_CONSTANT, RegistrationAPI, ChainRequestService) {
  return {
    restrict: 'E',
    scope: {
      registrationData: '=registrationData',
      educationActive: '=educationActive',
      educationsData: '=educationsData',
      isEditEducation: '=isEditEducation',
      currentStep: '=currentStep'
    },
    templateUrl: 'registration/views/education.html',
    link: function(scope, elem, attrs) {
      scope.existedEducations = angular.copy(scope.educationsData.existedEducations) || [];

      scope.newEducations = [];
      
      scope.validateMessages = ValidateMessagesService;

      scope.currentIndex = 0;

      scope.isUpdating = false;

      scope.isUploading = false;

      scope.documentData = {};

      scope.invalidEducation = {
        disableAdd: true,
        isShowError: false,
        duplicate: false,
        fileSize: false,
        wrongType: false,
        isEmpty: false
      };

      scope.countExistedEducations = scope.existedEducations.length;
      scope.educationTotal = angular.copy(scope.existedEducations.length);

      scope.addNewEducation = function() {
        scope.newEducations.push({
          name: '',
          updated: true,
          fileName: '',
          document_id: ''
        });
      };

      if (scope.isEditEducation) {
        scope.invalidEducation.disableAdd = false;
      } else {
        scope.addNewEducation();
        scope.educationTotal++;
      }

      scope.documentsFormat = APP_CONSTANT.documentFileFormat;

      // Remove school from newEducation array
      function removeSchool() {
        scope.newEducations.splice(scope.currentIndex, 1);
        scope.invalidEducation.isEmpty = scope.newEducations.length === 0;
        scope.invalidEducation.disableAdd = !EducationRegistrationService.validate(scope.newEducations);
        scope.invalidEducation.duplicate = EducationRegistrationService.checkDuplicateItem(scope.existedEducations.concat(scope.newEducations));
      };

      // Add new school
      scope.addSchool = function() {
        scope.addNewEducation();
        scope.invalidEducation.disableAdd = true;
        scope.educationTotal++;
      };


      // Delete school
      scope.deleteNewSchool =  function(index) {
        scope.currentIndex = index;
        var chain = ChainRequestService.startQueue();

        if (scope.newEducations[scope.currentIndex].document_id) {
          chain = chain.then(deleteEducation);
          chain = chain.then(function (data) {
            handleDeleteSuccess();
          });
        } 
        chain = chain.then(function () {
          removeSchool();
          scope.educationTotal--;
        });
      };

      function removeExistedSchool(id) {
        _.forEach(scope.existedEducations, function(education) {
          if (education.id === id) {
            education.updated = false;
          }
        });
      };

      scope.deleteExistedSchool =  function(id) {
        removeExistedSchool(id);
        scope.countExistedEducations--;
        scope.educationTotal--;
        scope.invalidEducation.duplicate = EducationRegistrationService.checkDuplicateItem(scope.existedEducations.concat(scope.newEducations));
      };


      // Handle when textbox changes]
      scope.inputChanged = function() {
        scope.invalidEducation.disableAdd = !EducationRegistrationService.validate(scope.newEducations);
        scope.invalidEducation.duplicate = EducationRegistrationService.checkDuplicateItem(scope.existedEducations.concat(scope.newEducations));
      };

      // Change current index of education
      scope.changeCurrentIndex = function(index) {
        scope.currentIndex = index;
      };

      // Handle when select file
      scope.changeFile = function(element) {

        var documentFile = element.files[0];
        // check if no select file
        if (!documentFile) {
          return;
        }

        var fileType = documentFile.type;
        var isValidType = ValidationFileTypeService.checkFileType(fileType, scope.documentsFormat);
        scope.invalidEducation.wrongType = isValidType;

        if (isValidType) {

          scope.$apply(function(scope) {
            scope.fileSize = documentFile.size;
            scope.inValidFileSize = ValidationFileTypeService.checkFileSize(scope.fileSize);

            if (scope.inValidFileSize) {

              scope.invalidEducation.fileSize = true;
              scope.newEducations[scope.currentIndex].fileName = documentFile = '';

            } else {
              scope.isUploading = true;

              scope.invalidEducation.fileSize = false;
              scope.documentData = documentFile;

              var chain = ChainRequestService.startQueue();

              if (scope.newEducations[scope.currentIndex].document_id) {

                // delete old document
                chain = chain.then(deleteEducation);
                chain = chain.then(function (data) {
                  handleDeleteSuccess();
                });
              }

              // upload document
              chain = chain.then(uploadEducation);
              chain = chain.then(function (data) {
                handleUploadSuccess(data);
                scope.newEducations[scope.currentIndex].fileName = documentFile.name;
                scope.invalidEducation.disableAdd = !EducationRegistrationService.validate(scope.newEducations);
              }, function (err) {
                CommonModals.openBaseModal("Server error!");
              });
            }

            scope.invalidEducation.wrongType = false;
          });

        } else {
          scope.$apply(function(scope) {
            scope.invalidEducation.wrongType = true;
            scope.newEducations[scope.currentIndex].fileName = documentFile = '';
          });
        }
      };

      // Call service to upload education document
      function uploadEducation() {
        return EducationRegistrationService.uploadFile(scope.documentData); 
      };

      // Call service to delete education document
      function deleteEducation() {
        var documentID = scope.newEducations[scope.currentIndex].document_id;
        return RegistrationAPI.education.deleteDocument(documentID);
      };

      // Handle when upload success
      function handleUploadSuccess(data) {
        scope.newEducations[scope.currentIndex].document_id = data.id;
        scope.isUploading = false;
      };

      // Handle when delete success
      function handleDeleteSuccess() {
        angular.element('.school' + scope.currentIndex + ' ' + '.uploadFile').val(null);
        scope.newEducations[scope.currentIndex].fileName = scope.newEducations[scope.currentIndex].document_id = '';
        scope.invalidEducation.disableAdd = true;
      };

      // handle for remove education document
      scope.removeEducationDocument = function(index) {
        scope.currentIndex = index;
        var chain = ChainRequestService.startQueue();
        chain = chain.then(deleteEducation);
        chain = chain.then(function (data) {
          handleDeleteSuccess();
        });
      };

      scope.$on('validateEducationForm', function(event) {
        var educationsLength = scope.newEducations.length;
        scope.educationForm.$setSubmitted();
        if (scope.educationForm.$valid && !scope.invalidEducation.duplicate && educationsLength > 0) {
          scope.registrationData.educations = EducationRegistrationService.formatEducationData(scope.newEducations);
          $rootScope.$broadcast('validateHospitalForm');
        } else {
          scope.educationActive = true;
        }
      });

      scope.$on('submitEducationForm', function(event) {
        scope.educationForm.$setSubmitted();
        if (scope.educationForm.$valid && !scope.invalidEducation.duplicate) {
          scope.educationsData.newEducation = EducationRegistrationService.formatEducationData(scope.newEducations);
          scope.educationsData.existedEducations = scope.existedEducations;
          $rootScope.$broadcast('validateHospitalForm');
        } else {
          scope.educationActive = true;
        }
      });
    }
  };
};

educationDirective.$inject = [
  '$rootScope',
  'EducationRegistrationService',
  'ValidationFileTypeService',
  'ValidateMessagesService',
  'CommonModals',
  'APP_CONSTANT',
  'RegistrationAPI',
  'ChainRequestService'
];

module.exports = educationDirective;
