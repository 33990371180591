'use strict';

var FilterService = function() {
  return {
    // Check duplicate specialty that selected to filter
    checkDuplicate: function(specialtiesFilterList, newSpecialtyFilter) {

      var specialtiesCount = specialtiesFilterList.length;

      for (var i = 0; i < specialtiesCount; i++) {
        if (newSpecialtyFilter.id === specialtiesFilterList[i].id) {
          return true;
        }
      }

      return false;
    }

  };
};

FilterService.$inject = [
];

module.exports = FilterService;
