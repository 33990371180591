'use strict';

require('angular');
require('../config');
require('restangular');
require('angular-ui-bootstrap');
require('angular-ui-router');
require('angular-resource');
require('angular-moment');
require('angular-messages');
require('angular-scroll');
require('d3');
require('nvd3');
require('angular-nvd3');
require('angular-sanitize');
require('angular-timer');
require('angular-audio');
require("ng-file-upload");
require("rx");
require("QRCode");
require("angular-qr");
require("angular-rateit");
require("infinite-scroll");

// require('angular-ui-calendar');
// require('full-calendar');

angular.element(document).ready(function () {
  angular.bootstrap(document, [
    require('./app').name
  ], {
    strictDi: true
  });
});
