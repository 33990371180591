'use strict';

var MedicalNewsCtrl = function ($rootScope, $timeout, MedicalNewsService, APP_CONSTANT) {
  var vm = this;

  function createVariables () {
    $rootScope.showIndicator = false;

    vm.medicalNews = [];
    vm.availableCountry = angular.copy($rootScope.availableCountry);
    vm.availableCountries = APP_CONSTANT.mims.availableCountries;

    vm.articlesLimit = 10;
    vm.loadNewsDelay = 250;
  }

  function getMedicalNews () {
    vm.countryNotAvailable = false;

    MedicalNewsService.getMimsData(vm.availableCountry.code).then(
      function (res) {

        vm.medicalNewsLimit = vm.articlesLimit;

        if (res.data.query.results && res.data.query.results.rss) {
          vm.medicalNews = MedicalNewsService.formatData(res.data.query.results.rss.channel.item);
        } else {
          vm.countryNotAvailable = true;
          vm.medicalNews = [];
        }

        $rootScope.showIndicator = false;
      }, function (error) {
        $rootScope.showIndicator = false;
      }
    );
  }

  vm.loadMoreMedicalNews = function () {

    if ($rootScope.showIndicator || vm.medicalNewsLimit === vm.medicalNews.length) {
      return;
    }

    $rootScope.showIndicator = true;

    $timeout(function () {
      var limit = vm.medicalNewsLimit + vm.articlesLimit;
      var medicalNewsLength = vm.medicalNews.length;

      vm.medicalNewsLimit = limit <= medicalNewsLength ? limit : medicalNewsLength;
      $rootScope.showIndicator = false;
    }, vm.loadNewsDelay);

  };

  vm.medicalNewsFilter = function (country) {
    vm.availableCountry = country;
    getMedicalNews();
  };

  createVariables();
  getMedicalNews();
};

MedicalNewsCtrl.$inject = [
  '$rootScope',
  '$timeout',
  'MedicalNewsService',
  'APP_CONSTANT'
];

module.exports = MedicalNewsCtrl;
