"use strict";

function OpenClaimItemCtrl($rootScope, initialsService, moment, ebService, $modal) {
  var ctrl = this;
  init();

  ctrl.onCreateClaimClicked = function () {
    ctrl.onCreateClaimClickedCallback({claim: ctrl.claim});
  };

  function init() {
    console.log(ctrl.claim);
    ctrl.claim.initials = initialsService.getInitials(ctrl.claim.personal_info.policy_owner);
  }
}

var openClaimItemComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/open-claim-item.html',
    controller: OpenClaimItemCtrl,
    bindings: {
      claim: '<',
      type: '<',
      onCreateClaimClickedCallback: '&'
    }
  };
};

OpenClaimItemCtrl.$inject = ['$rootScope', 'InitialsService', 'moment', 'ebService', '$modal'];

module.exports = openClaimItemComponent();
