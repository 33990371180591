/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.calendar.appointments.services')
  .factory("appointment.api.service", ["Restangular", "TimeZoneService", function (Restangular, TimeZoneService) {
    var service = {
      getAppointments: getAppointments,
      getStatisticAppointments: getStatisticAppointments,
      cancelAppointment: cancelAppointment,
      pushFollowUpNotification: pushFollowUpNotification,
      createAppointment: createAppointment,
      noShowAppt: noShowAppt,
      createTimeOff: createTimeOff,
      deleteTimeOff: deleteTimeOff,
      updateTimeOff: updateTimeOff,
      setReminder: setReminder,
      sendMCData: sendMCData,
      setGoogleEventID: setGoogleEventID,
      getVisitReasons: getVisitReasons,
      getPanelShipReason: getPanelShipReason,
      sendIcsFile: sendIcsFile,
      getAvailableSlots: getAvailableSlots,
      createVirtualAppt: createVirtualAppt,
      createFollowUpAppointment: createFollowUpAppointment,
      rescheduleVirtualAppt: rescheduleVirtualAppt,
      getDayAppts: getDayAppts,
      getSmsAllocation: getSmsAllocation
    };

    function getAppointments(start, end, location_id, doctor_id) {

      var params = {
        start: TimeZoneService.dateToTimestamp(start)/1000,
        end: TimeZoneService.endOfADayTimestamp(end)/1000,
        location_id: location_id,
        doctor_id: doctor_id
      };

      return Restangular.all("appointments").getList(params);
    }

    function getStatisticAppointments(start, end, location_id, doctorIdParam) {
      var params = {
        start: TimeZoneService.dateToTimestamp(start)/1000,
        end: TimeZoneService.endOfADayTimestamp(end)/1000,
        location_id: location_id,
        doctor_id: doctorIdParam
      };

      return Restangular.all("appointments").customGET("statistics", params);
    }

    function cancelAppointment(apptId) {
      return Restangular.one("appointments", apptId).remove();
    }

    function pushFollowUpNotification(apptId, params) {
      return Restangular.one("appointments", apptId).customPOST(params, "remind");
    }

    function createAppointment(appointment, appointmentId) {
      if (appointmentId) {
        return Restangular.one('appointments', appointmentId).customPUT(appointment, null);
      } else {
        return Restangular.all('appointments').customPOST(appointment, 'schedule');
      }

    }

    function createFollowUpAppointment(appointment) {
      return Restangular.one('appointments/schedule?type=followup').customPOST(appointment, null);
    }

    function noShowAppt(apptId) {
      return Restangular.one("appointments", apptId).customPUT(null, "hide");
    }

    function createTimeOff(rules) {
      return Restangular.all("time_offs").customPOST(rules, null);
    }

    function deleteTimeOff(id) {
      return Restangular.one("time_offs", id).remove();
    }

    function updateTimeOff(start, end, timeOffId, note) {
      var timeOffParams = {
        start_time: start,
        end_time: end,
        note: note
      };
      return Restangular.one("time_offs", timeOffId).customPUT(timeOffParams, null);
    }

    function setReminder(reminderParams) {

      return Restangular.one("appointments", reminderParams.id).customPUT(reminderParams , "update_remind_at");
    }

    function setGoogleEventID (id, eventID) {
      var data = {
        id: id,
        google_event_id: eventID
      };
      return Restangular.one("appointments", id).customPUT(data, "update_google_event");
    }

    function sendMCData(appointmentId, sendData) {
      return Restangular.one("appointments", appointmentId).customPUT(sendData, "set_medical_certification");
    }

    function getVisitReasons() {
      return Restangular.all("visit_reasons").customGET("", null);
    }

    function getPanelShipReason(params) {
      return Restangular.all("visit_reasons").customGET("", params, {Accept: 'application/laelia.v2'});
    }

    function sendIcsFile(apptId) {
      return Restangular.one('appointments', apptId).one('send_ics').customPOST();
    }

    function getAvailableSlots(location, date) {
      return Restangular.one("slots").customGET("", {location: location, date: date});
    }

    function createVirtualAppt(appt) {
      return Restangular.all("video_sessions/qr_codes").customPOST(appt, null);
    }
  
    function rescheduleVirtualAppt(videoSessionId, appointmentId, params) {
      return Restangular.all("doctors/video_sessions/" + videoSessionId + "?appointment_id=" + appointmentId)
        .customPUT(
          params
        );
    }

    function getSmsAllocation(params){
      return Restangular.all("dr/doctor_sms_reminders/allocation").customGET("", params);
    }


    function getDayAppts(start, end, locationId) {
      var params = {
        start: TimeZoneService.dateToTimestamp(start)/1000,
        end: TimeZoneService.endOfADayTimestamp(end)/1000,
      };
      if (locationId)
        params['location_id'] = locationId

      return Restangular.all("appointments/day_list").getList(params);
    }

    return service;
  }]);
