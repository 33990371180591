'use strict';

module.exports =
  angular
    .module('bookdoc.header', [
      'ui.bootstrap',
      'ui.router',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('./controllers').name
    ]);

