'use strict';

var _ = require('lodash');

var EducationService = function ($q, ArrayService, informationAPI) {
  var obj = {

    // format educations data
    formatEducations: function(data) {
      // prepare data to send update education request
      var requestObject = {
        educations: []
      };

      // add all existed education that deleted
      _.forEach(data.existedEducations, function(education) {
        if (!education.updated) {
          requestObject.educations.push(education);
        }
      });

      // add all new education
      _.forEach(data.newEducation, function(education) {
        requestObject.educations.push(education);
      });

      return requestObject;
    },
    // Check updated education
    hasUpdated: function(educationData) {
      var requestObject =  angular.copy(educationData.newEducation);
      // add all existed education that deleted
      _.forEach(educationData.existedEducations, function(education) {
        if (!education.updated) {
          requestObject.push(education);
        }
      });

      if (requestObject.length === 0) {
        return false;
      }

      return true;
    }
  };

  return obj;
};

EducationService.$inject = [
  '$q',
  'ArrayService',
  'bookdoc.information.api',
];

module.exports = EducationService;
