"use strict";

function SubmittedClaimDetailsModalController(claim, $modalInstance) {
  console.log("Submitted Claim Details Modal");
  var ctrl = this;

  ctrl.claim = claim;
  console.log(ctrl.claim);

  ctrl.getHeaderBackgroundClass = function () {
    if (ctrl.claim.status == "approved") {
      return 'modal-header-approved-container';
    } else if (ctrl.claim.status == "pending") {
      return 'modal-header-pending-container';
    } else {
      return 'modal-header-rejected-container';
    }
  };

  ctrl.getDiagnosisListAsStr = function () {
    var diagnosesNames = "";
    for (var i = 0; i < ctrl.claim.diagnosis.length; i++) {
      diagnosesNames += ctrl.claim.diagnosis[i].name;
      if (i != ctrl.claim.diagnosis.length - 1) diagnosesNames += ", ";
    }
    return diagnosesNames;
  };

  ctrl.showRejectReason = function () {
    return ctrl.claim.status == "rejected";
  };

  ctrl.onCancelBtnClicked = function () {
    $modalInstance.dismiss();
  };
}

SubmittedClaimDetailsModalController.$inject = ['claim', '$modalInstance'];

module.exports = SubmittedClaimDetailsModalController;
