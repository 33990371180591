'use strict';
var _ = require('lodash');
var PhotoCtrl = function ($scope, $modal, photoAPI,  $timeout, profileData, $rootScope) {
  var vm = this;

  function createVariables() {

    vm.profiles = $rootScope.profiles = profileData || $rootScope.profiles;
    vm.locations = vm.profiles.locations;
    vm.photosData = [];

    vm.modalConfig = {
      isAnimation: false
    };
  };

  createVariables();

  getPhotos();

  function callApi(locationId) {

    $rootScope.showIndicator = true;
    
    photoAPI.getPhotos(locationId)
      .then(function(data) {
        var idx = _.findIndex(vm.locations, {'id': locationId});
        vm.locations[idx].photos = data;
      }, function(data) {
        console.log(data);
      })
      .finally(function() {
        $rootScope.showIndicator = false;
      });
  }

  function getPhotos() {
    for(var i = 0 ; i < vm.locations.length; i++) {
      if(vm.locations[i].verified === true) {
        callApi(vm.locations[i].id);
      }
    }
  }

  /**
   * [openLocationModal open add location form]
   *
   */
  vm.openLocationModal = function () {
    var modalInstance = $modal.open({
      animation: vm.modalConfig.isAnimation,
      resolve: {
        getProfiles: function () {
          return {
            profiles: vm.profiles
          };
        }
      },
      templateUrl: 'profile/views/photo/upload-profile-photo.html',
      windowClass: 'modal-info modal-info--profile',
      controller: require('./upload-photo.controller'),
      controllerAs: 'upload'

    });

  };


};

PhotoCtrl.$inject = [
  '$scope',
  '$modal',
  'bookdoc.photos.api',
  '$timeout',
  'profileData',
  '$rootScope'
];

module.exports = PhotoCtrl;
