// TODO: handle event for audit page
'use strict';

var _ = require('lodash');


var AuditCtrl = function(profileData, AUDITS_API_SERVICE, AUDITS_API_ROUTE, $rootScope, $scope, CachingQueryParams, moment, AuditsFormatMessage, TimeZoneService) {

  var vm = this;

  function createVariables() {
    vm.locations = profileData.locations;
    vm.currentUserId = profileData.id;

    // Pagination
    vm.paginationSize = AUDITS_API_ROUTE.pagination.size;
    vm.notificationLimit = AUDITS_API_ROUTE.pagination.limit;
  }

  /**
   * Get location id, current page from url
   * 
   */
  function initDataFromParams() {

    var parameters = CachingQueryParams.get();
    var locationId = Number(parameters.location_id);
    var currentPage = Number(parameters.page);

    if (locationId) {
      var currentLocation = _.find(vm.locations, function (location) {
        return location.id === locationId;
      });

      vm.locationId = (currentLocation && currentLocation.id) || undefined;
      vm.currentLocation = (currentLocation && currentLocation.name) || 'All locations';
    } else {
      vm.currentLocation = 'All locations';
    }

    vm.currentPage = (!currentPage) ? 1 : currentPage;
  }

  /**
   * Get audits from server of a doctor
   */
  function fetchAuditsData() {

    // show indicator
    $rootScope.showIndicator = true;
    vm.noAudits = false;

    var parameters = {
      'page': vm.currentPage,
      'location_id': vm.locationId
    };

    // Caching query params
    CachingQueryParams.set(parameters);
    console.log(parameters);
    AUDITS_API_SERVICE.getAudits(vm.locationId, vm.currentPage)
      .then(
        function(data) {
          _.forEach(data.audit_logs, function (auditData) {

            if (auditData.payload && auditData.payload.rule_type) {
              auditData.payload.availabilityType = AuditsFormatMessage.formatAvailabilityType(auditData.payload.rule_type);
              auditData.payload.dateFormat = TimeZoneService.timestampToTime(auditData.payload.start_time, 'D MMM YYYY');
            }
          });

          // Get audit logs list, total page and total count
          vm.audits = data.audit_logs;
          vm.totalPages = data.meta.total_pages;
          vm.totalCount = data.meta.total_count;
          
          if (!vm.totalCount) {
            vm.noAudits = true;
            return;
          }
        },
        function(err) {
          console.log(err);
        }
      ).finally(function() {
        // hide indicator
        $rootScope.showIndicator = false;
      });
  }

  createVariables();
  initDataFromParams();
  fetchAuditsData();

  /**
   * Get audit in a page
   * @return {[type]} [description]
   */
  vm.getAudits = function() {

    fetchAuditsData();

  };

  /**
   * Change location
   * @param  {[string]} name Location name
   * @param  {number} id   Location id
   */
  vm.changeLocation = function (name, id) {

    vm.currentPage = 1;
    vm.currentLocation = name;
    vm.locationId = id;
    vm.getAudits();
  };
};

AuditCtrl.$inject = [
  'profileData',
  'AUDITS_API_SERVICE',
  'AUDITS_API_ROUTE',  
  '$rootScope',
  '$scope',
  'CachingQueryParams',
  'moment',
  'AuditsFormatMessage',
  'TimeZoneService'
];

module.exports = AuditCtrl;
