'use strict';

var onScrollDirective = function () {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      var element = elem[0];

      var callbackHasBeenCalled = false;

      elem.on('scroll', function (e) {
        // do your thing
        console.log("On scroll directive");
        if (element.scrollTop == 0 && !callbackHasBeenCalled) {
          scope.onScrollCallback();
          callbackHasBeenCalled = true;
        }
      });

      scope.$watch(function () {
        return elem[0].scrollHeight;
      }, function () {
        callbackHasBeenCalled = false;
      });
    },
    scope: {
      onScrollCallback: '&'
    }
  };
};

module.exports = onScrollDirective;
