'use strict';

module.exports =
  angular.module('bookdoc.registration.directives', [])
  .directive('carouselCustom', require('./carousel-custom.directive'))
  .directive('specialty', require('./specialty.directive'))
  .directive('searchLocation', require('./search-location.directive'))
  .directive('viewPracticeLocation', require('./view-practice-location.directive'))
  .directive('qualificationsDirective', require('./qualifications.directive'))
  .directive('educationDirective', require('./education.directive'))
  .directive('hospitalDirective', require('./hospital.directive'))
  .directive('languageDirective', require('./language.directive'))
  .directive('latestApcDirective', require('./latest-apc.directive'))
  .directive('signatureFormDirective', require('./signature-form.directive'));
