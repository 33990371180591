"use strict";

function MedicationFormItemCtrl(ebService, $scope) {
  var ctrl = this;
  init();

  ctrl.$onInit = function () {
    if (!ctrl.validateMedicationObsberver)
      ctrl.validateMedicationObsberver = ctrl.validateMedicationSubject.subscribe(function () {
        validateMedication();
      });
  };

  function init() {
    console.log(ctrl.medicine);
    ctrl.quantityRegex = "\\d+";
    ctrl.unitPriceRegex = "\\d+(\\.\\d{1,2})?";
    setTotalPrice();
    setUnitPriceAndQuantityClass();
  }

  ctrl.onDelete = function () {
    console.log("On medicine removed");
    ctrl.validateMedicationObsberver.dispose();
    ctrl.onRemoveBtnClicked({index: ctrl.index});
  };

  function setTotalPrice() {
    if (ctrl.medicine.unit_price === undefined || ctrl.medicine.quantity === undefined)
      ctrl.medicine.total_price = 0;
    else
      ctrl.medicine.total_price = (ctrl.medicine.unit_price * ctrl.medicine.quantity).toFixed(2);
    ctrl.onUpdateMedicineInfo();
  }

  ctrl.updateTotalPrice = function () {
    if (ctrl.medicine.price_limit)
      ctrl.medicine.unitPriceExceededLimit = ctrl.medicine.unit_price > ctrl.medicine.price_limit;

    ctrl.unit_price_has_error = !ctrl.medicine.unit_price;
    ctrl.quantity_has_error = !ctrl.medicine.quantity;

    setTotalPrice();
    setUnitPriceAndQuantityClass();
  };

  ctrl.getMedicationList = function (query) {
    return ebService
      .getClaimAutoCompleteList(query, ctrl.claim.id, "medication")
      .then(function (response) {
        return response.result.filter(function (medication) {
          return !ctrl.bannedMedicines.includes(medication.name);
        });
      });
  };

  ctrl.onSelectMedication = function (item, model, label) {
    ctrl.medicine.medication_id = item.id;
    ctrl.medicine.name = item.name;
    ctrl.medicine.price_limit = item.price_limit;
  };

  function setUnitPriceAndQuantityClass() {
    ctrl.unit_price_class = ctrl.medicine.unitPriceExceededLimit || ctrl.unit_price_has_error ?
      'input-field-error' : 'input-field';

    ctrl.quantity_class = ctrl.quantity_has_error ? 'input-field-error' : 'input-field';
  }

  function validateMedication() {
    ctrl.name_has_error = !ctrl.medicine.medication_id;
    ctrl.unit_price_has_error = !ctrl.medicine.unit_price || ctrl.medicine.unit_price < 0;
    ctrl.quantity_has_error = !ctrl.medicine.quantity || ctrl.medicine.quantity < 0 || ctrl.medicine.quantity % 1 > 0;
    setUnitPriceAndQuantityClass();
  }

  ctrl.clearMedicationErrorIfFound = function () {
    ctrl.name_has_error = false;
  };

  ctrl.onRemoveMedicine = function () {
    ctrl.medicine.medication_id = null;
    ctrl.medicine.name = "";

    if (ctrl.medicine.id)
      ctrl.medicine.hasBeenEdited = true;
  };
}

var medicationFormItemComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/medication-form-item.html',
    controller: MedicationFormItemCtrl,
    bindings: {
      medicine: '=',
      index: '<',
      claim: '<',
      bannedMedicines: '=',
      validateMedicationSubject: '<',
      onRemoveBtnClicked: '&',
      onUpdateMedicineInfo: '&',
    }
  };
};

MedicationFormItemCtrl.$inject = ['ebService', '$scope'];
module.exports = medicationFormItemComponent();
