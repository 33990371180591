'use strict';

var groupingStates = function ($stateProvider, $urlRouterProvider) {

  $stateProvider
    .state('main.grouping', {
      url: 'grouping/',
      templateUrl: "grouping/views/grouping.html",
      controller: 'GroupingCtrl',
      controllerAs: 'grouping'
    });
};

groupingStates.$inject = [
  '$stateProvider',
  '$urlRouterProvider'
];

module.exports = angular
  .module('bookdoc.grouping')
  .config(groupingStates);