'use strict';

var _ = require('lodash');

var RegistrationAPIService = function (APP_CONSTANT, BOOKDOC_PROFILE_API_ROUTE, Restangular) {

  var transformRequest = function (tdata) {
    var form = new FormData();

    form.append('file', tdata);

    return form;
  };

  return {

    latestApc: {

      addAPC: function (file) {
        return Restangular.all('apcs').withHttpConfig({transformRequest: transformRequest}).customPOST(file, undefined, undefined, {'Content-Type': undefined });
      },

      deleteAPC: function(id) {
        return Restangular.one("apcs", id).remove();
      }
    },

    signatureForm: {

      addSignatureForm: function (file) {
        return Restangular.all('signature_forms').withHttpConfig({transformRequest: transformRequest}).customPOST(file, undefined, undefined, {'Content-Type': undefined });
      },

      deleteSignatureForm: function(id) {
        return Restangular.one("signature_forms", id).remove();
      }
    },

    locationLisence: {
      root: Restangular.all('clinic_licenses'),

      uploadLisence: function(file) {
        return this.root.withHttpConfig({transformRequest: transformRequest}).customPOST(file, undefined, undefined, {'Content-Type': undefined });
      },
      deleteLisence: function(id) {
        return this.root.customDELETE(id);
      }
    },

    education: {
      deleteDocument: function(id) {
        return Restangular.one("documents", id).remove();
      }
    },

    registration: {

      root: Restangular.all(APP_CONSTANT.apiRoutes.registration),

      register: function (sendData) {
        return this.root.customPOST(sendData, '', '', { 'Content-Type': 'application/json' });
      }

    },

    timezone: {
      getTimezone: function() {
        return Restangular.all('timezones').getList();
      }
    }
  };
};

RegistrationAPIService.$inject = [
  'APP_CONSTANT',
  'BOOKDOC_PROFILE_API_ROUTE',
  'Restangular'
];

module.exports = RegistrationAPIService;
