'use strict';

var _ = require('lodash');

var bdDayDirective = function (moment, TimeZoneService) {
  return {
    restrict: 'A',
    transclude: true,
    templateUrl: 'calendar/availability/views/day.html',
    scope: {
      day: '=dayData',
      defaultDuration: '=defaultDuration',
      groupingLocation: '=groupingLocation'
    },

    link: function (scope) {

      var isAssistant = !! scope.groupingLocation;
      var defaultTime = TimeZoneService.defaultTimeslot(scope.day.date);

      scope.addSlot = function () {
        var newAvailability = {
          appointment_length: Number(scope.defaultDuration) || 15,
          location: {
            name: 'Select Location'
          },
          isAllowUpdate: true,
          off: false,
          start_time: defaultTime,
          end_time: defaultTime
        };

        // add location and location_id to new duration that added by assistant
        if (isAssistant) {
          newAvailability.location = scope.groupingLocation;
          newAvailability.location_id = scope.groupingLocation.id;
        }

        scope.day.timeslots.push(newAvailability);

      };

    }

  };

};

bdDayDirective.$inject = [
  'moment',
  'TimeZoneService'
];

module.exports = bdDayDirective;
