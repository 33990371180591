'use strict';

var profileStates = function ($stateProvider, $urlRouterProvider) {

  $urlRouterProvider.when('/profile/', '/profile/information/');

  $stateProvider
    .state('main.profile', {
      url: 'profile/',
      templateUrl: 'profile/views/profile.html',
      abstract: true,
      controller: 'ProfileCtrl',
      controllerAs: 'profile',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',

          function ($rootScope, ProfileAPI) {
            return ProfileAPI.get($rootScope.currentUser && $rootScope.currentUser.id);
          }

        ]
      }
    })

    // Define statue for grouping profile
    .state('main.groupingProfile', {
      url: 'doctor/:doctorId/:practiceLocationId/:practiceLocationName/profile',
      templateUrl: 'profile/views/profile.html',
      abstract: true,
      controller: 'ProfileCtrl',
      controllerAs: 'profile',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',
          '$stateParams',

          function ($rootScope, ProfileAPI, $stateParams) {
            return ProfileAPI.getDoctorProfile($stateParams.doctorId);
          }

        ]
      }
    });
};

profileStates.$inject = ['$stateProvider', '$urlRouterProvider'];

module.exports = profileStates;
