"use strict";

function ChatDialogHeaderCtrl(chatService, moment) {
  var fullSessionDetails;
  var ctrl = this;
  var timerInterval;

  ctrl.headerInfo = {
    patientFullName: '',
  };
  ctrl.hideTimer = false;

  var loadHeaderInfo = function () {
    if (fullSessionDetails.payment == null) return;
    if (timerInterval != null)
      clearInterval(timerInterval);
    ctrl.isAnonymous = fullSessionDetails.anonymous;
    ctrl.headerInfo.patientFullName = fullSessionDetails.anonymous ? "Anonymous Patient" : fullSessionDetails.payment.payer.fullname;
    ctrl.affected_parts = chatService.getPatientAffectedBodyParts(fullSessionDetails.body_part);
    ctrl.patient_initials = fullSessionDetails.anonymous ? fullSessionDetails.payment.payer.fullname : chatService.getPatientInitials(fullSessionDetails.payment.payer.fullname);
    ctrl.created_at = new moment(fullSessionDetails.created_at * 1000).format("DD MMM' YYYY");
    ctrl.end_at = new moment(fullSessionDetails.end_at * 1000).format("DD MMM' YYYY");

    ctrl.remainingTime = (fullSessionDetails.end_at - fullSessionDetails.server_current_time) * 1000;
    if (ctrl.remainingTime > 0)
      startCountDownTimer();
    else if (ctrl.sessionDetails.open_session) {
      ctrl.hideTimer= true;
    } else
      ctrl.headerInfo.remainingTimeLabel = "Ended";

  };

  var startCountDownTimer = function () {
    timerInterval = setInterval(function () {
      if (ctrl.remainingTime <= 0) {
        clearInterval(timerInterval);
        ctrl.headerInfo.remainingTimeLabel = "Ended";
        return;
      }
      ctrl.remainingTime = ctrl.remainingTime - 1000;
      var duration = moment.duration(ctrl.remainingTime);
      var days = duration.asDays();
      var hours = duration.asHours() % 24;
      var minutes = duration.asMinutes() % 60;
      var seconds = duration.asSeconds() % 60;

      ctrl.headerInfo.remainingTimeLabel = "0" + Math.floor(days) + "d : " + (hours < 9 ? "0" : "") + Math.floor(hours) + "h : " +
        (minutes < 9 ? "0" : "") + Math.floor(minutes) + "m : " + (seconds < 9 ? "0" : "") + Math.floor(seconds) + "s";

    }, 1000);
  };

  ctrl.$onChanges = function (changes) {
    if (changes == null || changes.sessionDetails == null) return;

    fullSessionDetails = changes.sessionDetails.currentValue;
    loadHeaderInfo();
  };

};

ChatDialogHeaderCtrl.$inject = ['chatService', 'moment'];

var chatDialogHeaderComponent = function () {
  return {
    templateUrl: 'chat/views/chatDialogHeader.html',
    controller: ChatDialogHeaderCtrl,
    bindings: {
      'sessionDetails': '<',
    }
  };
};

module.exports = chatDialogHeaderComponent();
