'use strict';

var MobileDeviceService = function (APP_CONSTANT) {
  return {

    setBodyHeight: function () {
      var isMobile = APP_CONSTANT.isMobile;
      var mediumScreenWidth = APP_CONSTANT.mediumScreenWidth; 
      if (isMobile) {
        // set default device height
        if (window.innerWidth > mediumScreenWidth) {
          angular.element('body').removeAttr('style');
        } else {
          angular.element('body').css('min-height', window.innerHeight + 'px');
        }
        angular.element(window).bind('resize', function() {
          if (window.innerWidth > mediumScreenWidth) {
            angular.element('body').removeAttr('style');
          } else {
            angular.element('body').css('min-height', window.innerHeight + 'px');
          }
        });
      }
    }
  };
};

MobileDeviceService.$inject = [
  'APP_CONSTANT'
];

module.exports = MobileDeviceService;