angular.module("config", [])

.constant("ENV", {
	"name": "qa",
	"api": {
		"ip": "//qa-api.bookdoc.com"
	},
	"googleClientId": "150985392142-a8bu7tdm12s4vnjmenfi3n7ep50qvkc7.apps.googleusercontent.com",
	"liveChatLicense": 7362041,
	"gaId": "G-2TGJ655SXJ",
	"QBconfig": {
		"credentials": {
			"appId": 5,
			"authKey": "wS6xKsc-6auH7vs",
			"authSecret": "JPN8BuKyrGTE4GP",
			"accountKey": "J1q9NLGdzJDy3znzWXrm"
		},
		"appConfig": {
			"chatProtocol": {
				"active": 2
			},
			"streamManagement": {
				"enable": true
			},
			"debug": {
				"mode": 1
			},
			"endpoints": {
				"api": "apibookdoc.quickblox.com",
				"chat": "chatbookdoc.quickblox.com"
			},
			"webrtc": {
				"autoReject": false,
				"incomingLimit": 3
			}
		}
	},
	"eblink": "https://dr-qa.bookdoc.com/eb/login"
})

;