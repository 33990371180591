'use strict';

var ValidationFileTypeService = function () {

  return {

    checkFileType: function (fileType, format) {
      var validType = format.match(fileType);
      return validType && validType.length > 0 && fileType !== "";
    },
    checkFileSize: function (fileSize) {
      return fileSize > 4194304;
    }
  };

};

ValidationFileTypeService.$inject = [
];

module.exports = ValidationFileTypeService;
