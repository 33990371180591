'use strict';

var RoleValidation = function () {
  return {
    // Check assistant by url
    isAssistantUrl: function () {
      var locationParams = window.location.pathname,
        searchParams = window.location.search,
        assistantUrl = locationParams.match(/group/g) || locationParams.match(/doctor/g) || searchParams.match(/isGrouping=true/g);
      return assistantUrl && (assistantUrl.length > 0);
    },

    // Check doctor by $rootscope.currentUser
    isDoctor: function (currentUser) {
      return currentUser && !currentUser.clinic_id;
    },

    // Check assistant by $rootscope.currentUser
    isAssistant: function (currentUser) {
      return currentUser && !!currentUser.clinic_id;
    }
  };
};

RoleValidation.$inject = [];

module.exports = RoleValidation;
