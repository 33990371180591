'use strict';

var PracticeLocationService = function(OperatingHour) {

  return {

    getLocationObj: function () {
      return {
        id: null,
        name: '',
        address: '',
        city: '',
        state: '',
        zipcode: ''
      };
    },

    getPracticeLocationObj: function (location) {
      var practiceLocationInfo = {
        assistant: '',
        phone: '',
        fax: '',
        clinic_license_id: null,
        operating_hours: angular.copy(OperatingHour.operatingData)
      };

      if (location) {
        practiceLocationInfo.location = location;
        practiceLocationInfo.location.state_id = null;
        practiceLocationInfo.location.country_id = null;
      }

      return practiceLocationInfo;

    }

  };
};

PracticeLocationService.$inject = [
  'OperatingHour'
];

module.exports = PracticeLocationService;
