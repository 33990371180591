function ChatDialogPlaceHolderCtrl() {
  console.log("Chat dialog place holder ctrl");
};

var chatDialogPlaceholderComponent = function () {
  return {
    transclude: true,
    templateUrl: 'chat/views/chatDialogPlaceholder.html',
    controller: ChatDialogPlaceHolderCtrl,
    bindings: {}
  };
};

ChatDialogPlaceHolderCtrl.$inject = [];

module.exports = chatDialogPlaceholderComponent();
