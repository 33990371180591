/* global moment*/
'use strict';

var indicatorDirective = function() {
  return {
    restrict: 'E',
    templateUrl: 'common/views/indicator-circle.html'
  };
};

indicatorDirective.$inject = [
];

module.exports = indicatorDirective;
