'use strict';

var alertsStates = function ($stateProvider) {
  $stateProvider
    .state('main.alerts', {
      url: 'alerts/?page?location_id?sort',
      reloadOnSearch: false,
      templateUrl: "alerts/views/alerts.html",
      controller: 'AlertsCtrl',
      controllerAs: 'alert',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',

          function ($rootScope, ProfileAPI) {
            return $rootScope.profiles || ProfileAPI.get($rootScope.currentUser && $rootScope.currentUser.id);
          }

        ]
      },
    })

    // Define state for grouping alerts
    .state('main.groupingAlerts', {
      url: 'doctor/:doctorId/:practiceLocationId/:practiceLocationName/alerts/?page?location_id?sort',
      reloadOnSearch: false,
      templateUrl: "alerts/views/alerts.html",
      controller: 'AlertsCtrl',
      controllerAs: 'alert',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',
          '$stateParams',

          function ($rootScope, ProfileAPI, $stateParams) {
            var doctorId = Number($stateParams.doctorId);
            if ($rootScope.profiles && $rootScope.profiles.id === doctorId) {
              return $rootScope.profiles;
            } else {
              return ProfileAPI.getDoctorProfile(doctorId);
            }
          }

        ]
      },
    });
};

alertsStates.$inject = ['$stateProvider'];

module.exports = alertsStates;
