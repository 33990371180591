'use strict';

var service = angular
  .module('Auth')
  .factory("Auth.AuthService", [
    '$http',
    'Restangular',
    'Auth.AuthSession',
    'Auth.Auth',
    'Auth.API_ROUTES',
    'Auth.RegisterSession',
    '$rootScope',

    function ($http, Restangular, Session, Provider, API_ROUTES, RegisterSession, $rootScope) {
      return {

        login: function (credentials, isAssistant) {

          var loginApiRoutes = isAssistant ? Provider.endpoints.assistantLogin : Provider.endpoints.login;

          return $http.post(loginApiRoutes, credentials)
            .then(function (res) {
              $rootScope.isAssistant = !!res.data.clinic_id;

              Session.destroy();

              Session.create('Bearer ' + res.data[Provider.tokenKey], res.data);
              $http.defaults.headers.common[Provider.authHeader] = Session.token;

              return res.data;
            });

        },

        logout: function (isAssistant) {
          var logoutApiRoutes = isAssistant ? Provider.endpoints.assistantLogout : Provider.endpoints.logout;

          return $http["delete"](logoutApiRoutes)
            .then(function (res) {
              $http.defaults.headers.common[Provider.authHeader] = '';
            });
        },

        isAuthenticated: function () {
          return !!Session.user;
        },
        currentUser: function () {
          return Session.user;
        },
        authToken: function () {
          return Session.token;
        },
        isAuthorized: function (authorizedRoles) {

          if (!angular.isArray(authorizedRoles)) {
            authorizedRoles = [authorizedRoles];
          }
          return this.isAuthenticated() && authorizedRoles.indexOf(Session.user.role) !== -1;
        },

        updatePassword: function (currentPW, newPW, confirmPW, isAssistant) {
          var updatePassWordApiRoutes = isAssistant ? API_ROUTES.assistants : API_ROUTES.doctors;
          return Restangular.all(updatePassWordApiRoutes).customPUT({

            current_password: currentPW,
            password: newPW,
            password_confirmation: confirmPW

          }, API_ROUTES.password);

        },

        getPassword: function (email, isAssistant) {

          var resetPassWordApiRoutes = isAssistant ? API_ROUTES.assistants : API_ROUTES.doctors;

          return Restangular.all(resetPassWordApiRoutes).customPOST({

            email: email

          }, API_ROUTES.password);

        },

        inviteDoctors: function (doctors) {
          return Restangular.all(API_ROUTES.invites).post({
            invites: doctors
          }, {}, {'Authorization': RegisterSession.token});
        },

        register: function (registerData) {
          return Restangular.all('').customPOST(registerData, API_ROUTES.doctors)
            .then(function (data) {
              RegisterSession.create('Bearer ' + data[Provider.tokenKey], data);
            });
        },

        registerUser: function () {
          return RegisterSession.user;
        },

        removeRegisterSession: function () {
          console.log('RegisterSession');
          RegisterSession.destroy();
        },

        removeUserSession: function () {
          Session.destroy();
        }
      };
    }
  ]);

module.exports = service;
