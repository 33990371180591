'use strict';

var _ = require('lodash');

var EducationRegistrationService = function (informationAPI) {
  return {

    validate: function(newEducations) {
      // get array of empty items
      var isExistedEmptyTextbox = _.filter(newEducations, function(item) {
        if (!item.name) {
          item.name = '';
        }
        return item.name === '' || item.fileName === '';
      }) || [];

      return isExistedEmptyTextbox.length === 0;
    },

    // Check duplicate education
    checkDuplicateItem: function(educationArray) {
      var duplicate = {};
      var propertyName = '';
      var length = educationArray.length;
      var i = 0;
      var item = {};

      for (i; i < length; i++) {
        item = educationArray[i];
        propertyName = item.name + item.updated;
        if (duplicate.hasOwnProperty(propertyName)) {
          duplicate[propertyName]++;
          if (duplicate[propertyName] > 1) {
            return true;
          }
        } else {
          duplicate[propertyName] = 1;
        }
      };

      return false;
    },

    // Upload file
    uploadFile: function(data) {
      return informationAPI
        .documents
        .postDocument(data);
    },

    // Format education data
    formatEducationData: function(dataFormat) {
      var educationData = angular.copy(dataFormat);

      _.forEach(educationData, function(education) {
        delete education.fileName;
      });
      return educationData;
    }
  };
};

EducationRegistrationService.$inject = [
  'bookdoc.information.api',
];

module.exports = EducationRegistrationService;
