'use strict';

var _ = require('lodash');

var QrCodeApptModal = function (
  $modal,
  deeplinkCode,
  qrCode,
  date,
  time,
  duration,
  snackbarService
) {
  var vm = this;

  vm.qrCode = qrCode;
  vm.deeplinkCode = deeplinkCode;
  vm.encodedQrURL = encodeURIComponent(deeplinkCode);
  vm.videoPrice = parseFloat(new URL(qrCode).searchParams.get("video_price"));
  vm.fee = parseFloat(new URL(qrCode).searchParams.get("fee"));
  vm.total = vm.videoPrice + vm.fee;
  vm.date = date;
  vm.time = time;
  vm.duration = duration;

  vm.openConfirmationModal = function () {
    $modal.open({
      animation: true,
      templateUrl:
        "calendar/appointments/views/qr-code-scan-appt-confirm-modal.html",
      controller: require("./qr-code-appt-scan-confirm-modal.controller"),
      controllerAs: "$ctrl",
      windowClass: "qr-code-appt-scan-confirm-modal",
    });
  };

  vm.copyURL = function () {
    const el = document.createElement("textarea");
    el.value = deeplinkCode;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    snackbarService.showSnakeBar("URL has been copied!");
  };
};

QrCodeApptModal.$inject = [
  "$modal",
  "deeplinkCode",
  "qrCode",
  "date",
  "time",
  "duration",
  "SnackbarService",
];

module.exports = QrCodeApptModal;
