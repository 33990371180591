'use strict';

var _ = require('lodash');

var GeneralInfoModalCtrl = function ($modalInstance, $modal, SpecialtiesApi, profile, $q, $scope, OpenInfoModalService, profileAPI, APP_CONSTANT, SearchDropdownService, ValidateMessagesService) {
  var vm = this;

  /**
   * [fetchPSpecialtiesData description]
   */
  function fetchSpecialtiesData() {
    // fetch all locations of current user id

    SpecialtiesApi
      .getList()
      .then(
        function (data) {
          vm.specialtiesList = data;
        },

        function (error) {
          console.log(error);
        }
      );
  }

  /**
   * [initEmptySpecialty description]
   * @return {[type]} [description]
   */
  function initEmptySpecialty() {
    return {
      name: "",
      updated: ""
    };
  }
  /**
   * [processServiceHospitals description]
   * @param  {array} serviceSpecialties
   * @return {array} specialties
   */
  function processServiceHospitals (serviceSpecialties) {
    var specialties = [];

    if (serviceSpecialties && serviceSpecialties.length > 0) {
      _.forEach(serviceSpecialties, function (item) {
        specialties.push({
          name: item.name,
          updated: ""
        });
      });
    } else {
      specialties.push(initEmptySpecialty());
    }

    return specialties;
  }

  /**
   * [createVariables create all variables]
   *
   */
  function createVariables() {
    // Get profile list
    vm.profile = profile.data;

    //Initialize phone and specialties array
    vm.origin = processServiceHospitals(vm.profile.specialties);
    vm.copy = angular.copy(vm.origin);
    vm.specialties = {
      specialties: angular.copy(vm.origin)
    };

    vm.phone = "+" + vm.profile.phone_number;
    vm.phoneBackup = angular.copy(vm.phone);

    vm.request = {
      specialties: []
    };

    // Get specialties list
    fetchSpecialtiesData();

    vm.phonePattern = APP_CONSTANT.phonePattern;
    vm.formatPhoneNumber = APP_CONSTANT.patterns.formatPhoneNumber;

    // Validate Specialty
    vm.invalidSpecialty = {
      require: false,
      duplicate: false,
      disabledAdd: (vm.specialties.specialties.length >= 2)
    };

    vm.isUpdating = false;

    // validation messages
    vm.messages = ValidateMessagesService;
  }

  createVariables();

  /**
   * [makeFilter description]
   * @param  {string} name
   * @return {int} filter's length
   */
  function makeFilter(name) {
    return _.filter(vm.specialties.specialties, function(item) {
      return item.name === name;
    }).length;
  }

  /**
   * [validateSpecialty description]
   */
  function validateSpecialty() {
    var filter = makeFilter('');

    if(filter > 0) {
      vm.invalidSpecialty.require = true;
      return false;
    }

    return true;
  }

  function checkExistSpecialty(name) {
    var filter = _.filter(vm.specialtiesList, function (item) {
      return item.name === name;
    })[0];

    if (filter) {
      return filter;
    }

    return false;
  }

  /**
   * [getInfoByName description]
   * @param  {string} name
   * @return {string} info
   */
  function getIdByName (name) {
    var specialty = checkExistSpecialty(name);

    // If it is be deleted from specialty list, we can get its id from profile's specialities
    // In case no selected change on current specialty
    if(!specialty) {
      specialty = _.filter(vm.profile.specialties, function (item) {
        return item.name === name;
      })[0];
    }

    return specialty.id;
  }

  /**
   * [formatRequest description]
   * @param  {[type]}  name     [description]
   * @param  {Boolean} isUpdate [description]
   * @return {[type]}           [description]
   */
  function formatRequest (name, isUpdate) {
    var id = getIdByName(name);

    var filter = _.filter(vm.request.specialties, function(item) {
      return id === item.id;
    });

    // Check duplicate
    if (filter.length > 0) {
      // Get exist element
      var specialty = filter[0];

      // If it is updated then be removed and it is old one
      // Need to remove it from request
      // If it is new one, set its updated to new value
      if (specialty.updated && !isUpdate) {
        if (!_.some(vm.origin, name)) {
          _.remove(vm.request.specialties, specialty);
        } else {
          specialty.updated = isUpdate;
        }
      }

      // If it is removed then be added
      // Need to remove it from request
      if(!specialty.updated && isUpdate) {
        _.remove(vm.request.specialties, specialty);
      }
    } else {
      // Add new choice into request
      vm.request.specialties.push({
        "id": id,
        "updated": isUpdate
      });
    }
  }

  /**
   * [addSpecialty description]
   */
  vm.addSpecialty = function() {
    var emptyObject = initEmptySpecialty();

    vm.specialties.specialties.push(emptyObject);

    vm.copy.push(emptyObject);

    if(vm.specialties.specialties.length >= 2) {
      vm.invalidSpecialty.disabledAdd = true;
    }
  };

  /**
   * [updateSpecialty description]
   * @param  {string} name specialty's name
   * @param  {[int} index specialty's index
   */
  vm.updateSpecialty = function(name, index) {
    name = name.trim();

    if(index === 0) {
      return;
    }

    // If an empty choice be removed, hide all invalid msg
    if(name === '') {
      vm.invalidSpecialty.require = true;
      return;
    }

    vm.invalidSpecialty.require = vm.invalidSpecialty.duplicate = false;

    // Filter to check duplicate
    var filter = makeFilter(name);

    // Check duplicate
    var duplicate = filter >= 1;
    duplicate = duplicate && vm.specialties.specialties.length > 1;
    duplicate = duplicate && vm.specialties.specialties[index].name !== name;

    if (duplicate) {
      vm.invalidSpecialty.duplicate = true;
      return;
    }

    // Get old choice to compare with new one
    var oldName = vm.copy[index]? vm.copy[index].name : '';

    if (name === oldName) {
      return;
    }

    // Check if new choice # old one and old choice is not empty
    if (name !== oldName && oldName !== '') {
      // Set updated false for old choice
      // and updated true for new choice
      formatRequest(oldName, false);
      formatRequest(name, true);
    } else {
      // If that is a new choice (no have old one)
      // Only set updated is true for it
      formatRequest(name, true);
    }

    // Update new choice into specialties list
    vm.specialties.specialties[index].name = name;

    // Update new choice into specialties list copy
    if (vm.copy[index]) {
      vm.copy[index].name = name;
    } else {
      var specialty = initEmptySpecialty();
      specialty.name = name;
      vm.copy.push(specialty);
    }
  };

  /**
   * [removeSpecialty description]
   * @param  {string} name specialty's name
   */
  vm.removeSpecialty = function(name) {
    name = name.trim();

    // If no duplicate and no empty choice, format request
    if (makeFilter(name) <= 1 && name !== '') {
      formatRequest(name, false);
    }

    // Remove specialty from list
    _.remove(vm.specialties.specialties, {
      name: name
    });

    // Remove specialty from list copy
    _.remove(vm.copy, {
      name: name
    });

    // If no duplicate, execute to hide invalid messages
    if (makeFilter(name) <= 1) {

      vm.invalidSpecialty.duplicate = false;

      if (name === '') {
        vm.invalidSpecialty.require = false;
      }
    }

    // Only show button Add Specialty when specialties < 2
    if(vm.specialties.specialties.length < 2) {
      vm.invalidSpecialty.disabledAdd = false;
    }
  };

  function saveSpecialty () {
    return SpecialtiesApi.save(vm.request);
  }

  function updatePhone () {
    vm.phoneNumber = vm.phone.replace(vm.formatPhoneNumber,'');
    var updatePhoneParams = {
      'phone_number': vm.phoneNumber
    };
    return profileAPI.updatePhone(updatePhoneParams, vm.profile.id);
  }

  /**
   * [save - handle user click save button]
   *
   */
  vm.save = function (form) {
    var deferred = $q.defer();
    deferred.resolve();
    var chain = deferred.promise;
    var isUpdateSpecialties = false;

    var result = {
      phone_number: '',
      specialties: []
    };

    validateSpecialty();

    if (form.$valid && !vm.invalidSpecialty.require) {
      if (vm.request.specialties.length === 0 && vm.phone === vm.phoneBackup) {
        vm.close();
        return;
      }

      if (!vm.isUpdating) {
        vm.isUpdating = true;
      } else {
        return;
      }

      $scope.$emit('updateLoadingStatus', true);

      // Check if any request, call api and send it
      // Call api to send request
      if (vm.request.specialties.length > 0) {
        chain = chain.then(saveSpecialty);
        chain = chain.then(function (data) {
          vm.hasUpdateSpecialties = _.find(vm.request.specialties, function(item) {
            return item.updated === true;
          });

          if (Array.isArray(data) && data.length > 0) {
            // Update into UI
            $scope.$emit('UpdateSpecialties', data);
            isUpdateSpecialties = true;
          }
        });
      }

      if (vm.phone !== vm.phoneBackup) {
        // Call api to update phone
        chain = chain.then(updatePhone);
        chain = chain.then(function (data) {
          if (data.phone_number) {
            // Update into UI
            $scope.$emit('UpdatePhoneNumber', data.phone_number);
          }
        });
      }

      // Update specialties and phone into UI
      chain = chain.then(function () {
        vm.isUpdating = false;
        $modalInstance.close();
        // Call thank you modal

        if (isUpdateSpecialties && vm.hasUpdateSpecialties) {
          OpenInfoModalService.open(
            'profile/views/information/location-thank.modal.html',
            require('./location-thank.controller'),
            'locaTks',
            'modal-info modal-thank-update'
          );
        }
      },
      function (err) {
        vm.isUpdating = false;
      })
      .finally(function() {
        $scope.$emit('updateLoadingStatus', false);
      });
    }
  };

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }
  });

  /**
   * [close be applied for close button of modal]
   */
  vm.close = function () {
    if (!vm.isUpdating) {
      $modalInstance.dismiss('cancel');
      // Reset request after sending
      vm.request.specialties = [];

      // Reset invalid variable after closing modal
      vm.invalidSpecialty.require = vm.invalidSpecialty.duplicate = false;
    } else {
      return;
    }
  };

  /* Handle auto complete for specialties dropdown*/

  // handle when toggle dropdown
  vm.toggleDropdown = function () {
    vm.searchKey = '';
    SearchDropdownService.setAutoFocus();
  };

  // keep dropdown open when click on input tab
  vm.keepDropdownOpen = function($event) {
    $event.stopPropagation();
  };


};

GeneralInfoModalCtrl.$inject = [
  '$modalInstance',
  '$modal',
  'bookdoc.specialties.api',
  'profile',
  '$q',
  '$scope',
  'OpenInfoModalService',
  'bookdoc.profile.api',
  'APP_CONSTANT',
  'SearchDropdownService',
  'ValidateMessagesService'
];

module.exports = GeneralInfoModalCtrl;
