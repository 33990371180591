'use strict';

var alertsConstant = {
  alerts: 'notifications',
  changeToRead: 'notifications/read',
  doctor: 'doctors',
  sendThankYouEmail: 'send_thank_you_email',
  pagination: {
    size: 9,
    limit: 12
  }
};

module.exports = alertsConstant;
