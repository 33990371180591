'use strict';

module.exports =
  angular.module('bookdoc.common.services', [])
  .factory('ArrayService', require('./array.service'))
  .factory('DataTimeService', require('./data-times.service'))
  .factory('CommonModals', require('./modals.service'))
  .factory('CachingQueryParams', require('./caching-query-params.service'))
  .factory('OperatingHour', require('./operating-hour.service'))
  .factory('OperatingValidation', require('./operating-validation.service'))
  .factory('ValidateMessagesService', require('./validate-messages.service'))
  .factory('ValidationFileTypeService', require('./validation-file-type.service'))
  .factory('ChainRequestService', require('./chain-request.service'))
  .factory('SearchDropdownService', require('./search-dropdown.service'))
  .factory('LockMechanismService', require('./lock-mechanism.service'))
  .factory('autoFocusService', require('./auto-focus.service'))
  .factory('RoleValidation', require('./role-validation.service'))
  .factory('MobileDeviceService', require('./mobile-device.service'))
  .factory('StateChangeService', require('./state-change.service'))
  .factory('TimeZoneService', require('./convert-timezone.service'))
  .factory('SnackbarService', require('./snackbar.service'))
  .factory('InitialsService',require('./initials.service'));
