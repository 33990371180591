/* Define controller for Terms of User module*/
'use strict';

var TeleconsultCtrl = function($rootScope, ENV) {
  var vm = this;
  vm.title = "Teleconsult";
  vm.ebLink = ENV.eblink;
  $rootScope.showIndicator = false;
};

TeleconsultCtrl.$inject = [
  '$rootScope',
  'ENV'
];

module.exports = TeleconsultCtrl;
