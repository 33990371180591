/* global moment*/

'use strict';

(typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);

var auditLogHourFormat = function (TimeZoneService) {
  return function (timeStamp) {
    return timeStamp ? TimeZoneService.timestampToTime(timeStamp, 'h:mm A') : '';
  };
};

auditLogHourFormat.$inject = [
  'TimeZoneService'
];

module.exports = auditLogHourFormat;
