"use strict";

function SubmittedClaimMedicationItemCtrl() {
  var ctrl = this;

  ctrl.getTotalPrice = function () {
    return (ctrl.medication.quantity * ctrl.medication.unit_price).toFixed(2);
  };
}

var SubmittedClaimMedicationItemComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/submitted-claim-medication-item.html',
    controller: SubmittedClaimMedicationItemCtrl,
    bindings: {
      medication: '<',
    }
  };
};

SubmittedClaimMedicationItemComponent.$inject = [];

module.exports = SubmittedClaimMedicationItemComponent();
