'use strict';

var _ = require('lodash');

var AdvanceInfoCtrl = function ($rootScope, ArrayService, $modalInstance, InformationAPI, $modal, $scope, EducationService, HospitalService, LanguageService, OpenInfoModalService, ChainRequestService, $q) {
  var vm = this;

  vm.profiles = $scope.profiles;

  vm.educationActive = true;
  vm.languagesActive = false;
  vm.hospitalActive = false;
  vm.isEditEducation = true;

  vm.close = function () {
    if (!vm.isUpdating) {
      $modalInstance.dismiss('cancel');
    } else {
      return;
    }
  };

  /*======= EDUCATION =======*/
  vm.existedEducations = EducationService.existedEducations = ArrayService.addProperty($rootScope.profiles.educations, 'updated', true);

  vm.educationsData = {
    existedEducations: vm.existedEducations,
    newEducation: []
  };

  function saveEducations() {
    var educationRequestData = EducationService.formatEducations(vm.educationsData);
    if (educationRequestData.educations.length === 0) {
      // if nothing to update (data empty)
      return;
    }
    return InformationAPI.educations.updateEducations(educationRequestData);
  } 

  /*======= HOSPITAL =======*/

  // Basic definitions for hospitals
  vm.existedHospitals = angular.copy(vm.profiles.hospitals);
  vm.hospitals = angular.copy(vm.profiles.hospitals);
 
  /*======= LANGUAGE =======*/

  vm.languagesBackup = angular.copy(LanguageService.processServiceLanguages(vm.profiles.languages));

  vm.languages = LanguageService.processServiceLanguages(vm.profiles.languages);

  /**
   * [save main actions]
   */
  vm.isUpdating = false;

  vm.save = function() {
    // Validate education form
    $scope.$broadcast('submitEducationForm');

  };

  // Save Hospitals
  function saveHospital() {
    var hospitalRequestData = {
      hospitals: []
    };

    if(!vm.hospitalHasUpdated) {
      return;
    }

    hospitalRequestData.hospitals  = HospitalService.formatHospital(vm.existedHospitals, vm.hospitals);

    return InformationAPI
            .hospitals
            .updateHospitals(hospitalRequestData);
  };

  vm.isEditLanguages = true;

  // save Languages
  function saveLanguages() {
    var request = LanguageService.formatLanguageRequest(vm.languages);

    if (!vm.languageHasUpdated) {
      return;
    }

    return InformationAPI
            .languages
            .saveLanguages(request);
  };

  $scope.$on('submitAdditionalInfor', function(event) {

    var educationHasUpdated = EducationService.hasUpdated(vm.educationsData);
    vm.hospitalHasUpdated = HospitalService.hasUpdated(vm.existedHospitals, vm.hospitals);
    vm.languageHasUpdated = LanguageService.hasUpdated(vm.languages, vm.languagesBackup);

    if (!educationHasUpdated && !vm.languageHasUpdated && !vm.hospitalHasUpdated) {
      vm.close();
      return;
    }

    if (!vm.isUpdating) {
      vm.isUpdating = true;
    } else {
      return;
    }

    $scope.$emit('updateLoadingStatus', true);

    var chain = ChainRequestService.startQueue();
    
    // send educations request
    chain = chain.then(saveEducations);

    chain = chain.then(function (data) {
      // Update educations data to view
      if (Array.isArray(data)) {
        $scope.$emit('UpdateEducations', data);
      }
    });

    // Call a request changing
    // If any error, the changing will be stopped.
    chain = chain.then(saveHospital);

    // Call hospital api and update data into profile
    chain = chain.then(function(data) {
      if (Array.isArray(data)) {
        $scope.$emit('UpdateHospitals', data);
      }
    });

    // Call language api and update data into profile
    chain = chain.then(saveLanguages);
    chain = chain.then(function (data) {
      if (data) {
        // Update language data to view
        $scope.$emit('UpdateLanguages', data.languages);
      }
    });

    chain = chain
      .then(function () {
        vm.isUpdating = false;
        $modalInstance.close();

        if (vm.educationsData.newEducation.length || HospitalService.hasAddHospital) {
          OpenInfoModalService.open(
            'profile/views/information/location-thank.modal.html',
            require('./location-thank.controller'),
            'locaTks',
            'modal-info modal-thank-update'
          );
        }

      }, function (data) {
        vm.isUpdating = false;
      })
      .finally(function() {
        $scope.$emit('updateLoadingStatus', false);
      });
  });

  // prevent close modal when updating profile
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (vm.isUpdating) {
      event.preventDefault();
    }
  });
};

AdvanceInfoCtrl.$inject = [
  '$rootScope',
  'ArrayService',
  '$modalInstance',
  'bookdoc.information.api',
  '$modal',
  '$scope',
  'EducationService',
  'HospitalService',
  'LanguageService',
  'OpenInfoModalService',
  'ChainRequestService',
  '$q'
];

module.exports = AdvanceInfoCtrl;
