'use strict';

module.exports =
  angular
    .module('bookdoc.profile', [
      'ui.bootstrap',
      'ui.router',
      'ngMessages',
      'ct.ui.router.extras.sticky',
      'ct.ui.router.extras.dsr',
      'ngSanitize',

      require('../../../tmp/templates').name,
      require('../common').name,
      require('../auth').name
    ])

    // routes
    .config(require('./profile.routes'))
    .config(require('./profile.photo.routes'))
    .config(require('./profile.information.routes'))

    // constants
    .constant('BOOKDOC_PROFILE_API_ROUTE', require('./profile.constant'))

    // services
    .factory('bookdoc.modal.service', require('./services/modal.service'))
    .factory('bookdoc.avatars.api', require('./services/avatars.api'))
    .factory('bookdoc.profile.api', require('./services/profile.api'))
    .factory('bookdoc.specialties.api', require('./services/specialties.api'))
    .factory('bookdoc.information.api', require('./services/information.api'))
    .factory('AdvanceInfoService', require('./services/advance-info.service'))
    .factory('LanguageService', require('./services/information/language.service'))
    .factory('HospitalService', require('./services/information/hospital.service'))
    .factory('EducationService', require('./services/information/education.service'))
    .factory('OpenInfoModalService', require('./services/information/open-modal.service'))
    .factory('bookdoc.photos.api', require('./services/photos.api'))
    .factory('InsuranceService', require('./services/information/insurance.service'))

    // controllers
    .controller('ProfileCtrl', require('./controllers/profile.controller'))
    .controller('PhotoCtrl', require('./controllers/photo/photo.controller'))
    .controller('InformationCtrl', require('./controllers/information/information.controller'))
    .directive('locationOperating', require('./directives/location-operating.directive'));
