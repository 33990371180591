'use strict';

var appConstant = {

  // Will remove it when refactor code
  phonePattern:  /^[+]?[0-9]{9,12}$/,
  postalCodePattern: /^[0-9]+$/,

  // Create patterns
  patterns: {
    name: /^[a-zA-Z ]+$/,
    nameWithoutNumber: /^([^0-9]*)$/,
    phoneNumber: /^[+]?[0-9]{9,12}$/,
    postalCode: /^[0-9]+$/,

    //use for function replace() to remove plus sign and all none numeric character in phone string
    formatPhoneNumber: /[^0-9]/g,
    email: /^[-+_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,63})+$/
  },

  importantSpecialties: [
    'General Practitioner (GP Doctor)',
    'Dentist (General)',
    'GP Doctor (Aesthetic Medicine)'
  ],

  documentFileFormat: "image/jpeg,image/jpg,image/png,application/pdf",

  // millisecond of a day
  millisecondOfADay: 24 * 60 * 60 * 1000,

  // pagination size
  pagination: {
    size: 9,
    limit: 12
  },

  maxAppointmentEmailRecipients: 3,

  apiRoutes: {
    registration: 'doctors'
  },

  languagesList: [
    {code: 'Abkhazian', name: 'Abkhazian'},
    {code: 'Afar', name: 'Afar'},
    {code: 'Afrikaans', name: 'Afrikaans'},
    {code: 'Albanian', name: 'Albanian'},
    {code: 'Amharic', name: 'Amharic'},
    {code: 'Arabic', name: 'Arabic'},
    {code: 'Armenian', name: 'Armenian'},
    {code: 'Assamese', name: 'Assamese'},
    {code: 'Aymara', name: 'Aymara'},
    {code: 'Azerbaijani', name: 'Azerbaijani'},
    {code: 'Bashkir', name: 'Bashkir'},
    {code: 'Basque', name: 'Basque'},
    {code: 'Bengali', name: 'Bengali'},
    {code: 'Bhutani', name: 'Bhutani'},
    {code: 'Bihari', name: 'Bihari'},
    {code: 'Bulgarian', name: 'Bulgarian'},
    {code: 'Burmese', name: 'Burmese'},
    {code: 'Cambodian', name: 'Cambodian'},
    {code: 'Catalan', name: 'Catalan'},
    {code: 'Chinese', name: 'Chinese'},
    {code: 'Corsican', name: 'Corsican'},
    {code: 'Czech', name: 'Czech'},
    {code: 'Danish', name: 'Danish'},
    {code: 'Dutch', name: 'Dutch'},
    {code: 'English', name: 'English'},
    {code: 'Esperanto', name: 'Esperanto'},
    {code: 'Fiji', name: 'Fiji'},
    {code: 'French', name: 'French'},
    {code: 'Gaelic', name: 'Gaelic'},
    {code: 'Georgian', name: 'Georgian'},
    {code: 'German', name: 'German'},
    {code: 'Greek', name: 'Greek'},
    {code: 'Greenlandic', name: 'Greenlandic'},
    {code: 'Guarani', name: 'Gujarati'},
    {code: 'Hausa', name: 'Hausa'},
    {code: 'Hebrew', name: 'Hebrew'},
    {code: 'Hindi', name: 'Hindi'},
    {code: 'Hungarian', name: 'Hungarian'},
    {code: 'Icelandic', name: 'Icelandic'},
    {code: 'Indonesian', name: 'Indonesian'},
    {code: 'Interlingua', name: 'Interlingua'},
    {code: 'Interlingue', name: 'Interlingue'},
    {code: 'Inupiak', name: 'Inupiak'},
    {code: 'Irish', name: 'Irish'},
    {code: 'Italian', name: 'Italian'},
    {code: 'Japanese', name: 'Japanese'},
    {code: 'Javanese', name: 'Javanese'},
    {code: 'Kannada', name: 'Kannada'},
    {code: 'Kashmiri', name: 'Kashmiri'},
    {code: 'Kazakh', name: 'Kazakh'},
    {code: 'Kinyarwanda', name: 'Kinyarwanda'},
    {code: 'Korean', name: 'Korean'},
    {code: 'Kurdish', name: 'Kurdish'},
    {code: 'Laothian', name: 'Laothian'},
    {code: 'Latin', name: 'Latin'},
    {code: 'Lingala', name: 'Lingala'},
    {code: 'Macedonian', name: 'Macedonian'},
    {code: 'Malaysia', name: 'Malaysia'},
    {code: 'Mongolian', name: 'Mongolian'},
    {code: 'Nepali', name: 'Nepali'},
    {code: 'Mongolian', name: 'Mongolian'},
    {code: 'Persian', name: 'Persian'},
    {code: 'Polish', name: 'Polish'},
    {code: 'Portuguese', name: 'Portuguese'},
    {code: 'Romanian', name: 'Romanian'},
    {code: 'Russian', name: 'Russian'},
    {code: 'Samoan', name: 'Samoan'},
    {code: 'Sangro', name: 'Sangro'},
    {code: 'Serbian', name: 'Serbian'},
    {code: 'Shona', name: 'Shona'},
    {code: 'Slovak', name: 'Slovak'},
    {code: 'Slovenian', name: 'Slovenian'},
    {code: 'Somali', name: 'Somali'},
    {code: 'Spanish', name: 'Spanish'},
    {code: 'Tajik', name: 'Tajik'},
    {code: 'Thailand', name: 'Thailand'},
    {code: 'Turkish', name: 'Turkish'},
    {code: 'Turkmen', name: 'Turkmen'},
    {code: 'Ukrainian', name: 'Ukrainian'},
    {code: 'Urdu', name: 'Urdu'},
    {code: 'Vietnamese', name: 'Vietnamese'},
    {code: 'Yiddish', name: 'Yiddish'},
    {code: 'Yoruba', name: 'Yoruba'},
    {code: 'Zulu', name: 'Zulu'}
  ],

  calendarScopes: ["https://www.googleapis.com/auth/calendar"],

  // Notification audio url
  notificationAudioUrl: 'assets/audio/notification.mp3',

  // Delay time for set auto focus (millisecond)
  focusDelayTime: 40,

  // Delay time for search practice location (millisecond)
  searchDelayTime: 200,

  isMobile: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/i.test(navigator.userAgent),

  chromeOfiOSDevices: /CriOS/.test(navigator.userAgent),

  // Medium screen width (px)
  mediumScreenWidth: 992,

  // Define time zone default
  timezoneDefault: 'Asia/Kuala_Lumpur',

  charactersLimit: 160,

  availableMonths: 12,

  geoApi: '//freegeoip.net/json/',

  mims: {
    api: 'https://specialty.mims.com/rss-feed/RSS-',
    availableCountries: [
      {
        name: 'Hong Kong',
        code: 'HK'
      },
      {
        name: 'Indonesia',
        code: 'ID'
      },
      {
        name: 'India',
        code: 'IN'
      },
      {
        name: 'Malaysia',
        code: 'MY'
      },
      {
        name: 'Philippines',
        code: 'PH'
      },
      {
        name: 'Singapore',
        code: 'SG'
      },
      {
        name: 'Thailand',
        code: 'TH'
      },
      {
        name: 'Vietnam',
        code: 'VN'
      }
    ],

    defaultCountry: {
      name: 'Malaysia',
      code: 'MY'
    },

    xml2JsonApi: "https://query.yahooapis.com/v1/public/yql?format=json&q=select * from xml where url=",

    avoidKeywords: [
      'getdoc',
      'mydoc',
      'practo',
      'doctor2u',
      'finddoc',
      'docdoc',
      '65doctor',
      'topdoc',
      'halodoc',
      'lybrate',
      'teleme'
    ]
  }
};

module.exports = appConstant;
