"use strict";

function WysiwygCtrl($rootScope, quillService, chatService, SnackbarService) {
  /////////////////////////////////// view model variables /////////////////////////////////////////
  var ctrl = this;
  ctrl.formatType = quillService.NORMAL_FONT;

  ctrl.normal = function () {
    quillService.normal();
    ctrl.formatType = quillService.NORMAL_FONT;
  };

  ctrl.italic = function () {
    quillService.italic();
    ctrl.formatType = quillService.ITALIC_FONT;
  };

  ctrl.bold = function () {
    quillService.bold();
    ctrl.formatType = quillService.BOLD_FONT;
  };

  ctrl.boldItalic = function () {
    quillService.boldItalic();
    ctrl.formatType = quillService.BOLD_ITALIC_FONT;
  };

  ctrl.alignRight = function () {
    quillService.alignRight();
  };

  ctrl.alignLeft = function () {
    quillService.alignLeft();
  };

  ctrl.alignCenter = function () {
    quillService.alignCenter();
  };

  ctrl.alignJustify = function () {
    quillService.alignJustify();
  };

  ctrl.save = function () {
    var notes = quillService.getSaveableNotes();
    chatService.saveChatNote(ctrl.sessionDetails.id, notes, function () {
      console.log("saved")
      SnackbarService.showSnakeBar("Notes have been saved!");
    }, function () {});
  };


  ctrl.print = function () {
    var printWindow = window.open("", "", "height=400,width=600");
    printWindow.document.write(document.head.outerHTML);
    printWindow.document.write(quillService.getSaveableNotes());
    printWindow.print();
    printWindow.close();
  };

  ctrl.export = function () {
    Export2Doc('editor', 'test');
  };

  function Export2Doc(element, filename) {
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
    filename = filename ? filename + ".doc" : "document.doc";
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };

  this.$onChanges = function (changes) {
    if (changes != null && changes.sessionDetails != null) {
      var newSessionDetails = changes.sessionDetails.currentValue;
      if (!!newSessionDetails) {
        quillService.setContent(newSessionDetails.doctor_note || '', function (format) {
          ctrl.formatType = format;
        });
      }
    }
  };
}

var WysiwygComponent = function () {
  return {
    transclude: true,
    templateUrl: "chat/views/wysiwyg.html",
    controller: WysiwygCtrl,
    bindings: {
      'sessionDetails': '<'
    }
  };
};

WysiwygCtrl.$inject = ["$rootScope", "quillService", "chatService", "SnackbarService"];

module.exports = WysiwygComponent();
