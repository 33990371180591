'use strict';

module.exports = angular
  .module('bookdoc.calendar.appointments.services')
  .factory('appointment.common.service', [
    "moment",
    "CommonModals",
    "TimeZoneService",
    "APP_CONSTANT",
    function (moment, CommonModals, TimeZoneService, APP_CONSTANT) {

      var availableMonths = APP_CONSTANT.availableMonths;

      function rangeIsValid(appt) {
        var apptDate = moment(appt.date).format('DD/MM/YYYY').split("/"),
            date = [apptDate[2], apptDate[1], apptDate[0]].join("/"),
            startTime = new Date(date + " " + appt.start).valueOf(),
            endTime = new Date(date + " " + appt.end).valueOf();

        console.log(startTime < endTime);
        return startTime < endTime;
      }

      function timeIsInFuture(appt) {
        var apptDate = moment(appt.date).format('DD/MM/YYYY').split("/"),
            date = [apptDate[2], apptDate[1], apptDate[0]].join("/"),
            startTime = TimeZoneService.timeToTimeStamp(date, appt.start),
            currentTime = TimeZoneService.currentTime();

        console.log(startTime >= currentTime);
        return startTime >= currentTime;
      }

      function formatTime(time) {
        console.log(time);
        var timeArr = time.match(/\d+/g);
        var isPM = /pm/gi.test(time);

        return ((isPM && 12) || 0) + parseInt(timeArr[0], 10) + ':' + timeArr[1];
      }

      function showServerErrorMsg() {
        CommonModals.openBaseModal('Server error!');
      }

      function showPastTimeMsg() {
        CommonModals.openBaseModal('Time off is in the past.');
      }

      function showChooseLocationMsg() {
        CommonModals.openBaseModal('We are sorry. Time Off cannot be set when the calendar is viewed in All Locations mode. Please select a practice location from your calendar to apply Time Off.');
      }


      function showWithoutAvailabilitiesMsg() {
        CommonModals.openBaseModal('Time-off cannot be set for unavailable time duration. Please select time range based on availability to apply the settings.');
      }


      function showExistingApptMsg() {
        CommonModals.openBaseModal('The period you are trying to make unavailable (time-off) or switch to another practice location already has one or more appointments booked. If you are certain about making these changes, please cancel those appointments first.');
      }

      function showConflictingOtherLocationMsg() {
        CommonModals.openBaseModal('The available period you just set seems to overlap with the availability for at least one other practice location. As doctor can’t be present at multiple locations at the same time, please check your inputs and try again.');
      }

      function utcFormat(time) {
        // return moment.tz((moment(st).format('L') + ' ' + moment(st).format('HH:mm')), 'Etc/UTC').utc().format();
      }

      function showApptOverAvailableMonthsMsg() {
        CommonModals.openBaseModal('Appointment must be in ' + availableMonths + ' months.');
      }

      function showTimeOffOverAvailableMonthsMsg() {
        CommonModals.openBaseModal('Time off must be in ' + availableMonths + ' months.');
      }

      return {
        rangeIsValid: rangeIsValid,
        formatTime: formatTime,
        utcFormat: utcFormat,
        showServerErrorMsg: showServerErrorMsg,
        showPastTimeMsg: showPastTimeMsg,
        showChooseLocationMsg: showChooseLocationMsg,
        showExistingApptMsg: showExistingApptMsg,
        showWithoutAvailabilitiesMsg: showWithoutAvailabilitiesMsg,
        showConflictingOtherLocationMsg: showConflictingOtherLocationMsg,
        timeIsInFuture: timeIsInFuture,
        showApptOverAvailableMonthsMsg: showApptOverAvailableMonthsMsg,
        showTimeOffOverAvailableMonthsMsg: showTimeOffOverAvailableMonthsMsg
      };

    }

  ]);

