'use strict';

var _ = require('lodash');

var CachingQueryParams = function($location, $stateParams) {
  return {

    /**
     * Caching query params
     * @param {[object]} params
     */
    set: function(params) {

      $location.search(params);

      for (var key in params) {
        $stateParams[key] = params[key];
      }

    },

    get: function() {
      return $stateParams;
    }

  };
};

CachingQueryParams.$inject = [
  '$location',
  '$stateParams'
];

module.exports = CachingQueryParams;
