/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.signup.controllers',
    [
    ]
  )
  .controller('SignupCtrl', require('./registration.controller'))
  .controller('ListPracticeCtrl', require('./list-practice.controller'))
  .controller('CredentialsCtrl', require('./credentials.controller'))
  .controller('PracticeLocationCtrl', require('./practice-location.controller'))
  .controller('AdditionalInfoCtrl', require('./additional-info.controller'));
