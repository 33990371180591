'use strict';

var _ = require('lodash');

var commonModals = function ($modal) {

  return {

    openBaseModal: function (messages, isGroupingError) {

      $modal.open({
        animation: true,
        templateUrl: 'common/views/base-modal.html',
        controllerAs: 'baseModalCtrl',
        controller: [
          '$modalInstance',
          'messages',
          '$state',
          function ($modalInstance, messages, $state) {
            var vm = this;

            vm.closeModal = function () {
              $modalInstance.dismiss();

              if (messages.isGroupingError) {
                $state.go('main.grouping');
              }
            };

            vm.isMultipleMessages = false;

            vm.messages = messages.data;

            if (_.isArray(vm.messages)) {
              vm.isMultipleMessages = true;
            }

          }

        ],
        windowClass: 'modal-incorrect modal-medium',
        backdropClass: 'modal-backdrop',
        resolve: {
          messages: function () {
            return {
              data: messages,
              isGroupingError: isGroupingError
            };
          }
        }

      });

    },

    // Only use for grouping error
    showInvalidPracticeLocationModal: function (errorResponse, isGroupingError) {
      if (errorResponse.status === 422 && isGroupingError) {

        var errorMessage = !errorResponse.data ? 'This Doctor/Dentist is no longer in group practice.' : errorResponse.data.errors;

        this.openBaseModal(errorMessage, isGroupingError);
      }
    }

  };

};

commonModals.$inject = ['$modal'];

module.exports = commonModals;
