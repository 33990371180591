"use strict";
const { Client } = require("twilio-chat");

var twilioChatService = function ($rootScope, ENV) {
  var service = {
    init: init,
    getChannel: getChannel,
    sendMessage: sendMessage,
  };

  function init(twilioToken){
    return new Promise((resolve, reject) => {
      var client = new Client(twilioToken);
      client.on("stateChanged", function (state) {
        if (state === "initialized") {
          console.log("connected...", client);
          $rootScope.twilioClient = client;
          resolve(true);
        }else{
          reject('Unable to initiate chat');
        }
      });
    });
  }

  function getChannel(channelSid, callback) {
    if($rootScope.twilioClient){
      $rootScope.twilioClient.getChannelBySid(channelSid).then(callback);
    }
  }

  function sendMessage(channel, msg) {
    console.log("send msg", channel, msg);
    channel.sendMessage(msg);
  }

  return service;
};

twilioChatService.$inject = ["$rootScope", "ENV"];

module.exports = twilioChatService;
