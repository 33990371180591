/* Define controller for header module*/
'use strict';
var _ = require('lodash');
var HeaderCtrl = function ($scope, $rootScope, ALERTS_API_SERVICE, ProfileAPI,  $state, NotificationsService,
                           GroupingAPI, LockMechanismService, RoleValidation, chatService, quickBloxService, NotificationSoundService, ENV) {
  var vm = this;
  vm.totalChatNotifications = 0;
  vm.firstTimeLogin = ($rootScope.currentUser) ? $rootScope.currentUser.first_time_login : true;
  vm.grouping = RoleValidation.isAssistant($rootScope.currentUser) || false;
  vm.profiles = $rootScope.profiles;
  vm.currentUser = $rootScope.currentUser;
  vm.isGroupingTermsPage = $state.params && $state.params.isGrouping === "true";
  vm.logoUrl = vm.firstTimeLogin ? (vm.isGroupingTermsPage ? 'loginGroup' : 'login') : (vm.grouping ? 'main.grouping' : 'main.dashboard');
  vm.showTeleConsultTab = $rootScope.chatEnabled;
  vm.showEB = $rootScope.ebEnabled;
  vm.ebLink = ENV.eblink;

  if (vm.profiles) {
    vm.username = (vm.profiles.title ? vm.profiles.title + ' ' : '') + vm.profiles.name;
  }

  vm.unSubscribeNotification = function () {
    if ($rootScope.currentUser && $rootScope.currentUser.clinic_id) {
      NotificationsService.unsubscribe('/notifications/doctor/');
      NotificationsService.unsubscribe('/lock_mechanism/');
      $rootScope.enableNotification = false;

      var practiceLocationIdParam = Number($state.params.practiceLocationId);

      var inViewMode = practiceLocationIdParam ? LockMechanismService.checkLocationInViewMode($rootScope.profiles.locations, practiceLocationIdParam) : false;

      if (!inViewMode && practiceLocationIdParam) {
        GroupingAPI.unLockMechanism(practiceLocationIdParam)
          .then(
            function (data) {
              var locationIndex = LockMechanismService.findLocationIndex($rootScope.profiles.locations, practiceLocationIdParam);
              $rootScope.profiles.locations[locationIndex].checkRemainingTime = false;
              $state.go(vm.logoUrl);
            },
            function (error) {
              console.log(error);
            }
          );
      } else {
        $state.go(vm.logoUrl);
      }

    } else {
      $state.go(vm.logoUrl);
    }
  };

  /**
   * Get profile
   */
  vm.getProfiles = function () {
    ProfileAPI.get($rootScope.currentUser && $rootScope.currentUser.id)
      .then(
        function (data) {

          vm.profiles = $rootScope.profiles = data;
          vm.username = (vm.profiles.title ? vm.profiles.title + ' ' : '') + vm.profiles.name;

        },
        function (err) {
          console.log(err);
        }
      );
  };

  /**
   * Get notifications
   */
  vm.getNotifications = function () {
    var practiceLocationId = vm.grouping ? Number($state.params.practiceLocationId) : null,
      doctorId = vm.grouping ? Number($state.params.doctorId) : null;

    ALERTS_API_SERVICE.getAlerts(practiceLocationId, 1, 'newest', doctorId)
      .then(
        function (data) {

          // Get alert number
          vm.alertsNumber = ALERTS_API_SERVICE.alertsNumberBackup = ALERTS_API_SERVICE.alertsNumber = data.meta.unread_count;
          updateTabTitleByNotificationsCount();
        },
        function (err) {
          console.log(err);
        }
      );
  };

  // Get profile and notification of a user
  if ($rootScope.currentUser && ($state.current && $state.current.name !== "main.signup") && (!$rootScope.currentUser.clinic_id || $rootScope.currentUser.clinic_id && $state.params && $state.params.doctorId)) {
    // vm.getProfiles();
    vm.getNotifications();
    if ($rootScope.chatEnabled) {
      getChatNotifications();
    }
  }

  function getChatNotifications() {
    /* TURN OFF QUICKBLOX */
    // quickBloxService.getTotalNumberOfUnreadMsgs(function (total) {
    //   vm.totalChatNotifications = total
    // })
  }

  /**
   * Get new notification
   * @return {[type]}]
   */
  $scope.$on('newNotification', function (event, data) {
    var notificationData = data;

    try {
      // Reset user profile when have verified/rejected from admin
      if (notificationData.payload.profile_status === 'Profile Update') {
        vm.getProfiles();
      }

      // Set alerts number
      ALERTS_API_SERVICE.alertsNumber++;

      vm.alertsNumber = ++ALERTS_API_SERVICE.alertsNumberBackup;
      updateTabTitleByNotificationsCount();

      if (!$scope.$$phase) {
        $scope.$digest();
      }

      /**
       * Subscribe to quickblox dialog in case there is a new chat dialog created notification.
       */
      if (notificationData.payload.type === 'chat') {
        var onJoinQBDialogSuccess = function (success) {
        };
        var onJoinQBDialogFailure = function (failure) {
        };
        quickBloxService.joinQBDialog(notificationData.payload.quickblox_dialog_id, onJoinQBDialogSuccess, onJoinQBDialogFailure);
      }
    } catch (error) {
      if ($rootScope.chatEnabled) {
        getChatNotifications();
      }
    }
  });

  /**
   * Decrease total number of notification when user open session.
   */
  $scope.$on('totalChatNotificationChanged', function (event, decreasedBy) {
    if (vm.totalChatNotifications == 0) return;
    vm.totalChatNotifications -= decreasedBy;
    updateTabTitleByNotificationsCount();
  });


  /* For state change success */
  $scope.$on('changeNotification', function (event, alertsNumber) {
    vm.alertsNumber = alertsNumber;
    updateTabTitleByNotificationsCount();
  });

  /**
   * For pagination on alert page
   */
  $scope.$on('changeAlertsNumber', function (event, alertsNumber) {
    vm.alertsNumber = alertsNumber;
    updateTabTitleByNotificationsCount();
  });

  $rootScope.$on('updateUnreadChatCount',  function () {
    if ($rootScope.chatEnabled) {
      getChatNotifications();
    }
  })

  function updateTabTitleByNotificationsCount() {
    var totalNumberOfNoitifications = vm.alertsNumber;
    if (vm.totalChatNotifications !== undefined) totalNumberOfNoitifications += vm.totalChatNotifications;
    ALERTS_API_SERVICE.updateTitle(totalNumberOfNoitifications);
  }
};

HeaderCtrl.$inject = [
  '$scope',
  '$rootScope',
  'ALERTS_API_SERVICE',
  'bookdoc.profile.api',
  // '$stateParams',
  '$state',
  'NotificationsService',
  'GroupingAPI',
  'LockMechanismService',
  'RoleValidation',
  'chatService',
  'quickBloxService',
  'NotificationSoundService',
  'ENV'
];

module.exports = HeaderCtrl;
