/* Define controller for filter modal */
'use strict';

var FilterCtrl = function ($modalInstance, specialtiesData, $scope, SearchDropdownService, FilterService, ValidateMessagesService) {
  var vm = this;

  vm.specialtiesList = specialtiesData.specialtiesList;
  vm.specialtySelected = angular.copy(specialtiesData.specialtySelected);
  vm.specialtySelectedList = angular.copy(specialtiesData.specialtySelectedList);
  vm.messages = ValidateMessagesService;
  vm.validate = {
    duplicate: false
  };
  /**
   * [closeFilterModal description] close modal filter doctor
   * @return {[type]} [description]
   */
  vm.closeFilterModal = function () {
    $modalInstance.dismiss('cancel');
  };

  /**
   * [selectSpecialty description] select Specialty
   * @return {[type]} [description]
   */
  vm.selectSpecialty = function (specialty) {
    vm.specialtySelected = specialty;

    // check duplicate specialty
    vm.validate.duplicate = FilterService.checkDuplicate(vm.specialtySelectedList, vm.specialtySelected);

  };

  /* Handle auto complete for specialties dropdown*/

  // handle when toggle dropdown
  vm.toggleDropdown = function () {
    vm.filterKey = {};
    SearchDropdownService.setAutoFocus();
  };

  // keep dropdown open when click on input tab
  vm.keepDropdownOpen = function($event) {
    $event.stopPropagation();
  };
  /**
   * [applyFilter description] show results filter doctor
   * @return {[type]} [description]
   */
  vm.applyFilter = function () {
    if (vm.validate.duplicate) {
      return;
    }

    $scope.$emit('handleFilterDoctor', vm.specialtySelected);

    // Close filter modal
    vm.closeFilterModal();
  };

};

FilterCtrl.$inject = [
  '$modalInstance',
  'specialtiesData',
  '$scope',
  'SearchDropdownService',
  'FilterService',
  'ValidateMessagesService'
];

module.exports = FilterCtrl;
