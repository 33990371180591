"use strict";

function EbPreRegistrationItemCtrl($rootScope, initialsService, moment, $modal, ebService) {
  var ctrl = this;

  ctrl.visit.initials = initialsService.getInitials(ctrl.visit.personal_info.policy_owner);
  ctrl.visit.dateStr = ctrl.visit.date;

  ctrl.onAttendBtnClicked = function () {
    ctrl.onAttendBtnClickedCallback({visit: ctrl.visit});
  };
}

var EbPreRegistrationItemComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/pre-registration-item.html',
    controller: EbPreRegistrationItemCtrl,
    bindings: {
      visit: '<',
      onAttendBtnClickedCallback: '&'
    }
  };
};

EbPreRegistrationItemCtrl.$inject = ['$rootScope', 'InitialsService', 'moment', '$modal', 'ebService'];

module.exports = EbPreRegistrationItemComponent();
