'use strict';

var profileAPI = function (BOOKDOC_PROFILE_API_ROUTE, Restangular) {

  return {

    getDoctorProfile: function (doctorId) {
      return Restangular.one('doctors', doctorId).customGET("", null);
    },

    getAssistantProfile: function (assistantId) {
      return Restangular.one('assistants', assistantId).customGET("profile", null);
    },
    
    // root: Restangular.one(BOOKDOC_PROFILE_API_ROUTE.doctors, $rootScope.currentUser.id),

    init: function (userId) {

      if (userId) {
        this.root = Restangular.one(BOOKDOC_PROFILE_API_ROUTE.doctors, userId);
      }

    },

    get: function (userId, isAssistant) {
      var getProfileApiRoute = isAssistant ? 'assistants' : 'doctors';
      return Restangular.one(getProfileApiRoute, userId).customGET("profile", null);
    },

    getLocation: function (userId) {

      this.init(userId);

      return this.root && this.root.customGETLIST(BOOKDOC_PROFILE_API_ROUTE.locationByUser);
    },

    updatePhone: function(newPhone, doctorId) {

      return Restangular.one('doctors', doctorId).customPUT(newPhone, 'update_phone');

    },

    toggleTeleconsult: function(params, doctorId){
      return Restangular.one('doctors', doctorId).customPUT(params, 'toggle_teleconsult');
    }

  };

};

profileAPI.$inject = ['BOOKDOC_PROFILE_API_ROUTE', 'Restangular'];

module.exports = profileAPI;
