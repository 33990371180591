'use strict';

var autofocusDirective = function ($timeout, APP_CONSTANT) {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      scope.$watch(attrs.autoFocus, function (newVal) {
        if (newVal) {
          var delayTime = attrs.delayFocus ? APP_CONSTANT.searchDelayTime : APP_CONSTANT.focusDelayTime;
          $timeout(function () {
            elem[0].focus();
          }, delayTime);
        }
      });
    }
  };
};

autofocusDirective.$inject = [
  '$timeout',
  'APP_CONSTANT'
];

module.exports = autofocusDirective;
