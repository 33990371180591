 'use strict';

var alertsAPI = function (Restangular, ALERTS_API_ROUTE) {

  return {

    alertsNumber: 0,
    alertsNumberBackup: 0,

    getAlerts: function (location, page, sort, doctorId) {
      return Restangular.all(ALERTS_API_ROUTE.alerts).customGET('', {location_id: location, page: page, sort_by: sort, doctor_id: doctorId});
    },

    readAlerts: function(ids) {
      return Restangular.all(ALERTS_API_ROUTE.changeToRead).customPUT({notification_ids: ids});
    },

    setSendThankYouEmail: function (userId, value) {
      return Restangular.one(ALERTS_API_ROUTE.doctor, userId).customPUT({send_thank_you_email: value}, ALERTS_API_ROUTE.sendThankYouEmail);
    },

    updateTitle: function(number) {
      if (number && number > 0) {
        window.document.title = '('+ number +') BookDoc - Portal';
      } else {
        window.document.title = 'BookDoc - Portal';
      }

    }
  };

};

alertsAPI.$inject = [
  'Restangular',
  'ALERTS_API_ROUTE'
];

module.exports = alertsAPI;
