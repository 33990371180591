/*global moment*/

'use strict';

var states = function ($stateProvider) {
  $stateProvider
    .state('main.dashboard', {
      url: '',
      templateUrl: "dashboard/views/dashboard.html",
      controller: 'DashboardCtrl',
      controllerAs: 'dashboard',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',

          function ($rootScope, ProfileAPI) {
            return $rootScope.profiles || ProfileAPI.get($rootScope.currentUser && $rootScope.currentUser.id);
          }
        ]
      },
    })

    // Define state for grouping dashboard
    .state('main.groupingDashboard', {
      url: 'doctor/:doctorId/:practiceLocationId/:practiceLocationName/dashboard',
      templateUrl: "dashboard/views/dashboard.html",
      controller: 'DashboardCtrl',
      controllerAs: 'dashboard',
      resolve: {
        profileData: [
          '$rootScope',
          'bookdoc.profile.api',
          '$stateParams',

          function ($rootScope, ProfileAPI, $stateParams) {
            var doctorId = Number($stateParams.doctorId);
            if ($rootScope.profiles && $rootScope.profiles.id === doctorId) {
              return $rootScope.profiles;
            } else {
              return ProfileAPI.getDoctorProfile(doctorId);
            }
          }
        ]
      },
    });
};

states.$inject = [
  '$stateProvider'
];

module.exports = angular
  .module('bookdoc.dashboard')
  .config(states);
