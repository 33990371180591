'use strict';

module.exports =
  angular
    .module('bookdoc.chat.components', [
      require('../../../../tmp/templates').name,
      require('../../common').name
    ])
    .component('chatDialogPlaceholder', require('./chatDialogPlaceholder.component'))
    .component('emptySessionsPlaceholder', require('./emptySessionsPlaceholder.component'))
    .component('chatDialogHeader', require('./chatDialogHeader.component'))
    .component('chatDialog', require('./chatDialog.component'))
    .component('videoChat', require('./videoChat.component'))
    .component('wysiwyg', require('./wysiwyg.component'))
    .component('chatSessions', require('./chatSessions.component'))
    .component('msg', require('./msg.component'))
    .component('patientSymptoms', require('./patientSymptoms.component'))
    .component('chatSession', require('./chatSession.component'))
    .component('prescription', require('./prescription.component'));
