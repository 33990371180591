'use strict';

var states = function ($stateProvider) {

  $stateProvider
    .state('main.chat', {
      url: 'chat/?chatId&future',
      templateUrl: "chat/views/chat.html",
      controller: 'ChatCtrl',
      controllerAs: 'chat'
    });
};

states.$inject = ['$stateProvider'];

module.exports = angular
  .module('bookdoc.chat')
  .config(states);
