'use strict';

var _ = require('lodash');

var LockMechanismService = function (NotificationsService, $rootScope) {

  return {

    checkLocationInViewMode: function (locations, location_id) {
      var inViewMode = false;

      var location = _.find(locations, function (location) {
        return location.id === location_id;
      });

      if (location && location.inViewMode) {
        inViewMode = true;
      }

      return inViewMode;
    },

    findLocationIndex: function (locations, locationId) {
      var locationIndex = _.findIndex(locations, function(location) { 
        return location.id === locationId; 
      });

      return locationIndex;
    },

    checkAllLocationInViewMode: function (isAssistant, locations, practiceLocationId) {
      var locationCount = locations.length,
          allLocationInViewMode = true;

      // Check Location that Assistant select is in ViewMode
      if (isAssistant) {
        allLocationInViewMode = this.checkLocationInViewMode(locations, practiceLocationId);
      } else {
        // Check all location of doctor are in ViewMode
        for (var i = 0; i < locationCount; i++) {
          if (!locations[i].inViewMode) {
            allLocationInViewMode = false;
            break;
          }
        }
      }
      return allLocationInViewMode;
    },

    getViewModeLocation: function (locations) {

      // Filter locations that is in View mode
      var locationInViewMode = _.filter(locations, function(location) { 
        return location.inViewMode; 
      });

      // Need to remove duplicate location
      var locationViewModeList = _.uniq(locationInViewMode, 'id');
      
      return locationViewModeList;
    },

    subscribeLockMechanism: function () {
      NotificationsService.subscribe('/lock_mechanism/', function(data) {
        var changeModeData = JSON.parse(data);
        $rootScope.$broadcast('changeMode', changeModeData);
      });
    }
  };

};

LockMechanismService.$inject = [
  'NotificationsService',
  '$rootScope'
];

module.exports = LockMechanismService;
