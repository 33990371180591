"use strict";

var prescriptionService = function (Restangular) {
  var searchMedicine = function (query, successCallback, errorCallback) {
    Restangular.all("medicines")
      .customGET("", { query: query })
      .then(
        function (prescription) {
          successCallback(prescription);
        },
        function (error) {
          errorCallback(error);
          console.log(error);
        }
      );
  };

  var getPrescription = function (id, successCallback, errorCallback) {
    Restangular.one("prescriptions", id)
      .get()
      .then(
        function (prescription) {
          if (prescription != null) successCallback(prescription.prescription);
        },
        function (error) {
          errorCallback(error);
        }
      );
  };
  var savePrescription = function (params, successCallback, errorCallback) {
    Restangular.all("prescriptions")
      .customPOST(params, null)
      .then(
        function (prescription) {
          console.log(prescription);
          if (prescription != null) successCallback(prescription.prescription);
        },
        function (error) {
          errorCallback(error);
        }
      );
  };
  var requiredPrescription = function (
    sessionId,
    params,
    successCallback,
    errorCallback
  ) {
    Restangular.all("chat_sessions/" + sessionId + "/prescription_required")
      .customPUT(params, null)
      .then(
        function () {
          successCallback();
        },
        function (error) {
          errorCallback(error);
        }
      );
  };
  return {
    searchMedicine: searchMedicine,
    getPrescription: getPrescription,
    savePrescription: savePrescription,
    requiredPrescription: requiredPrescription,
  };
};

prescriptionService.$inject = ["Restangular"];

module.exports = prescriptionService;
