'use strict';

module.exports =
  angular
    .module('bookdoc.referral.referred', [
      'ui.bootstrap',
      'ui.router',
      'ui.calendar',

      require('../../../../tmp/templates').name,
      require('../../common').name,
      // require('./directives').name,
      // require('./services').name,
      require('../refer-to/controllers').name
    ])
    .config(require('./referred.routes'));
