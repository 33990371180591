'use strict';

var _ = require('lodash');

var ServiceModalCtrl = function ($modalInstance, $modal, sendData) {
  var vm = this;
  vm.serviceList = sendData.services;
  vm.panelShips = sendData.panelShips;
  vm.closeModal = function() {
    $modalInstance.dismiss();
  };
};

ServiceModalCtrl.$inject = [
  '$modalInstance',
  '$modal',
  'sendData'
];

module.exports = ServiceModalCtrl;
