'use strict';

var profileInforStates = function ($stateProvider) {

  $stateProvider
    .state('main.profile.information', {
      url: 'information/',
      // sticky: true,
      views: {
        'information': {
          templateUrl: 'profile/views/information/information.html',
          controller: 'InformationCtrl',
          controllerAs: 'info'
        }
      }
    })

    // Define state for grouping information
    .state('main.groupingProfile.information', {
      url: '/information/',
      views: {
        'information': {
          templateUrl: 'profile/views/information/information.html',
          controller: 'InformationCtrl',
          controllerAs: 'info'
        }
      }
    });

};

profileInforStates.$inject = ['$stateProvider'];

module.exports = profileInforStates;
