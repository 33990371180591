/* Define controller for  sign-up module*/
'use strict';

var SignupCtrl = function($scope, $state, RegistrationAPI, CommonModals, Provider, RegisterSession, ValidateMessagesService, autoFocusService) {

  var vm = this;

  // Create variables
  function createVariables () {

    // Registration data
    $scope.registrationData = {};

    $scope.isRegistering = false;

    // For form validate
    $scope.formValidate = {
      valid: false
    };

    // Set current step for carousel
    $scope.currentStep = 1;
    vm.isFirstStep = false;
    vm.isLastStep = false;

    // Specialties Data
    $scope.specialtiesData = [];

    // Reset title for sign-up page
    window.document.title = 'BookDoc - Portal';

  };

  createVariables();

  /**
   * Carousel next
   */
  vm.nextStep = function(){

    // Check current step to validate form
    switch($scope.currentStep) {
      case 1:

        // Call submit step one
        $scope.$broadcast('submitListPractice');
        break;
      case 2:
        $scope.$broadcast('submitCredentials');
        break;

      case 3:
        $scope.$broadcast('submitPracticeLocation');

        var educationInput = angular.element(".education-0");
        autoFocusService.setAutoFocus(educationInput);
        break;
      // case 4:
      //   $scope.$broadcast('submitStepFour');
      //   break;
    };
  };

  /**
   * Carousel previous
   */
  vm.prevStep = function(){
    // FIX ME handle validation before move to prev step
    console.log('prev');
  };

  /*
   * [submitRegistration - handle for button submit]
   * @desc: Validate step 4 before send request:
   * 1: Registration controller broadcast 'validateEducationForm' event
   * 2: Education form listen 'validateEducationForm' event
   *    If Education form valid, it will broadcast 'validateHospitalForm' event
   * 3: Hospital form listen 'validateHospitalForm' event
   *    If Hospital form valid, it will broadcast 'validateLanguageForm' event
   * 4: Language form listen 'validateLanguageForm' event
   *    If Language form valid, it will broadcast 'validateSignatureForm' event
   * 5: Signature form listen 'validateSignatureForm' event
   *    If Signature form valid, it will broadcast 'validateLatestApcForm' event
   * 6: LatestApc form listen 'validateLatestApcForm' event
   *    If LatestApc form valid, it will broadcast 'sendRegistrationRequest' event
   * 7: Registration controller listen 'sendRegistrationRequest' event
   *    Registration controller call api to register
   */
  vm.submitRegistration = function() {
    // Mock event submit
    $scope.$broadcast('validateEducationForm');
  };

  $scope.$on('sendRegistrationRequest', function () {
    console.log('Registration Data:');
    console.log($scope.registrationData);

    // delete this property before send object to server
    delete $scope.registrationData.practice_location.location.state;
    delete $scope.registrationData.practice_location.location.country;
    delete $scope.registrationData.listCountries;

    // Fix me: update it when have consistant between zipcode and postal
    $scope.registrationData.practice_location.location.postal = $scope.registrationData.practice_location.location.zipcode;
    delete $scope.registrationData.practice_location.location.zipcode;

    $scope.isRegistering = true;

    RegistrationAPI.registration.register($scope.registrationData)
      .then(function (data) {
        RegisterSession.create('Bearer ' + data[Provider.tokenKey], data);
        $state.go('thankyou');
      }, function (err) {
        if (err.data && err.data.errors) {
          var errorsMessages = [];

          for (var property in err.data.errors) {
            if (err.data.errors.hasOwnProperty(property)) {
              switch(property) {
                case 'email':
                  errorsMessages.push('We are sorry, but it seems that the email you entered already exists in our database. Please try a different email address.');
                  break;
                case 'nric':
                  errorsMessages.push(ValidateMessagesService.exists('NRIC'));
                  break;
              }

              // Return to step 1
              $scope.currentStep = 1;
              vm.isFirstStep = true;
              vm.isLastStep = false;
            }
          }

          CommonModals.openBaseModal(errorsMessages);
        }
      })
      .finally(function() {
        $scope.isRegistering = false;
      });
  });

};

SignupCtrl.$inject = [
  '$scope',
  '$state',
  'RegistrationAPI',
  'CommonModals',
  'Auth.Auth',
  'Auth.RegisterSession',
  'ValidateMessagesService',
  'autoFocusService'
];

module.exports = SignupCtrl;
