'use strict';

var languageDirective = function ($rootScope, APP_CONSTANT, ValidateMessagesService, ArrayService, SearchDropdownService, $timeout) {
  return {
    restrict: 'E',
    scope: {
      registrationData: '=registrationData',
      languagesActive: '=languagesActive',
      languages: '=languages',
      isEditLanguages: '=isEditLanguages'
    },
    templateUrl: 'registration/views/languages.html',
    link: function(scope, element, attrs) {
      // all language that user can choose
      scope.languagesList = APP_CONSTANT.languagesList;

      // all validation messages
      scope.messages = ValidateMessagesService;

      // error object
      scope.errors = {
        isDuplicate: false
      };

      /**
       * [selectLanguage - handle when user select an item in language list]
       *
       */
      scope.selectLanguage = function (language, index) {
        scope.languages[index].name = language;
        scope.errors.isDuplicate = ArrayService.checkDuplicateItem(scope.languages, 'name');
      };

      /**
       * [addLanguage - handle when user click add language]
       *
       */
      scope.addLanguage = function () {
        scope.languages.push({name: ''});
      };

      // validate for languages
      function languageValidate () {             
        scope.errors.isDuplicate = ArrayService.checkDuplicateItem(scope.languages, 'name');
      };

      /**
       * [addLanguage - handle when user click delete language]
       *
       */
      scope.deleteLanguage = function (index) {
        scope.languages.splice(index, 1);
        languageValidate();
      };

      /**
       * [languageNameChanged - handle when user type to language textbox]
       *
       */
      scope.languageNameChanged = function (index) {
        scope.languages[index].isOpenDropdown = true;
        languageValidate();
      };

      scope.$on('validateLanguageForm', function(event) {
        scope.languageForm.$setSubmitted();
        if (scope.languageForm.$valid && !scope.errors.isDuplicate) {

          if (!scope.isEditLanguages) {

            scope.registrationData.languages = ArrayService.arrayToString(scope.languages, 'name', ',');
            $rootScope.$broadcast('validateSignatureForm');
          } else {
            
            $rootScope.$broadcast('submitAdditionalInfor');
          }
          
        } else {
          scope.languagesActive = true;
        }
      });

      /* Handle auto complete for languages dropdown*/

      // handle when toggle dropdown
      scope.toggleDropdown = function () {
        SearchDropdownService.setAutoFocus();
      };

      // keep dropdown open when click on input tab
      scope.keepDropdownOpen = function($event) {
        $event.stopPropagation();
      };
    }
  };
};

languageDirective.$inject = [
  '$rootScope',
  'APP_CONSTANT',
  'ValidateMessagesService',
  'ArrayService',
  'SearchDropdownService',
  '$timeout'
];

module.exports = languageDirective;
