'use strict';

var _ = require('lodash');

var searchLocationDirective = function($modal, InformationAPI, ValidateMessagesService, CommonModals, PracticeLocationService) {
  return {
    restrict: 'E',
    scope: false,
    templateUrl: 'registration/views/search-location.html',
    link: function (scope, element, attrs) {

      /**
       * [createVariables create all variables]
       */
      function createVariables() {
        // all validation messages
        scope.messages = ValidateMessagesService;

        // contain all result of search location
        scope.searchLocationResult = [];

        // contain info of selected location
        scope.selectedLocation = angular.copy(PracticeLocationService.getLocationObj());

        // backup info of latest selected location
        scope.bakSelectedLocation = angular.copy(PracticeLocationService.getLocationObj());

        scope.searchKey = '';

        scope.isLoading = false;

        scope.showNoSearchResultMsg = false;

        scope.isOpenDropdown = false;
      }

      createVariables();

      /**
       * [selectLocation - handle when user select a location]
       *
       */
      scope.selectLocation = function (selectedLocation) {
        scope.selectedLocation = scope.bakSelectedLocation = selectedLocation;
        scope.searchKey = selectedLocation.name;
      };

      /**
       * [searchLocation - handle when user type keyword to search - call api]
       *
       */
      scope.searchLocation = function(searchKey) {
        scope.isLoading = true;
        scope.isOpenDropdown = true;
        var searchExec = _.debounce(function() {
          InformationAPI
            .locations
            .searchLocation(searchKey, scope.registrationData.timezone_id)
            .then(function(data) {
              var searchResultCount = data.length;

              scope.showNoSearchResultMsg = searchResultCount === 0;
              scope.searchLocationResult = data;
            },
            function(data) {
              CommonModals.openBaseModal('Server error!');
            })
            .finally(function() {
              scope.isLoading = false;
            });
        },
        200);
        // handle after user select location then change the search key
        if (scope.bakSelectedLocation.name === searchKey) {
          // when user change search key to previous selected location, backup bakSelectedLocation will be reasign to selectedLocation
          scope.selectedLocation = scope.bakSelectedLocation;
        } else {
          // when user change search key different from selected location name, selectedLocation will be removed - equal with no location selected
          scope.selectedLocation = angular.copy(PracticeLocationService.getLocationObj());
          scope.selectedLocation.name = searchKey;
        }

        if (searchKey) {
          searchExec();
        } else {
          scope.searchLocationResult = [];
          scope.isLoading = false;
          scope.showNoSearchResultMsg = false;
        }
      };

      /**
       * [save - handle when user click save button]
       *
       */
      scope.save = function () {
        var practiceLocationInfo = angular.copy(PracticeLocationService.getPracticeLocationObj(scope.selectedLocation));

        // only handle when there is no added location
        if (!scope.locations.length) {
          var addLocationModalInstance = $modal.open({
            animation: true,
            resolve: {
              sendData: function () {
                return {
                  locations: scope.searchLocationResult,
                  selectedLocation: practiceLocationInfo,
                  isRegistration: scope.isRegistration,
                  listCountries: scope.registrationData.listCountries
                };
              }
            },
            templateUrl: 'registration/views/location-with-address.modal.html',
            controller: require('../controllers/location-with-address.controller'),
            controllerAs: 'locaAdd',
            windowClass: 'modal-info other-location'
          });

          addLocationModalInstance.result.then(function (data) {
            // user submit modal
            scope.locations.push(data);
          }, function (reason) {
            // user cancel modal
          });

          if (scope.isRegistration) {
            // remove error message when user begin add location
            scope.errors.hasNoLocation = false;
          }
        }
      };

    }

  };
};

searchLocationDirective.$inject = [
  '$modal',
  'bookdoc.information.api',
  'ValidateMessagesService',
  'CommonModals',
  'PracticeLocationService'
];

module.exports = searchLocationDirective;
