/*global d3, moment*/
/* Define controller for appointments module*/
'use strict';

var _ = require('lodash');

var DashboardCtrl = function (profileData, appointmentApi, chartService, BOOKDOC_DASHBOARD, moment, CommonModals,
                              $rootScope, $stateParams, dashboardApiService, chatService, $modal) {
  var vm = this;

  var doctorIdParam = $stateParams.doctorId;
  vm.practiceLocationIdParam = $stateParams.practiceLocationId;

  if (vm.practiceLocationIdParam) {
    $rootScope.profiles = profileData || $rootScope.profiles;
    vm.locationId = vm.practiceLocationIdParam;
  }

  var currentDate = moment().utc().format(BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT);
  var currentDateTimestamp = moment(currentDate, BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT).utc().valueOf();

  vm.statictisData = chartService.resetStatictisData();

  var endDay = moment.unix(Math.floor(currentDateTimestamp / 1000));
  var startDay = moment(endDay).subtract(1, 'month');

  endDay = endDay.format(BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT);
  startDay = startDay.format(BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT);

  /* get current week number until december */
  var seasonBannerStartDate = moment('2023-07-01');
  var seasonBannerEndDate = moment('2024-12-31');
  var today = moment();
  vm.currentWeekNumber = null;

  if (seasonBannerStartDate <= today && seasonBannerEndDate >= today){
    var currentDay = today.clone().day(); // Sunday is 0 and Saturday is 6
    var diffToNextWeek = 7 - currentDay;
    var nextWeekStartDate = today.date() + diffToNextWeek;
    vm.currentWeekNumber = Math.ceil((nextWeekStartDate) / 7);
  }

  vm.doctorStatistic = {};

  vm.showChatReviews = $rootScope.chatEnabled && !$rootScope.profiles.video_enabled;

  appointmentApi
    .getStatisticAppointments(startDay, endDay, vm.locationId, doctorIdParam)
    .then(
      function (data) {

        initVariables();
        handleReceiveData(data);
      },
      function (error) {
        CommonModals.showInvalidPracticeLocationModal(error, doctorIdParam);
      }
    )
    .finally(function () {
      // hide indicator
      $rootScope.showIndicator = false;
    });

  /**
   * [handleReceiveData - handle appointment data]
   *
   * [params] {Array} data
   */
  function handleReceiveData(data) {
    // backup appointment data
    vm.appointmentData.location = vm.locationId;
    vm.appointmentData.month = vm.selectedMonthRange;
    vm.appointmentData.data = data;

    // view data
    if (data.metadata && data.metadata.total > 0) {
      var convertedData = chartService.convertAppointmentData(data);
      vm.statictisData = convertedData.convertData;
      vm.totalByMonthRange = convertedData.totalByMonthRange;
    } else {
      vm.statictisData = chartService.resetStatictisData();
      vm.totalByMonthRange = chartService.resetTotalByMonthRangeData();
    }
  }


  /**
   * [getAppointmentData - get appointment data]
   *
   */
  function getAppointmentData() {
    var startDay = vm.startDay.format(BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT);
    var endDay = vm.endDay.format(BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT);

    appointmentApi
      .getStatisticAppointments(startDay, endDay, vm.locationId, doctorIdParam)
      .then(
        function (data) {
          // hide indicator
          $rootScope.showIndicator = false;
          handleReceiveData(data);
        },
        function (error) {
          CommonModals.showInvalidPracticeLocationModal(error, doctorIdParam);
        }
      );
  }

  /**
   * [initVariables create all variables]
   *
   */
  function initVariables() {
    vm.locations = _.filter(profileData.locations, {verified: true});
    vm.appointmentData = {
      locationId: null,
      monthRange: 1,
      data: []
    };

    vm.totalByMonthRange = chartService.resetTotalByMonthRangeData();

    // default value of location selectbox and month range selectbox
    vm.selectedLocation = 'All locations';
    vm.locationId = vm.practiceLocationIdParam || null;
    vm.selectedMonthRange = 1;

    // set default start day and end day for chart
    var currentDate = moment().utc().format(BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT);
    var currentDateTimestamp = moment(currentDate, BOOKDOC_DASHBOARD.DATE_NONE_HYPHEN_FORMAT).utc().valueOf();
    vm.endDay = moment.unix(Math.floor(currentDateTimestamp / 1000));

    vm.startDay = moment(vm.endDay).subtract(vm.selectedMonthRange, 'month');

    // chart configuration
    vm.tickValues = chartService.getTickValues(vm.selectedMonthRange);
    vm.options = {
      chart: {
        type: BOOKDOC_DASHBOARD.chartType,
        showLegend: false,
        height: 450,
        margin: {
          top: 20,
          right: 25,
          bottom: 60,
          left: 45
        },
        color: ['#94ca59', '#6286e4', '#e9da58', '#ec4c4f'],
        // interpolate: 'basis',

        x: function (d) {
          return d[0];
        },

        y: function (d) {
          return d[1];
        },
        transitionDuration: 300,
        useInteractiveGuideline: true,

        xAxis: {
          tickFormat: function (d, x) {
            if (x === undefined) {
              return d3.time.format('%d-%m-%Y')(new Date(d));
            }

            return d3.time.format('%b-%Y')(new Date(d));
          },
          tickValues: vm.tickValues,
          showMaxMin: false,
          staggerLabels: false,
          tickPadding: 15
        },
        yAxis: {
          tickFormat: function (d) {
            return (d === Math.floor(d)) ? d : '';
          },
          showMaxMin: true,
          staggerLabels: false
        }
      }
    };
  }

  /**
   * [changeLocation - handle when location changed]
   *
   * @param {object} e
   */
  vm.changeLocation = function (e, locationId) {
    if (vm.selectedLocation !== e.target.attributes.data.value) {
      // show indicator
      $rootScope.showIndicator = true;
      vm.selectedLocation = e.target.attributes.data.value;
      vm.locationId = locationId;
      getAppointmentData();
    }
  };

  /**
   * [changeMonthRange - hangle when month range changed]
   *
   * @param {object} e
   */
  vm.changeMonthRange = function (e) {
    if (vm.selectedMonthRange !== e.target.attributes.data.value) {
      $rootScope.showIndicator = true;
      vm.selectedMonthRange = e.target.attributes.data.value;
      vm.startDay = moment(vm.endDay).subtract(vm.selectedMonthRange, 'month');
      getAppointmentData();

      // re-get tick value of xaxis
      vm.options.chart.xAxis.tickValues = chartService.getTickValues(vm.selectedMonthRange);
    }
  };

  // Get doctor statistic
  function getDoctorStatistic() {

    var requestParams = doctorIdParam ? {location_id: vm.practiceLocationIdParam} : null;
    dashboardApiService.getDoctorStatistic(requestParams)
      .then(
        function (data) {
          vm.doctorStatistic = data;
        },
        function (error) {
          console.log(error);
        }
      );
  }

  getDoctorStatistic();

  function startCarousel() {
    function carouselDisplayNext(index) {
      for (var i = 0; i < totalElements; i++) {
        carouselContainer.children[i].style.display = 'none';
        var indicator = document.querySelector('.carousel-circles .fa-circle[data-index="' + i + '"]');

        if (indicator) {
          indicator.style.color = '#AAA';
        }
      }

      var carouselLink  = document.querySelector('#carousel-container .carousel-images a[data-index="' + index + '"]');
      var indicator     = document.querySelector('.carousel-circles .fa-circle[data-index="' + index + '"]');

      if (carouselLink) {
        carouselLink.style.display  = 'block';
      }

      if (indicator) {
        indicator.style.color       = '#000';
      }
    }

    function startCarouselInterval() {
      carouselInterval = setInterval(function() {
        currentIndex + 1 < totalElements ? currentIndex += 1 : currentIndex = 0;
        carouselDisplayNext(currentIndex);
      }, 5000);
    }

    function displaySelected() {
      carouselDisplayNext(currentIndex);
      clearInterval(carouselInterval);
      startCarouselInterval();
    }

    var arrowLeft   = document.querySelector('.fa-chevron-left.carousel-arrows');
    var arrowRight  = document.querySelector('.fa-chevron-right.carousel-arrows');
    startCarouselInterval();

    arrowLeft.addEventListener('click', function() {
      currentIndex === 0 ? currentIndex = totalElements - 1 : currentIndex -= 1;
      displaySelected();
    });

    arrowRight.addEventListener('click', function() {
      currentIndex + 1 < totalElements ? currentIndex += 1 : currentIndex = 0;
      displaySelected();
    });

    for (var x = 0; x < totalElements; x++) {
      var circle  = document.querySelector('.carousel-circles .fa-circle[data-index="' + x + '"]');

      circle.addEventListener('click', function(e) {
        currentIndex = parseInt(e.target.getAttribute('data-index'));;
        displaySelected();
      });
    }

    var banners  = document.querySelectorAll('.carousel-images a');
    function handleBannerClick(event){
      gtag('event', 'banner_click', {
        'event_category': 'Banner',
        'event_label': event.target.dataset.info
      });

    }
    banners.forEach(button => {
      button.addEventListener('click', handleBannerClick);
    });
  }

  var carouselInterval
  var currentIndex        = 0;
  const carouselContainer = document.querySelector('#carousel-container .carousel-images');
  var totalElements     = carouselContainer.childElementCount;
  // if(vm.currentWeekNumber && vm.currentWeekNumber >= 0){
  //   totalElements += 1;
  // }
  startCarousel();

  vm.generateQRCode = function () {
    console.log("Generate qr code");
    $rootScope.showIndicator = true;

    var successCallback = function (qrcodeUrl) {
      $rootScope.showIndicator = false;
      console.log(qrcodeUrl);
      vm.qrcodeUrl = qrcodeUrl;
      var modal = $modal.open({
        animation: true,
        resolve: {
          QRCodeUrl: function () {
            return qrcodeUrl;
          }
        },
        templateUrl: 'dashboard/views/qrcode-modal.html',
        controller: "QRCodeModalController",
        controllerAs: '$ctrl',
        size: 'lg'
      });
    };
    var errorCallback = function (error) {
      $rootScope.showIndicator = false;
      var errorMsg = "Error happens while generating QR code.";
      if (error.data.errors && error.data.errors[0])
        errorMsg = error.data.errors[0];
      window.alert(errorMsg);
    };
    chatService.generateQRCode(successCallback, errorCallback);
  };

  vm.qrcodeUrl = "String";
};

DashboardCtrl.$inject = [
  'profileData',
  'appointment.api.service',
  'bookdoc.dashboard.service',
  'BOOKDOC_DASHBOARD',
  'moment',
  'CommonModals',
  '$rootScope',
  '$stateParams',
  'dashboardApiService',
  'chatService', '$modal'
];

module.exports = DashboardCtrl;
