'use strict';

var escapeDirective = function() {
  return {
    link: function(scope, element, attrs) {
      element.bind('keydown keypress', function(event) {
        if (event.which === 27) {
          scope.$apply(function() {
            scope.$eval(attrs.mnEscape);
          });

          event.preventDefault();
        }
      });
    }
  };
};

escapeDirective.$inject = [
];

module.exports = escapeDirective;
