"use strict";

function MedicationHistoryController(sessionDetails, chat, $modalInstance, $scope, moment) {
  var ctrl = this;
  ctrl.page = 1
  ctrl.startDate = moment().subtract(1, 'months').format("YYYY-MM-DD")
  ctrl.startDateOpen = false
  ctrl.endDate   = moment().format("YYYY-MM-DD")
  ctrl.endDateOpen = false
  ctrl.sessionDetails = sessionDetails
  ctrl.totalPages = 1
  ctrl.historyInfo = []

  var medicationMapping = {
      "acei_arb_arni": "ACEi/ARB/ARNi",
      "beta_blocker": "Beta-blocker",
      "mra": "MRA",
      "sglt2_i": "SGLT2-i",
      "loop_diuretic": "Loop Diuretic"
  }


  ctrl.closeModal = function () {
    $modalInstance.dismiss();
  }

  ctrl.openStartDate = function () {
    ctrl.startDateOpen = true
  }

  ctrl.openEndDate = function () {
    ctrl.endDateOpen = true
  }

  ctrl.nextPage = function () {
    if (ctrl.page === ctrl.totalPages) return
    ctrl.page = ctrl.page + 1
  }

  ctrl.previousPage = function () {
    if (ctrl.page === 1) return
    ctrl.page = ctrl.page - 1
  }

  ctrl.parseDate = function (date) {
    return moment(date).format("DD.MM.YYYY")
  }

  ctrl.parseMedication = function (medication) {
    var appliedMedication = Object.keys(medication).filter(function (m) {
      return medication[m]
    }).map(function (m) { return medicationMapping[m]}).filter(function (m) { return !!m } )

    return appliedMedication.reduce(function (acc, m, index) {
      if (!m) return acc
      if (index === 0) return m
      if (index === appliedMedication.length - 1) return acc + ", " + m
      return acc + ", " + m
    }, "")
  }

  var getChatMedication = function() {
    chat.getChatMedicationHistory(function(history) {
      ctrl.historyInfo = history.chat_medications || []
      ctrl.totalPage = history.meta?.total_pages || 1
    }, function() {}, ctrl.sessionDetails.id, ctrl.page, moment(ctrl.startDate).format("YYYY-MM-DD"), moment(ctrl.endDate).format("YYYY-MM-DD"))
  }

  $scope.$watch(angular.bind(ctrl, function() {
    return ctrl.startDate;
  }), function () {
    getChatMedication()
  });

  $scope.$watch(angular.bind(ctrl, function() {
    return ctrl.endDate;
  }), function () {
    getChatMedication()
  });

  $scope.$watch(angular.bind(ctrl, function() {
    return ctrl.page;
  }), function () {
    getChatMedication()
  });
};

MedicationHistoryController.$inject = ["sessionDetails", "chatService", "$modalInstance", "$scope", "moment"];

module.exports = MedicationHistoryController;
