"use strict";

function EbClaimsCtrl() {
  var ctrl = this;
  ctrl.activeTab = 0;

  console.log("here is the employee benefits");

  ctrl.onOpenClaimsClicked = function () {
    console.log("on open claims clicked");
    ctrl.activeTab = 0;
  };

  ctrl.onSubmittedClaimsClicked = function () {
    console.log("on submitted claims clicked");
    ctrl.activeTab = 1;
  };
}

var ebClaimsComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/claims.html',
    controller: EbClaimsCtrl
  };
};

EbClaimsCtrl.$inject = [];

module.exports = ebClaimsComponent();
