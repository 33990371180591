'use strict';

module.exports = angular
  .module('bookdoc.dashboard', [
    'ui.bootstrap',
    'ui.router',
    'nvd3',

    require('../../../tmp/templates').name,
    require('../common').name,
    require('./controllers').name,
    require('./components').name
  ])

  // constants
  .constant('BOOKDOC_DASHBOARD', require('./dashboard.constant'))

  // services
  .factory('bookdoc.dashboard.service', require('./services/chart.service'))
  .factory('dashboardApiService', require('./services/dashboard.api.service'));

require('./dashboard.routes');
