/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.audits.controllers',
    [
      'restangular',
      'bookdoc.profile',
      'angularMoment'
    ]
  )
  .controller('AuditCtrl', require('./audits.controller'));
