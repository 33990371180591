'use strict';

var selectWeekDirective = function() {
  return {
    restrict: 'A',
    transclude: true,
    templateUrl: 'calendar/availability/views/select-week.html',
    scope: {
      slStart: '=startWeekData',
      slEnd: '=endWeekData',
      slPrev: '&prevWeek',
      slNext: '&nextWeek',
      disablePrev: '=disablePrev',
      disableNext: '=disableNext'
    },
    link: function($scope, $elem, attrs) {

    }
  };
};

selectWeekDirective.$inject = [
];

module.exports = selectWeekDirective;
