"use strict";

function EbPreRegistrationsCtrl($rootScope, ebService, $modal) {
  var ctrl = this;
  init();

  function init() {
    ctrl.preRegistrationsList = [];
    ctrl.page = 1;
    ctrl.per_page = 10;
    ctrl.totalPages = 1;
    ctrl.isLoading = true;
    ctrl.listIsEmpty = false;
    reloadCurrentPagePreRegistrationVisits();
  }

  function reloadCurrentPagePreRegistrationVisits() {
    var successCallback = function (preRegistrationsListResponse) {
      ctrl.totalPages = preRegistrationsListResponse.metadata.total_pages;
      ctrl.preRegistrationsList = ctrl.preRegistrationsList.concat(preRegistrationsListResponse.visits);
      ctrl.isLoading = false;
      if (ctrl.preRegistrationsList.length === 0 && ctrl.page === 1)
        ctrl.listIsEmpty = true;
    };
    var errorCallback = function (error) {
      window.alert("Can't load the pre-registration visits");
    };
    ebService.getPreRegistrationsList(ctrl.page, ctrl.per_page, successCallback, errorCallback);
  }

  ctrl.onAttendBtnClickedCallback = function (visit) {
    var modalInstance = $modal.open({
      animation: true,
      resolve: {
        visit: function () {
          return visit;
        },
        ebService: function () {
          return ebService;
        }
      },
      templateUrl: 'employee-benefits/views/confirm-attendance-modal.html',
      controller: require("../controllers/confirm-attendance-modal.controller"),
      controllerAs: '$ctrl'
    });

    modalInstance.result.then(function (status) {
      if (status) init();
    });
  };

  ctrl.onLoadMore = function () {
    if (ctrl.page >= ctrl.totalPages) return;
    ctrl.page = ctrl.page + 1;
    ctrl.isLoading = true;
    reloadCurrentPagePreRegistrationVisits();
  };
}

var ebPreRegistrationsComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/pre-registrations.html',
    controller: EbPreRegistrationsCtrl
  };
};

EbPreRegistrationsCtrl.$inject = ['$rootScope', 'ebService', '$modal'];

module.exports = ebPreRegistrationsComponent();
