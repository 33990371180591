"use strict";

function SubmitClaimErrorDialogCtrl($modalInstance) {
  var ctrl = this;

  ctrl.onDismissBtnClicked = function () {
    $modalInstance.close(false);
  };
}

SubmitClaimErrorDialogCtrl.$inject = ["$modalInstance"];

module.exports = SubmitClaimErrorDialogCtrl;
