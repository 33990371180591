/*Con fig router for app*/
'use strict';

var routerConfig = function ($urlRouterProvider) {
  $urlRouterProvider.rule(function ($injector, $location) {
    var path = $location.url();

    // check to see if the path already has a slash where it should be
    if (path[path.length - 1] === '/' || path.indexOf('/?') > -1) {
      return;
    }

    if (path.indexOf('?') > -1) {
      return path.replace('?', '/?');
    }

    return path + '/';
  });

  $urlRouterProvider.otherwise('/');
};

var states = function ($stateProvider, $locationProvider) {
  $stateProvider
    .state("main", {
      url: "/",
      abstract: true,
      views: {
        header: {
          templateUrl: "header/views/header.html",
          controller: require("../header/controllers/header.controller"),
          controllerAs: "head",
        },
        video: {
          templateUrl: "video/views/video.html",
          controller: require("../video/controllers/video.controller"),
          controllerAs: "video",
        },
        "": {
          template: "<div ui-view>Content Place Holder</div>",
        },
        footer: {
          templateUrl: "footer/views/footer.html",
          controller: require("../footer/controllers/footer.controller"),
          controllerAs: "footer",
        },
      },
    })
    .state("login", {
      url: "/login/",
      templateUrl: "auth/views/login.html",
      // controller: AuthController,
      data: {
        isPublic: true,
      },
    })
    .state("loginGroup", {
      url: "/login-group/",
      templateUrl: "auth/views/login.html",
      data: {
        isPublic: true,
      },
    })
    .state("thankyou", {
      url: "/thankyou/",
      templateUrl: "auth/views/thankyou.html",
      // controller: 'Auth.ThankyouController'
      data: {
        isPublic: true,
      },
    });
  $locationProvider.html5Mode(true);
};

routerConfig.$inject = ['$urlRouterProvider'];
states.$inject = ['$stateProvider', '$locationProvider'];

module.exports = angular
  .module('bookdoc')
  .config(states)
  .config(routerConfig);
