'use strict';

var _ = require('lodash');

var LocationModalCtrl = function($modalInstance) {
  var vm = this;

  /**
   * [close - handle user click close button]
   *
   */
  vm.close = function() {
    $modalInstance.dismiss();
  };
};

LocationModalCtrl.$inject = [
  '$modalInstance'
];

module.exports = LocationModalCtrl;
