'use strict';

var ngPhoneNumberDirective = function($filter) {
  function link(scope, element, attributes) {

    // scope.inputValue is the value of input element
    // used in template
    scope.inputValue = scope.phoneModel;

    scope.$watch('inputValue', function(value, oldValue) {

      value = String(value);
      var number = value.replace(/[^0-9]+/g, '');
      scope.phoneModel = "+" + number;
      scope.inputValue = "+" + number;

    });
  }

  return {
    link: link,
    restrict: 'E',
    replace: true,
    scope: {
      phoneModel: '=model',
    },
    template: '<input ng-model="inputValue" type="tel" class="form-control modal-info__text-field">',
  };
};

ngPhoneNumberDirective.$inject = [
  '$filter'
];

module.exports = ngPhoneNumberDirective;
