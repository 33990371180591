'use strict';

var _ = require('lodash');

var AdvanceInfoService = function (ArrayService) {
  return {
    educationRequest: {},
    hospitalRequest: {},
    languageRequest: {},
    profiles: {},
    formatLanguageRequest: function (request) {
      // Remove empty choice
      request = ArrayService.removeEmptyElement(request, 'language');

      // Make request from language list that has format as a string "English|Vietnamese"
      var tmp = [];
      _.forEach(request, function (item) {
        tmp.push(item.language);
      });

      // Join request as format {languages:"English|Vietnamese"}
      request = {
        languages: tmp.join('|')
      };

      return request;
    }
  };
};

AdvanceInfoService.$inject = [
  'ArrayService'
];

module.exports = AdvanceInfoService;
