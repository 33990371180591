/*global gapi*/
'use strict';

var addToCalendarService = function ($rootScope, ENV, APP_CONSTANT) {

  return {
    checkAuth: function () {
      gapi.auth.authorize(
        {
          'client_id': ENV.googleClientId,
          'scope': APP_CONSTANT.calendarScopes.join(' '),
          'immediate': true
        }, function(res) {
          console.log(res);
          if (res && !res.error && res.status.signed_in) {
            $rootScope.authCalendar = true;
            gapi.client.load('calendar', 'v3');
          } else {
            $rootScope.authCalendar = false;
          }
        });
    },

    authCalendar: function(callback) {

      gapi.auth.authorize(
        {
          'client_id': ENV.googleClientId,
          'scope': APP_CONSTANT.calendarScopes.join(' '),
          'immediate': false
        }, function(res) {
          console.log(res);
          if (res && !res.error) {
            $rootScope.authCalendar = true;
            gapi.client.load('calendar', 'v3', callback);

          }
        });
    },

    addToCalendar: function(event) {
      return gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        'resource': event
      });
    },

    deleteGoogleCalendarEvent: function (eventID) {
      return gapi.client.calendar.events.delete({
        'calendarId': 'primary',
        'eventId': eventID
      });
    }
  };

};

addToCalendarService.$inject = [
  '$rootScope',
  'ENV',
  'APP_CONSTANT'
];

module.exports = addToCalendarService;
