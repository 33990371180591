var onRenderFinished = function () {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      if (scope.$last === true) {
        setTimeout(function () {
          scope.$emit("onMsgRenderFinished");
        });
      }
    }
  };
};

module.exports = onRenderFinished;
