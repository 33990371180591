'use strict';

require('angular');
require('angular-ui-router');
require('angular-cookies');
require('angular-messages');

var auth = angular
  .module('Auth', [
    'ngCookies',
    'ui.router',
    'ngMessages',
    'restangular',
    'ui.bootstrap'
  ])
  .config([
    '$httpProvider', function($httpProvider) {
      $httpProvider.interceptors.push([
        "$injector", function($injector) {
          return $injector.get("Auth.AuthInterceptor");
        }
      ]);
    }
  ])
  .run([
    '$http',
    '$rootScope',
    'Auth.AuthService',
    'Auth.Auth',
    'Auth.ROLES',
    'Auth.EVENTS',
    '$cookieStore',
    'Auth.AuthSession',
    function($http, $rootScope, AuthService, AuthProvider, USER_ROLES, AUTH_EVENTS, $cookieStore, Session) {
      $rootScope.userRoles = USER_ROLES;
      $rootScope.currentUser = AuthService.currentUser();
      $rootScope.isAuthenticated = AuthService.isAuthenticated();
      // $rootScope.isAuthorized = AuthService.isAuthorized;
      $http.defaults.headers.common[AuthProvider.authHeader] = AuthService.authToken;

      $rootScope.$on("$stateChangeStart", function(event, next, nextParams) {
        var auth_event, authorizedRoles, isPublic;
        // var cookie = ($cookieStore.get('AuthSession') || {token: 'cookie'});
        var tokens = {
          doctorToken: $cookieStore.get('AuthSession'),
          assistantToken: $cookieStore.get('AssistantSession')
        };

        var token = (next.name === 'main.terms' || next.name === 'main.privacy') ? nextParams.token : '';

        isPublic = next.data && next.data.isPublic || false;
        if (!!isPublic) {
          return;
        }

        if (!$rootScope.isAuthenticated && ((tokens.doctorToken && token !== tokens.doctorToken.token) && (tokens.assistantToken && token !== tokens.assistantToken))) {
          // clear Session when authenticated false
          Session.destroy();
          event.preventDefault();
          $rootScope.$emit(AUTH_EVENTS.unauthenticated);
        }
      });
    }
  ]);

module.exports = auth;

require('./constants');
require('./modals.service');
require('./interceptor');
require('./provider');
require('./service');
require('./session');
require('./controllers');
require('./directives');
require('./notifications.service');
