/* global moment*/

'use strict';
var _ = require('lodash');

var specialtiesFilter = function() {
  return function (specialties) {

    var specialtiesFormat = specialties.map(function(specialty) {
      return specialty.name;
    }).join(", ");

    return specialtiesFormat;
  };
};

specialtiesFilter.$inject = [];

module.exports = specialtiesFilter;
