/* Define controller for registration step 3 module*/
'use strict';

var PracticeLocationCtrl = function ($scope, InformationApi) {
  var vm = this;

  /**
   * [createVariables create all variables]
   */
  function createVariables() {
    // list of all practice locations that user added
    $scope.locations = [];

    // true - registration
    // false - update profile
    $scope.isRegistration = true;

    $scope.errors = {
      hasNoLocation: false
    };

  }

  createVariables();

  $scope.$on('submitPracticeLocation', function () {
    if ($scope.locations.length) {
      // there is no location

      // update registration data
      $scope.registrationData.practice_location = $scope.locations[0];

      // Set current form is valid
      $scope.formValidate.valid = true;

      // hide error message
      $scope.errors.hasNoLocation = false;
      return;
    }

    //set form is invalid
    $scope.formValidate.valid = false;

    // show error message
    $scope.errors.hasNoLocation = true;

  });

};

PracticeLocationCtrl.$inject = [
  '$scope',
  'bookdoc.information.api'
];

module.exports = PracticeLocationCtrl;
