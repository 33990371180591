'use strict';

var moment = (typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);
var _ = require('lodash');

require('moment-timezone');

var availabilitiesFormatData = function ($rootScope, LockMechanismService, TimeZoneService, $q) {
  var DATE_HYPHEN_FORMAT = 'YYYY-MM-DD';
  var DATE_NONE_HYPHEN_FORMAT = 'YYYYMMDD';

  function transformTimeSlot(timeslot, repeatType) {
    console.log(timeslot);

    return {

      date: moment.utc(timeslot.date, DATE_HYPHEN_FORMAT).format(DATE_NONE_HYPHEN_FORMAT),
      appointment_length: Number(timeslot.appointment_length),
      off: timeslot.off,
      start_time: timeslot.start_time/1000,
      end_time: timeslot.end_time/1000,
      location_id: (timeslot.location && timeslot.location.id) || timeslot.location_id,
      type: timeslot.type,
      inValidData: timeslot.inValidData,
      id: timeslot.id,
      _destroy: timeslot._destroy
    };

  }

  function formatDataBeforeUpdate(data, repeatType, locations) {
    var _data = angular.copy(data);
    var updatedTimeslots = [];
    var result;
    console.log(data);

    locations = _.filter(locations, function(location) {
      return location.verified === true;
    });
    console.log(locations);

    _.each(_data, function (obj) {
      var timeslotsLen = obj.timeslots.length,
          locationLen = locations.length;
      for (var i = 0; i < locationLen; i++) {
        var locationAvails = _.filter(obj.timeslots, function(local) {
          var id = local.location_id;
          if (local.location && local.location.id) {
            id = local.location.id;
          }
          return id ===  locations[i].id && local.off === false;
        });

        if (locationAvails.length > 0) {
          _.each(locationAvails, function(avail) {
            console.log(avail.start_time);
            if (avail.removeByUpdated) {
              result = avail;
            } else {
              result = _.merge(_.clone(obj, true), avail);
            }
            if (avail.isUpdated) {
              updatedTimeslots.push(transformTimeSlot(result));
            }
          });

        }
      }
    });

    return {
      results: {
        rules: updatedTimeslots
      }
    };
  }

  function findIndex(data, attr, value) {
    return _.findIndex(data, function (_avail) {
      return _avail[attr] === value;
    }) >= 0;
  }

  function addEmptyDay(start, data) {
    var i;
    var availDate;
    var exist;
    var startDay;
    var now;

    startDay = moment.utc(start, DATE_NONE_HYPHEN_FORMAT).format(DATE_NONE_HYPHEN_FORMAT);

    now = moment.utc();

    now.hours(0);
    now.minutes(0);
    now.seconds(0);
    now.milliseconds(0);

    for (i = 0; i < 7; i++) {

      exist = findIndex(data, 'week_day', i);

      if (!exist) {

        availDate = moment.utc(startDay, DATE_NONE_HYPHEN_FORMAT).add(i ? i - 1 : 6, 'days');

        data.push({
          date: availDate.format(DATE_HYPHEN_FORMAT),
          isAllowUpdate: availDate.diff(now) >= 0,
          timeslots: [],
          type: 1,
          week_day: i
        });

      }

    }

    return _.sortBy(data, function(a, b) {
      return new Date(a.date);
    });

  }

  function updateDateBasedOnTimezone(data) {
    // Convert data of availablity to correct timezone date
    _.each(data, function(availability, idx) {
      availability.date = TimeZoneService.hyphenDateFormat(availability.start_time);
      availability.week_day = moment(availability.date).day();
    });

    return data;
  }

  function transformDataByWeekday(data, locations) {
    var now;
    var currentDate;
    var _data;
    var _avail;
    data = updateDateBasedOnTimezone(data);

    if (!data || !data.length) {
      return data;
    }

    var currentTime = TimeZoneService.currentTime();
    now = moment(currentTime);

    currentDate = angular.copy(now);

    currentDate.hours(0);
    currentDate.minutes(0);
    currentDate.seconds(0);
    currentDate.milliseconds(0);

    _data = _.map(_.groupBy(data, 'date'), function (avails) {

      _avail = {
        date: avails[0].date,
        week_day: avails[0].week_day,
        timeslots: []
      };

      _.each(avails, function (avail) {
        console.log(avail);

        avail.isAllowUpdate = TimeZoneService.timeInFuture(avail.start_time, currentTime);

        avail.start_time = moment.utc(avail.start_time * 1000).diff(0);
        avail.end_time = moment.utc(avail.end_time * 1000).diff(0);
        avail.status = '';
        avail.inViewMode = LockMechanismService.checkLocationInViewMode(locations,avail.location_id);

        _avail.timeslots.push(avail);

        delete avail.date;
        delete avail.week_day;

      });

      // Sort timeslots in a day
      _avail.timeslots.sort(function (timeslot, anotherTimeslot) {
        if (timeslot.start_time < anotherTimeslot.start_time) {
          return -1;
        }

        if (timeslot.start_time > anotherTimeslot.start_time) {
          return 1;
        }

        return 0;
      });

      _avail.isAllowUpdate = moment.utc(_avail.date, DATE_NONE_HYPHEN_FORMAT).diff(currentDate) >= 0;

      return _avail;
    });

    return _data;
  }

  function formatClearAllAvailabilities(availabilitiesBackup, availabilities, isAssistant, practiceLocationIdParam) {
    // Use $q to make sure check all timeslot before update
    return $q(function(resolve, reject) {
      var currentTime = new Date().valueOf(),
        avails = angular.copy(availabilitiesBackup);
      currentTime = moment(currentTime).format('YYYY-MM-DD HH:mm');
      currentTime = moment.tz(currentTime, 'Etc/UTC').utc().valueOf();

      angular.forEach(avails, function(avail, idx) {
        _.each(avail.timeslots, function(timeslot, index) {
          if (!timeslot.off && timeslot.start_time >= currentTime && timeslot.id) {
            // Remove valid time of availabilities
            _.each(availabilities[idx].timeslots, function(timeslotData) {

              if (timeslot.id === timeslotData.id && !timeslotData.inViewMode && (!isAssistant || (isAssistant && timeslot.location_id === practiceLocationIdParam))) {
                timeslot._destroy = true;
                timeslot.isUpdated = true;
              }
            });
          }
        });
      });
      resolve(avails);
    });
  }

  return {
    transformDataByWeekday: transformDataByWeekday,
    addEmptyDay: addEmptyDay,
    formatDataBeforeUpdate: formatDataBeforeUpdate,
    updateDateBasedOnTimezone: updateDateBasedOnTimezone,
    formatClearAllAvailabilities: formatClearAllAvailabilities
  };

};

availabilitiesFormatData.$inject = [
  '$rootScope',
  'LockMechanismService',
  'TimeZoneService',
  '$q'
];

module.exports = availabilitiesFormatData;
