/*global require*/

'use strict';

module.exports = angular
  .module('bookdoc.contact.controllers',
    [
    ]
  )
  .controller('ContactCtrl', require('./contact.controller'));
