/*global moment*/

'use strict';
var _ = require("lodash");

var chartService = function (BOOKDOC_DASHBOARD) {

  return {
    resetStatictisData: function () {
      return [
        {
          key: BOOKDOC_DASHBOARD.statictis.totalAppointments,
          values: []
        },
        {
          key: BOOKDOC_DASHBOARD.statictis.doctorCancel,
          values: []
        },
        {
          key: BOOKDOC_DASHBOARD.statictis.noShow,
          values: []
        },
        {
          key: BOOKDOC_DASHBOARD.statictis.patientCancel,
          values: []
        }
      ];
    },

    resetTotalByMonthRangeData: function () {
      return {
        total: 0,
        noShow: 0,
        doctorCancel: 0,
        patientCancel: 0
      };
    },

    getTickValues: function (monthTotal) {
      var result = [];
      var dayString = moment.utc().format("YYYY-MM-DD");

      // get date value
      var day = dayString.substring(8, 10);

      if (day === '01') {
        // if current date is 01, increase month need to show by 1
        monthTotal++;
      }

      // replace "DD" in dayString by "01"
      dayString = dayString.substring(0, 8);
      dayString = dayString + '01';

      var i = 0;
      day = moment(dayString, 'YYYY-MM-DD');

      for (i; i < monthTotal; i++) {
        result.push(day.valueOf());
        day = day.subtract(1, 'month');
      }

      return result;
    },

    convertAppointmentData: function (data) {
      var statisticData = data.statistics;
      var statisticMetadata = data.metadata;
      var convertedData = this.resetStatictisData();
      var totalByMonthRange = this.resetTotalByMonthRangeData();
      var resetStatictiKeys = _.keys(statisticData);

      // Update statistic data for each date
      _.forEach(resetStatictiKeys, function (key) { 
        var timestamp = moment(key, "DD-MM-YYYY").valueOf();
        convertedData[0].values.push([timestamp, statisticData[key].total]);
        convertedData[1].values.push([timestamp, statisticData[key].cancel_by_doctor]);
        convertedData[2].values.push([timestamp, statisticData[key].no_show]);
        convertedData[3].values.push([timestamp, statisticData[key].cancel_by_patient]);
      });

      // Update total data
      totalByMonthRange = { 
        total: statisticMetadata.total,
        noShow: statisticMetadata.no_show,
        doctorCancel: statisticMetadata.cancel_by_doctor,
        patientCancel: statisticMetadata.cancel_by_patient
      };

      return {
        convertData: convertedData,
        totalByMonthRange: totalByMonthRange
      };
    }

  };

};

chartService.$inject = ['BOOKDOC_DASHBOARD'];

module.exports = chartService;
