/* Define controller for appointments module*/
'use strict';

var _ = require('lodash');

var AlertCtrl = function(profileData, ALERTS_API_SERVICE, ALERTS_API_ROUTE, $scope, $rootScope, CachingQueryParams, $stateParams, AlertService, $state, DataTimeService, moment) {

  var vm = this;

  var doctorId = Number($stateParams.doctorId) || null;

  var practiceLocationIdParam = Number($stateParams.practiceLocationId) || null;

  vm.isAssistant = !!doctorId;

  if (vm.isAssistant) {
    $rootScope.profiles = profileData || $rootScope.profiles;
  }

  function createVariables() {

    vm.locations = profileData.locations;

    // In case is Assistant only show location that Assistant selected to view alerts
    if (vm.isAssistant) {
      vm.locations = _.filter(vm.locations, function (location) {
        return location.id === practiceLocationIdParam;
      });
    }

    vm.sendEmail = profileData.send_thank_you_email;
    vm.currentUserId = profileData.id;

    /* Pagination */
    vm.paginationSize = ALERTS_API_ROUTE.pagination.size;
    vm.notificationLimit = ALERTS_API_ROUTE.pagination.limit;

  }

  /**
   * Get location id, current page, sort page from url
   * @return {[type]} [description]
   */
  function initDataFromParams() {

    var parameters = CachingQueryParams.get();
    var locationId = vm.isAssistant ? practiceLocationIdParam : Number(parameters.location_id);
    var currentPage = Number(parameters.page);
    var sortBy = parameters.sort;

    if (locationId) {
      var currentLocation = _.find(vm.locations, function (location) {
        return location.id === locationId;
      });

      vm.locationId = (currentLocation && currentLocation.id) || undefined;
      vm.currentLocation = (currentLocation && currentLocation.name) || 'All locations';

    } else {
      vm.currentLocation = 'All locations';
    }

    vm.currentPage = (!currentPage) ? 1 : currentPage;
    vm.currentSortBy = (sortBy === 'Oldest') ? sortBy : 'Newest';
  }

  /**
   * Get alerts from server of a doctor
   */
  function fetchAlertsData() {

    // Show indicator
    $rootScope.showIndicator = true;

    vm.noAlerts = false;

    var parameters = {'page': vm.currentPage, 'location_id': vm.locationId, 'sort': vm.currentSortBy, 'doctor_id': doctorId};

    // Caching query params
    CachingQueryParams.set(parameters);


    ALERTS_API_SERVICE.getAlerts(vm.locationId, vm.currentPage, vm.currentSortBy.toLowerCase(), doctorId)
      .then(
        function(data) {
          console.log(data.notifications);

          // Get no tification list, total pages and total count
          vm.alerts = data.notifications;
          vm.totalPages = data.meta.total_pages;
          vm.totalCount = data.meta.total_count;

          if (!vm.totalCount) {
            vm.noAlerts = true;
            return;
          }

          //pagination();

          // Get list id unread
          var ids = [];
          _.forEach(vm.alerts, function(item) {
            if (!item.read) {
              ids.push(item.id);
            }
          });

          // Get alertNumber and alertNumberBackup
          ALERTS_API_SERVICE.alertsNumberBackup =  ALERTS_API_SERVICE.alertsNumber =  Number(data.meta.unread_count);

          if (ids.length) {
            readAlerts(ids);
          }

        },
        function(err) {
          console.log(err);
        }
      ).finally(function() {
        // Hide indicator
        $rootScope.showIndicator = false;
      });

  }

  /**
   * Read alerts
   * @param  {array} List alerts Ids
   */
  function readAlerts(alertsIds) {

    ALERTS_API_SERVICE.readAlerts(alertsIds)
      .then(
        function(data) {

          // Set current alert number if is a Doctor
          if (!vm.isAssistant) {
            ALERTS_API_SERVICE.alertsNumber = data.total;
          }

        },
        function(err) {
          console.log(err);
        }
      );
  }

  // Set alert number in header
  function updateAlertsNumber() {
    $rootScope.$broadcast('changeAlertsNumber', ALERTS_API_SERVICE.alertsNumber);
  }

  createVariables();
  initDataFromParams();
  fetchAlertsData();

  /**
   * Set email to on/off
   * @param {[type]} value [description]
   */
  vm.setSendThankYouEmail = function(value) {

    if (!$rootScope.isEditMode) {
      return;
    }

    ALERTS_API_SERVICE.setSendThankYouEmail(vm.currentUserId, value)
      .then(
        function(data) {
          console.log(data);
          vm.sendEmail = $rootScope.profiles.send_thank_you_email = value;
        },
        function(err) {
          console.log(err);
        }
      );

  };

    /**
   * Get alert in a page
   */
  vm.getAlerts = function() {

    fetchAlertsData();
    updateAlertsNumber();

  };


  /**
   * Change location
   * @param  {[string]} name Location name
   * @param  {number} id   Location id
   */
  vm.changeLocation = function (name, id) {

    vm.currentPage = 1;
    vm.currentLocation = name;
    vm.locationId = id;
    vm.getAlerts();

  };

  /**
   * Change order by
   * @param  {[string]} repeatVal Newest or Oldest
   * @return {[array]}
   */
  vm.changeSortBy = function (repeatVal) {

    if (repeatVal === 'newest') {
      vm.currentSortBy = 'Newest';
    } else {
      vm.currentSortBy = 'Oldest';
    }

    vm.getAlerts();

  };

  vm.openAppointmentDetail = function(alert) {
    if (!alert.payload.appointment_time) {
      return;
    }
    var appointmentStart = moment(moment.unix(alert.payload.appointment_time)).format("YYYYMMDD");
    // Init start week
    var appointmentStartWeek = moment(DataTimeService.initWeekDate(appointmentStart, 'YYYYMMDD', true, 'Week'), 'YYYYMMDD').format('YYYYMMDD');
    // Init end week
    var appointmentEndWeek = moment(DataTimeService.initWeekDate(appointmentStart, 'YYYYMMDD', false, 'Week'), 'YYYYMMDD').format('YYYYMMDD');

    if (vm.isAssistant) {
      $state.go('main.groupingCalendar.appointments', {'doctorId': $rootScope.currentDoctor.id, 'practiceLocationId': $rootScope.currentDoctor.practiceLocationId, 'practiceLocationName': $rootScope.currentDoctor.practiceLocationName, 'appointment_time': alert.payload.appointment_time, 'start': appointmentStartWeek, 'end': appointmentEndWeek, 'on': 'Week'});
    } else {
      $state.go('main.calendar.appointments', {'appointment_time': alert.payload.appointment_time, 'start': appointmentStartWeek, 'end': appointmentEndWeek, 'on': 'Week'});
    }
  };

  $scope.$on('newNotification', function(event, data) {

    console.log(JSON.parse(data));
    data = JSON.parse(data);

    // Add new notification
    if ((vm.currentLocation === data.payload.location || vm.currentLocation === 'All locations') && vm.currentPage === 1) {
      if (data.payload.appointment_status === 'Cancelled Appointment') {
        var alertIndex = AlertService.findAlertIndex(data.payload.appointment_time, vm.alerts);
        if (alertIndex >= 0) {
          vm.alerts[alertIndex].payload.cancelled = true;
        }
      }
      // Add new notification in the first list
      vm.alerts.unshift(data);
      vm.alertsLen = vm.alerts.length;

      if (vm.alertsLen) {
        vm.noAlerts = false;
      }

      // Remove the last notification in curren page
      if (vm.alertsLen > vm.notificationLimit) {
        vm.alerts.pop();
      }

      var alertId = [];

      alertId.push(data.id);

      // To set unread notification to read
      readAlerts(alertId);

    }

    if (!$scope.$$phase) {
      $scope.$digest();
    }

  });

};

AlertCtrl.$inject = [
  'profileData',
  'ALERTS_API_SERVICE',
  'ALERTS_API_ROUTE',
  '$scope',
  '$rootScope',
  'CachingQueryParams',
  '$stateParams',
  'AlertService',
  '$state',
  'DataTimeService',
  'moment'
];

module.exports = AlertCtrl;
