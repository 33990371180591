/* Define controller for contact module*/
'use strict';

var ContactCtrl = function ($scope, ContactService) {
  var vm = this;

  /**
   * Send contact from doctor
   * @param  {object} form name of form
   * @param  {object} data data to send
   */
  vm.sendContact = function (form, data) {

    if (form.$valid) {
      data.remove_location = $scope.isLocation;
      ContactService
        .sendContactUs(data)
        .then(function(res) {
          // Close modal when sent
          $scope.closeModal(form);

          // clear input values
          data.email = "";
          data.subject = "";
          data.message = "";
        });
      console.log($scope.isLocation);
    } else {
      form.$setPristine();
      form.$setSubmitted();
    }
  };
};

ContactCtrl.$inject = [
  '$scope',
  'contact.api.service'
];

module.exports = ContactCtrl;
