"use strict";

function ChatSessionCtrl(quickBloxService) {
  console.log(this.session);
}

var chatSessionComponent = function () {
  return {
    templateUrl: "chat/views/chatSession.html",
    controller: ChatSessionCtrl,
    bindings: {
      session: "=",
    },
  };
};

ChatSessionCtrl.$inject = ["quickBloxService"];

module.exports = chatSessionComponent();
