"use strict";
var _ = require("lodash");

function ChatSessionsCtrl(
  quickBloxService,
  chatService,
  twilioChatService,
  moment,
  $rootScope,
  $scope,
  $stateParams
) {
  /**
   * Controller configuration
   */
  var ctrl = this;
  ctrl.isOnGoing = true;
  ctrl.isUpcoming = false;
  ctrl.isHistory = false;
  ctrl.sessions = [];
  ctrl.currentPage = 1;
  ctrl.itemsPerPage = 10;
  ctrl.loadingSessions = false;
  ctrl.selectedChat = null;
  ctrl.searchTerm = "";
  ctrl.selectedTokenId = null;
  ctrl.sessionId = null;
  ctrl.unreadCounts = 0;
  ctrl.unreadSessionIds = []

  var selectedSessionId = 0; //No Id selected
  var VERY_LARGE_TIMESTAMP_FOR_TOP_SORTING = 999999999999999;

  if ($stateParams.future && $stateParams.future === "true") {
    ctrl.isUpcoming = true;
    ctrl.isOnGoing = false;
  }

  /**
   * Initialize
   */
  function init() {
    loadSessions();
    subscribteToQuickBlox();
    listenForNewChatMessages();
  }

  ctrl.searchPatient = _.debounce(function () {
    console.log(ctrl.searchTerm);
    ctrl.currentPage = 1;
    ctrl.pageHasChanged();
  }, 500);

  /**
   * On chat session clicked
   */
  ctrl.onSelectSession = function (chatSession) {
    if (chatSession == null) {
      ctrl.onSelectSessionCallback(null);
      return;
    }
    ctrl.sessionId = chatSession.id;
    ctrl.selectedDialogId = chatSession.qb_id;
    ctrl.selectedDialogTwilioId = chatSession.twilio_sid;

    if(ctrl.selectedTokenId != chatSession.token_id){
      ctrl.selectedTokenId = chatSession.token_id;
      ctrl.selectedToken = $rootScope.twilio_tokens.find(element => element.id == chatSession.token_id) 
      twilioChatService.init(ctrl.selectedToken.token).then((value) => {
        if(value){
          runSession(chatSession);
        }
      }, reason => {
        alert(reason);
      });
    }else{
      runSession(chatSession);
    }
  };

  var runSession = function (chatSession){
    ctrl.onSelectSessionCallback({
      dialogId: ctrl.selectedDialogId,
      twilioDialogId: ctrl.selectedDialogTwilioId,
    });

    $rootScope.$broadcast(
      "totalChatNotificationChanged",
      chatSession.unread_messages_count
    );
    chatSession.unread_messages_count = 0;

    ctrl.sessions = ctrl.sessions.map(function (session) {
      if (session.id == selectedSessionId) {
        session.isSelected = false;
      }
      return session;
    });

    selectedSessionId = chatSession.id;
    chatSession.isSelected = true;
    chatSession.read = true;

    // update unread count manually
    if(ctrl.unreadSessionIds.includes(chatSession.id)){
      ctrl.unreadCounts = ctrl.unreadCounts - 1;
      var unreadIndex = ctrl.unreadSessionIds.indexOf(chatSession.id);
      ctrl.unreadSessionIds.splice(unreadIndex, 1);
    }
  };

  /**
   * Load sessions page by page
   */
  var loadSessions = function () {
    var indexType = "isOnGoing";
    if (ctrl.isUpcoming) indexType = "isUpcoming";
    if (ctrl.isHistory) indexType = "isHistory";
    chatService.getChatSessions(
      onLoadSessionsSuccess,
      onLoadSessionsError,
      indexType,
      ctrl.currentPage,
      ctrl.itemsPerPage,
      ctrl.searchTerm
    );
  };

  /**
   * Load single chat session
   * @param {*} sessions
   * @param {*} isOngoing
   */
  var loadSingleSession = function () {};

  /**
   * Success callback on retreiving chat sessions
   * @param {*} sessions
   * @param {*} isOngoing
   */
  var onLoadSessionsSuccess = function (sessions, indexType) {
    ctrl.loadingSessions = false;
    if (indexType != "isOnGoing" && ctrl.isOnGoing) return;
    if (indexType != "isUpcoming" && ctrl.isUpcoming) return;
    if (indexType != "isHistory" && ctrl.isHistory) return;

    ctrl.totalItems = sessions.metadata.total_pages * ctrl.itemsPerPage; // this number is not accurate, we are just using it to show pages.
    ctrl.unreadCounts = sessions.metadata.unread_count
    
    sessions.chat_sessions.forEach(function (chatSession) {
      var duration = new moment.unix(chatSession.start_at).diff(
        new moment(),
        "minutes"
      );
      var durationMsg = "Starts in " + duration + " mins";
      if (duration > 60) {
        durationMsg =
          "Starts in " +
          new moment(chatSession.start_at * 1000).diff(new moment(), "hours") +
          " hours ";
        if (duration % 60 !== 0) {
          durationMsg += (duration % 60) + " minutes";
        }
      }

      var isInBuffer = new moment().isBetween(
        new moment(chatSession.start_at * 1000).subtract(
          chatSession.chat_buffer
        ),
        new moment(chatSession.start_at * 1000)
      );

      var canChat = isInBuffer || chatSession.start_at < new moment().unix();

      if(!chatSession.read_by_doctor && chatSession.read_by_user){
        ctrl.unreadSessionIds.push(chatSession.id);
      }

      ctrl.sessions.push({
        id: chatSession.id,
        created_at_miliseconds: chatSession.updated_at,
        created_at: new moment(chatSession.created_at * 1000).format(
          "DD MMM YYYY"
        ),
        start_at: new moment(chatSession.start_at * 1000).format("DD MMM YYYY"),
        is_in_buffer: isInBuffer,
        can_chat: canChat,
        start_duration: durationMsg,
        patient_name: chatSession.payment.payer.fullname,
        video_ended:
          chatSession.video_session &&
          new moment().unix() > chatSession.video_session.end_at,
        affected_parts: chatService.getPatientAffectedBodyParts(
          chatSession.body_part
        ),
        patient_initials: chatSession.anonymous
          ? chatSession.payment.payer.fullname
          : chatService.getPatientInitials(chatSession.payment.payer.fullname),
        is_my_patient: false,
        qb_id: chatSession.qb_id,
        twilio_sid: chatSession.twilio_sid,
        anonymous: chatSession.anonymous,
        video_session: chatSession.video_session,
        shouldLoad: $stateParams.chatId === chatSession.qb_id,
        read: chatSession.read_by_doctor,
        token_id: chatSession.token_id
      });
    });

    loadChatDialogsFromQuickBlox(
      ctrl.sessions.map(function (chatSession) {
        return chatSession.qb_id;
      })
    );
  };

  /**
   * Error callback on retreiving chat sessions
   * @param {*} sessions
   * @param {*} isOngoing
   */
  var onLoadSessionsError = function (error) {
    console.log("Error happened while loading the sessions");
    console.log(error);
    ctrl.loadingSessions = false;
  };

  /**
   * Listen for incoming messages through websocket
   */
  var listenForNewChatMessages = function () {
    $rootScope.$on("newNotification", function (event, incomingMessage) {
      if (incomingMessage.qb_id == null) return;

      chatService.getChatSession(
        incomingMessage.qb_id,
        function (response) {
          var session = response.chat_session;
          if (
            ctrl.sessions.findIndex(function (chatSession) {
              return chatSession.id == session.id;
            }) != -1
          ) {
            return;
          }

          ctrl.sessions.unshift({
            id: session.id,
            created_at_miliseconds: VERY_LARGE_TIMESTAMP_FOR_TOP_SORTING,
            created_at: new moment(session.created_at * 1000).format(
              "DD MMM YYYY"
            ),
            patient_name: session.payment.payer.fullname,
            affected_parts: chatService.getPatientAffectedBodyParts(
              session.body_part
            ),
            patient_initials: session.anonymous
              ? session.payment.payer.fullname
              : chatService.getPatientInitials(session.payment.payer.fullname),
            is_my_patient: false,
            qb_id: session.qb_id,
            anonymous: session.anonymous,
          });

          loadChatDialogsFromQuickBlox([session.qb_id]);
        },
        function (error) {}
      );
    });
  };

  /**
   * Load dialogs from quickblox to listen for updates from QB
   */
  var loadChatDialogsFromQuickBlox = function (dialogIds) {
    var onQbDialogsLoadSuccess = function (dialogs) {
      dialogs.items.forEach(function (qbDialog) {
        updateChatSession(qbDialog);
        sortSessions();
      });
    };

    var onQbDialogsLoadFailure = function (error) {
      console.log("An error happened while fetching dialogs from QB: ", error);
    };

    console.log("Test 4", dialogIds);

    quickBloxService.joinQuickBloxDialogs(dialogIds);
    quickBloxService.getDialogs(
      dialogIds,
      onQbDialogsLoadSuccess,
      onQbDialogsLoadFailure
    );
    if (ctrl.selectedChat) return;
    ctrl.sessions.forEach(function (session) {
      if (session.shouldLoad) {
        ctrl.onSelectSession(session);
        ctrl.selectedChat = true;
      }
    });
  };

  /**
   * Update received qb dialog
   * @param {*} qbDialog
   */
  var updateChatSession = function (qbDialog) {
    var correspondingSession = ctrl.sessions.find(function (chatSession) {
      return chatSession.qb_id == qbDialog._id;
    });
    if (!correspondingSession) return; // Return if we're showing chat session from different page.
    if (qbDialog.last_message != null) {
      correspondingSession.last_msg = qbDialog.last_message;
    } else if (
      qbDialog.last_message == null &&
      qbDialog.last_message_date_sent
    ) {
      correspondingSession.last_msg = "Attachment";
    } else {
      correspondingSession.last_msg = "No message yet";
    }

    correspondingSession.last_msg_id = qbDialog.last_message_id;
    correspondingSession.unread_messages_count = qbDialog.unread_messages_count;
    quickBloxService.isChatMessageRead(
      correspondingSession,
      qbDialog._id,
      qbDialog.last_message_id,
      function (isRead) {
        console.log(isRead);
      }
    );

    var indexOfSessionToUpdate = ctrl.sessions.findIndex(function (
      chatSession
    ) {
      return chatSession.id == correspondingSession.id;
    });
    ctrl.sessions[indexOfSessionToUpdate] = correspondingSession;
  };

  /**
   * Toggle loading modes
   */
  ctrl.onGoingTabSelected = function () {
    console.log("on going tab selected");
    reset();
    ctrl.isOnGoing = true;
    ctrl.isUpcoming = false;
    ctrl.isHistory = false;
    loadSessions();
  };

  ctrl.onUpcomingTabSelected = function () {
    console.log("on going tab selected");

    reset();
    ctrl.isOnGoing = false;
    ctrl.isUpcoming = true;
    ctrl.isHistory = false;
    loadSessions();
  };

  ctrl.historyTabSelected = function () {
    console.log("history tab ");

    reset();
    ctrl.isOnGoing = false;
    ctrl.isUpcoming = false;
    ctrl.isHistory = true;
    loadSessions();
  };

  /**
   * Reset loaded chat sessions
   */
  var reset = function () {
    ctrl.sessions = [];
    ctrl.currentPage = 1;
    selectedSessionId = 0;
    ctrl.sessionId = null;
    ctrl.onSelectSessionCallback(null);
  };

  /**
   * Subscribe to chat messages via quickblox
   */
  var subscribteToQuickBlox = function () {
    quickBloxService.subscribeToMessageListener(function onNext(event) {
      onMessageReceived(event.userId, event.message);
    });
  };

  var onMessageReceived = function (userId, msg) {
    var correspondingSession = ctrl.sessions.find(function (chatSession) {
      return chatSession.qb_id == msg.dialog_id;
    });

    if (!correspondingSession) return; // Return if we're showing chat session from different page.
    if (correspondingSession.last_msg_id !== msg.id) {
      correspondingSession.last_msg =
        msg.body == null ? "attachment" : msg.body;
      correspondingSession.last_msg_id = msg.id;
      correspondingSession.created_at_miliseconds = parseInt(
        msg.extension.date_sent
      );
      if (!correspondingSession.isSelected)
        correspondingSession.unread_messages_count++;
      quickBloxService.isChatMessageRead(
        correspondingSession,
        msg.dialog_id,
        msg.id,
        function (isRead) {
          console.log(isRead);
        }
      );

      sortSessions();
    }
  };

  /**
   * Sort chat sessions
   */
  var sortSessions = function () {
    ctrl.sessions.sort(function (a, b) {
      return b.created_at_miliseconds - a.created_at_miliseconds;
    });
    console.log(ctrl.sessions);
    $scope.$apply();
  };

  /**
   * Load different page
   */
  ctrl.pageHasChanged = function () {
    console.log(ctrl.currentPage);
    ctrl.sessions = [];
    loadSessions();
  };

  init();
}

var chatSessionsComponent = function () {
  return {
    transclude: true,
    templateUrl: "chat/views/chatSessions.html",
    controller: ChatSessionsCtrl,
    bindings: {
      onSelectSessionCallback: "&",
    },
  };
};

ChatSessionsCtrl.$inject = [
  "quickBloxService",
  "chatService",
  "twilioChatService",
  "moment",
  "$rootScope",
  "$scope",
  "$stateParams",
];

module.exports = chatSessionsComponent();
