'use strict';

var _ = require('lodash');

var locationOperatingDirective = function(moment, OperatingHour) {
  return {
    restrict: 'E',
    templateUrl: 'profile/views/information/location-operating.html',
    scope: {
      operating: '@operatingData',
      operatingHours: '=operatingHours'
    },
    link: function(scope, elem, attrs) {
      // convert string to json
      scope.operatingData = JSON.parse(scope.operating);

      if(scope.operatingData.open_time) {
        var currentDate = moment().format("YYYY/MM/DD");
        scope.operatingData.dataHourStart = moment(currentDate + ' ' + scope.operatingData.open_time, "YYYY/MM/DD HH:mm").format('hh:mm A');
        scope.operatingData.dataHourEnd = moment(currentDate + ' ' + scope.operatingData.close_time, "YYYY/MM/DD HH:mm").format('hh:mm A');
      }

      scope.breakTimeData = _.filter(scope.operatingHours, function(operating) { 
        return (operating.day_of_week === 'lunch_break' || operating.day_of_week === 'dinner_break') && operating.open_time; 
      });
    }
  };
};

locationOperatingDirective.$inject = [
  'moment',
  'OperatingHour'
];

module.exports = locationOperatingDirective;