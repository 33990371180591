'use strict';

var _ = require('lodash');

var QrCodeApptScanConfirmModal = function ($modalInstance, $modalStack) {
  var vm = this;

  vm.close = function () {
    $modalStack.dismissAll();
  }

  vm.backToScan = function () {
    $modalInstance.dismiss();
  }
};

QrCodeApptScanConfirmModal.$inject = [
  '$modalInstance',
  '$modalStack',
];

module.exports = QrCodeApptScanConfirmModal;
