'use strict';

var moment = (typeof window !== "undefined" ? window['moment'] : typeof global !== "undefined" ? global['moment'] : null);
var _ = require('lodash');
require('moment-timezone');

var TimeZoneService = function(APP_CONSTANT) {
  return {

    timeZone: APP_CONSTANT.timezoneDefault,

    setTimezone: function(profiles) {
      this.timeZone = profiles && profiles.timezone ? profiles.timezone.identifier : APP_CONSTANT.timezoneDefault;
    },

    defaultTimeslot: function(date) {
      return moment.tz(date + " 8:00 am", 'YYYY-MM-DD H:mm a', this.timeZone).diff(0);
    },

    currentTime: function() {
      var currentTime = new Date().valueOf();
      currentTime = moment.tz(currentTime, this.timeZone).format("YYYY-MM-DD HH:mm:ss");
      currentTime = moment.tz(currentTime, this.timeZone).valueOf();
      return currentTime;
    },

    timeInFuture: function(startTime, currentTime) {
      return moment.tz(startTime * 1000, this.timeZone).diff(currentTime) > 0;
    },

    timeToTimeStamp: function(date, time) {
      return moment.tz(date + " " + time, "YYYY-MM-DD h:mm a", this.timeZone).valueOf();
    },

    // Convert a timestamp to correct timezone
    convertTimestamp: function(timeStamp) {
      return moment.tz(timeStamp, this.timeZone);
    },

    renderCalendar: function(timeStamp) {
      // return moment.tz(moment.unix(timeStamp).format(), this.timeZone).format();
      return this.timestampToTime(timeStamp);
    },

    timestampToTime: function(timeStamp, format) {
      var timeFormat = format || null;
      return moment.tz(moment.unix(timeStamp).format(), this.timeZone).format(timeFormat);
    },

    defaultStartDate: function(date) {
      return moment.tz(moment(moment(date).format()).format('YYYY-MM-DD HH:mm'), this.timeZone).format();
    },

    unixTimeStamp: function(time) {
      return moment(time).valueOf()/1000;
    },

    appointmentHourFormat: function(date, time) {
      var timeStamp = this.timeToTimeStamp(date, time);
      var hourFormat = moment.utc(timeStamp).format('H:mm');
      return  hourFormat;
    },

    reminderTimeStamp: function(time) {
      var reminderTime = moment(time).format('YYYY/MM/DD');
      reminderTime = moment.tz(reminderTime + ' ' + '08:00:00 AM', 'YYYY/MM/DD h:mm:ss a', this.timeZone).format();
      reminderTime = moment.tz(reminderTime, this.timeZone).valueOf();
      return reminderTime;
    },

    calendarSelectedTime: function(time) {
      var endHours = moment(time).format("HH:mm"),
          endDate = moment(time).format("YYYY-MM-DD"),
          endTime = moment.tz(endDate + ' ' + endHours, this.timeZone).format('YYYY-MM-DD HH:mm:ss');
      endTime = moment.tz(endTime, this.timeZone).valueOf();
      return endTime;
    },

    availabilityTimeFormat: function(timeStamp) {
      return this.timestampToTime(timeStamp, 'YYYY-MM-DD HH:mm');
    },

    appointmentDateFormat: function(date, time) {
      var timeStamp = this.timeToTimeStamp(date, time);
      var DateFormat = moment.utc(timeStamp).format('YYYYMMDD');
      return  DateFormat;
    },

    dateToTimestamp: function(date) {
      return moment.tz(date, "YYYYMMDD", this.timeZone).valueOf();
    },

    timeoffFormat: function(date, time) {
      return moment.tz(date + " " + time, this.timeZone).format();
    },

    utcTimestamp: function(date, time) {
      var timestamp = moment.tz(date + " " + time, "YYYY/MM/DD h:mm a", this.timeZone).valueOf();
      return timestamp/1000;
    },

    timeToTimezoneFormat: function(dateTime, format) {
      var timeFormat = format || null;
      return moment.tz(dateTime, this.timeZone).format(timeFormat);
    },

    noneHyphenDateFormat: function(dateTime) {
      return this.timeToTimezoneFormat(dateTime, 'YYYYMMDD');
    },

    hyphenDateFormat: function(timeStamp) {
      return this.timestampToTime(timeStamp, 'YYYY-MM-DD');
    },
    
    timeStampToNoneHyphenDate: function(timeStamp) {
      return this.timestampToTime(timeStamp, 'YYYYMMDD');
    },

    endOfADayTimestamp: function(date) {
      date = moment(date, 'YYYYMMDD').format('YYYY/MM/DD');
      var endDate = moment.tz(date + ' ' + '23:59:59', 'YYYY/MM/DD HH:mm:ss', this.timeZone).format();
      endDate = moment.tz(endDate, this.timeZone).valueOf();
      return endDate;
    },

    alertTimeFormat: function(timeStamp) {
      return this.timestampToTime(timeStamp, 'D MMM,  h:mm A'); 
    },

    alertCreateAtFormat: function(dateTime) {
      return this.timeToTimezoneFormat(dateTime, 'D MMM,  h:mm A');
    },

    dateToTimezoneDate: function(date, oldFormat, newFormat) {
      return moment.tz(date, oldFormat, this.timeZone).format(newFormat);
    }
  };
};

TimeZoneService.$inject = [
  'APP_CONSTANT'
];

module.exports = TimeZoneService;
