"use strict";

function EbDashboardCtrl($rootScope) {
  var ctrl = this;

  console.log("here is the employee benefits");
}

var ebDashboardComponent = function () {
  return {
    templateUrl: 'employee-benefits/views/dashboard.html',
    controller: EbDashboardCtrl
  };
};

EbDashboardCtrl.$inject = ['$rootScope'];

module.exports = ebDashboardComponent();
