'use strict';

var service = angular
  .module('Auth')
  .factory("Auth.ModalsService", [
    '$modal',

    function ($modal) {

      return {
        openModalForgotPW: function (isAssistant) {
          
          $modal.open({

            animation: true,
            resolve: {
              sendData: function () {
                return {
                  isAssistant: isAssistant,
                };
              }
            },
            templateUrl: 'auth/views/forgot-password.html',
            controller: [
              '$scope',
              '$modalInstance',
              'Auth.AuthService',
              'sendData',

              function ($scope, $modalInstance, AuthService, sendData) {

                var vm = this;
                var isAssistant = sendData.isAssistant;

                ///////////////////////////////////////////////////////////////////
                // private function

                function closeModal() {
                  $modalInstance.dismiss('cancel');
                }

                function getPassword() {

                  $scope.forgotPasswordForm.$setSubmitted();

                  vm.isGetting = true;

                  AuthService
                    .getPassword(vm.email, isAssistant)
                    .then(

                      function () {
                        alert("Email has been sent out successfully.");
                        closeModal();
                      },

                      function () {
                        $scope.forgotPasswordForm.email.$setValidity('emailInvalid', false);
                      }

                    )['finally'](function () {
                      vm.isGetting = false;
                    });

                }

                ///////////////////////////////////////////////////////////////////
                // public function

                vm.getPassword = getPassword;

                vm.closeModal = closeModal;

              }
            ],
            controllerAs: 'forgotPWCtrl',
            windowClass: 'modal fade modal-incorrect forgot-password',
            backdropClass: 'modal-backdrop'

          });

        }

      };

    }

  ]);

module.exports = service;
