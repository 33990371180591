'use strict';

function ReviewController(moment) {
  var ctrl = this;
  ctrl.min = 0.0;
  ctrl.max = 5.0;
  ctrl.step = 1.0;
  ctrl.readOnly = true;

  ctrl.reviewDate = moment(ctrl.review.created_at * 1000).format('DD MMM YYYY');
  console.log(ctrl.review);
};

var reviewComponent = function () {
  return {
    templateUrl: 'dashboard/views/review.html',
    controller: ReviewController,
    bindings: {
      'review': '='
    }
  };
};

ReviewController.$inject = ['moment'];

module.exports = reviewComponent();
