
'use strict';

var practiceLocationFilter = function() {

  function escapeRegexp(queryToEscape) {
    return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  };

  return function (result, searchKey) {
    if (searchKey) {
      return result.replace(new RegExp(escapeRegexp(searchKey), 'gi'), '<strong class="is-highlight">$&</strong>');
    }
  };
};

practiceLocationFilter.$inject = [

];

module.exports = practiceLocationFilter;
