'use strict';

var auditsAPI = function (Restangular, AUDITS_API_ROUTE) {

  return {

    getAudits: function (location, page) {
      return Restangular.all(AUDITS_API_ROUTE.audits).customGET('', {
        location_id: location,
        page: page
      });
    }
  };
};

auditsAPI.$inject = [
  'Restangular',
  'AUDITS_API_ROUTE'
];

module.exports = auditsAPI;
