'use strict';

var profilePhotoStates = function ($stateProvider) {
  $stateProvider
    .state('main.profile.photo', {
      url: 'photos/',
      // sticky: true,
      views: {
        'photo': {
          templateUrl: 'profile/views/photo/photo.html',
          controller: 'PhotoCtrl',
          controllerAs: 'photo'
        }
      }
    })

    // Define state for grouping photo
    .state('main.groupingProfile.photo', {
      url: '/photos/',
      views: {
        'photo': {
          templateUrl: 'profile/views/photo/photo.html',
          controller: 'PhotoCtrl',
          controllerAs: 'photo'
        }
      }
    });
};

profilePhotoStates.$inject = ['$stateProvider'];

module.exports = profilePhotoStates;

