'use strict';

module.exports =
  angular
    .module('bookdoc.eb.components', [
      require('../../../../tmp/templates').name,
      require('../../common').name
    ])
    .component('ebDashboard', require('./dashboard.component'))
    .component('ebPreRegistration', require('./pre-registrations.component'))
    .component('ebClaims', require('./claims.component'))
    .component('ebPreRegistrationItem', require('./pre-registration-item.component'))
    .component('openClaims', require('./open-claims.component'))
    .component('submittedClaims', require('./submitted-claims.component'))
    .component('openClaimItem', require('./open-claims-item.component'))
    .component('submittedClaimMedicationItem', require('./submitted-claim-medication-item.component'))
    .component('ebPersonalInfo', require('./ebPersonalInfo.component'))
    .component('ebServiceFormItem', require('./service-form-item.component'))
    .component('medicationFormItem', require('./medication-form-item.component'))
    .component('submittedClaimServiceItem', require('./submitted-claim-service-item.component'))
    .component('diagnosisFormItem', require('./diagnose-form-item.component'));
